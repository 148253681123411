import Formula from '../Formula';
import {
  FORMULA_TYPE__LITERAL,
} from '../../../constants';

class FormulaLiteral extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (this.settings.value === undefined || this.settings.value === null) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate() {
    return {
      value: this.settings.value,
    };
  }

  toMongoExpression() {
    return {
      $literal: this.settings.value,
    };
  }

  static withValue(value) {
    return new this({
      type: FORMULA_TYPE__LITERAL,
      settings: {
        value,
      },
    });
  }
}

Formula.types[FORMULA_TYPE__LITERAL] = FormulaLiteral;

export default FormulaLiteral;
