import React from 'react';
import {
  useTranslation,
} from 'react-i18next';
import Stack from '../../common/components/primitives/Stack';
import VariablesFilters from './components/VariablesFilters';
import VariablesTable from './components/VariablesTable';
import useDocumentTitle from '../../utils/useDocumentTitle';
import ConnectedEditVariableDialog from './components/ConnectedEditVariableDialog';

const SettingsVariables = () => {
  const {
    t,
  } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('variable', {
      count: 0,
    }),
  ]);

  return (
    <>
      <ConnectedEditVariableDialog />
      <Stack>
        <VariablesFilters />
        <VariablesTable />
      </Stack>
    </>
  );
};

export default SettingsVariables;
