import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import every from 'lodash/every';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import map from 'lodash/map';
import moment from 'moment';
import {
  PARTICIPANT_STATE__PAUSED,
  PARTICIPANT_STATE__IN_PROGRESS,
  ROLE_TAG__DOCTOR,
  ROLE_TAG__CASE_MANAGER,
  QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR,
  QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER,
  QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT,
  ANSWER_VALUE_MAX_LENGTH,
  ANSWERS_SHEET_TYPE__CONSENT,
  ANSWERS_SHEET_TYPE__RESPONSE,
  ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR,
} from '../../constants';
import createMerge from '../../utils/createMerge';
import {
  getStorageKey,
  setStorageKey,
  delStorageKey,
} from '../../utilsClient/redux/storage';
import {
  getIdentifier,
} from '../../utils/versions';
import {
  getKey,
  toFormValues,
  filterResponses,
} from '../../utils/responses';
import {
  fromBindingsArray,
} from '../../utils/formValues';
import SignedNote from '../SignedNote';
import BaseModel from '../BaseModel';

/**
 * Represents an AnswersSheet.
 * @class
 */
class AnswersSheet extends BaseModel {
  constructor(doc) {
    super(doc);

    this.responses = this.responses || [];
    this.signedNotes = (this.signedNotes || []).map(
      note => new SignedNote(note),
    );
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getRecipientId() {
    return this.recipientId;
  }

  getProjectId() {
    return this.projectId;
  }

  getParticipationId() {
    return this.participationId;
  }

  getMilestoneId() {
    return this.milestoneId;
  }

  getActivityId() {
    return this.activityId;
  }

  getEncounterId() {
    return this.encounterId;
  }

  getAssigneeId() {
    return this.assigneeId;
  }

  getAssigneeType() {
    return this.assigneeType;
  }

  getNavigationType() {
    return this.navigationType || ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR;
  }

  getAssigneeRoleTag() {
    return this.constructor.mapAssigneeTypeToRoleTag(this.assigneeType);
  }

  getState() {
    return this.state;
  }

  canBeCompletedByPatient() {
    return this.isAssignedToPatient() || this.delegatedToPatient;
  }

  canBeCompletedByUser(user, domain) {
    if (!user) {
      return false;
    }
    const tag = this.getAssigneeRoleTag();
    if (!tag) {
      return false;
    }
    return user.hasRoleTag(tag, {
      relativeTo: domain,
    });
  }

  isAssignedToPatient() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__PATIENT;
  }

  // TODO: This method is never used. We should probably remove it.
  isAssignedToStaff() {
    return this.isAssignedToDoctor() || this.isAssignedToCaseManager();
  }

  isAssignedToDoctor() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR;
  }

  isAssignedToCaseManager() {
    return this.assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER;
  }

  hasAssignee() {
    return !!this.assigneeId;
  }

  isInProgress() {
    return this.state === PARTICIPANT_STATE__IN_PROGRESS;
  }

  isDuplicate() {
    return !!this.duplicate;
  }

  isOriginal() {
    return !this.originalId;
  }

  isCompleted() {
    return !!this.completed;
  }

  isAmended() {
    return !!this.amended;
  }

  isCanceled() {
    return !!this.canceled;
  }

  isDischarged() {
    return !!this.discharged;
  }

  isSuspended() {
    return !!this.suspended;
  }

  isRecruited() {
    return !!this.recruited;
  }

  getProcessingPriority() {
    return (this.system && this.system.priority) || 0;
  }

  isBlacklisted() {
    return !!this.blacklisted;
  }

  isStarted() {
    return !!this.started;
  }

  isStartedAndPaused() {
    return this.isStarted() && this.state === PARTICIPANT_STATE__PAUSED;
  }

  isPaused() {
    return this.state === PARTICIPANT_STATE__PAUSED;
  }

  isConsent() {
    return this.type === ANSWERS_SHEET_TYPE__CONSENT;
  }

  isResponse() {
    return !this.type || this.type === ANSWERS_SHEET_TYPE__RESPONSE;
  }

  getLastVersion() {
    return this.lastSavedVersion || 0;
  }

  getLastSavedVersionId() {
    return this.lastSavedVersionId;
  }

  getNextVersion() {
    return this.getLastVersion() + 1;
  }

  shouldSendNotification() {
    return (
      this.isResponse() &&
      this.isOriginal() &&
      this.canBeCompletedByPatient() &&
      !this.isCompleted() &&
      !this.isSuspended() &&
      !this.isCanceled()
    );
  }

  getSnoozeEnd(userId = this.assigneeId) {
    if (!this.snoozeEnd || !userId) {
      return undefined;
    }
    return this.snoozeEnd[userId];
  }

  hasErrors() {
    return some(
      this.responses,
      response => response.errors && response.errors.length > 0,
    );
  }

  canOverwriteFields() {
    return !this.isStarted() && !this.isCompleted() && !this.isBlacklisted();
  }

  formatPausedAt() {
    if (!this.pausedAt) return '';
    return moment(this.pausedAt).fromNow();
  }

  getSignedNotes() {
    return this.signedNotes;
  }

  getNotes() {
    return this.freeNotes || '';
  }

  getQuestionnaireId() {
    return this.questionnaireId;
  }

  getQuestionnaireName() {
    this.constructor.logger.warn(
      'AnswersSheet.getQuestionnaireName() is deprecated',
    );
    return this.questionnaireId;
  }

  /**
   * Returns a simplified version of responses array,
   * only containing the most relevant keys.
   * @returns {Array}
   */
  getSimpleResponses() {
    return map(
      this.responses,
      ({
        questionId,
        answer,
        hierarchyKey,
        whyEmpty,
      }) => ({
        questionId,
        answer,
        hierarchyKey,
        whyEmpty,
      }),
    );
  }

  secondsSinceCompletion() {
    if (!this.isCompleted() || !this.completedAt) {
      return NaN;
    }
    return moment().diff(this.completedAt, 'seconds');
  }

  describeQuestionnaire(questionnaireName) {
    let description;
    if (questionnaireName) {
      description = questionnaireName;
    } else {
      description = getIdentifier(this.getQuestionnaireId());
    }
    const navigationType = this.getNavigationType();
    if (navigationType !== ANSWERS_SHEET_NAVIGATION_TYPE__CURSOR) {
      description = `${description} {${navigationType}}`;
    }
    return description;
  }

  describeAssignee(userName) {
    if (!this.assigneeId && this.isAssignedToPatient()) {
      return '(patient questionnaire)';
    }
    let baseName;
    if (!this.assigneeId) {
      baseName = '[Not assigned]';
    } else {
      baseName = userName || `[userId: ${this.assigneeId}]`;
    }
    if (this.delegatedToPatient) {
      return `${baseName} (patient questionnaire)`;
    }
    return baseName;
  }

  formatCreatedAt() {
    return !this.createdAt ? '' : moment(this.createdAt).format('MMMM Do YYYY');
  }

  mergeResponses(...args) {
    return this.constructor.mergeResponses(this.responses, ...args);
  }

  createCopyWithMergedResponses(...args) {
    return new AnswersSheet({
      ...this,
      responses: this.mergeResponses(...args),
    });
  }

  createCopyWithResponsesMergedTo(oldResponses) {
    return new AnswersSheet({
      ...this,
      responses: this.constructor.mergeResponses(oldResponses, this.responses),
    });
  }

  createCopyWithReplacedResponses(responses = []) {
    return new AnswersSheet({
      ...this,
      responses,
    });
  }

  toFormValues(options) {
    return toFormValues(this.responses, options);
  }

  /**
   * Return variables object that can be later passed
   * to EvaluationScope constructor.
   * @returns {Object}
   */
  getEvaluationScopeVariables() {
    return fromBindingsArray(this.variables);
  }

  static mapAssigneeTypeToRoleTag(assigneeType) {
    if (assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__DOCTOR) {
      return ROLE_TAG__DOCTOR;
    }
    if (assigneeType === QUESTIONNAIRE_ASSIGNEE_TYPE__CASE_MANAGER) {
      return ROLE_TAG__CASE_MANAGER;
    }
    return null;
  }

  static filterAnswers(answers) {
    return filter(
      answers,
      answer => answer && answer.key && answer.questionId,
    );
  }

  static selectRecruited(selector = {}) {
    return {
      recruited: true,
      ...selector,
    };
  }

  static responseMeetsConstraints(response) {
    if (!response.answer) {
      return true;
    }
    const validate = (value) => {
      if (typeof value === 'string') {
        return value.length <= ANSWER_VALUE_MAX_LENGTH;
      }
      if (isArray(value)) {
        return every(value, validate);
      }
      // TODO: I am not sure what we should do in case `value` is an object.
      return true;
    };
    return validate(response.answer.value);
  }

  static isEquivalent(r1, r2) {
    return isEqual(
      pick(r1, 'questionId', 'answer', 'whyEmpty', 'hierarchyKey'),
      pick(r2, 'questionId', 'answer', 'whyEmpty', 'hierarchyKey'),
    );
  }

  static areResponsesEquivalent(responses1, responses2) {
    if (!responses1 && !responses2) {
      return true;
    }
    const l1 = responses1 && responses1.length;
    const l2 = responses2 && responses2.length;
    if (l1 !== l2) {
      return false;
    }
    return every(responses1, (r1, i) => this.isEquivalent(r1, responses2[i]));
  }

  static saveResponsesToStorage(answersSheetId, responses) {
    return setStorageKey(
      `entities.AnswersSheets.${answersSheetId}.responses`,
      responses,
    );
  }

  static updateResponsesInStorage(answersSheetId, responses) {
    return (dispatch, getState) => {
      const state = getState();
      const rawDataFromStorage = getStorageKey(
        'entities',
        'AnswersSheets',
        answersSheetId,
      )(state);
      return dispatch(
        setStorageKey(
          `entities.AnswersSheets.${answersSheetId}.responses`,
          AnswersSheet.mergeResponses(
            rawDataFromStorage && rawDataFromStorage.responses,
            responses,
          ),
        ),
      );
    };
  }

  static removeFromStorage(answersSheetId, value) {
    return delStorageKey(`entities.AnswersSheets.${answersSheetId}`, value);
  }
}

AnswersSheet.getResponseKey = getKey;

/**
 * Merge new responses with the existing ones. Responses are
 * identified by questionId and potentially by hierarchy.elementId (if it exists),
 * and the new ones always overwrite the old ones.
 * We try to keep the original elements ordering.
 * @param {Object[]} oldResponses
 * @param {Object[]} newResponses
 * @returns {Object[]} merged responses
 */
AnswersSheet.mergeResponses = createMerge({
  getKey,
  filter: filterResponses,
});

// NOTE: This is no longer necessary but keeping it for future reference.
// AnswersSheet.statusSummaryFields = [
//   'paused',
//   'started',
//   'canceled',
//   'suspended',
//   'recruited',
//   'completed',
//   'amended',
//   'locked',
//   'assigneeType',
// ];

AnswersSheet.collection = 'AnswersSheets';
AnswersSheet.scopeName = '@answersSheet';

export default AnswersSheet;
