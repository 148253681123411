import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  compose,
} from 'recompose';
import {
  createSelector,
} from 'reselect';
import {
  PROJECT_DASHBOARD_TYPE__ACTION_ITEMS,
  PROJECT_DASHBOARD_TYPE__GENERAL,
  PROJECT_DASHBOARD_TYPE__PATIENT,
  PROJECT_DASHBOARD_TYPE__CAT,
  PROJECT_DASHBOARD_TYPE__NLP,
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS,
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
  PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME,
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE,
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  PROJECT_DASHBOARD_TYPE__DETAILS,
  PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES,
  PROJECT_DASHBOARD_TYPE__EAPP,
} from '../../../../common/constants';
import Loading from '../../../../common/components/Loading';
import Stack from '../../../../common/components/primitives/Stack';
import Cluster from '../../../../common/components/primitives/Cluster';
import Breadcrumb from '../../../../common/components/base/Breadcrumb';
import Text from '../../../../common/components/base/Text';
import DashboardCAT from '../DashboardCAT';
import DashboardNLP from '../DashboardNLP';
import DashboardAnalytics from '../DashboardAnalytics';
import DashboardChartBuilder from '../DashboardChartBuilder';
import DashboardExternalIFrame from '../DashboardExternalIFrame';
import DashboardTimeline from '../DashboardTimeline';
import DashboardDocuments from '../DashboardDocuments';
import DashboardDetails from '../DashboardDetails';
import DashboardLatestResponses from '../DashboardLatestResponses';
import DashboardEApp from '../DashboardEApp';
import {
  selectProjectId,
  selectAllCustomViewsWithFullHierarchy,
  selectViewsTreeWithTitles,
  selectCurrentViewUrl,
  selectCurrentTreeNode,
} from '../../selectors';
import {
  switchAndDeleteTabAndSubTabs,
} from '../../actions';
import ActionItems from '../ActionItems';
import {
  apiZedocOneProject,
  patientDetails,
  patientActivityDetails,
  answersSheetDetails,
} from '../../../../common/api/zedoc';
import {
  forEachActive,
  getClosestViewUrl,
} from '../../viewsTree';

const Container = compose(
  ddp({
    subscriptions: createSelector(
      selectProjectId,
      selectAllCustomViewsWithFullHierarchy,
      (projectId, views) => {
        if (!projectId) {
          return [];
        }
        const subs = [
          apiZedocOneProject.withParams({
            projectId,
          }),
        ];
        forEach(views, (view) => {
          if (view && view.hierarchy) {
            if (view.hierarchy.recipientId) {
              subs.push(
                patientDetails.withParams({
                  recipientId: view.hierarchy.recipientId,
                  projectId,
                }),
              );
            }
            if (view.hierarchy.activityId) {
              subs.push(
                patientActivityDetails.withParams({
                  activityId: view.hierarchy.activityId,
                }),
              );
            }
            if (view.hierarchy.answersSheetId) {
              subs.push(
                answersSheetDetails.withParams({
                  answersSheetId: view.hierarchy.answersSheetId,
                }),
              );
            }
          }
        });
        return subs;
      },
    ),
    selectors: () => {
      return {
        viewsTree: selectViewsTreeWithTitles,
        currentTreeNode: selectCurrentTreeNode,
        currentViewUrl: selectCurrentViewUrl,
      };
    },
  }),
)(
  ({
    viewsTree,
    currentTreeNode,
    currentViewUrl,
    projectId,
    title,
    subTitle,
    description,
    dispatch,
  }) => {
    // const {
    //   t,
    // } = useTranslation('projects');
    const renderDashboard = (treeNode) => {
      const {
        view,
      } = treeNode;
      if (!view) {
        return null;
      }
      const {
        type,
        url,
        preset,
        settings,
      } = view;
      const tabSettings = {
        ...(preset && preset.settings),
        ...settings,
      };
      const {
        iframeUrl,
        dashboardId,
        questionnaireId,
        columns,
        variables,
        activityId,
        participationId,
        patientId: recipientId,
        responseId: answersSheetId,
      } = tabSettings;
      if (currentViewUrl !== url) {
        return null;
      }
      switch (type) {
        case PROJECT_DASHBOARD_TYPE__ACTION_ITEMS:
          return <ActionItems projectId={projectId} />;
        case PROJECT_DASHBOARD_TYPE__DEMOGRAPHICS:
        case PROJECT_DASHBOARD_TYPE__GENERAL:
        case PROJECT_DASHBOARD_TYPE__PATIENT:
          // NOTE: These dashboard types are deprecated.
          return null;
        case PROJECT_DASHBOARD_TYPE__LATEST_RESPONSES:
          return (
            <DashboardLatestResponses
              dashboardId={dashboardId}
              columns={columns}
              projectId={projectId}
              questionnaireId={questionnaireId}
            />
          );
        case PROJECT_DASHBOARD_TYPE__EAPP:
          return (
            <DashboardEApp
              projectId={projectId}
              activityId={activityId}
              participationId={participationId}
              recipientId={recipientId}
              answersSheetId={answersSheetId}
              dashboardId={dashboardId}
            />
          );
        case PROJECT_DASHBOARD_TYPE__NLP: {
          return (
            <DashboardNLP
              projectId={projectId}
              dashboardId={dashboardId}
            />
          );
        }
        case PROJECT_DASHBOARD_TYPE__ANALYTICS: {
          if (!dashboardId) {
            return null;
          }
          return (
            <DashboardAnalytics
              projectId={projectId}
              recipientId={recipientId}
              dashboardId={dashboardId}
            />
          );
        }
        case PROJECT_DASHBOARD_TYPE__CHART_BUILDER: {
          if (!dashboardId) {
            return null;
          }
          return (
            <DashboardChartBuilder
              projectId={projectId}
              recipientId={recipientId}
              dashboardId={dashboardId}
            />
          );
        }
        case PROJECT_DASHBOARD_TYPE__TIMELINE:
        case PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE:
          return (
            <DashboardTimeline
              projectId={projectId}
              dashboardId={dashboardId}
              variables={variables}
            />
          );
        case PROJECT_DASHBOARD_TYPE__CAT:
          return <DashboardCAT answersSheetId={answersSheetId} />;
        case PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME:
          return <DashboardExternalIFrame src={iframeUrl} />;
        case PROJECT_DASHBOARD_TYPE__DOCUMENTS:
          return (
            <DashboardDocuments
              recipientId={recipientId}
              answersSheetId={answersSheetId}
              dashboardId={dashboardId}
            />
          );
        case PROJECT_DASHBOARD_TYPE__DETAILS:
          return (
            <DashboardDetails
              projectId={projectId}
              recipientId={recipientId}
            />
          );
        default:
          return null;
      }
    };
    if (!currentTreeNode) {
      return <Loading />;
    }
    const breadcrumbRoutes = [
      {
        key: 'projects',
        path: 'projects',
        breadcrumbName: title,
      },
      {
        key: projectId,
        path: projectId,
        breadcrumbName: subTitle,
      },
    ];
    forEachActive(viewsTree, (node) => {
      if (node.children) {
        breadcrumbRoutes.push({
          key: node.id || 'root',
          children: map(node.children, (childNode) => {
            return {
              key: childNode.id,
              path: getClosestViewUrl(childNode),
              breadcrumbName: childNode.title,
              isActive: childNode.active,
              isClearable: !!(childNode.perspective && childNode.perspectiveId),
              onClear: () => {
                const {
                  perspective,
                  perspectiveId,
                } = childNode;
                dispatch(
                  switchAndDeleteTabAndSubTabs({
                    perspective,
                    perspectiveId,
                  }),
                );
              },
            };
          }),
        });
      }
    });

    return (
      <Stack>
        <Cluster wrap="nowrap">
          <Breadcrumb
            size="small"
            routes={breadcrumbRoutes}
          />
          {description && (
            <Text.Paragraph
              title={description}
              size="small"
              isClipped
            >
              {description}
            </Text.Paragraph>
          )}
        </Cluster>
        {renderDashboard(currentTreeNode)}
      </Stack>
    );
  },
);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;
