import {
  createSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import AnswersSheet from '../models/AnswersSheet';
import {
  getStorageKey,
} from '../utilsClient/redux/storage';

const Select = createSelectors(AnswersSheet, {
  getFromLocalStorage: () => select => select.map(
    createSelector(
      getStorageKey('entities', 'AnswersSheets'),
      rawEntities => (answersSheet) => {
        if (!rawEntities || !rawEntities[answersSheet._id]) {
          return answersSheet;
        }
        const {
          responses,
        } = rawEntities[answersSheet._id];
        return new AnswersSheet({
          ...answersSheet,
          responses: answersSheet.mergeResponses(responses),
        });
      },
    ),
  ),
});

export default Select;
