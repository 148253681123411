import {
  SearchOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const InputSearch = ({
  'data-testid': testId,
  size,
  placeholder,
  disabled,
  value,
  onChange,
}) => (
  <Input
    data-testid={testId}
    size={size}
    placeholder={placeholder}
    disabled={disabled}
    suffix={<SearchOutlined />}
    value={value}
    onChange={onChange}
  />
);

InputSearch.propTypes = {
  'data-testid': PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

InputSearch.defaultProps = {
  'data-testid': 'search-first',
  size: 'default',
  placeholder: null,
  disabled: false,
  value: null,
  onChange: () => {},
};

export default InputSearch;
