import map from 'lodash/map';
import includes from 'lodash/includes';
import find from 'lodash/find';
import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import ProjectTrackSelect from '../../../common/selectors/ProjectTrack';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import FormFieldSelect from '../../forms/FormFieldSelect';

const getOption = ({
  _id,
  name,
}) => ({
  value: _id,
  label: name,
});

const FormFieldMilestone = React.forwardRef(
  ({
    projectId,
    trackId,
    ...otherProps
  }, forwardedRef) => {
    const {
      t,
    } = useTranslation();

    const tracks = useSelector(
      ProjectTrackSelect.all().where({
        projectId,
      }),
    );

    const track = find(tracks, {
      _id: trackId,
    });

    const milestones = useSelector(
      ProjectMilestoneSelect.all()
        .where({
          projectId,
        })
        .satisfying((milestone) => {
          if (!trackId || !milestone.selectedTracksOnly) {
            return true;
          }
          return includes(milestone.selectedTracks, trackId);
        })
        .sort({
          index: 1,
        }),
    );

    const milestoneOptions = useMemo(() => {
      return map(milestones, getOption);
    }, [
      milestones,
    ]);

    return (
      <FormFieldSelect
        ref={forwardedRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        options={milestoneOptions}
        extra={
          track &&
          tracks.length > 1 &&
          `${t('forms:track.label')}: ${track.name}`
        }
        showSearch
      />
    );
  },
);

FormFieldMilestone.propTypes = {
  projectId: PropTypes.string.isRequired,
  trackId: PropTypes.string,
};

FormFieldMilestone.defaultProps = {
  trackId: null,
};

export default FormFieldMilestone;
