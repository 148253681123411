import React, {
  useCallback,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  getOpenPermissionsDomainDialogVisible,
  setPermissionsDomainEditDialogVisible,
  getCreateNewDomainFlag,
  setCreateNewDomainFlag,
  getDomain,
  setDomain,
} from './store';
import Stack from '../../common/components/primitives/Stack';
import PermissionsDomainFilters from './components/PermissionsDomainFilters';
import PermissionsDomainTable from './components/PermissionsDomainTable';
import EditDomainDialog from '../../components/dialogs/EditDomain';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  apiAdminCreatePermissionsDomain,
  apiAdminUpdatePermissionsDomain,
} from '../../common/api/admin';
import {
  callMethod,
} from '../../common/utilsClient/ddp/actions';
import {
  notifyError,
  notifySuccess,
} from '../../utils/notify';

const SettingsDomains = () => {
  const {
    t,
  } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('domain', {
      count: 0,
    }),
  ]);

  const dispatch = useDispatch();
  const resetForm = useCallback(() => {
    dispatch(setPermissionsDomainEditDialogVisible(false));
    dispatch(setCreateNewDomainFlag(false));
    dispatch(
      setDomain({
        id: null,
        name: null,
      }),
    );
  }, [
    dispatch,
  ]);

  const onCancel = useCallback(() => {
    resetForm();
  }, [
    resetForm,
  ]);

  const isCreate = useSelector(getCreateNewDomainFlag);
  const currentDomain = useSelector(getDomain);
  const successMessage = t('Domain saved');
  const onCreate = useCallback(() => {
    if (isCreate) {
      dispatch(
        callMethod(apiAdminCreatePermissionsDomain, {
          id: currentDomain.id,
          name: currentDomain.name,
        }),
      )
        .then(() => {
          resetForm();
          notifySuccess(successMessage)();
        })
        .catch(notifyError());
    } else {
      dispatch(
        callMethod(apiAdminUpdatePermissionsDomain, {
          id: currentDomain.id,
          name: currentDomain.name,
        }),
      )
        .then(() => {
          resetForm();
          notifySuccess(t('Domain updated'))();
        })
        .catch(notifyError());
    }
  }, [
    t,
    isCreate,
    currentDomain,
    dispatch,
    resetForm,
    successMessage,
  ]);

  const open = useSelector(getOpenPermissionsDomainDialogVisible);
  return (
    <>
      <EditDomainDialog
        open={open}
        creating={isCreate}
        onCancel={onCancel}
        onCreate={onCreate}
      />
      <Stack>
        <PermissionsDomainFilters />
        <PermissionsDomainTable />
      </Stack>
    </>
  );
};

export default SettingsDomains;
