import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import React, {
  useEffect,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  languages,
} from 'countries-list';
import QuestionnaireSelect from '../../common/selectors/Questionnaire';
import QuestionnaireTranslationSelect from '../../common/selectors/QuestionnaireTranslation';
import Select from './Select';

const TranslationsSelect = ({
  questionnaireId,
  value,
  onChange,
  compact,
}) => {
  const {
    i18n,
  } = useTranslation();

  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const translations = useSelector(QuestionnaireTranslationSelect.all());
  const filteredTranslations = questionnaireId
    ? translations.filter(translation => translation._id.includes(questionnaireId))
    : [];
  const translationThatMatchesUILanguage = filteredTranslations.find(
    ({
      language,
    }) => language === i18n.language,
  );

  const handleCurrentLanguageChange = language => onChange(language);
  const languageOptions = [
    {
      value: questionnaire?.language,
      label: languages[questionnaire?.language]?.name,
    },
    ...filteredTranslations.map(({
      language,
    }) => ({
      value: language,
      label: languages[language]?.name,
    })),
  ];

  useEffect(() => {
    if (translationThatMatchesUILanguage) {
      onChange(translationThatMatchesUILanguage.language);
    } else if (questionnaire) {
      onChange(questionnaire.language);
    }
  }, [
    translationThatMatchesUILanguage,
    questionnaire,
    onChange,
  ]);

  return !isEmpty(filteredTranslations) ? (
    <Select
      options={languageOptions}
      onChange={handleCurrentLanguageChange}
      value={value}
      compact={compact}
    />
  ) : null;
};

TranslationsSelect.propTypes = {
  questionnaireId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
};

TranslationsSelect.defaultProps = {
  questionnaireId: null,
  value: null,
  onChange: () => {},
  compact: false,
};

export default TranslationsSelect;
