import map from 'lodash/map';
import Formula from '../Formula';
import {
  FORMULA_TYPE__CONCATENATE_VALUES,
} from '../../../constants';
import Schema from '../../../utils/Schema';

const settingsSchema = new Schema(
  {
    chunks: [
      new Schema({
        id: {
          type: String,
        },
      }),
    ],
  },
  {
    additionalProperties: true,
  },
);

class FormulaConcatenateValues extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const separator = this.settings.separator || '';
    return {
      value: this.settings.chunks.reduce((combined, {
        id,
      }) => {
        const data = scope.lookupAnswer(id);
        if (!data || typeof data.value !== 'string') {
          return combined;
        }
        if (combined) {
          return combined + separator + data.value;
        }
        return data.value;
      }, ''),
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {
        // TODO: We need to make it configurable. The problem is (of course)
        //       SimpleSchema trimming values by default.
        separator: this.meta && this.meta.useSeparator ? ' ' : '',
      },
    };
    if (this.meta && this.meta.sectionId) {
      compiled.settings = {
        ...compiled.settings,
        chunks: questionsHierarchy.mapQuestions(
          q => ({
            id: q.id,
          }),
          {
            sectionId: this.meta.sectionId,
          },
        ),
      };
    }
    return compiled;
  }

  // TODO: Implement mongo expression

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'chunks':
          return map(value, fields => ({
            ...fields,
            id: mapQuestionId(fields.id),
          }));
        default:
          return value;
      }
    };
  }

  static createMapMeta(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'sectionId':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__CONCATENATE_VALUES] = FormulaConcatenateValues;

export default FormulaConcatenateValues;
