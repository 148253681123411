import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import {
  MoreHoriz,
} from 'styled-icons/material';
import Sidebar from '../primitives/Sidebar';
import Cluster from '../primitives/Cluster';
import Stack from '../primitives/Stack';
import Text from '../base/Text';
import Menu from '../Menu';
import Dropdown from '../Dropdown';
import Avatar from '../Avatar';
import Button from '../Button';
import Icon from '../Icon';

const UserProfile = ({
  'data-testid': testId,
  age,
  gender,
  studyNo,
  name,
  actions,
  // identifier,
}) => {
  return (
    <Sidebar
      data-testid={testId}
      sidebar={
        !isEmpty(actions) && (
          <Dropdown
            overlay={(
              <Menu>
                {actions.map(({
                  title,
                  onClick,
                  disabled,
                }, i) => (
                  <Menu.Item
                    data-testid={`profile-menu-item-${title}`}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    onClick={onClick}
                    disabled={disabled}
                  >
                    {title}
                  </Menu.Item>
                ))}
              </Menu>
            )}
            trigger={[
              'click',
            ]}
          >
            <Button
              data-testid="button-profile-menu"
              type="link"
              icon={<MoreHoriz />}
            />
          </Dropdown>
        )
      }
    >
      <Cluster wrap="nowrap">
        <Avatar
          kind="bold"
          size="large"
        >
          {age}
        </Avatar>
        <Stack space={0}>
          <Text.Paragraph>{name}</Text.Paragraph>
          {(!!gender || !!studyNo) && (
            <Cluster>
              <Text.Span
                size="small"
                importance="low"
              >
                <Icon type={gender} />
              </Text.Span>
              {studyNo && (
                <Text.Span
                  size="small"
                  importance="low"
                >
                  {studyNo}
                </Text.Span>
              )}
            </Cluster>
          )}
        </Stack>
      </Cluster>
    </Sidebar>
  );
};

UserProfile.propTypes = {
  'data-testid': PropTypes.string,
  age: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  gender: PropTypes.oneOf([
    'man',
    'woman',
    'question-circle',
  ]),
  studyNo: PropTypes.node,
  name: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
};

UserProfile.defaultProps = {
  'data-testid': null,
  age: null,
  gender: null,
  studyNo: null,
  actions: [],
};

export default UserProfile;
