import {
  SyncOutlined,
  MailOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import Schema from '../../common/utils/Schema';
import {
  SCHEMA_RX__EMAIL,
} from '../../common/constants/schema';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import TextField from '../../components/forms/FormFieldText';
import Error from '../../components/forms/Error';
import useDocumentTitle from '../../utils/useDocumentTitle';

const propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const defaultProps = {
  error: '',
};

const Forgot = ({
  error,
  handleSubmit,
  onSubmit,
  isLoading,
}) => {
  useDocumentTitle([
    'Reset',
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        title="Reset your password"
        footer={(
          <Cluster justify="center">
            <Button
              type="primary"
              icon={<SyncOutlined />}
              htmlType="submit"
              loading={isLoading}
            >
              Reset
            </Button>
          </Cluster>
        )}
        bordered={false}
      >
        <Stack>
          {error && <Error>{error}</Error>}
          <Field
            key="1"
            component={TextField}
            name="email"
            type="text"
            label="Email"
            placeholder="Please enter your email"
            prefix={<MailOutlined />}
            required
          />
        </Stack>
      </Card>
    </form>
  );
};

Forgot.propTypes = propTypes;
Forgot.defaultProps = defaultProps;

export default reduxForm({
  form: 'entry',
  validate: new Schema({
    email: {
      type: String,
      label: 'Email',
      regEx: SCHEMA_RX__EMAIL,
    },
  }).validator({
    noException: true,
  }),
})(Forgot);
