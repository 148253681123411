/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-return-assign */
import styled from 'styled-components/macro';
import React from 'react';
import {
  QUESTION_TYPE__FREE_TEXT,
} from '../../common/constants';
import Input from '../../common/components/Input';
import TextArea from '../../common/components/TextArea';
import {
  theme,
} from '../../common/utilsClient/cssHelpers';

const Wrapper = styled.span`
  .ant-input {
    &.ant-input-disabled {
      background-color: ${theme('background.color.light')};
    }
  }
`;

class InputComponent extends React.Component {
  componentDidMount() {
    const {
      // eslint-disable-next-line react/prop-types
      autoFocus,
    } = this.props;

    setTimeout(() => {
      const {
        elem,
      } = this;

      if (elem && autoFocus) {
        elem.focus();
      }
    }, 0);
  }

  render() {
    const {
      // eslint-disable-next-line react/prop-types
      type,
      ...rest
    } = this.props;

    return (
      <Wrapper>
        {type === QUESTION_TYPE__FREE_TEXT ? (
          <TextArea
            type={type}
            {...rest}
            ref={elem => (this.elem = elem)}
          />
        ) : (
          <Input
            type={type}
            {...rest}
            ref={elem => (this.elem = elem)}
          />
        )}
      </Wrapper>
    );
  }
}

export default InputComponent;
