import isNil from 'lodash/isNil';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import getDynamicQuestionnaire from './getDynamicQuestionnaire';
import EvaluationScope from '../../models/EvaluationScope';

function presentAnswersSheet(
  questionnaire,
  answersSheet,
  {
    includeMissing = false,
    filterQuestions = null,
  } = {},
) {
  const evaluationScope = EvaluationScope.create({
    questionnaire,
    answersSheet,
  });
  const {
    formValues,
    properties,
  } = evaluateFormValuesAndProperties(
    evaluationScope,
  );
  const dynamicQuestionnaire = getDynamicQuestionnaire(
    questionnaire,
    properties,
  );
  const results = [];
  dynamicQuestionnaire.forEachQuestion(
    (question) => {
      const answer = formValues[question.id];
      const formattedAnswer = question.formatAnswer(answer);
      // NOTE: In this case, "nil" means either empty answer or
      //       problematic question type, e.g. collection
      if (includeMissing || !isNil(formattedAnswer)) {
        results.push({
          label: question.getTitle(),
          value: formattedAnswer,
          answer,
          questionType: question.type,
          questionId: question.id,
        });
      }
    },
    {
      stopRecursion: question => question.isCollection(),
      filterQuestions: (question) => {
        if (!question.hasPresentableValue()) {
          return false;
        }
        if (typeof filterQuestions !== 'function') {
          return true;
        }
        return !!filterQuestions(question);
      },
    },
  );
  return results;
}

export default presentAnswersSheet;
