import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../../utilsClient/cssHelpers';

const BoxDefault = styled.div`
  padding: ${props => `${theme(`space.${props.space}`)(props)}`};
  border-radius: ${props => `${theme(`border.radius.${props.radius}`)(props)}`};
  border: ${props => `${props.borderWidth}px solid`};
  border-color: hsla(${theme('color.darkBase')}, 0.25);
  box-shadow: ${props => props.theme.boxShadow[props.boxShadow]};
`;

const Box = ({
  className,
  style,
  children,
  space,
  radius,
  borderWidth,
  // eslint-disable-next-line no-shadow
  boxShadow,
}) => (
  <BoxDefault
    className={className}
    style={style}
    space={space}
    // TODO: Change to borderRadius
    radius={radius}
    borderWidth={borderWidth}
    boxShadow={boxShadow}
  >
    {children}
  </BoxDefault>
);

const propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.node,
  space: PropTypes.oneOf([
    0,
    1,
    2,
    3,
    4,
    5,
  ]),
  radius: PropTypes.oneOf([
    0,
    1,
    2,
    3,
  ]),
  borderWidth: PropTypes.number,
  boxShadow: PropTypes.string,
};

const defaultProps = {
  className: '',
  style: {},
  children: null,
  space: 3,
  radius: 2,
  borderWidth: 0,
  boxShadow: null,
};

Box.propTypes = propTypes;
Box.defaultProps = defaultProps;

export default Box;

export {
  propTypes,
  defaultProps,
};
