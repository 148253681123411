import {
  PlusOutlined,
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import Recipient from '../../../common/models/Recipient';
import Participation from '../../../common/models/Participation';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Empty from '../../../common/components/base/Empty';
import ProjectProfile from './ProjectProfile';
import {
  PATIENT_ATTACH_PARTICIPATION,
  PROJECT_ATTACH_PARTICIPATION,
} from '../../../common/permissions';
import usePermission from '../../../utils/usePermission';

const PatientProjects = ({
  recipient,
  participations,
  onAddToProject,
}) => {
  const {
    t,
  } = useTranslation();

  const canAddParticipant = usePermission([
    PROJECT_ATTACH_PARTICIPATION,
  ]);

  const canEnrollPatient = usePermission([
    PATIENT_ATTACH_PARTICIPATION,
  ], {
    relativeTo: recipient.getDomains(),
  });

  return (
    <Stack>
      <Sidebar
        sidebar={(
          <Button
            data-testid="button-add-to-project"
            type="primary"
            icon={<PlusOutlined />}
            onClick={onAddToProject}
            disabled={!(canAddParticipant && canEnrollPatient)}
          >
            {t('addToProject')}
          </Button>
        )}
      />
      {isEmpty(participations) ? (
        <Empty />
      ) : (
        map(participations, participation => (
          <ProjectProfile
            key={participation._id}
            participation={participation}
          />
        ))
      )}
    </Stack>
  );
};

PatientProjects.propTypes = {
  recipient: PropTypes.instanceOf(Recipient).isRequired,
  participations: PropTypes.arrayOf(PropTypes.instanceOf(Participation)),
  onAddToProject: PropTypes.func.isRequired,
};

PatientProjects.defaultProps = {
  participations: [],
};

export default PatientProjects;
