import forEach from 'lodash/forEach';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import isPlainObject from 'lodash/isPlainObject';
import includes from 'lodash/includes';
import every from 'lodash/every';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import Questionnaire from '../../../common/models/Questionnaire';
import {
  getPattern,
  getPatternExample,
  getPatternErrorMessage,
} from '../../../common/utils/checkSchema';
import {
  QUESTION_TYPE__COLLECTION,
  QUESTION_TYPE__SHORT_TEXT,
  QUESTION_TYPE__FULL_DATE,
  QUESTION_TYPE__DATE,
  QUESTION_TYPE__DATE_TIME,
  QUESTION_TYPE__TIMESTAMP,
  QUESTION_TYPE__TIME,
  QUESTION_TYPE__YEAR,
  QUESTION_TYPE__SWITCH,
  QUESTION_TYPE__NUMBER,
  QUESTION_TYPE__SELECT_ONE,
  QUESTION_TYPE__SECTION,
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__EMAIL,
  QUESTION_TYPE__PHONE,
  QUESTION_TYPE__SELECT_MANY,
  QUESTION_TYPE__RAW_JSON,
  QUESTION_TYPE__LIST_OF_STRINGS,
  QUESTION_TYPE__NOTHING,
} from '../../../common/constants';

function mapProperties(schema, iterator) {
  if (schema.form && schema.form.fieldsOrder) {
    const properties = [];
    if (schema.properties) {
      forEach(schema.form.fieldsOrder, (key) => {
        if (schema.properties[key]) {
          properties.push({
            key,
            property: schema.properties[key],
          });
        }
      });
    }
    return map(properties, ({
      key,
      property,
    }) => {
      return iterator(property, key);
    });
  }
  return map(schema.properties, iterator);
}

function isChoices(schemata) {
  if (!isArray(schemata)) {
    return false;
  }
  return every(schemata, (schema) => {
    return has(schema, 'const');
  });
}

const toChoice = schema => ({
  value: schema.const,
  label: schema.title || schema.const,
});

function schemaToQuestionTemplate(schema, options = {}) {
  const {
    title = schema.title,
    description = schema.description,
    variableId,
    optional,
  } = options;

  const createTemplate = (type, properties = {}) => ({
    variableId,
    type,
    optional,
    title,
    description,
    ...properties,
  });

  const createChildTemplateCreator = required => (subSchema, propertyKey) => schemaToQuestionTemplate(subSchema, {
    title: subSchema.title || propertyKey,
    description: schema.description,
    variableId: propertyKey,
    optional: !includes(required, propertyKey),
  });

  const createDefaultTemplate = (
    type = QUESTION_TYPE__SHORT_TEXT,
    anotherSchema,
  ) => {
    const {
      format,
      pattern,
    } = anotherSchema;
    if (pattern) {
      return createTemplate(type, {
        settings: {
          pattern,
        },
      });
    }
    if (format) {
      return createTemplate(type, {
        settings: {
          pattern: getPattern(format),
          patternExample: getPatternExample(format),
          patternErrorMessage: getPatternErrorMessage(format),
        },
      });
    }
    return createTemplate(type);
  };

  if (schema === false) {
    return createTemplate(QUESTION_TYPE__NOTHING, {});
  }

  if (isPlainObject(schema)) {
    switch (schema.type) {
      case 'string': {
        if (schema.enum) {
          return createTemplate(QUESTION_TYPE__SELECT_ONE, {
            choices: map(schema.enum, value => ({
              value,
            })),
          });
        }
        if (isChoices(schema.oneOf)) {
          return createTemplate(QUESTION_TYPE__SELECT_ONE, {
            choices: map(schema.oneOf, toChoice),
          });
        }
        if (isChoices(schema.anyOf)) {
          return createTemplate(QUESTION_TYPE__SELECT_ONE, {
            choices: map(schema.anyOf, toChoice),
          });
        }
        switch (schema.format) {
          case 'full-date': {
            return createTemplate(QUESTION_TYPE__FULL_DATE);
          }
          case 'date': {
            return createTemplate(QUESTION_TYPE__DATE);
          }
          case 'date-time': {
            return createTemplate(QUESTION_TYPE__DATE_TIME);
          }
          case 'timestamp': {
            return createTemplate(QUESTION_TYPE__TIMESTAMP);
          }
          case 'time': {
            return createTemplate(QUESTION_TYPE__TIME);
          }
          case 'year': {
            return createTemplate(QUESTION_TYPE__YEAR);
          }
          case 'phone': {
            return createTemplate(QUESTION_TYPE__PHONE);
          }
          case 'email': {
            return createTemplate(QUESTION_TYPE__EMAIL);
          }
          case 'json': {
            return createTemplate(QUESTION_TYPE__RAW_JSON, {
              settings: {
                jsonSchemaString: '{}', // accept any valid json
              },
            });
          }
          default: {
            return createDefaultTemplate(QUESTION_TYPE__SHORT_TEXT, schema);
          }
        }
      }
      case 'boolean':
        return createTemplate(QUESTION_TYPE__SWITCH);
      case 'integer':
        return createTemplate(QUESTION_TYPE__NUMBER, {
          settings: {
            valueStep: 1,
            precision: 0,
          },
        });
      case 'number':
        return createTemplate(QUESTION_TYPE__NUMBER, {
          settings: {},
        });
      case 'object': {
        return createTemplate(QUESTION_TYPE__SECTION, {
          children: mapProperties(
            schema,
            createChildTemplateCreator(schema.required),
          ),
        });
      }
      case 'array': {
        if (schema.items) {
          if (schema.items.type === 'object') {
            return createTemplate(QUESTION_TYPE__COLLECTION, {
              children: mapProperties(
                schema.items,
                createChildTemplateCreator(schema.items.required),
              ),
            });
          }
          if (isChoices(schema.items.oneOf)) {
            return createTemplate(QUESTION_TYPE__SELECT_MANY, {
              choices: map(schema.items.oneOf, toChoice),
            });
          }
          if (isChoices(schema.items.anyOf)) {
            return createTemplate(QUESTION_TYPE__SELECT_MANY, {
              choices: map(schema.items.anyOf, toChoice),
            });
          }
          if (schema.items.type === 'string') {
            if (schema.items.enum) {
              return createTemplate(QUESTION_TYPE__SELECT_MANY, {
                choices: map(schema.items.enum, value => ({
                  value,
                })),
              });
            }
            return createDefaultTemplate(
              QUESTION_TYPE__LIST_OF_STRINGS,
              schema.items,
            );
          }
          return createTemplate(QUESTION_TYPE__COLLECTION, {
            children: [
              schemaToQuestionTemplate(schema.items, {
                title,
                variableId: '$',
              }),
            ],
          });
        }
        break;
      }
      default:
      // pass
    }
    if (isChoices(schema.oneOf) || isChoices(schema.anyOf)) {
      return createTemplate(QUESTION_TYPE__SELECT_ONE, {
        choices: map(schema.oneOf || schema.anyOf, subSchema => ({
          value: subSchema.const,
          label: subSchema.title,
        })),
      });
    }
  }
  return createTemplate(QUESTION_TYPE__EMPTY);
}

const createMaterialize = sectionId => (template) => {
  if (!template) {
    return [];
  }
  const {
    children,
    ...other
  } = template;

  const id = sectionId
    ? `${sectionId}/${template.variableId}`
    : template.variableId;
  if (id) {
    return [
      {
        ...other,
        id,
        sectionId,
      },
      ...flatMap(children, createMaterialize(id)),
    ];
  }
  return flatMap(children, createMaterialize(''));
};

const createQuestionnaire = (schema) => {
  const template = schemaToQuestionTemplate(schema);
  const questionnaire = new Questionnaire({
    questions: createMaterialize()(template),
    rootSectionId: '',
  });
  return questionnaire;
};

export default createQuestionnaire;
