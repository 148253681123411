import {
  QUESTION_TYPE__TIME,
} from '../../../constants';
import Question from '../Question';

export default class QuestionTime extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__TIME;
  }
}

QuestionTime.expectedValueType = {
  type: 'string',
  format: 'time', // i.e. 00:00:00
};

QuestionTime.editorSettings = {};

Question.types[QUESTION_TYPE__TIME] = QuestionTime;
