import isNil from 'lodash/isNil';
import styled, {
  css,
} from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const types = {
  info: css`
    background-color: ${theme('color.primary')};
    color: ${theme('color.onPrimary')};
  `,
  default: css`
    background-color: ${theme('color.danger')};
    color: ${theme('color.onDanger')};
  `,
};

const Component = styled.div`
  position: relative;
  display: inline-block;
`;

const ComponentCount = styled.div`
  ${props => types[props.type]};

  position: absolute;
  top: 0;
  right: 0;
  transform: ${props => `translate(calc(50% + ${props.offsetX}px), calc(-50% + ${props.offsetY}px))`};
  font-size: ${theme('font.size.small')};
  /* TODO: 1.5rem can be replaced with theme.space.3 when we use 1.5 line height */
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  border-radius: 50%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: 0 0 0 1px ${theme('color.white')};
`;

const Badge = ({
  children,
  count,
  type,
  offsetX,
  offsetY,
  'data-testid': testId,
}) => {
  return (
    <Component>
      {children}
      {!isNil(count) && (
        <ComponentCount
          data-testid={testId}
          type={type}
          title={count}
          offsetX={offsetX}
          offsetY={offsetY}
        >
          {count}
        </ComponentCount>
      )}
    </Component>
  );
};

Badge.propTypes = {
  'data-testid': PropTypes.string,
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  type: PropTypes.oneOf([
    'default',
    'info',
  ]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
};

Badge.defaultProps = {
  'data-testid': 'badge',
  count: null,
  type: 'default',
  offsetX: 0,
  offsetY: 0,
};

export default Badge;
