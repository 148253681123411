import map from 'lodash/map';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';
import React, {
  useMemo,
} from 'react';
import {
  compose,
} from 'recompose';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import Pagination from '../../../../common/components/Pagination';
import Box from '../../../../common/components/primitives/Box';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Stack from '../../../../common/components/primitives/Stack';
import ActivitiesTimeline from './ActivitiesTimeline';
import ActivitiesList from './ActivitiesList';
import ActivitiesPatientProfile from './ActivitiesPatientProfile';
import {
  openEditActivityDialog,
  openProjectProfileDialog,
} from '../../actions';
import {
  theme,
} from '../../../../common/utilsClient/cssHelpers';
import ProjectTrackSelect from '../../../../common/selectors/ProjectTrack';
import PatientRecord from '../../../../common/models/PatientRecord';
import VariablesTag from './VariablesTag';

const StyledListItem = styled.li`
  padding: ${theme('space.2')};
  border-top: 1px dashed ${theme('color.border')};

  ${props => props.hasMultipleParticipations &&
    `
    border: 1px dashed hsl(220, 80%, 86%);
    border-radius: 0.5em;
  `}
`;

const StyledPatientProfileWrapper = styled.div`
  width: 300px;

  div[data-testid='default-stack'] {
    flex: 1;
  }

  a[data-testid^='button-patient'] span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    /* max-width: 80%; */
  }
`;

const StyledTimelineWrapper = styled(Box)`
  /* So you can scroll the timeline horizontally on small screens  */
  overflow-x: auto;
  overflow-y: hidden;
`;

const PatientProfileContainer = compose()(
  ({
    record,
    actions,
    shouldHideMeta,
  }) => (
    <StyledPatientProfileWrapper>
      <ActivitiesPatientProfile
        patientRecord={record}
        actions={actions}
        shouldHideMeta={shouldHideMeta}
      />
    </StyledPatientProfileWrapper>
  ),
);

const ActivitiesDirectory = compose()(
  ({
    patients,
    pagination,
    projectId,
    onPatientClick,
    getIsDisabled,
    milestones,
    variables,
    onMilestoneClick,
    loading,
  }) => {
    const {
      t,
    } = useTranslation();

    const tracksById = useSelector(
      ProjectTrackSelect.all()
        .where({
          projectId,
        })
        .byId(),
    );

    const getTrackName = (trackId) => {
      const track = tracksById[trackId];
      return track && track.name;
    };

    const dispatch = useDispatch();
    // eslint-disable-next-line react/jsx-props-no-spreading
    const renderPagination = () => <Pagination {...pagination} />;

    const dataSource = useMemo(() => {
      const byRecipientId = groupBy(patients, 'recipientId');
      const newDataSource = [];
      forEach(patients, (patientRecord) => {
        const {
          recipientId,
        } = patientRecord;
        if (byRecipientId[recipientId]) {
          newDataSource.push(
            new PatientRecord({
              ...patientRecord.raw,
              allPatientRecords: byRecipientId[recipientId],
            }),
          );
          delete byRecipientId[recipientId];
        }
      });
      return newDataSource;
    }, [
      patients,
    ]);

    return (
      <Stack>
        <Box.Primary boxShadow="base">
          <ActivitiesList
            data-testid="project-patient-table"
            // TODO: Translate
            title="Activities"
            dataSource={dataSource}
            loading={loading}
            // pageSize={pageSize}
            renderItem={({
              _id: patientRecordId,
              allPatientRecords,
            }) => (
              <StyledListItem
                key={patientRecordId}
                hasMultipleParticipations={!isEmpty(allPatientRecords)}
              >
                <Stack>
                  {map(allPatientRecords, patientRecord => (
                    <Sidebar
                      key={patientRecord._id}
                      side="left"
                      sidebar={(
                        <PatientProfileContainer
                          shouldHideMeta={patientRecord._id !== patientRecordId}
                          projectId={projectId}
                          record={patientRecord}
                          actions={[
                            {
                              title: t('settings'),
                              onClick: () => {
                                const {
                                  recipientId,
                                  participationId,
                                } = patientRecord;
                                dispatch(
                                  openProjectProfileDialog({
                                    recipientId,
                                    participationId,
                                  }),
                                );
                              },
                              disabled: getIsDisabled(patientRecord),
                            },
                            {
                              title: t('viewInsights'),
                              onClick: () => onPatientClick(
                                patientRecord.getFullName(),
                                patientRecord.getRecipientId(),
                              ),
                              disabled: getIsDisabled(patientRecord),
                            },
                            {
                              // TODO: Add any permission?
                              title: t('addActivity'),
                              onClick: () => {
                                const {
                                  recipientId,
                                  participationId,
                                } = patientRecord;
                                dispatch(
                                  openEditActivityDialog({
                                    recipientId,
                                    participationId,
                                  }),
                                );
                              },
                            },
                          ]}
                        />
                      )}
                    >
                      <Sidebar
                        side="left"
                        sidebar={(
                          <VariablesTag
                            name={getTrackName(patientRecord.trackId)}
                            variables={variables}
                            projectVariables={patientRecord.variables}
                          />
                        )}
                      >
                        <StyledTimelineWrapper space={0}>
                          <ActivitiesTimeline
                            patientRecord={patientRecord}
                            milestones={milestones}
                            onMilestoneClick={onMilestoneClick}
                          />
                        </StyledTimelineWrapper>
                      </Sidebar>
                    </Sidebar>
                  ))}
                </Stack>
              </StyledListItem>
            )}
            renderPagination={renderPagination}
          />
        </Box.Primary>
      </Stack>
    );
  },
);

export default ActivitiesDirectory;
