import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

const defaultKeys = [
  'value',
  'other',
  'text1',
  'text2',
];

/**
 * Get the first meaningful error message from the descriptor object.
 * @param {Object} descriptor
 * @param {String} dataKey
 * @returns {String}
 */
function getErrorMessageFromDescriptor(descriptor, dataKey) {
  if (isPlainObject(descriptor)) {
    for (let i = 0; i < defaultKeys.length; i += 1) {
      const key = defaultKeys[i];
      const error = descriptor[key];
      if (error) {
        return dataKey ? `${error} at ${dataKey}` : error;
      }
    }
    let keysToCheck;
    if (descriptor._elements || descriptor._elementsOrder) {
      if (descriptor._elementsOrder) {
        keysToCheck = descriptor._elementsOrder;
      } else {
        keysToCheck = keys(descriptor._elements);
      }
    }
    if (isArray(keysToCheck)) {
      for (let i = 0; i < keysToCheck.length; i += 1) {
        const key = keysToCheck[i];
        const error = getErrorMessageFromDescriptor(
          descriptor._elements && descriptor._elements[key],
          dataKey ? `${dataKey}.${key}` : key,
        );
        if (error) {
          return error;
        }
      }
    }
  }
  return dataKey ? `Unknown error at ${dataKey}` : 'Unknown error';
}

/**
 * Get the first meaningful error message from formErrors object.
 * @param {Object} formErrors
 * @returns {String}
 */
function getErrorMessage(formErrors) {
  return getErrorMessageFromDescriptor({
    _elements: formErrors,
  });
}

export default getErrorMessage;
