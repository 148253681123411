import omitBy from 'lodash/omitBy';
import {
  createStructuredSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import PatientRecord from '../models/PatientRecord';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';

const Select = createSelectors(
  PatientRecord,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (rawPatientRecord, {
      piiRealm,
      variablesDb,
    } = {}) => {
      if (
        PermissionsDomain.belongsToRealm(rawPatientRecord.ownership, piiRealm)
      ) {
        return rawPatientRecord;
      }
      const newPatientRecord = omitBy(rawPatientRecord, (value, key) => {
        switch (key) {
          case 'recipientName':
          case 'recipientNameTerms':
          case 'primaryIdentifier': {
            return true;
          }
          default:
            return false;
        }
      });
      if (newPatientRecord.variables) {
        newPatientRecord.variables = omitBy(
          newPatientRecord.variables,
          (value, key) => {
            const variable = variablesDb && variablesDb[key];
            return !variable || variable.isPII();
          },
        );
      }
      return newPatientRecord;
    },
  },
);

export default Select;
