import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import {
  useDDPCall,
} from '@theclinician/ddp-connector';
import {
  useTranslation,
  Trans,
} from 'react-i18next';
import {
  useDispatch,
} from 'react-redux';
import {
  submit,
} from 'redux-form';
import Stack from '../../../../common/components/primitives/Stack';
import EditNoteForm, {
  form,
} from '../../../../components/dialogs/EditNote/EditNoteForm';
import Text from '../../../../common/components/base/Text';
import {
  apiZedocUpdateEAPP,
} from '../../../../common/api/zedoc';
import {
  notifyError,
} from '../../../../utils/notify';
import Modal from '../../../../components/dialogs/Modal';

const DashboardResolveModal = ({
  visible,
  onCancel,
  eappId,
  patientName,
}) => {
  const {
    t,
  } = useTranslation();
  const {
    ddpCall,
    ddpIsPending,
  } = useDDPCall();
  const onSubmit = useCallback(
    ({
      noteContent,
    }) => {
      ddpCall(
        apiZedocUpdateEAPP.withParams({
          eappId,
          noteContent,
          completeStep: true,
        }),
      )
        .then(() => {
          onCancel();
        })
        .catch(notifyError());
    },
    [
      ddpCall,
      eappId,
      onCancel,
    ],
  );
  const dispatch = useDispatch();
  const handleOnOk = useCallback(() => dispatch(submit(form)), [
    dispatch,
  ]);
  return (
    <>
      <Modal
        title={t('confirmations:resolveStatus.title')}
        visible={visible}
        onOk={handleOnOk}
        onCancel={onCancel}
        confirmLoading={ddpIsPending}
        centered
      >
        <Stack>
          <Text.Paragraph>
            <Trans i18nKey="confirmations:resolveStatus.descriptionWithName">
              <Text.Span importance="high">
                {{
                  recipientName: patientName,
                }}
              </Text.Span>
            </Trans>
          </Text.Paragraph>
          <EditNoteForm onSubmit={onSubmit} />
        </Stack>
      </Modal>
    </>
  );
};

DashboardResolveModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  eappId: PropTypes.string,
  patientName: PropTypes.string,
};

DashboardResolveModal.defaultProps = {
  visible: true,
  onCancel: null,
  eappId: null,
  patientName: null,
};

export default DashboardResolveModal;
