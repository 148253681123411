import {
  useRef,
} from 'react';
import isEqual from 'lodash/isEqual';

const useReconcile = (value) => {
  const memo = useRef();
  if (!isEqual(value, memo.current)) {
    memo.current = value;
  }
  return memo.current;
};

export default useReconcile;
