import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
} from 'recompose';
import {
  useTranslation,
} from 'react-i18next';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import Button from '../../../common/components/Button';
import List from '../../../common/components/List';
import Markdown from '../../../common/components/Markdown';
import Modal from '../Modal';

const PreviewTabularData = compose()(
  ({
    open,
    title,
    onClose,
    subscriptionsReady,
    dataSource,
    footer,
  }) => {
    const {
      t,
    } = useTranslation();

    return (
      <Modal
        title={title}
        footer={
          footer || (
            <Button
              data-testid="button-close-preview"
              onClick={onClose}
            >
              {t('cancel')}
            </Button>
          )
        }
        visible={open}
        onCancel={onClose}
      >
        <Box.Primary>
          <List
            dataSource={dataSource}
            loading={!subscriptionsReady}
            pageSize={5}
            renderItem={(item, idx) => (
              <Stack
                key={idx}
                space={2}
              >
                <Markdown.h4 source={item.title} />
                <Markdown source={item.description} />
              </Stack>
            )}
          />
        </Box.Primary>
      </Modal>
    );
  },
);

PreviewTabularData.propTypes = {
  open: PropTypes.bool.isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  onClose: PropTypes.func,
  subscriptionsReady: PropTypes.bool,
  footer: PropTypes.node,
};

PreviewTabularData.defaultProps = {
  title: 'Tabular Data',
  subscriptionsReady: true,
  dataSource: [],
  onClose: () => {},
  footer: null,
};

export default PreviewTabularData;
