export const FILTER_TYPE__ANSWER_VALUE = 'answerValue';
export const FILTER_TYPE__ANSWER_VALUE_PREFIX = 'answerValuePrefix';
export const FILTER_TYPE__VARIABLE = 'variable';
export const FILTER_TYPE__PROPERTY = 'property';
export const FILTER_TYPE__TAG = 'tag';
export const FILTER_TYPE__META_DATE = 'metaDate';
export const FILTER_TYPE__META_NUMBER = 'metaNumber';
export const FILTER_TYPE__SCHEDULE = 'schedule';
export const FILTER_TYPE__FULL_TEXT = 'fullText';
export const FILTER_TYPE__ANY_OF = 'anyOf';
export const FILTER_TYPE__ALL_OF = 'allOf';
export const FILTER_TYPE__NOT_COMPLETED = 'notCompleted';

export const FILTER_TYPES = [
  FILTER_TYPE__ANSWER_VALUE,
  FILTER_TYPE__ANSWER_VALUE_PREFIX,
  FILTER_TYPE__VARIABLE,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__TAG,
  FILTER_TYPE__META_DATE,
  FILTER_TYPE__META_NUMBER,
  FILTER_TYPE__SCHEDULE,
  FILTER_TYPE__FULL_TEXT,
  FILTER_TYPE__ANY_OF,
  FILTER_TYPE__ALL_OF,
  FILTER_TYPE__NOT_COMPLETED,
];

export const FILTER_CONDITION__INCLUDE = 'include';
export const FILTER_CONDITION__EXCLUDE = 'exclude';
export const FILTER_CONDITION__EXISTS = 'exists';
export const FILTER_CONDITION__DOES_NOT_EXIST = 'doesNotExist';
export const FILTER_CONDITION__TEXT = 'text';
export const FILTER_CONDITION__SEARCH_TERMS = 'searchTerms';
export const FILTER_CONDITION__DATE_RANGE = 'dateRange';
export const FILTER_CONDITION__DATE_EQUALS = 'dateEquals';
export const FILTER_CONDITION__DATE_SAME_OR_BEFORE = 'dateSameOrBefore';
export const FILTER_CONDITION__DATE_BEFORE = 'dateBefore';
export const FILTER_CONDITION__DATE_SAME_OR_AFTER = 'dateSameOrAfter';
export const FILTER_CONDITION__DATE_AFTER = 'dateAfter';
export const FILTER_CONDITION__MINIMUM = 'minimum';
export const FILTER_CONDITION__EXCLUSIVE_MINIMUM = 'exclusiveMinimum';
export const FILTER_CONDITION__MAXIMUM = 'maximum';
export const FILTER_CONDITION__EXCLUSIVE_MAXIMUM = 'exclusiveMaximum';
export const FILTER_CONDITION__EMPTY = 'empty';
export const FILTER_CONDITION__NON_EMPTY = 'nonEmpty';

export const FILTER_CONDITIONS = [
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__DATE_RANGE,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
];

export const FILTER_CONDITION__OPTIONS = [
  {
    label: 'Value contains any of',
    value: FILTER_CONDITION__INCLUDE,
  },
  {
    label: 'Value is missing or contains none of',
    value: FILTER_CONDITION__EXCLUDE,
  },
  {
    label: 'Value is present',
    value: FILTER_CONDITION__EXISTS,
  },
  {
    label: 'Value is missing',
    value: FILTER_CONDITION__DOES_NOT_EXIST,
  },
  {
    label: 'Value starts with text',
    value: FILTER_CONDITION__TEXT,
  },
  // {
  //   label: 'Value is within date range',
  //   value: FILTER_CONDITION__DATE_RANGE,
  // },
];

export const FILTER_WIDGET_TYPE__TEXT_INPUT = 'textInput';
export const FILTER_WIDGET_TYPE__SELECT_ONE = 'selectOne';
export const FILTER_WIDGET_TYPE__SELECT_MANY = 'selectMany';
export const FILTER_WIDGET_TYPE__DATE_RANGE = 'dateRange';

export const FILTER_WIDGET_TYPES = [
  FILTER_WIDGET_TYPE__TEXT_INPUT,
  FILTER_WIDGET_TYPE__SELECT_ONE,
  FILTER_WIDGET_TYPE__SELECT_MANY,
  FILTER_WIDGET_TYPE__DATE_RANGE,
];

export const SORTER_TYPE__ANSWER_VALUE = 'answerValue';
export const SORTER_TYPE__VARIABLE = 'variable';
export const SORTER_TYPE__PROPERTY = 'property';

export const SORTER_TYPES = [
  SORTER_TYPE__ANSWER_VALUE,
  SORTER_TYPE__VARIABLE,
  SORTER_TYPE__PROPERTY,
];
