import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import {
  setAtKey,
  pullAtKey,
  delAtKey,
} from '../../common/utilsClient/immutable';

const reducer = (state, action) => {
  const {
    filters,
    filtersOrder,
  } = state;
  let newState = {
    ...state,
  };
  switch (action.type) {
    case 'SYNC': {
      newState = {
        ...newState,
        filters: keyBy(action.payload, 'id'),
        filtersOrder: map(action.payload, 'id'),
      };
      break;
    }
    case 'CHANGE': {
      newState = setAtKey(newState, action.meta.key, action.payload);
      break;
    }
    case 'REMOVE': {
      newState = pullAtKey(newState, 'filtersOrder', action.meta.id);
      newState = delAtKey(newState, `filters.${action.meta.id}`);
      break;
    }
    case 'UPDATE': {
      newState = {
        ...newState,
        filters: {
          ...newState.filters,
          [action.meta.id]: {
            ...newState.filters[action.meta.id],
            ...action.payload,
          },
        },
      };
      break;
    }
    case 'INSERT': {
      newState = {
        ...newState,
        filters: {
          ...filters,
          [action.meta.id]: {
            id: action.meta.id,
            ...action.payload,
          },
        },
        filtersOrder: [
          ...filtersOrder,
          action.meta.id,
        ],
      };
      break;
    }
    default:
    // ...
  }
  if (action.type === 'SUBMIT' || (action.meta && action.meta.submit)) {
    newState = {
      ...newState,
      newValue: map(newState.filtersOrder, id => newState.filters[id]),
    };
  }
  return newState;
};

export default reducer;
