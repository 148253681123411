import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  createSelector,
} from 'reselect';
import {
  compose,
  lifecycle,
  withHandlers,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import NLPResponseSelect from '../../../../../../common/selectors/NLPResponse';
import CountSelect from '../../../../../../common/selectors/Count';
import {
  apiNlpAllResponses,
} from '../../../../../../common/api/nlp';
import store from '../../../../store';
import TableResponses from './TableResponses';
import {
  identity,
  property,
  higherOrderSelector,
} from '../../../../../../common/utilsClient/selectors';

const selectNumberOfItems = higherOrderSelector(
  property('dashboardId'),
  dashboardId => store.get(`dashboards.${dashboardId}.responses.nItems`),
);

const selectPageSize = higherOrderSelector(
  property('dashboardId'),
  dashboardId => store.get(`dashboards.${dashboardId}.responses.pageSize`, identity, 5),
);

const selectCurrentPage = higherOrderSelector(
  property('dashboardId'),
  dashboardId => store.get(`dashboards.${dashboardId}.responses.currentPage`, identity, 0),
);

const selectNumberOfPages = createSelector(
  selectPageSize,
  selectNumberOfItems,
  (pageSize, numberOfItems) => {
    if (!pageSize || !numberOfItems) {
      return 0;
    }
    return Math.ceil(numberOfItems / pageSize);
  },
);

const selectCurrentPageIndex = createSelector(
  selectCurrentPage,
  selectNumberOfPages,
  (currentPage, numberOfPages) => {
    if (!currentPage || !numberOfPages) {
      return 0;
    }
    return Math.max(0, Math.min(currentPage - 1, numberOfPages - 1));
  },
);

const Container = compose(
  ddp({
    subscriptions: createSelector(
      property('projectId'),
      property('questionnaireId'),
      property('questionId'),
      selectCurrentPageIndex,
      selectPageSize,
      (projectId, questionnaireId, questionId, pageIndex, pageSize) => ({
        items: apiNlpAllResponses.withParams({
          projectId,
          questionnaireId,
          questionId,
          controlId: '$meta.id',
          pageIndex,
          resultsPerPage: pageSize,
        }),
      }),
    ),
    selectors: ({
      subscriptions,
    }) => ({
      nItems: selectNumberOfItems,
      pageSize: selectPageSize,
      currentPage: selectCurrentPage,
      totalItems: createSelector(
        CountSelect.one().whereIdEquals(
          createSelector(
            subscriptions,
            subs => `${apiNlpAllResponses.getName()}.${subs.items.id}`,
          ),
        ),
        doc => get(doc, 'count', null),
      ),
      items: NLPResponseSelect.all()
        .where(
          createSelector(
            createSelector(
              subscriptions,
              subs => `_pagination_${subs.items.id}`,
            ),
            selectCurrentPageIndex,
            (key, pageIndex) => record => record[key] && record[key].pageIndex === pageIndex,
          ),
        )
        .sort(
          createSelector(
            createSelector(
              subscriptions,
              subs => `_pagination_${subs.items.id}.index`,
            ),
            key => ({
              [key]: 1,
            }),
          ),
        ),
    }),
    renderLoader: null,
    subscriptionsUpdateDelay: 1000,
  }),
  withHandlers({
    onPageChange: ({
      dispatch,
      dashboardId,
    }) => currentPage => dispatch(
      store.set(
        `dashboards.${dashboardId}.responses.currentPage`,
        currentPage,
      ),
    ),
    onShowSizeChange: ({
      dispatch,
      dashboardId,
    }) => (current, pageSize) => dispatch(
      store.set(`dashboards.${dashboardId}.responses.pageSize`, pageSize),
    ),
    setNumberOfItems: ({
      dispatch,
      dashboardId,
    }) => nItems => dispatch(store.set(`dashboards.${dashboardId}.responses.nItems`, nItems)),
  }),
  lifecycle({
    componentDidMount() {
      const {
        dispatch,
        totalItems,
        setNumberOfItems,
      } = this.props;
      if (totalItems !== null) {
        dispatch(setNumberOfItems(totalItems));
      }
    },
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        totalItems,
        setNumberOfItems,
      } = this.props;
      if (totalItems !== null && totalItems !== prevProps.totalItems) {
        dispatch(setNumberOfItems(totalItems));
      }
    },
  }),
)(TableResponses);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default Container;
