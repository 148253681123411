import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useHistory,
} from 'react-router-dom';
import Text from '../Text';
import {
  theme,
} from '../../../utilsClient/cssHelpers';
import {
  getIsLastRoute,
  renderItem,
} from './helpers';
import {
  sizes as sizesStyles,
} from './styles';

const Component = styled.div`
  white-space: nowrap;

  ${props => props.size && sizesStyles[props.size]};
`;

const StyledSeparator = styled(Text.Span)`
  margin: 0 ${theme('space.1')};
`;

const Breadcrumb = ({
  size,
  routes,
}) => {
  const history = useHistory();

  return (
    <Component size={size}>
      {routes.map((route) => {
        const isLastRoute = getIsLastRoute(route, routes);

        return (
          <Text.Span key={route.key}>
            {renderItem(route, routes, history, size)}
            {!isLastRoute && <StyledSeparator>/</StyledSeparator>}
          </Text.Span>
        );
      })}
    </Component>
  );
};

const routeShape = {
  key: PropTypes.string,
  path: PropTypes.string,
  breadcrumbName: PropTypes.string,
  isActive: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClear: PropTypes.func,
};

routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));

Breadcrumb.propTypes = {
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  routes: PropTypes.arrayOf(PropTypes.shape(routeShape)),
};

Breadcrumb.defaultProps = {
  size: 'default',
  routes: [],
};

export default Breadcrumb;
