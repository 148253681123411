import store from './store';

const toBoolean = x => !!x;

export const getDeferredPrompt = store.get('deferredPrompt');
export const getIsTourOpen = store.get('isTourOpen', toBoolean);

// TODO: Get rid of

export const getIsActive = store.get('active');
