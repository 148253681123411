import PropTypes from 'prop-types';
import React from 'react';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import {
  withState,
  compose,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import styled from 'styled-components/macro';
import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import Suggestions from '../../common/components/Suggestions';
import {
  expandValueSet,
} from '../../common/api/terminology';
import {
  formFieldPropTypes,
  selectOptionsPropTypes,
} from './propTypes';

const StyledSuggestions = styled(Suggestions)`
  .ant-input {
    height: auto;
  }
`;

const autoSize = {
  minRows: 2,
};

const toOptions = result => map(
  result && result.expansion && result.expansion.contains,
  ({
    code,
    display,
  }) => ({
    value: code,
    label: display,
  }),
);

class FormFieldSuggestions extends React.Component {
  // NOTE: We need to do it to prevent results disappearing
  //       while the next query is evaluated.
  static getDerivedStateFromProps(props, state) {
    const {
      expandQueryResult,
    } = props;
    const newState = {
      ...state,
    };
    if (expandQueryResult && expandQueryResult !== state.expandQueryResult) {
      newState.expandQueryResult = expandQueryResult;
    }
    return newState;
  }

  constructor(props) {
    super(props);
    this.state = {
      expandQueryResult: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleSearch(searchText) {
    const {
      setSearchText,
    } = this.props;
    if (setSearchText) {
      setSearchText(searchText);
    }
  }

  render() {
    const {
      label,
      input,
      meta,
      required,
      placeholder,
      prefixSearchOnly,
      notFoundContent,
      options,
      'data-testid': datatestid,
    } = this.props;
    const {
      expandQueryResult,
    } = this.state;

    return (
      <FormFieldWrapper
        label={label}
        required={required}
        meta={meta}
      >
        <StyledSuggestions
          data-testid={datatestid}
          notFoundContent={notFoundContent}
          suggestions={map(
            uniqBy([
              ...options,
              ...toOptions(expandQueryResult),
            ], 'value'),
            'label',
          )}
          prefixSearchOnly={prefixSearchOnly}
          autoSize={autoSize}
          onSearch={this.handleSearch}
          placeholder={placeholder}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...input}
        />
      </FormFieldWrapper>
    );
  }
}

FormFieldSuggestions.propTypes = {
  input: formFieldPropTypes.input,
  meta: formFieldPropTypes.meta,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  prefixSearchOnly: PropTypes.bool,
  notFoundContent: PropTypes.string,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  expandQueryResult: PropTypes.any,
  mode: PropTypes.string,
  options: selectOptionsPropTypes,
  'data-testid': PropTypes.string,
};

FormFieldSuggestions.defaultProps = {
  input: null,
  meta: null,
  label: null,
  required: false,
  placeholder: null,
  prefixSearchOnly: false,
  notFoundContent: 'Nothing found',
  searchText: null,
  setSearchText: null,
  expandQueryResult: null,
  mode: null,
  options: null,
  'data-testid': 'select',
};

export default compose(
  withState('searchText', 'setSearchText', null),
  ddp({
    queries: (_, {
      valueSetId,
      searchText,
      prefixSearchOnly,
    }) => ({
      expandQueryResult: valueSetId
        ? expandValueSet.withParams({
          searchText,
          prefixSearchOnly,
          id: valueSetId,
        })
        : null,
    }),
    renderLoader: null,
  }),
)(FormFieldSuggestions);
