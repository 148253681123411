import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import keys from 'lodash/keys';
import map from 'lodash/map';
import has from 'lodash/has';

function getDescriptorType(descriptor) {
  if (!isPlainObject(descriptor)) {
    return null;
  }
  if (descriptor.type) {
    return descriptor.type;
  }
  if (descriptor._elementsOrder) {
    return 'array';
  }
  if (descriptor._elements) {
    return 'object';
  }
  const type = typeof descriptor.value;
  switch (type) {
    case 'number':
    case 'boolean':
    case 'string':
      return type;
    case 'object': {
      if (isArray(descriptor.value) || isPlainObject(descriptor.value)) {
        return 'json';
      }
      break;
    }
    default:
    // pass
  }
  return 'unknown';
}

export function expand(value) {
  if (isArray(value)) {
    const descriptor = {
      _elementsOrder: [],
      _elements: {},
    };
    forEach(value, (element, index) => {
      descriptor._elements[index] = expand(element);
      descriptor._elementsOrder.push(index.toString());
    });
    return descriptor;
  }
  if (isPlainObject(value)) {
    return {
      _elements: mapValues(value, expand),
    };
  }
  return {
    value,
  };
}

export function collapse(descriptor) {
  const type = getDescriptorType(descriptor);
  if (!type) {
    return undefined;
  }
  if (type === 'array') {
    return map(descriptor._elementsOrder, id => collapse(descriptor._elements[id]));
  }
  if (type === 'object') {
    return mapValues(descriptor._elements, collapse);
  }
  return descriptor.value;
}

const addToken = (list, id) => (token) => {
  const newToken = {
    ...token,
  };
  if (!token.id) {
    newToken.id = id;
  } else {
    newToken.hierarchyKey = newToken.hierarchyKey
      ? `${id}.${newToken.hierarchyKey}`
      : id;
  }
  list.push(newToken);
};

export function descriptorToTokens(descriptor) {
  const type = getDescriptorType(descriptor);
  if (!type) {
    return [];
  }
  if (type === 'array') {
    const list = [
      {
        type: 'array',
      },
    ];
    forEach(descriptor._elementsOrder, (id) => {
      forEach(descriptorToTokens(descriptor._elements[id]), addToken(list, id));
    });
    return list;
  }
  if (type === 'object') {
    const list = [
      {
        type: 'object',
      },
    ];
    forEach(descriptor._elements, (element, id) => {
      forEach(descriptorToTokens(element), addToken(list, id));
    });
    return list;
  }
  return [
    {
      type,
      value: descriptor.value,
    },
  ];
}

export const toBindingsArray = context => descriptorToTokens({
  _elements: context,
}).slice(1);

const isPrefix = (s1, s2) => {
  if (typeof s1 !== 'string') {
    return true;
  }
  if (typeof s2 !== 'string') {
    return false;
  }
  if (s1 === s2) {
    return true;
  }
  if (s1.length >= s2.length) {
    return false;
  }
  return s1 === s2.substr(0, s1.length) && s2.charAt(s1.length - 1) === '.';
};

function parse(tokens, start = 0) {
  const token = tokens[start];
  const n = tokens.length;
  const {
    id,
    type,
    value,
    hierarchyKey = '',
  } = token;
  let i = start + 1;
  const descriptor = {};
  if (type === 'object' || type === 'array') {
    const elements = {};
    const order = [];
    const hierarchyKeyPrefix = hierarchyKey ? `${hierarchyKey}.${id}` : id;
    while (i < n && isPrefix(hierarchyKeyPrefix, tokens[i].hierarchyKey)) {
      const parsed = parse(tokens, i);
      const elementId = parsed.id;
      elements[elementId] = parsed.descriptor;
      order.push(elementId);
      i = parsed.index;
    }
    descriptor._elements = elements;
    if (type === 'array') {
      descriptor._elementsOrder = order;
    }
  } else {
    descriptor.value = value;
  }
  const parsed = {
    descriptor,
    index: i,
  };
  if (id) {
    parsed.id = id;
  }
  return parsed;
}

export function tokensToDescriptor(tokens) {
  const parsed = parse(tokens, 0);
  return parsed.descriptor;
}

export function mergeDescriptors(oldDescriptor, newDescriptor) {
  const oldType = getDescriptorType(oldDescriptor);
  const newType = getDescriptorType(newDescriptor);
  if (!oldType && !newType) {
    return {};
  }
  if (!newType) {
    return oldDescriptor;
  }
  if (!oldType || oldType !== newType) {
    return newDescriptor;
  }
  const type = oldType;
  const merged = {
    ...oldDescriptor,
    ...newDescriptor,
  };
  if (type === 'object' || type === 'array') {
    // NOTE: It is theoretically possible that there will be an element listed in _elementsOrder,
    //       but we will not have any _elements definitions. This typically indicates a brand new
    //       element added to an empty array.
    const oldElements = oldDescriptor._elements || {};
    const newElements = newDescriptor._elements || {};
    let listOfIds;
    if (type === 'object') {
      listOfIds = union(keys(oldElements), keys(newElements));
    } else {
      listOfIds = newDescriptor._elementsOrder;
    }
    merged._elements = {};
    forEach(listOfIds, (id) => {
      if (getDescriptorType(newElements[id]) !== 'delete') {
        merged._elements[id] = mergeDescriptors(
          oldElements[id],
          newElements[id],
        );
      }
    });
  }
  return merged;
}

export function mergeFormValues(oldFormValues, newFormValues) {
  return mergeDescriptors(
    {
      _elements: oldFormValues,
    },
    {
      _elements: newFormValues,
    },
  )._elements;
}

export function getDiffDescriptor(
  oldDescriptor,
  newDescriptor,
  {
    skipOrthogonal = false,
  } = {},
) {
  const oldType = getDescriptorType(oldDescriptor);
  const newType = getDescriptorType(newDescriptor);
  if (!oldType && !newType) {
    return null;
  }
  if (!newType) {
    if (skipOrthogonal) {
      return null;
    }
    return {
      type: 'delete',
    };
  }
  if (!oldType) {
    if (skipOrthogonal) {
      return null;
    }
    return newDescriptor;
  }
  if (oldType !== newType) {
    return newDescriptor;
  }
  const result = {};
  const type = oldType;
  switch (type) {
    case 'array':
    case 'object': {
      const elements = {};
      forEach(newDescriptor._elements, (newValue, key) => {
        const oldValue =
          oldDescriptor._elements && oldDescriptor._elements[key];
        const elementDiff = getDiffDescriptor(oldValue, newValue, {
          skipOrthogonal,
        });
        if (!isEmpty(elementDiff)) {
          elements[key] = elementDiff;
        }
      });
      if (!skipOrthogonal) {
        forEach(oldDescriptor._elements, (value, key) => {
          if (!newDescriptor._elements || !has(newDescriptor._elements, key)) {
            elements[key] = {
              type: 'delete',
            };
          }
        });
      }
      if (!isEmpty(elements)) {
        result._elements = elements;
      }
      break;
    }
    case 'delete':
      // if both descriptors describe deletion, they're treated as the same thing
      break;
    default: {
      if (!isEqual(oldDescriptor.value, newDescriptor.value)) {
        result.value = newDescriptor.value;
      }
    }
  }
  if (type === 'array') {
    if (!isEqual(newDescriptor._elementsOrder, oldDescriptor._elementsOrder)) {
      result._elementsOrder = newDescriptor._elementsOrder;
    }
  }
  if (isEmpty(result)) {
    return null;
  }
  return {
    type,
    ...result,
  };
}

export const fromBindingsArray = (variables) => {
  if (!isArray(variables)) {
    return {};
  }
  const descriptor = tokensToDescriptor([
    {
      type: 'object',
    },
    ...variables,
  ]);
  return descriptor._elements || {};
};
