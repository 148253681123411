import React from 'react';

export default () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
  >
    <title>Settings</title>
    <g fillRule="nonzero">
      <path d="M10.086 9.8a.79.79 0 00-.781-.8H6.18a.79.79 0 00-.782.8v1.435H2v1.6h3.398V14.2c0 .442.35.8.782.8h3.125a.79.79 0 00.78-.8v-1.365H22v-1.6H10.086V9.8zm-1.563 3.6H6.961v-2.8h1.562v2.8zM18.836 16.8a.79.79 0 00-.781-.8H14.93a.79.79 0 00-.782.8v1.4H2v1.6h12.148v1.4c0 .442.35.8.782.8h3.125a.79.79 0 00.78-.8v-1.4H22v-1.6h-3.164v-1.4zm-1.563 3.6h-1.562v-2.8h1.562v2.8zM18.914 4.24V2.8a.79.79 0 00-.781-.8h-3.125a.79.79 0 00-.781.8v1.44H2v1.6h12.227V7.2c0 .442.35.8.78.8h3.126a.79.79 0 00.781-.8V5.84H22v-1.6h-3.086zM17.352 6.4h-1.563V3.6h1.563v2.8z" />
    </g>
  </svg>
);
