/* eslint-disable react/prop-types */
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import {
  compose,
} from 'redux';
import {
  withModal,
} from '../../../../utils/modal';
import Box from '../../../../common/components/primitives/Box';
import List from '../../../../common/components/List';
import Button from '../../../../common/components/Button';
import Modal from '../../../../components/dialogs/Modal';
import ActivityButton from './ActivityButton';

const StyledButton = styled(Button)`
  min-width: 90px;
`;

const MilestonesModalActivity = ({
  patientRecord,
  activity,
  onMilestoneClick,
}) => {
  const {
    state,
    activityId,
  } = activity;
  const milestoneId = activity && activity.milestoneId;
  const {
    recipientId,
  } = patientRecord;

  const handleMilestoneClick = () => onMilestoneClick({
    milestoneId,
    recipientId,
    activityId,
  });

  return (
    <ActivityButton
      patientRecord={patientRecord}
      state={state}
      activityId={activityId}
      milestoneId={milestoneId}
      onClick={handleMilestoneClick}
    />
  );
};

const MilestonesModal = compose(withModal())(
  ({
    modal,
    activities,
    patientRecord,
    onMilestoneClick,
  }) => {
    const {
      t,
    } = useTranslation();

    const handleMilestoneClick = (args) => {
      onMilestoneClick(args);
      modal.cancel();
    };

    const onCreateModal = () => {
      modal.render(
        <Modal
          title={t('milestone', {
            count: 0,
          })}
          onOk={modal.cancel}
          onCancel={modal.cancel}
          visible
        >
          <Box.Primary>
            <List
              dataSource={activities}
              renderItem={activity => (
                <MilestonesModalActivity
                  key={activity.activityId}
                  patientRecord={patientRecord}
                  activity={activity}
                  onMilestoneClick={handleMilestoneClick}
                />
              )}
            />
          </Box.Primary>
        </Modal>,
      );
    };

    return !isEmpty(activities) ? (
      <StyledButton
        size="small"
        type="link"
        onClick={onCreateModal}
      >
        {t('moreWithCount', {
          count: activities.length,
        })}
      </StyledButton>
    ) : (
      <div
        style={{
          width: 105,
        }}
      />
    );
  },
);

MilestonesModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activities: PropTypes.array,
};

MilestonesModal.defaultProps = {
  activities: [],
};

export default MilestonesModal;
