import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const Component = styled.img`
  border-radius: ${theme('border.radius.2')};

  /* Responsive */
  max-width: 100%;
  height: auto;
`;

const Image = ({
  src,
  alt,
}) => {
  return (
    <Component
      src={src}
      alt={alt}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
