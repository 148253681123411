import map from 'lodash/map';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';
import Chart from '../Chart';
import {
  CHART_TYPE__SERIAL_CHART,
  VALUE_AXIS_POSITIONS,
  GRAPH_TYPE__COLUMN,
  GRAPH_TYPE__LINE,
  GRAPH_TYPE__DOTS,
} from '../../../constants';

const DEFAULT_BULLET = {
  bullet: 'round',
  bulletColor: '#ffffff',
  useLineColorForBulletBorder: true,
  bulletBorderThickness: 3,
  bulletSize: 7,
  bulletBorderAlpha: 1,
};

// eslint-disable-next-line import/prefer-default-export
class ChartSerialChart extends Chart {
  constructor(doc) {
    super(doc);
    this.type = this.type || CHART_TYPE__SERIAL_CHART;
  }

  getAmChartValueAxes() {
    return map(this.valueAxes, axis => ({
      id: axis.id,
      position: axis.position || VALUE_AXIS_POSITIONS[0],
      title: axis.title,
      minimum: axis.minimum,
      maximum: axis.maximum,
      precision: axis.precision,
      gridThickness: 1,
    }));
  }

  getAmChartGraphOptions({
    valueField,
    colorField,
    color,
    id,
    title,
    type,
    columnWidth,
    bullets,
    valueAxis,
    mapToPercents,
  }) {
    let options = {
      title,
      id,
      valueField,
      fillColorsField: colorField,
      fillColors: color,
      lineColorField: colorField,
      lineColor: color,
      balloonText: `${title} ${
        this.getCategoryFieldId() ? 'in [[category]]' : ''
      }: <b>[[value]]</b>`,
      valueAxis,
    };
    if (this.settings.labelsEnabled) {
      if (mapToPercents) {
        options.labelText = '[[value]]%';
      } else {
        options.labelText = '[[value]]';
      }
    }
    switch (type) {
      case GRAPH_TYPE__COLUMN:
        options = {
          ...options,
          type: 'column',
          columnWidth: columnWidth || 0.35,
          lineThickness: 0,
          fillAlphas: 1,
          ...(bullets && {
            ...DEFAULT_BULLET,
          }),
        };
        break;
      case GRAPH_TYPE__LINE:
        options = {
          ...options,
          lineThickness: 3,
          fillAlphas: 0,
          ...(bullets && {
            ...DEFAULT_BULLET,
          }),
        };
        break;
      case GRAPH_TYPE__DOTS:
        options = {
          ...options,
          fillAlphas: 0,
          lineThickness: 0,
          ...DEFAULT_BULLET,
          bulletSize: 10,
        };
        break;
      default:
      // do nothing ...
    }
    return options;
  }

  getAmChartGraphs({
    useOverlayGraphs,
  } = {}) {
    const fieldsWithGraphs = filter(
      this.valueFields,
      field => !field.noGraph,
    );
    const graphs = [];
    forEach(fieldsWithGraphs, (valueField) => {
      const title = valueField.graphTitle || valueField.name;
      const {
        id,
      } = valueField;
      graphs.push(
        this.getAmChartGraphOptions({
          valueField: id,
          colorField:
            useOverlayGraphs && !valueField.useOverlayGraph
              ? `color.${id}`
              : null,
          color:
            useOverlayGraphs && valueField.useOverlayGraph ? 'lightgrey' : null,
          id,
          title,
          type: valueField.graphType,
          bullets: valueField.graphBullets,
          valueAxis: valueField.valueAxisId,
          mapToPercents: valueField.mapToPercents,
        }),
      );
      if (useOverlayGraphs && valueField.useOverlayGraph) {
        graphs.push(
          this.getAmChartGraphOptions({
            valueField: `overlay.${id}`,
            colorField: `color.${id}`,
            id: `overlay.${id}`,
            title:
              valueField.overlayGraphTitle ||
              valueField.graphTitle ||
              valueField.name,
            type: valueField.overlayGraphType,
            bullets: valueField.overlayGraphBullets,
            valueAxis: valueField.overlayValueAxisId,
            mapToPercents: valueField.mapToPercents,
          }),
        );
      }
    });
    return graphs;
  }

  getAmChartSettings(options) {
    return {
      ...super.getAmChartSettings(options),
      type: 'serial',
      theme: 'light',
      addClassNames: true,
      startDuration: 0,
      valueAxes: this.getAmChartValueAxes(),
      precision: 1,
      balloon: {
        shadowAlpha: 0,
      },
      graphs: this.getAmChartGraphs(options),
      categoryField: this.getCategoryFieldId() || '_category',
      categoryAxis: {
        title: this.getCategoryAxisTitle(),
        gridPosition: 'start',
        axisAlpha: 0,
        tickLength: 0,
        labelRotation: this.getCategoryAxisLabelRotation(),
      },
    };
  }
}

Chart.types[CHART_TYPE__SERIAL_CHART] = ChartSerialChart;

export default ChartSerialChart;
