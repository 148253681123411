import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, {
  useMemo,
} from 'react';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import Divider from '../../../common/components/Divider';
import Empty from '../../../common/components/base/Empty';
import NotificationLog from '../../../components/NotificationLog';
import NotificationLogSelect from '../../../common/selectors/NotificationLog';
import {
  apiZedocPatientActivityNotifications,
} from '../../../common/api/zedoc';

const constant = x => () => x;

const RemindersList = ({
  activityId,
}) => {
  useDDPSubscription(
    apiZedocPatientActivityNotifications.withParams({
      activityId,
    }),
  );

  const notifications = useSelector(
    useMemo(() => {
      return (
        NotificationLogSelect.all()
          .satisfying(
            notification => !!find(notification.reasonsToSend, {
              activityId,
            }),
          )
          // NOTE: Filter to hide legacy notifications.
          .satisfying(
            notification => notification.getState() && !notification.isAborted(),
          )
          .map(
            constant(notification => ({
              address: notification.getAddress(),
              state: notification.getState(),
              sentAt: notification.getTimestamp()
                ? moment(notification.getTimestamp()).format('DD/MM/YYYY HH:mm')
                : null,
              deliveryType: notification.getType(),
              numberOfMessageParts: notification.getNumberOfMessageParts(),
            })),
          )
      );
    }, [
      activityId,
    ]),
  );

  return (
    // TODO: Should be done via some kind of a <List /> component
    <Box.Primary>
      {!isEmpty(notifications) ? (
        <Stack>
          {notifications.map(
            (
              {
                address,
                deliveryType,
                state,
                sentAt,
                numberOfMessageParts,
              },
              i,
            ) => (
              <>
                {i !== 0 && <Divider />}
                <NotificationLog
                  address={address}
                  deliveryType={deliveryType}
                  state={state}
                  sentAt={sentAt}
                  numberOfMessageParts={numberOfMessageParts}
                />
              </>
            ),
          )}
        </Stack>
      ) : (
        <Empty />
      )}
    </Box.Primary>
  );
};

RemindersList.propTypes = {
  activityId: PropTypes.string.isRequired,
};

export default RemindersList;
