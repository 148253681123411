import filter from 'lodash/filter';
import React from 'react';
import {
  withRouter,
} from 'react-router';
import {
  connect,
} from 'react-redux';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import CurrentUserSelect from '../common/selectors/CurrentUser';
import Box from '../common/components/primitives/Box';
import Tabs from '../components/layouts/Tabs';

const RouterTabs = compose(
  withRouter,
  connect(() => createStructuredSelector({
    activeKey: (state, props) => props.match && props.match.params && props.match.params.tab,
    tabs: createSelector(
      (state, props) => props.tabs,
      CurrentUserSelect.getCurrentPermissionsValidator(),
      (tabs, validate) => filter(tabs, tab => !tab.permissions || validate(tab.permissions)),
    ),
  })),
  withHandlers({
    handleOnChange: ({
      history,
      path,
    }) => value => history.push(`${path}/${value}`),
  }),
)(({
  tabs,
  activeKey,
  handleOnChange,
}) => (
  <Box.Primary boxShadow="base">
    <Tabs
      activeKey={activeKey}
      tabs={tabs}
      onChange={handleOnChange}
    />
  </Box.Primary>
));

export default RouterTabs;
