import BaseModel from './BaseModel';

class ParticipationAndQuestionnaire extends BaseModel {}

ParticipationAndQuestionnaire.collection =
  'projections.ParticipationAndQuestionnaire';
ParticipationAndQuestionnaire.defaultSortingProperties = [
  'completedAt',
  '_id',
];

export default ParticipationAndQuestionnaire;
