import moment from 'moment';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import {
  levels,
} from './constants';

const createDefaultLogger = (colors) => {
  let print;
  if (colors) {
    print = (timestamp, level, topic, message) => console.log(
      `%c${timestamp}%c @${topic} %c[${level.toUpperCase()}]: ${message}`,
      'background: navy; color: white',
      'color: magenta',
      colors[level],
    );
  } else {
    print = (timestamp, level, topic, message) => console.log(
      `${timestamp} @${topic} [${level.toUpperCase()}]: ${message}`,
    );
  }
  const log = ({
    level,
    message,
    stack,
    topic,
    ...other
  }) => {
    const timestamp = moment().format('HH:mm:ss.SSS');
    print(timestamp, level, topic, message);
    if (!isEmpty(other)) {
      console.log(other);
    }
    if (stack) {
      console.warn(stack);
    }
  };
  const createLogLevel = level => (message, props) => log({
    level,
    message,
    ...props,
  });
  const logger = {
    log,
  };
  forEach(levels, (_, level) => {
    logger[level] = createLogLevel(level);
  });
  return logger;
};

export default createDefaultLogger;
