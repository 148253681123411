import isNil from 'lodash/isNil';
import React from 'react';
import PropTypes from 'prop-types';
import FormFieldWrapper from '../FormFieldWrapper';
import Input from '../Input';
import {
  formFieldPropTypesInput,
  formFieldPropTypesMeta,
} from '../../utilsClient/propTypes';
import {
  getCountryPrefix,
} from './helpers';

const FormFieldPhoneNumber = ({
  'data-testid': testId,
  label,
  required,
  disabled,
  autoFocus,
  defaultCountry,
  placeholder,
  input,
  meta,
}) => {
  const {
    onChange,
  } = input;
  let {
    value,
  } = input;
  if (isNil(value)) {
    value = getCountryPrefix(defaultCountry);
  }

  const handleOnChange = event => onChange(event.target.value);
  return (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
    >
      <Input
        data-testid={testId}
        inputMode="tel"
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
    </FormFieldWrapper>
  );
};

FormFieldPhoneNumber.propTypes = {
  input: formFieldPropTypesInput.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultCountry: PropTypes.string,
};

FormFieldPhoneNumber.defaultProps = {
  'data-testid': 'form-field-phone-number',
  label: null,
  required: false,
  disabled: false,
  autoFocus: false,
  placeholder: null,
  defaultCountry: null,
};

export default FormFieldPhoneNumber;
