import Slider from './LazySlider';
import SliderVertical from './SliderVertical';
import SliderHorizontal from './SliderHorizontal';
import Handle from './Handle';

Slider.Vertical = SliderVertical;
Slider.Horizontal = SliderHorizontal;

export default Slider;

export {
  Handle,
};
