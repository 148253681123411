import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import AnalyticsDashboardFilterOption from '../../../../common/models/AnalyticsDashboardFilterOption';
import {
  apiAnalyticsDashboardFilterOptions,
} from '../../../../common/api/analytics';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters from './defaultFilters';
import useAnalyticsFilters from '../../../../utils/useAnalyticsFilters';
import FiltersToolbar from '../FiltersToolbar';

const AnalyticsDashboardFilterOptionSelect = createSelectors(
  AnalyticsDashboardFilterOption,
  {},
);

const ConnectedFilters = ({
  storeKey,
  projectId,
  dashboardId,
}) => {
  const presets = useAnalyticsFilters({
    dashboardId,
    defaultFilters,
  });
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      currentFilters,
      type,
      condition,
      state,
      settings,
    }) => {
      return apiAnalyticsDashboardFilterOptions.withParams({
        projectId,
        dashboardId,
        controlId: '$meta.id',
        searchText,
        filters: currentFilters,
        filter: {
          type,
          condition,
          state,
          settings,
        },
      });
    },
    [
      projectId,
      dashboardId,
    ],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      presets={presets}
      optionsSelector={AnalyticsDashboardFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default ConnectedFilters;
