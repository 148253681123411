import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  createStructuredSelector,
  createSelector,
} from 'reselect';
import {
  connect,
} from 'react-redux';
import {
  PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
} from '../../../../../common/permissions';
import {
  ROLE_TIER__HIGH,
} from '../../../../../common/constants';
import AnswersSheet from '../../../../../common/models/AnswersSheet';
import CurrentUserSelect from '../../../../../common/selectors/CurrentUser';
import {
  property,
} from '../../../../../common/utilsClient/selectors';
import FormActions from './FormActions';
import {
  dialog as reassignAnswersSheet,
} from '../../ReassignAnswersSheet';
import {
  setActiveAnswersSheetId,
  openAnswersSheetDialog,
  closeAnswersSheetDialog,
  openRemoveAnswersSheetDialog,
} from '../../../actions';
import {
  withModal,
} from '../../../../../utils/modal';

const Container = compose(
  connect(() => {
    const selectAnswersSheet = property('answersSheet');
    return createStructuredSelector({
      answersSheetId: createSelector(
        selectAnswersSheet,
        answersSheet => answersSheet && answersSheet._id,
      ),
      canRemove: createSelector(
        selectAnswersSheet,
        answersSheet => answersSheet && !answersSheet.isStarted(),
      ),
      canReassign: createSelector(
        selectAnswersSheet,
        CurrentUserSelect.user(),
        CurrentUserSelect.getPermissionGrant(
          PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
        ),
        (answersSheet, currentUser, permission) => {
          if (answersSheet && currentUser && permission) {
            return (
              answersSheet.getAssigneeId() !== currentUser._id &&
              (answersSheet.canBeCompletedByUser(currentUser) ||
                permission.tier === ROLE_TIER__HIGH)
            );
          }
          return false;
        },
      ),
    });
  }),
  withModal(),
  withHandlers({
    onClose: ({
      dispatch,
    }) => () => dispatch(closeAnswersSheetDialog()),
    onReassign: ({
      dispatch,
      answersSheetId,
    }) => () => dispatch(
      reassignAnswersSheet.open({
        answersSheetId,
      }),
    ),
    onNotesOpen: ({
      dispatch,
      answersSheetId,
    }) => () => dispatch(
      openAnswersSheetDialog({
        answersSheetId,
      }),
    ),
    onRequestRemove: ({
      dispatch,
      answersSheetId,
    }) => () => {
      dispatch(setActiveAnswersSheetId(answersSheetId));
      dispatch(openRemoveAnswersSheetDialog());
    },
  }),
)(FormActions);

Container.propTypes = {
  answersSheet: PropTypes.instanceOf(AnswersSheet).isRequired,
};

export default Container;
