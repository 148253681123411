import React from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  SubmissionError,
} from 'redux-form';
import {
  createAccount,
} from '../../common/utilsClient/ddp/actions';
import SignUpForm from './SignUpForm';

const SignUp = ({
  onSubmit,
}) => (
  <SignUpForm
    form="entry"
    onSubmit={onSubmit}
  />
);

SignUp.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(createAccount(data)).catch((err) => {
    throw new SubmissionError({
      _error: err.message,
    });
  }),
});

const DecoratedSignUp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUp);

export default DecoratedSignUp;
