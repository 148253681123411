import { LinkOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React from 'react';
import copyToClipboard from '../../utilsClient/copyToClipboard';
import message from '../../utilsClient/message';
import Button from '../Button';

const Clipboard = ({
  text,
}) => {
  const onCopyToClipboard = () => copyToClipboard(text, () => message.success('Copied to clipboard!'));

  return (
    <Button
      icon={<LinkOutlined />}
      onClick={onCopyToClipboard}
    >
      Copy
    </Button>
  );
};

Clipboard.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Clipboard;
