import {
  SearchOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import Button from '../../common/components/Button';
import Input, {
  InputGroup,
} from '../../common/components/Input';
import AutoComplete from '../../common/components/AutoComplete';

const filterOption = (inputValue, option) => {
  if (option.key === 'preset:0') {
    return true;
  }
  if (typeof option.label !== 'string' || typeof inputValue !== 'string') {
    return false;
  }
  if (!inputValue) {
    return true;
  }
  const index = option.label.toLowerCase().indexOf(inputValue.toLowerCase());
  return index >= 0;
};

const Search = React.forwardRef(
  ({
    options,
    value,
    active,
    onChange,
    onSelect,
    onFocus,
  }, forwardedRef) => {
    const {
      t,
    } = useTranslation();

    return (
      <InputGroup>
        <AutoComplete
          ref={forwardedRef}
          options={options}
          value={value}
          onSearch={onChange}
          onSelect={onSelect}
          onFocus={onFocus}
          filterOption={filterOption}
        >
          <Input
            data-testid="filters-search-input"
            placeholder={t('search')}
          />
        </AutoComplete>
        <Button
          type={active ? 'primary' : null}
          icon={<SearchOutlined />}
          disabled={!active}
          data-testid="filters-search-button"
        />
      </InputGroup>
    );
  },
);

Search.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
  onFocus: PropTypes.func,
};

Search.defaultProps = {
  options: [],
  value: null,
  active: false,
  onChange: null,
  onSelect: null,
  onFocus: null,
};

export default Search;
