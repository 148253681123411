import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import {
  useTranslation,
} from 'react-i18next';
import Text from '../../../common/components/base/Text';
import Checkbox from '../../../common/components/Checkbox';
import Center from '../../../common/components/primitives/Center';
import Stack from '../../../common/components/primitives/Stack';
import Table from '../../../common/components/Table';
import {
  RESOURCE_TYPE__WORKFLOW,
  RESOURCE_TYPE__REPORT,
  RESOURCE_TYPE__PROJECT,
  RESOURCE_TYPE__PATIENT,
  RESOURCE_TYPE__QUESTIONNAIRE,
  RESOURCE_TYPE__PATIENT_NOTE,
  RESOURCE_TYPE__DASHBOARD,
  RESOURCE_TYPE__MILESTONE,
  RESOURCE_TYPE__PARTICIPATION,
  RESOURCE_TYPE__ACTIVITY,
  RESOURCE_TYPE__ANSWERS_SHEET,
  RESOURCE_TYPE__AUDIT_LOG,
  RESOURCE_TYPE__USER,
  RESOURCE_TYPE__DOMAIN,
  RESOURCE_TYPE__GROUP,
  RESOURCE_TYPE__ROLE,
  RESOURCE_TYPE__VARIABLE,
  RESOURCE_TYPE__EAPP,
} from '../../../common/permissions/new';

const getLabelForResourceType = (resourceType) => {
  switch (resourceType) {
    case RESOURCE_TYPE__WORKFLOW:
      return 'permissions:resource.workflow';
    case RESOURCE_TYPE__REPORT:
      return 'permissions:resource.report';
    case RESOURCE_TYPE__PROJECT:
      return 'permissions:resource.project';
    case RESOURCE_TYPE__PATIENT:
      return 'permissions:resource.patient';
    case RESOURCE_TYPE__QUESTIONNAIRE:
      return 'permissions:resource.questionnaire';
    case RESOURCE_TYPE__PATIENT_NOTE:
      return 'permissions:resource.patientNote';
    case RESOURCE_TYPE__DASHBOARD:
      return 'permissions:resource.dashboard';
    case RESOURCE_TYPE__MILESTONE:
      return 'permissions:resource.milestone';
    case RESOURCE_TYPE__PARTICIPATION:
      return 'permissions:resource.participation';
    case RESOURCE_TYPE__ACTIVITY:
      return 'permissions:resource.activity';
    case RESOURCE_TYPE__ANSWERS_SHEET:
      return 'permissions:resource.answersSheet';
    case RESOURCE_TYPE__AUDIT_LOG:
      return 'permissions:resource.auditLog';
    case RESOURCE_TYPE__USER:
      return 'permissions:resource.user';
    case RESOURCE_TYPE__DOMAIN:
      return 'permissions:resource.domain';
    case RESOURCE_TYPE__GROUP:
      return 'permissions:resource.group';
    case RESOURCE_TYPE__ROLE:
      return 'permissions:resource.role';
    case RESOURCE_TYPE__VARIABLE:
      return 'permissions:resource.variable';
    case RESOURCE_TYPE__EAPP:
      return 'permissions:resource.eapp';
    default:
      return '';
  }
};

const renderLabel = (permission, t) => {
  return (
    <>
      <strong>
        {'['}
        {t(getLabelForResourceType(permission.resourceType))}
        {']'}
      </strong>
      {' '}
      <span>{t(permission.label)}</span>
    </>
  );
};

const PermissionsGroup = ({
  role,
  onChange,
  options,
}) => {
  const {
    t,
  } = useTranslation();
  const labels = uniq(
    map(
      filter(options, {
        showInTable: true,
      }),
      'label',
    ),
  );

  const onOneOffPermissionChange = useCallback(
    (perm, event) => {
      const roleCopy = cloneDeep(role);
      const index = findIndex(roleCopy.permissions, {
        key: perm.value,
      });
      const state = event.target.checked;
      if (index > -1) {
        if (state) {
          roleCopy.permissions[index] = {
            key: perm.value,
          };
        } else {
          roleCopy.permissions.splice(index, 1);
        }
      } else {
        roleCopy.permissions.push({
          key: perm.value,
        });
      }
      onChange(roleCopy);
    },
    [
      role,
      onChange,
    ],
  );

  const onGeneralPermissionChange = useCallback(
    ({
      label,
      permissions,
    }, event) => {
      const roleCopy = cloneDeep(role);
      const permByLabel = find(permissions, {
        label,
      });
      const index = !permByLabel
        ? -1
        : findIndex(roleCopy.permissions, {
          key: permByLabel.value,
        });
      const state = event.target.checked;
      if (index > -1) {
        if (state) {
          roleCopy.permissions[index] = {
            key: permByLabel.value,
          };
        } else {
          roleCopy.permissions.splice(index, 1);
        }
      } else if (permByLabel) {
        roleCopy.permissions.push({
          key: permByLabel.value,
        });
      }

      onChange(roleCopy);
    },
    [
      role,
      onChange,
    ],
  );

  const generalPermissions = filter(options, {
    showInTable: true,
  });
  const oneOffPermissions = filter(options, opt => !opt.showInTable);

  const mappedOneOffPermissions = oneOffPermissions.map((oop) => {
    const copy = cloneDeep(oop);
    const checked = !!find(role.permissions, {
      key: copy.value,
    });
    copy.checked = checked;
    return copy;
  });

  const groupedGeneralPermissions = reduce(
    generalPermissions,
    (acc, cur) => {
      const index = findIndex(acc, {
        resourceType: cur.resourceType,
      });

      // check if this permission option already
      // exists in this role
      const checked = !!find(role.permissions, {
        key: cur.value,
      });

      if (index >= 0) {
        const found = acc[index];
        found.permissions.push({
          label: cur.label,
          value: cur.value,
          checked,
        });
        acc.splice(index, 1, found);
      } else {
        acc.push({
          key: cur.resourceType,
          resourceType: cur.resourceType,
          permissions: [
            {
              label: cur.label,
              value: cur.value,
              checked,
            },
          ],
        });
      }
      return acc;
    },
    [],
  );

  const columns = [
    {
      dataIndex: 'resourceType',
      key: 'resourceType',
      render: value => t(getLabelForResourceType(value)),
    },
    ...labels.map(label => ({
      title: <Text.Paragraph align="center">{t(label)}</Text.Paragraph>,
      dataIndex: 'permissions',
      key: label,
      render: (_, {
        permissions,
        resourceType,
      }) => {
        const columnPermission = find(permissions, {
          label,
        });
        const checked = !!(columnPermission && columnPermission.checked);
        return (
          columnPermission && (
            <Center
              intrinsic
              key={`${resourceType}:${label}`}
            >
              <Checkbox
                checked={checked}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onGeneralPermissionChange.bind(null, {
                  label,
                  permissions,
                })}
                disabled={!columnPermission}
              />
            </Center>
          )
        );
      },
    })),
  ];

  return (
    <Stack>
      {!isEmpty(groupedGeneralPermissions) && (
        <Stack>
          <Text.Heading level={3}>
            {t('permissions:generalPermissions')}
          </Text.Heading>
          <Table
            dataSource={groupedGeneralPermissions}
            columns={columns}
            pagination={null}
          />
        </Stack>
      )}
      {!isEmpty(mappedOneOffPermissions) && (
        <Stack>
          <Text.Heading level={3}>
            {t('permissions:oneOffPermissions')}
          </Text.Heading>
          <Stack space={0}>
            {mappedOneOffPermissions.map(oop => (
              // TODO: Remove extra div when moving checkboxes to block level
              // It can be achieved after moving away from Ant Design
              <div>
                <Checkbox
                  key={oop.value}
                  checked={oop.checked}
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={onOneOffPermissionChange.bind(null, oop)}
                >
                  {renderLabel(oop, t)}
                </Checkbox>
              </div>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

PermissionsGroup.propTypes = {
  role: PropTypes.shape({
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
      }),
    ),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      showInTable: PropTypes.bool,
      resourceType: PropTypes.string,
    }),
  ),
};

PermissionsGroup.defaultProps = {
  options: [],
};

export default PermissionsGroup;
