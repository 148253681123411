import DashboardExternalIFrame from './DashboardExternalIFrame';
import {
  PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__ANSWERS,
} from '../../../../common/constants';
import {
  registerType,
} from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__EXTERNAL_IFRAME, {
  component: DashboardExternalIFrame,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ASSIGNMENTS,
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ACTIVITIES,
    },
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__ANSWERS,
    },
  ],
});

export default DashboardExternalIFrame;
