import {
  QUESTION_TYPE__PHONE,
} from '../../../constants';
import Question from '../Question';

export default class QuestionPhone extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__PHONE;
  }
}

QuestionPhone.expectedValueType = {
  type: 'string',
  format: 'phone',
};

QuestionPhone.editorSettings = {};

Question.types[QUESTION_TYPE__PHONE] = QuestionPhone;
