import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withHandlers,
} from 'recompose';
import {
  connect,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  Field,
  reduxForm,
  submit,
} from 'redux-form';
import Schema from '../../../../common/utils/Schema';
import {
  QUESTION_TYPE__FREE_TEXT,
} from '../../../../common/constants';
import Stack from '../../../../common/components/primitives/Stack';
import Text from '../../../../common/components/base/Text';
import FormFieldText from '../../../../components/forms/FormFieldText';

const DEFAULT_FORM = 'screens_patients_directory__editNote';
const EditNoteForm = reduxForm({
  form: DEFAULT_FORM,
  validate: new Schema({
    content: {
      type: String,
    },
  }).validator({
    noException: true,
  }),
})(({
  error,
  handleSubmit,
  onSubmit,
  disabled,
}) => {
  const {
    t,
  } = useTranslation('forms');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <Field
          data-testid="note-textarea"
          component={FormFieldText}
          type={QUESTION_TYPE__FREE_TEXT}
          name="content"
          placeholder={t('note.placeholder')}
          disabled={disabled}
          autoFocus
        />
        {error && (
          <Text.Paragraph type="danger">
            <strong>{error}</strong>
          </Text.Paragraph>
        )}
      </Stack>
    </form>
  );
});

const EditNote = compose(
  connect(
    () => ({}),
    (dispatch, {
      form,
    }) => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
  withProps(({
    content: initialContent,
  }) => ({
    initialValues: {
      content: initialContent,
    },
  })),
  withHandlers({
    onSubmit: ({
      id,
      recipientId,
      onCreate,
      onUpdate,
    }) => ({
      content,
    }) => (id
      ? onUpdate({
        recipientId,
        content,
        id,
      })
      : onCreate({
        recipientId,
        content,
      })),
  }),
)(({
  form,
  onCancel,
  onSubmit,
  doSubmit,
  initialValues,
  editing,
}) => (editing ? (
  <EditNoteForm
    form={form}
    onSubmit={onSubmit}
    initialValues={initialValues}
    disabled={!editing}
  />
) : (
  <Text.Paragraph>{initialValues.content}</Text.Paragraph>
)));

EditNote.propTypes = {
  form: PropTypes.string,
  recipientId: PropTypes.string,
  editing: PropTypes.bool,
  id: PropTypes.string,
  content: PropTypes.string,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

EditNote.defaultProps = {
  id: null,
  form: DEFAULT_FORM,
  recipientId: null,
  editing: false,
  content: '',
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};

export default EditNote;
