import omit from 'lodash/omit';
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  createStructuredSelector,
  createSelector,
} from 'reselect';
import {
  branch,
  compose,
  renderNothing,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  saveAs,
} from 'file-saver';
import {
  useTranslation,
} from 'react-i18next';
import QuestionnaireSelect from '../../common/selectors/Questionnaire';
import {
  one as oneQuestionnaire,
} from '../../common/api/collections/Questionnaires2';
import Center from '../../common/components/primitives/Center';
import Stack from '../../common/components/primitives/Stack';
import PageBar from '../../components/Layout/PageBar';
import SingleScreenQuestionnaire from './components/SingleScreenQuestionnaire';
import {
  property,
} from '../../common/utilsClient/selectors';
import useDocumentTitle from '../../utils/useDocumentTitle';
import {
  ButtonLink,
} from '../../common/components/Button';

const identity = x => x;
const selectQuestionnaireId = createSelector(
  property('match.params.questionnaireId'),
  // this can be removed after react-router@4.4.0 is released, see:
  // https://github.com/ReactTraining/react-router/issues/4605#issuecomment-284912141
  decodeURIComponent,
);

const Sandbox = compose(
  ddp({
    subscriptions: createSelector(selectQuestionnaireId, questionnaireId => [
      oneQuestionnaire.withParams(questionnaireId),
    ]),
    selectors: () => createStructuredSelector({
      questionnaire: QuestionnaireSelect.one().whereIdEquals(
        selectQuestionnaireId,
      ),
    }),
  }),
  branch(({
    questionnaire,
  }) => !!questionnaire, identity, renderNothing),
)(({
  questionnaire,
}) => {
  const {
    t,
  } = useTranslation();

  useDocumentTitle([
    t('questionnaire', {
      count: 0,
    }),
    questionnaire.getName(),
  ]);

  const handleCopy = useCallback(() => {
    const properties = omit(questionnaire, [
      '_id',
    ]);
    const blob = new Blob([
      JSON.stringify(properties, null, 2),
    ], {
      type: 'application/json',
    });
    saveAs(blob, questionnaire._id);
  }, [
    questionnaire,
  ]);

  return (
    <Stack>
      <PageBar
        title={t('questionnaire', {
          count: 0,
        })}
        subTitle={(
          <ButtonLink onClick={handleCopy}>
            {questionnaire.getName()}
            {' '}
            {questionnaire.getVersion()}
          </ButtonLink>
        )}
        backUrl="/forms"
      />
      <Center>
        <SingleScreenQuestionnaire questionnaire={questionnaire} />
      </Center>
    </Stack>
  );
});

Sandbox.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      questionnaireId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Sandbox;
