import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import {
  useDispatch,
} from 'react-redux';
import {
  compose,
  mapProps,
} from 'recompose';
import {
  Field,
  reduxForm,
  submit,
} from 'redux-form';
import {
  useTranslation,
} from 'react-i18next';
import Schema from '../../../common/utils/Schema';
import User from '../../../common/models/User';
import Stack from '../../../common/components/primitives/Stack';
import Spacer from '../../../common/components/primitives/Spacer';
import Text from '../../../common/components/base/Text';
import FormFieldPhoneNumber from '../../../common/components/FormFieldPhoneNumber';
import FormFieldText from '../../forms/FormFieldText';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldCheckbox from '../../forms/FormFieldCheckbox';
import Modal from '../Modal';
import {
  ADMIN_UPDATE_USER_PHONES,
  ADMIN_UPDATE_USER_EMAILS,
} from '../../../common/permissions';
import usePermission from '../../../utils/usePermission';

const DEFAULT_FORM = 'EditUser';
const EditUserForm = reduxForm({
  form: DEFAULT_FORM,
  validate: new Schema({
    name: {
      type: String,
    },
    email: {
      type: String,
      regEx: Schema.RegEx.Email,
    },
    phone: {
      type: String,
      optional: true,
    },
    roles: {
      type: [
        String,
      ],
      minCount: 1,
    },
    groups: {
      type: [
        String,
      ],
      minCount: 1,
    },
    locked: {
      type: Boolean,
      optional: true,
    },
    resetPassword: {
      type: Boolean,
      optional: true,
    },
  }).validator({
    noException: true,
  }),
})(
  ({
    error,
    handleSubmit,
    onSubmit,
    groupedRolesOptions,
    groupedGroupsOptions,
    create,
    readOnly,
    canEditPhones,
    canEditEmails,
  }) => {
    const {
      t,
    } = useTranslation();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          {error && <p>{error}</p>}
          <Spacer space={2}>
            <Text.Paragraph importance="high">
              {t('personalInfo')}
            </Text.Paragraph>
          </Spacer>
          <Field
            data-testid="form-field-name"
            component={FormFieldText}
            name="name"
            type="text"
            label={t('forms:name.label')}
            disabled={readOnly}
          />
          <Field
            data-testid="form-field-email"
            component={FormFieldText}
            name="email"
            type="email"
            label={t('forms:email.label')}
            disabled={!create && (!canEditEmails || readOnly)}
          />
          <Field
            data-testid="form-field-phone"
            component={FormFieldPhoneNumber}
            name="phone"
            type="text"
            label={t('forms:phone.label')}
            disabled={!create && (!canEditPhones || readOnly)}
          />
          <Spacer space={2}>
            <Text.Paragraph importance="high">
              {t('rolesAndPermissions')}
            </Text.Paragraph>
          </Spacer>
          <Field
            data-testid="form-field-roles"
            component={FormFieldSelect}
            name="roles"
            label={t('forms:roles.label')}
            options={groupedRolesOptions}
            disabled={readOnly}
            mode="multiple"
            showSearch
          />
          <Field
            data-testid="form-field-groups"
            component={FormFieldSelect}
            name="groups"
            label={t('forms:groups.label')}
            options={groupedGroupsOptions}
            disabled={readOnly}
            mode="multiple"
            showSearch
          />
          <Field
            data-testid="form-field-locked"
            component={FormFieldCheckbox}
            name="locked"
            label={t('lockAccount')}
            disabled={readOnly}
          />
          <Spacer space={2}>
            <Text.Paragraph importance="high">
              {t('additionalActions')}
            </Text.Paragraph>
          </Spacer>
          <Field
            data-testid="form-field-resetPassword"
            component={FormFieldCheckbox}
            name="resetPassword"
            label={
              create ? t('sendEnrollmentEmail') : t('sendPasswordResetEmail')
            }
            disabled={readOnly}
          />
        </Stack>
      </form>
    );
  },
);

const getAllowedValues = (values, groupedOptions) => {
  const allowedValues = {};
  forEach(groupedOptions, ({
    options,
  }) => {
    forEach(options, ({
      value,
    }) => {
      allowedValues[value] = true;
    });
  });
  return filter(values, value => !!allowedValues[value]);
};

const EditUser = compose(
  mapProps(
    ({
      user,
      onCreate,
      onUpdate,
      readOnly,
      groupedRolesOptions,
      groupedGroupsOptions,
      ...rest
    }) => ({
      ...rest,
      user,
      readOnly,
      groupedRolesOptions,
      groupedGroupsOptions,
      onSubmit: (data) => {
        if (user) {
          return onUpdate({
            ...data,
            _id: user._id,
          });
        }
        return onCreate({
          ...data,
        });
      },
      initialValues: {
        roles: [],
        groups: [],
        resetPassword: !user,
        ...(user && {
          name: user.getFullName(),
          phone: user.getPhoneNumber(),
          email: user.getEmailAddress(),
          roles: getAllowedValues(user.getRolesIds(), groupedRolesOptions),
          groups: getAllowedValues(user.getGroupsIds(), groupedGroupsOptions),
          locked: user.isLocked(),
        }),
      },
    }),
  ),
)(
  ({
    open,
    user,
    form,
    onCancel,
    isConfirmLoading,
    readOnly,
    onSubmit,
    groupedRolesOptions,
    groupedGroupsOptions,
    initialValues,
  }) => {
    const dispatch = useDispatch();
    const doSubmit = useCallback(() => {
      dispatch(submit(form));
    }, [
      form,
      dispatch,
    ]);
    const canEditPhones = usePermission(ADMIN_UPDATE_USER_PHONES);
    const canEditEmails = usePermission(ADMIN_UPDATE_USER_EMAILS);
    const {
      t,
    } = useTranslation();

    return (
      <Modal
        title={user ? t('editUser') : t('addUser')}
        visible={open}
        onOk={doSubmit}
        onCancel={onCancel}
        confirmLoading={isConfirmLoading}
      >
        <EditUserForm
          form={form}
          create={!user}
          readOnly={readOnly}
          canEditPhones={canEditPhones}
          canEditEmails={canEditEmails}
          onSubmit={onSubmit}
          groupedRolesOptions={groupedRolesOptions}
          groupedGroupsOptions={groupedGroupsOptions}
          initialValues={initialValues}
        />
      </Modal>
    );
  },
);

EditUser.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(User),
  groupedRolesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  groupedGroupsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  isConfirmLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

EditUser.defaultProps = {
  form: DEFAULT_FORM,
  user: null,
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
  isConfirmLoading: false,
  readOnly: false,
};

export default EditUser;
