export const levels = {
  fatal: 0,
  alert: 1, // requires immediate action, otherwise system unstable
  critical: 2, // probably a serious problem, needs investigation
  error: 3, // error, requires attention but not urgent
  warn: 4, // not an error but something is not good
  info: 5, // default operational level
  verbose: 6,
  debug: 7,
  trace: 8,
};

export const valueToLevel = {
  0: 'fatal',
  1: 'alert',
  2: 'critical',
  3: 'error',
  4: 'warn',
  5: 'info',
  6: 'verbose',
  7: 'debug',
  8: 'trace',
};
