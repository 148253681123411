import moment from 'moment';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import last from 'lodash/last';
import initial from 'lodash/initial';
import tail from 'lodash/tail';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import compact from 'lodash/compact';
import map from 'lodash/map';
import React, {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import Timeline from '../../../../common/components/Timeline';
import Stack from '../../../../common/components/primitives/Stack';
import ProjectSelect from '../../../../common/selectors/Project';
import {
  ACTIVITY_STATE__PLANNED,
  ACTIVITY_STATE__SCHEDULED,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__EXPIRED,
  ACTIVITY_STATE__COMPLETED,
  ACTIVITY_STATE__ABORTED,
  ACTIVITY_STATE__CANCELED,
  ACTIVITY_STATE__OMITTED,
  ACTIVITY_STATE__SUSPENDED,
  YEAR_MONTH_DAY,
  TIME_OF_DAY,
} from '../../../../common/constants';
import MilestonesModal from './MilestonesModal';
import ActivityButton from './ActivityButton';
import ActivityStatus from './ActivityStatus';
import PatientRecord from '../../../../common/models/PatientRecord';
import ProjectMilestone from '../../../../common/models/ProjectMilestone';

const orderingNumber = {
  [ACTIVITY_STATE__CANCELED]: 0,
  [ACTIVITY_STATE__ABORTED]: 1,
  [ACTIVITY_STATE__OMITTED]: 2,
  [ACTIVITY_STATE__EXPIRED]: 3,
  [ACTIVITY_STATE__COMPLETED]: 4,
  [ACTIVITY_STATE__ACTIVE]: 5,
  [ACTIVITY_STATE__SUSPENDED]: 6,
  [ACTIVITY_STATE__SCHEDULED]: 7,
  [ACTIVITY_STATE__PLANNED]: 8,
};

const timelinePlacement = {
  [ACTIVITY_STATE__CANCELED]: 'left',
  [ACTIVITY_STATE__ABORTED]: 'left',
  [ACTIVITY_STATE__OMITTED]: 'left',
  [ACTIVITY_STATE__EXPIRED]: 'left',
  [ACTIVITY_STATE__COMPLETED]: 'left',
  [ACTIVITY_STATE__ACTIVE]: 'today',
  [ACTIVITY_STATE__SUSPENDED]: 'today',
  [ACTIVITY_STATE__SCHEDULED]: 'right',
  [ACTIVITY_STATE__PLANNED]: 'right',
};

const Activity = ({
  activity,
  patientRecord,
  onMilestoneClick,
}) => {
  if (!activity) {
    return <div />;
  }

  const {
    state,
    activityId,
  } = activity;
  const milestoneId = activity && activity.milestoneId;
  const {
    recipientId,
  } = patientRecord;

  const handleMilestoneClick = () => onMilestoneClick({
    milestoneId,
    recipientId,
    activityId,
  });
  const handleStatusClick = () => onMilestoneClick({
    milestoneId,
    recipientId,
    activityId,
    activeKey: 'activity',
  });

  return (
    <Stack space={1}>
      <ActivityButton
        patientRecord={patientRecord}
        state={state}
        activityId={activityId}
        milestoneId={milestoneId}
        onClick={handleMilestoneClick}
      />
      <ActivityStatus
        activityId={activityId}
        patientRecord={patientRecord}
        onClick={handleStatusClick}
      />
    </Stack>
  );
};

Activity.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  activity: PropTypes.shape({
    state: PropTypes.string,
    activityId: PropTypes.string,
    milestoneId: PropTypes.string,
  }),
  onMilestoneClick: PropTypes.func,
};

Activity.defaultProps = {
  activity: null,
  onMilestoneClick: null,
};

const align = (n, seed = '00') => {
  if (isNil(n)) {
    return seed;
  }
  return (seed + n.toString()).slice(-seed.length);
};

const ActivitiesTimeline = ({
  patientRecord,
  milestones,
  onMilestoneClick,
}) => {
  const {
    projectId,
  } = patientRecord;
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const timeline = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let left = [];
    let today = [];
    let right = [];

    const dates = {};
    forEach(
      patientRecord.activities,
      ({
        state,
        activityId,
        dateEnd = '9999-99-99',
        dateStart = '0000-00-00',
        timeStart = '00:00:00',
        timeEnd = '24:00:00',
      }) => {
        const completedAt = patientRecord.getActivityCompletedAt(activityId);
        // NOTE: See ActivityButton.js for reference.
        switch (state) {
          case ACTIVITY_STATE__COMPLETED: {
            if (project) {
              dates[activityId] = project
                .getMomentInLocalTime(completedAt)
                .format(`${YEAR_MONTH_DAY}T${TIME_OF_DAY}`);
            } else {
              dates[activityId] = moment(completedAt).format(
                `${YEAR_MONTH_DAY}T${TIME_OF_DAY}`,
              );
            }
            break;
          }
          case ACTIVITY_STATE__EXPIRED:
          case ACTIVITY_STATE__ACTIVE:
          case ACTIVITY_STATE__SUSPENDED: {
            dates[activityId] = `${dateEnd}T${timeEnd}`;
            break;
          }
          case ACTIVITY_STATE__SCHEDULED:
          case ACTIVITY_STATE__PLANNED:
          case ACTIVITY_STATE__OMITTED: {
            dates[activityId] = `${dateStart}T${timeStart}`;
            break;
          }
          default: {
            dates[activityId] = '0000-00-00T00:00:00';
          }
        }
      },
    );

    const indexes = mapValues(keyBy(milestones, '_id'), 'index');

    const activities = sortBy(
      patientRecord.activities,
      ({
        state,
        activityId,
        milestoneId,
      }) => {
        return `${orderingNumber[state] || 9}:${dates[activityId]}:${align(
          indexes[milestoneId],
        )}`;
      },
    );

    forEach(activities, (activity) => {
      const {
        state,
      } = activity;
      switch (timelinePlacement[state]) {
        case 'left':
          left.push(activity);
          break;
        case 'today': {
          today.push(activity);
          break;
        }
        case 'right':
          right.push(activity);
          break;
        default:
        // ...
      }
    });

    // NOTE: This is a temporary workaround because later on we will have
    //       a better solution for showing multiple "today" items.
    if (today.length > 1) {
      right = [
        ...tail(today),
        ...right,
      ];
      today = [
        first(today),
      ];
    }

    return {
      left,
      right,
      today,
    };
  }, [
    project,
    patientRecord,
    milestones,
  ]);

  const activities = useMemo(() => {
    const milestoneNames = mapValues(keyBy(milestones, '_id'), 'name');
    return compact(
      map(patientRecord.activities, (activity) => {
        if (milestoneNames[activity.milestoneId]) {
          return {
            state: activity.state,
            milestoneName: milestoneNames[activity.milestoneId],
          };
        }
        return null;
      }),
    );
  }, [
    milestones,
    patientRecord.activities,
  ]);

  return (
    <Timeline
      data-testid={`Timeline:${
        patientRecord.recipientName ||
        patientRecord.primaryIdentifier ||
        patientRecord.recipientId
      }`}
      data-test={JSON.stringify({
        activities,
      })}
      beforeNode={(
        <MilestonesModal
          milestones={milestones}
          activities={initial(timeline.left)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
        />
      )}
      items={[
        <Activity
          key="before"
          activity={last(timeline.left)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
        />,
        <Activity
          key="today"
          activity={timeline.today[0]}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
        />,
        <Activity
          key="after"
          activity={first(timeline.right)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
        />,
      ]}
      afterNode={(
        <MilestonesModal
          milestones={milestones}
          activities={tail(timeline.right)}
          patientRecord={patientRecord}
          onMilestoneClick={onMilestoneClick}
        />
      )}
    />
  );
};

ActivitiesTimeline.propTypes = {
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  milestones: PropTypes.arrayOf(PropTypes.instanceOf(ProjectMilestone)),
  onMilestoneClick: PropTypes.func,
};

ActivitiesTimeline.defaultProps = {
  milestones: [],
  onMilestoneClick: null,
};

export default ActivitiesTimeline;
