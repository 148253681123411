import isNull from 'lodash/isNull';
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import NativeDatePicker from './NativeDatePicker';
import FallbackDatePicker from './DatePicker';

const getIsNativeDateSupported = () => {
  const input = document.createElement('input');
  const value = 'a';

  input.setAttribute('type', 'date');
  input.setAttribute('value', value);

  return input.value !== value;
};

const InputDate = ({
  value,
  onChange,
  disabled,
  autoFocus,
  'data-testid': testId,
}) => {
  const [
    isNativeDateSupported,
    setIsNativeDateSupported,
  ] = useState(null);

  useEffect(() => {
    if (getIsNativeDateSupported()) {
      setIsNativeDateSupported(true);
    } else {
      setIsNativeDateSupported(false);
    }
  }, []);

  if (isNull(isNativeDateSupported)) {
    return null;
  }

  return isNativeDateSupported ? (
    <NativeDatePicker
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoFocus={autoFocus}
      data-testid={testId}
    />
  ) : (
    <FallbackDatePicker
      value={value}
      onChange={onChange}
      disabled={disabled}
      autoFocus={autoFocus}
      data-testid={testId}
    />
  );
};

InputDate.propTypes = {
  'data-testid': PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

InputDate.defaultProps = {
  'data-testid': 'date-picker',
  disabled: false,
  autoFocus: false,
};

export default InputDate;
