// eslint-disable-next-line import/prefer-default-export
export const getPagesRange = (current, pagesLength) => {
  if (current === 1) {
    return [
      current,
      current + 1,
      current + 2,
      current + 3,
      current + 4,
    ];
  }

  if (current === 2) {
    return [
      current - 1,
      current,
      current + 1,
      current + 2,
      current + 3,
    ];
  }

  if (current === pagesLength - 1) {
    return [
      current - 3,
      current - 2,
      current - 1,
      current,
      current + 1,
    ];
  }

  if (current === pagesLength) {
    return [
      current - 4,
      current - 3,
      current - 2,
      current - 1,
      current,
    ];
  }

  return [
    current - 2,
    current - 1,
    current,
    current + 1,
    current + 2,
  ];
};
