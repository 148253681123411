import {
  createSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import PermissionsDomain from '../models/PermissionsDomain';
import RoleSelect from './Role';
import toSelector from '../utils/toSelector';

const constant = x => () => x;

const Select = createSelectors(PermissionsDomain, {
  whereUserHasPermission: (selectUser, selectPermission) => select => select.where(
    createSelector(
      toSelector(selectUser),
      toSelector(selectPermission),
      RoleSelect.all().byId(),
      (user, permission, rolesDB) => {
        if (!user) {
          return constant(false);
        }
        return domain => user.hasPermission(permission, {
          rolesDB,
          relativeTo: domain._id,
        });
      },
    ),
  ),
});

export default Select;
