import getFormErrors from './getFormErrors';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';

/**
 * Evaluate form errors for the given form values but ignore
 * errors for hidden (masked) questions.
 * @param {Questionnaire} evaluationScope
 * @param {Object} [propertiesOverrides]
 * @returns {Object}
 */
function getVisibleFormErrors(evaluationScope, propertiesOverrides) {
  const questionnaire = evaluationScope.getQuestionnaire();
  if (!questionnaire) {
    throw new Error('Cannot evaluate form errors if questionnaire is missing');
  }
  const {
    formValues,
    properties,
  } = evaluateFormValuesAndProperties(
    evaluationScope,
    {
      propertiesOverrides,
    },
  );
  return getFormErrors(questionnaire, formValues, {
    properties,
    skipHidden: true,
  });
}

export default getVisibleFormErrors;
