import ApiSpec from './ApiSpec';
import Schema from '../utils/Schema';

export const expandValueSet = new ApiSpec({
  name: 'api.terminology.expandValueSet',
  permissions: [],
  schema: new Schema({
    id: {
      type: Schema.IdentifierWithVersionRange,
    },
    searchText: {
      type: String,
      optional: true,
    },
    domains: {
      type: [
        String,
      ],
      optional: true,
    },
    prefixSearchOnly: {
      type: Boolean,
      optional: true,
    },
    limit: {
      type: Number,
      optional: true,
    },
    lockedDate: {
      type: String,
      optional: true,
    },
  }),
});

export const lookupValueSet = new ApiSpec({
  name: 'api.terminology.lookupValueSet',
  permissions: [],
  schema: new Schema({
    id: {
      type: Schema.IdentifierWithVersionRange,
    },
    codes: {
      type: [
        String,
      ],
      optional: true,
    },
    lockedDate: {
      type: String,
      optional: true,
    },
  }),
});
