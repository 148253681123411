import castArray from 'lodash/castArray';
import includes from 'lodash/includes';
import some from 'lodash/some';
import Formula from '../Formula';
import {
  FORMULA_TYPE__ANSWER_IS_ONE_OF,
} from '../../../constants';

class FormulaAnswerIsOneOf extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.id) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile() {
    const compiled = {
      ...this,
      settings: {
        id: this.settings.id,
        literals: this.settings.literals || [],
      },
    };
    return compiled;
  }

  evaluate(scope) {
    const questionnaire = scope.getQuestionnaire();
    if (!questionnaire) {
      return {
        error: this.constructor.NoQuestionnaire,
      };
    }
    const answers = scope.pickAllAnswers(this.settings.id);
    return {
      value:
        !!this.settings.literals &&
        some(answers, answer => some(castArray(answer.value), v => includes(this.settings.literals, v))),
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }

  static withQuestionIdAndLiterals(id, literals) {
    return new this({
      type: FORMULA_TYPE__ANSWER_IS_ONE_OF,
      settings: {
        id,
        literals,
      },
    });
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__ANSWER_IS_ONE_OF] = FormulaAnswerIsOneOf;

export default FormulaAnswerIsOneOf;
