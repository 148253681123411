import BaseModel from './BaseModel';

class Report extends BaseModel {
  getVersionId() {
    return this.versionId;
  }

  getProjectId() {
    return this.projectId;
  }
}

Report.collection = 'Reports';

export default Report;
