import {
  getCurrentPage,
  getPageSize,
} from './selectors';
import store from './store';
import {
  setQueryParam,
} from '../../store/router';
import {
  ALL_PROJECTS_PLACEHOLDER,
} from './constants';

const toBoolean = x => !!x;

export const setPatientModalVisible = store.create.set(
  'patientModalVisible',
  toBoolean,
  true,
);
export const setRemovePatientModalVisible = store.create.set(
  'removePatientModalVisible',
  toBoolean,
  true,
);
export const setActiveProjectId = projectId => setQueryParam('projectId', projectId || ALL_PROJECTS_PLACEHOLDER);
export const setProjectProfileEditing = ({
  projectId,
  editing,
}) => store.set(`projectProfiles.${projectId}.editing`, editing);
export const setAddToProjectModalVisible = store.create.set(
  'addToProjectModalVisible',
  toBoolean,
  true,
);
export const setActivePatientNoteId = store.create.set('activePatientNoteId');
export const setEditNoteDialogVisible = store.create.set(
  'editNoteDialogVisible',
);
export const setCurrentProjectId = projectId => store.set('currentProjectId', projectId);

// Dialogs

export const openProjectProfileDialog = (options = {}) => {
  return store.set('dialogs.projectProfile', options);
};

export const closeProjectProfileDialog = () => {
  return store.del('dialogs.projectProfile');
};

export const openEditPatientDialog = (options = {}) => {
  return store.set('dialogs.editPatient', options);
};

export const closeEditPatientDialog = () => {
  return store.del('dialogs.editPatient');
};

export const openEditNoteDialog = noteId => (dispatch) => {
  dispatch(setActivePatientNoteId(noteId));
  dispatch(setEditNoteDialogVisible(true));
};

export const closeEditNoteDialog = () => (dispatch) => {
  dispatch(setActivePatientNoteId(null));
  dispatch(setEditNoteDialogVisible(false));
};

// Table

export const setCurrentPage = store.create.set('currentPage');
export const setPageSize = store.create.set('pageSize');
export const setPatientCurrentTab = store.create.set('patientCurrentTab');
export const setNumberOfPatients = value => (dispatch, getState) => {
  const state = getState();
  const currentPage = getCurrentPage(state);
  const pageSize = getPageSize(state);
  const nPages = Math.ceil(value / pageSize);
  if (currentPage > nPages) {
    // make sure we don't set the value to 0
    dispatch(setCurrentPage(Math.max(1, nPages)));
  }
  dispatch(store.set('nPatients', value));
};
