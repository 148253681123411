import {
  createEntitiesSelectors,
} from '@theclinician/ddp-connector/lib/selectors';
import EJSON from '../utils/ejson';

const defaultTransform = x => x;

const createSelectors = (
  Model,
  plugins,
  {
    transform = defaultTransform,
    transformContextSelector,
  } = {},
) => {
  if (!Model.collection) {
    throw new Error(
      `Cannot create selectors for model: "${Model.name}" because it does not have collection property`,
    );
  }
  const selectors = createEntitiesSelectors(Model.collection, {
    Model,
    prefix: Model.store,
    plugins,
    transformContextSelector,
    transform: (doc, ctx) => transform(EJSON.fromJSONValue(doc), ctx),
  });
  return selectors;
};

export default createSelectors;
