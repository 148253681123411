import omit from 'lodash/omit';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';
import createGetAtKey from './createGetAtKey';
import shallowEqual from './shallowEqual';
import reconcile from './reconcile';
import toSelector from '../utils/toSelector';

export const identity = x => x;

export const constant = x => () => x;

export const argument = (i, k) => {
  if (!k) {
    return (...args) => args[i];
  }
  const getAtKey = createGetAtKey(k);
  return (...args) => getAtKey(args[i]);
};

export const property = (name, selector = argument(1)) => {
  const getAtKey = createGetAtKey(name);
  return createSelector(
    selector,
    value => getAtKey(value),
  );
};

export const shallowEqualSelector = createSelectorCreator(
  defaultMemoize,
  shallowEqual,
);

export const ownPropsSelector = (options) => {
  let selectProps;
  if (options && options.omit) {
    selectProps = (state, props) => omit(props, options.omit);
  } else {
    selectProps = argument(1);
  }
  return shallowEqualSelector(selectProps, identity);
};

export const higherOrderSelector = (...selectorArgs) => {
  const selectGetValue = createSelector(...selectorArgs);
  return (...args) => {
    const getValue = selectGetValue(...args);
    if (typeof getValue === 'function') {
      return getValue(...args);
    }
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        `HigherOrderSelector requires a function, received ${typeof getValue}`,
      );
    }
    return undefined;
  };
};

const normalize = (args) => {
  if (isArray(args[0])) {
    return [
      args[0],
      args[1],
    ];
  }
  return [
    args.slice(0, args.length - 1),
    args[args.length - 1],
  ];
};

export const reconcilingSelector = (...args) => {
  const [
    selectors,
    evaluate,
  ] = normalize(args);
  let cached;
  return createSelector(
    selectors,
    (...values) => {
      const result = evaluate(...values);
      cached = reconcile(cached, result);
      return cached;
    },
  );
};

export const getMultiKey = (...selectors) => {
  const keySelectors = isArray(selectors[0]) ? selectors[0] : selectors;
  const selectGetter = createSelector(
    map(keySelectors, toSelector),
    (...parts) => createGetAtKey(parts),
  );
  return (selectObject = identity) => createSelector(
    selectGetter,
    toSelector(selectObject),
    (getter, object) => getter(object),
  );
};

export const formValue = selectForm => selectName => getMultiKey(
  'form',
  toSelector(selectForm),
  'values',
  toSelector(selectName),
)(identity);
