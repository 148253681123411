import {
  compose,
} from 'redux';
import {
  withRouter,
} from 'react-router';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createStructuredSelector,
} from 'reselect';
import {
  withHandlers,
} from 'recompose';
import {
  logout,
} from '../../../common/utilsClient/ddp/actions';
import {
  getDeferredPrompt,
} from '../../../store/ui/drawer/selectors';
import {
  openEditUserDialog,
} from '../../../store/ui/header/actions';
import tracker from '../../../utils/tracker';
import Drawer from './Drawer';

const Container = compose(
  withRouter,
  ddp({
    subscriptions: () => [
      {
        name: 'Users.current',
        params: [],
      },
    ],
    selectors: () => createStructuredSelector({
      deferredPrompt: getDeferredPrompt,
    }),
    renderLoader: null,
  }),
  withHandlers({
    onInstall: ({
      deferredPrompt,
    }) => () => {
      if (deferredPrompt) {
        deferredPrompt.prompt();
      }
    },
    onEdit: ({
      dispatch,
    }) => () => dispatch(openEditUserDialog()),
    onLogout: ({
      dispatch,
    }) => () => {
      tracker.resetUser();

      dispatch(logout());
    },
  }),
)(Drawer);

export default Container;
