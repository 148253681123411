import Schema from '../utils/Schema';
import ApiSpec from './ApiSpec';
import {
  PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
} from '../permissions';
import {
  NULL_ANSWERS,
  RESPONSE_SOURCES,
} from '../constants';

export const ResponseSchema = new Schema({
  questionId: {
    type: String,
  },
  answer: {
    type: Object,
    optional: true,
    blackbox: true,
  },
  whyEmpty: {
    type: String,
    optional: true,
    allowedValues: NULL_ANSWERS,
  },
  hierarchyKey: {
    type: String,
    optional: true,
  },
  source: {
    type: String,
    optional: true,
    allowedValues: RESPONSE_SOURCES,
  },
  editedTs: {
    type: Number,
    optional: true,
  },
});

export const answersSheetAndQuestionnaire = new ApiSpec({
  name: 'api.forms.answersSheetAndQuestionnaire',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const complete = new ApiSpec({
  name: 'api.forms.complete',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    validateOnly: {
      type: Boolean,
      optional: true,
    },
  }),
});

export const currentSessions = new ApiSpec({
  name: 'api.forms.currentSessions',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const getInitialValues = new ApiSpec({
  name: 'api.forms.getInitialValues',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const heartbeat = new ApiSpec({
  name: 'api.forms.heartbeat',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const pauseAndSaveDraft = new ApiSpec({
  name: 'api.forms.pauseAndSaveDraft',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
      optional: true,
    },
  }),
});

export const resolveConflicts = new ApiSpec({
  name: 'api.forms.resolveConflicts',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    resolution: {
      type: [
        ResponseSchema,
      ],
      optional: true,
    },
    initialResolution: {
      type: [
        ResponseSchema,
      ],
      optional: true,
    },
  }),
});

export const saveProgress = new ApiSpec({
  name: 'api.forms.saveProgress',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
      optional: true,
    },
    newResponses: {
      type: [
        ResponseSchema,
      ],
    },
  }),
});

export const undoLastChanges = new ApiSpec({
  name: 'api.forms.undoLastChanges',
  permissions: [
    PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
      optional: true,
    },
  }),
});
