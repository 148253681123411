import PropTypes from 'prop-types';
import {
  compose,
} from 'recompose';
import React, {
  useEffect,
  useImperativeHandle,
} from 'react';
import {
  connect,
  useDispatch,
} from 'react-redux';
import Context, {
  withContext,
} from './QuestionnaireContext';
import Model from '../../models/Questionnaire';
import {
  clearQuestionnaire,
  initializeQuestionnaire,
} from './actions';
import {
  property,
} from '../../utilsClient/selectors';
import {
  liftContextSelectors,
} from './selectors';
import useSelectorsHub from './useSelectorsHub';

const Questionnaire = React.forwardRef(
  (
    {
      name,
      questionnaire,
      properties,
      variables,
      sortedBy,
      sessionId,
      answersSheetId,
      debounceEdit,
      disabled,
      children,
      onSubmit,
      onSubmitError,
      readOnly,
      initialValues,
      keepStateOnUnmount,
      allowReinitialize,
    },
    forwardRef,
  ) => {
    const context = useSelectorsHub({
      name,
      questionnaire,
      properties,
      variables,
      sortedBy,
      sessionId,
      answersSheetId,
      debounceEdit,
      disabled,
    });

    const dispatch = useDispatch();

    useEffect(() => {
      if (allowReinitialize && !readOnly && initialValues) {
        dispatch(initializeQuestionnaire(name, initialValues));
      }
    }, [
      name,
      dispatch,
      readOnly,
      allowReinitialize,
      initialValues,
    ]);

    useEffect(
      () => {
        if (allowReinitialize) {
          return () => {};
        }
        if (!readOnly && initialValues) {
          // NOTE: initialValues is intentionally not included
          //       in dependencies array because we don't want
          //       to re-run this hook when initialValues change.
          dispatch(initializeQuestionnaire(name, initialValues));
        }
        return () => {
          if (!keepStateOnUnmount) {
            dispatch(clearQuestionnaire(name));
          }
        };
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        name,
        dispatch,
        readOnly,
        allowReinitialize,
      ],
    );

    useImperativeHandle(
      forwardRef,
      () => ({
        setErrors: (errors) => {
          return dispatch(context.setErrors(errors));
        },
        submit: ({
          dryRun,
        } = {}) => {
          return dispatch(context.validate(dryRun))
            .then((value) => {
              if (onSubmit) {
                onSubmit(value);
              }
              return value;
            })
            .catch((err) => {
              if (onSubmitError) {
                onSubmitError(err);
              }
              throw err;
            });
        },
      }),
      [
        context,
        dispatch,
        onSubmit,
        onSubmitError,
      ],
    );

    return <Context.Provider value={context}>{children}</Context.Provider>;
  },
);

Questionnaire.propTypes = {
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.objectOf(PropTypes.any),
  keepStateOnUnmount: PropTypes.bool,
  variables: PropTypes.objectOf(PropTypes.any),
  properties: PropTypes.objectOf(PropTypes.any),
  sortedBy: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
  ]),
  allowReinitialize: PropTypes.bool,
  questionnaire: PropTypes.instanceOf(Model).isRequired,
  children: PropTypes.node.isRequired,
  debounceEdit: PropTypes.number,
  sessionId: PropTypes.string,
  answersSheetId: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func,
  onSubmitError: PropTypes.func,
};

Questionnaire.defaultProps = {
  initialValues: null,
  keepStateOnUnmount: false,
  variables: null,
  properties: null,
  sortedBy: null,
  allowReinitialize: false,
  sessionId: null,
  answersSheetId: null,
  debounceEdit: 0,
  disabled: false,
  readOnly: false,
  onSubmit: null,
  onSubmitError: null,
};

Questionnaire.connect = (createMapStateToProps) => {
  return compose(
    withContext(),
    connect(() => createMapStateToProps(liftContextSelectors(property('context')))),
  );
};

export default Questionnaire;
