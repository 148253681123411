import {
  QUESTION_TYPE__FULL_DATE,
} from '../../../constants';
import Question from '../Question';

export default class QuestionFullDate extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__FULL_DATE;
  }
}

QuestionFullDate.expectedValueType = {
  type: 'string',
  format: 'full-date',
};

QuestionFullDate.editorSettings = {};

Question.types[QUESTION_TYPE__FULL_DATE] = QuestionFullDate;
