import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import isNil from 'lodash/isNil';
import {
  assignError,
} from './checkSchema';

const binaryOperators = {
  $lte: (a, b) => a <= b,
  $lt: (a, b) => a < b,
  $gte: (a, b) => a >= b,
  $gt: (a, b) => a > b,
  $eq: (a, b) => a === b,
};

const validateOperator = (operator, values) => {
  if (binaryOperators[operator]) {
    return (
      values.length === 2 && binaryOperators[operator](values[0], values[1])
    );
  }
  return true;
};

const checkConstraints = (constraints, object) => {
  if (!isPlainObject(object)) {
    return undefined;
  }
  const errors = {};
  forEach(constraints, ({
    fields,
    operator,
  }) => {
    const values = map(fields, ({
      name,
    }) => get(object, name));
    let isOk = true;
    if (!some(values, isNil)) {
      isOk = validateOperator(operator, values);
    }
    if (!isOk) {
      forEach(fields, ({
        name,
        message,
      }) => {
        assignError(errors, name, message);
      });
    }
  });
  if (isEmpty(errors)) {
    return undefined;
  }
  return errors;
};

export default checkConstraints;
