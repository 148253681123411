import {
  createSelector,
} from 'reselect';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import ProjectSelect from '../../common/selectors/Project';
import store from './store';
import {
  selectQueryParam,
} from '../../store/router';
import {
  ALL_PROJECTS_PLACEHOLDER,
} from './constants';

const toBoolean = x => !!x;

export const getRemovePatientModalVisible = store.get(
  'removePatientModalVisible',
  toBoolean,
  false,
);

export const getActiveProjectId = createSelector(
  selectQueryParam('projectId'),
  ProjectSelect.all().whereOneOfMembersIs(CurrentUserSelect.user()),
  (projectId, currentUserProjects) => {
    if (!projectId) {
      return currentUserProjects[0] && currentUserProjects[0]._id;
    }
    if (projectId === ALL_PROJECTS_PLACEHOLDER) {
      return null;
    }
    return projectId;
  },
);

export const getActivePatientNoteId = store.get('activePatientNoteId');
export const getEditNoteDialogVisible = store.get('editNoteDialogVisible');
export const getCurrentProjectId = store.get('currentProjectId');

// Dialogs

export const getProjectProfileDialog = store.get('dialogs.projectProfile');
export const getEditPatientDialog = store.get('dialogs.editPatient');

// Table

export const getCurrentPage = store.get('currentPage');
export const getNumberOfPatients = store.get('nPatients');
export const getPageSize = store.get('pageSize');
export const getPatientCurrentTab = store.get('patientCurrentTab');
export const getNumberOfPages = createSelector(
  getPageSize,
  getNumberOfPatients,
  (pageSize, numberOfPatients) => {
    if (!pageSize || !numberOfPatients) {
      return 0;
    }
    return Math.ceil(numberOfPatients / pageSize);
  },
);
export const getCurrentPageIndex = createSelector(
  getCurrentPage,
  getNumberOfPages,
  (currentPage, numberOfPages) => {
    if (!currentPage || !numberOfPages) {
      return 0;
    }
    return Math.max(0, Math.min(currentPage - 1, numberOfPages - 1));
  },
);
