import startsWith from 'lodash/startsWith';
import some from 'lodash/some';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  createEntitiesSelectors,
} from '@theclinician/ddp-connector/lib/selectors';
import {
  normalize,
} from '../common/utils/url';

const ServiceConfiguration = createEntitiesSelectors(
  'meteor_accounts_loginServiceConfiguration',
);

const useLoginServices = () => {
  const {
    ready,
  } = useDDPSubscription({
    name: 'meteor.loginServiceConfiguration',
    params: [],
  });

  const services = useSelector(
    ServiceConfiguration.all().satisfying(({
      appUrls,
    }) => {
      return (
        !appUrls ||
        some(appUrls, appUrl => startsWith(normalize(window.location), appUrl))
      );
    }),
  );

  return {
    ready,
    services,
  };
};

export default useLoginServices;
