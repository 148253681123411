import isEqual from 'lodash/isEqual';
import Formula from '../Formula';
import {
  FORMULA_TYPE__EQUALS,
} from '../../../constants';

class FormulaEquals extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.left) {
      return this.constructor.NotConfigured;
    }
    if (!this.settings.right) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  compile(questionsHierarchy) {
    const {
      left,
      right,
    } = this.settings;
    return {
      ...this,
      settings: {
        left: Formula.create(left).compile(questionsHierarchy),
        right: Formula.create(right).compile(questionsHierarchy),
      },
    };
  }

  evaluate(scope) {
    const left = Formula.create(this.settings.left).evaluate(scope);
    if (left.error) {
      return {
        error: left.error,
      };
    }
    const right = Formula.create(this.settings.right).evaluate(scope);
    if (right.error) {
      return {
        error: right.error,
      };
    }
    return {
      value: isEqual(left, right),
    };
  }

  toMongoExpression() {
    const left = Formula.create(this.settings.left).toMongoExpression();
    const right = Formula.create(this.settings.right).toMongoExpression();
    return {
      $eq: [
        left,
        right,
      ],
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'left':
        case 'right':
          return Formula.create(value).remap(mapQuestionId).toRawFormula();
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__EQUALS] = FormulaEquals;

export default FormulaEquals;
