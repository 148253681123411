import {
  createSelector,
} from 'reselect';
import {
  mapQuestionnaireIdToRegExp,
  getVersion,
  compareVersions,
} from '../utils/versions';
import toSelector from '../utils/toSelector';

const constant = x => () => x;

const createMaxMatchingVersionSelector = select => selectIdWithVersionRange => select
  .one()
  .where(
    createSelector(
      toSelector(selectIdWithVersionRange),
      (idWithVersionRange) => {
        if (!idWithVersionRange) {
          return constant(false);
        }
        const re = new RegExp(mapQuestionnaireIdToRegExp(idWithVersionRange));
        return doc => !!(doc._id && re.test(doc._id));
      },
    ),
  )
  .withCompare(
    (a, b) => -compareVersions(getVersion(a._id), getVersion(b._id)),
  );

export default createMaxMatchingVersionSelector;
