import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import FormItem from './FormItem';
import Input from './Input';
import {
  formFieldPropTypesMeta,
} from '../utilsClient/propTypes';

const StyledFormItem = styled(FormItem)`
  width: 100%;
  margin-bottom: 0;
  .ant-form-item-children {
    position: static;
    overflow: hidden;
  }
`;

const FormFieldUnsupported = ({
  meta: {
    error,
    touched,
  },
  required,
  questionType,
}) => (
  <StyledFormItem
    required={required}
    validateStatus={
      error ? (touched && 'error') || '' : (required && 'success') || ''
    }
    help={touched && error}
  >
    <Input
      placeholder={`[unsupported question: ${questionType}]`}
      disabled
    />
  </StyledFormItem>
);

FormFieldUnsupported.propTypes = {
  meta: formFieldPropTypesMeta.isRequired,
  questionType: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormFieldUnsupported.defaultProps = {
  required: false,
};

export default FormFieldUnsupported;
