import {
  registerStore,
} from '../../common/utilsClient/multiReducer';

export const PATIENTS_PER_PAGE = 10;

const store = registerStore({
  path: 'ui.screens.PatientsDirectory',
  initialState: {
    nPatients: PATIENTS_PER_PAGE,
    pageSize: PATIENTS_PER_PAGE,
    currentPage: 1,
    activePatientNoteId: null,
    editNoteDialogVisible: false,
    currentProjectId: null,
    patientModalVisible: false,
    removePatientModalVisible: false,
    addToProjectModalVisible: false,
    patientCurrentTab: 'projects',
    dialogs: {},
  },
});

export default store;
