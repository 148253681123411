import includes from 'lodash/includes';
import compact from 'lodash/compact';
import {
  useMemo,
} from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  apiZedocOneProject,
} from '../common/api/zedoc';
import {
  apiAnalyticsDashboardProgram,
} from '../common/api/analytics';
import ProjectSelect from '../common/selectors/Project';
import ProjectDashboardSelect from '../common/selectors/ProjectDashboard';
import {
  FILTER_TYPE__VARIABLE,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../common/constants';

const projectFilterToPreset = (
  {
    title,
    type,
    settings,
  },
  {
    schema,
    // language,
  },
) => {
  let variableId;

  if (type === FILTER_TYPE__VARIABLE) {
    variableId = settings.id;
  }

  let condition;
  let conditions;
  switch (schema.type) {
    case 'string':
      condition = FILTER_CONDITION__INCLUDE;
      conditions = [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
      break;
    case 'number':
      condition = FILTER_CONDITION__MINIMUM;
      conditions = [
        FILTER_CONDITION__MINIMUM,
        FILTER_CONDITION__EXCLUSIVE_MINIMUM,
        FILTER_CONDITION__MAXIMUM,
        FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
      ];
      break;
    default:
      condition = FILTER_CONDITION__EXISTS;
      conditions = [
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
  }

  if (!variableId) {
    return [];
  }

  return [
    {
      name: title,
      type: FILTER_TYPE__VARIABLE,
      condition,
      settings: {
        id: variableId,
        namespace: 'variables',
      },
      meta: {
        conditions,
      },
    },
  ];
};

const useAnalyticsFilters = ({
  dashboardId,
  defaultFilters,
}) => {
  const {
    i18n: {
      language,
    },
  } = useTranslation();

  const {
    ready: dashboardReady,
  } = useDDPSubscription(
    dashboardId &&
      apiAnalyticsDashboardProgram.withParams({
        dashboardId,
      }),
  );

  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  const projectId = dashboard && dashboard.projectId;

  const {
    ready: projectReady,
  } = useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const timezone = project && project.getTimezone();
  const empty = useMemo(() => [], []);

  const presets = useMemo(() => {
    const allPresets = compact(
      map(defaultFilters, (preset) => {
        if (
          preset.meta &&
          preset.meta.perspectives &&
          !includes(preset.meta.perspectives, dashboard?.program?.perspective)
        ) {
          return null;
        }
        switch (preset.condition) {
          case FILTER_CONDITION__DATE_EQUALS:
          case FILTER_CONDITION__DATE_AFTER:
          case FILTER_CONDITION__DATE_BEFORE:
          case FILTER_CONDITION__DATE_SAME_OR_AFTER:
          case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
            return {
              ...preset,
              state: {
                ...preset.state,
                timezone,
                threshold: project && project.getCurrentYearMonthDay(),
              },
            };
          default:
            return preset;
        }
      }),
    );

    forEach(
      dashboard?.program?.variables?.outputs,
      ({
        name,
        namespace,
        schema,
      }) => {
        if (schema && schema.type) {
          forEach(
            projectFilterToPreset(
              {
                type: FILTER_TYPE__VARIABLE,
                title: name,
                settings: {
                  id: `${namespace || ''}:${name}`,
                },
              },
              {
                schema,
                language,
              },
            ),
            (preset) => {
              allPresets.push(preset);
            },
          );
        }
      },
    );
    return allPresets;
  }, [
    project,
    dashboard,
    timezone,
    defaultFilters,
    language,
  ]);

  if (!projectReady || !dashboardReady) {
    return empty;
  }

  return presets;
};

export default useAnalyticsFilters;
