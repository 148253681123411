import Formula from '../Formula';
import {
  FORMULA_TYPE__UNKNOWN,
} from '../../../constants';

class FormulaUnknown extends Formula {}

Formula.types[FORMULA_TYPE__UNKNOWN] = FormulaUnknown;

export default FormulaUnknown;
