import React from 'react';
import {
  Email,
  PhoneIphone,
} from 'styled-icons/material';
import {
  NOTIFICATION_LOG_STATE__INITIAL,
  NOTIFICATION_LOG_STATE__ABORTED,
  NOTIFICATION_LOG_STATE__SENDING,
  NOTIFICATION_LOG_STATE__ENQUEUED,
  NOTIFICATION_LOG_STATE__SENT,
  NOTIFICATION_LOG_STATE__FAILED,
  NOTIFICATION_LOG_STATE__DELIVERED,
  NOTIFICATION_LOG_STATE__RECEIVED,
  NOTIFICATION_LOG_STATE__OPENED,
  NOTIFICATION_LOG_STATE__CLICKED,
} from '../../common/constants/collections/NotificationsLogs';
import {
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
} from '../../common/constants';

export const stateTypes = {
  [NOTIFICATION_LOG_STATE__INITIAL]: 'neutral',
  [NOTIFICATION_LOG_STATE__ABORTED]: 'danger',
  [NOTIFICATION_LOG_STATE__SENDING]: 'info',
  [NOTIFICATION_LOG_STATE__ENQUEUED]: 'neutral',
  [NOTIFICATION_LOG_STATE__SENT]: 'success',
  [NOTIFICATION_LOG_STATE__FAILED]: 'danger',
  [NOTIFICATION_LOG_STATE__DELIVERED]: 'success',
  [NOTIFICATION_LOG_STATE__RECEIVED]: 'success',
  [NOTIFICATION_LOG_STATE__OPENED]: 'success',
  [NOTIFICATION_LOG_STATE__CLICKED]: 'success',
};

export const stateTranslationKeys = {
  [NOTIFICATION_LOG_STATE__INITIAL]: 'initial',
  [NOTIFICATION_LOG_STATE__ABORTED]: 'aborted',
  [NOTIFICATION_LOG_STATE__SENDING]: 'attempted',
  [NOTIFICATION_LOG_STATE__ENQUEUED]: 'enqueued',
  [NOTIFICATION_LOG_STATE__SENT]: 'sent',
  [NOTIFICATION_LOG_STATE__FAILED]: 'failed',
  [NOTIFICATION_LOG_STATE__DELIVERED]: 'delivered',
  [NOTIFICATION_LOG_STATE__RECEIVED]: 'received',
  [NOTIFICATION_LOG_STATE__OPENED]: 'opened',
  [NOTIFICATION_LOG_STATE__CLICKED]: 'clicked',
};

export const deliveryTypeIcons = {
  [NOTIFICATION_DELIVERY_TYPE_SMS]: <PhoneIphone />,
  [NOTIFICATION_DELIVERY_TYPE_EMAIL]: <Email />,
};

export const deliveryTypeTranslationKeys = {
  [NOTIFICATION_DELIVERY_TYPE_SMS]: 'sms',
  [NOTIFICATION_DELIVERY_TYPE_EMAIL]: 'email',
};
