import find from 'lodash/find';
import forEach from 'lodash/forEach';
import {
  getViewUrl,
  getPerspectiveIdField,
} from './dashboards';
import {
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
  DASHBOARD_PERSPECTIVE__ACTIVITIES,
  DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
} from '../../common/constants';
import createGetViewParams from './createGetViewParams';

const insertViewIfDoesNotExist = (children, view) => {
  let subTree = find(
    children,
    child => child.view && child.view.url === view.url,
  );
  if (!subTree) {
    subTree = {
      view,
    };
    children.push(subTree);
  }
  return subTree;
};

export const createViewsTree = (views, dashboards) => {
  const root = {
    children: [],
  };

  const getViewParams = createGetViewParams(dashboards);

  const maybeInsertDashboard = (children, dashboardId, settings) => {
    const params = getViewParams({
      settings,
      dashboardId,
    });
    if (params.preset) {
      // NOTE: If preset is not present, then it means this dashboard,
      //       does not fit within the given constraints.
      insertViewIfDoesNotExist(children, {
        ...params,
        url: getViewUrl(params),
      });
    }
  };

  const getOrCreateSubTree = (perspective, perspectiveId, children) => {
    let subTree = find(children, {
      perspective,
      perspectiveId,
    });
    if (!subTree) {
      subTree = {
        perspective,
        perspectiveId,
        children: [],
      };
      const settings = {
        [getPerspectiveIdField(perspective)]: perspectiveId,
      };
      forEach(dashboards, (dashboard) => {
        maybeInsertDashboard(subTree.children, dashboard._id, settings);
      });
      children.push(subTree);
    }
    return subTree;
  };

  const insertNode = (tree, view, hierarchy = {}) => {
    const {
      answersSheetId,
      activityId,
      participationId,
      recipientId,
    } = hierarchy;
    let subTree;
    switch (tree.perspective) {
      case DASHBOARD_PERSPECTIVE__RESPONSES: {
        break;
      }
      case DASHBOARD_PERSPECTIVE__ACTIVITIES: {
        if (answersSheetId) {
          subTree = getOrCreateSubTree(
            DASHBOARD_PERSPECTIVE__RESPONSES,
            answersSheetId,
            tree.children,
          );
        }
        break;
      }
      case DASHBOARD_PERSPECTIVE__PARTICIPATIONS: {
        if (activityId) {
          subTree = getOrCreateSubTree(
            DASHBOARD_PERSPECTIVE__ACTIVITIES,
            activityId,
            tree.children,
          );
        }
        break;
      }
      case DASHBOARD_PERSPECTIVE__PATIENTS: {
        if (participationId) {
          subTree = getOrCreateSubTree(
            DASHBOARD_PERSPECTIVE__PARTICIPATIONS,
            participationId,
            tree.children,
          );
        }
        break;
      }
      default: {
        if (recipientId) {
          subTree = getOrCreateSubTree(
            DASHBOARD_PERSPECTIVE__PATIENTS,
            recipientId,
            tree.children,
          );
        }
      }
    }
    if (subTree) {
      insertNode(subTree, view, view.hierarchy);
    } else {
      insertViewIfDoesNotExist(tree.children, view);
    }
  };

  forEach(dashboards, (dashboard) => {
    maybeInsertDashboard(root.children, dashboard._id);
  });

  forEach(views, (view) => {
    insertNode(root, view, view.hierarchy);
  });

  // return cleanTree(root);
  return root;
};

export const forEachActive = (tree, fn) => {
  if (!tree.active) {
    return;
  }
  fn(tree);
  forEach(tree.children, (child) => {
    forEachActive(child, fn);
  });
};

export const forEachNode = (tree, fn) => {
  if (tree) {
    forEach(tree.children, (child) => {
      forEachNode(child, fn);
    });
    fn(tree);
  }
};

export const findTreeNode = (tree, predicate) => {
  if (predicate(tree)) {
    return tree;
  }
  if (tree.children) {
    for (let i = 0; i < tree.children.length; i += 1) {
      const result = findTreeNode(tree.children[i], predicate);
      if (result) {
        return result;
      }
    }
  }
  return undefined;
};

export const findParentOf = (tree, predicate) => {
  if (tree.children) {
    for (let i = 0; i < tree.children.length; i += 1) {
      if (predicate(tree.children[i])) {
        return tree;
      }
    }
    for (let i = 0; i < tree.children.length; i += 1) {
      const result = findParentOf(tree.children[i], predicate);
      if (result) {
        return result;
      }
    }
  }
  return undefined;
};

export const getClosestViewUrl = (treeNode) => {
  if (treeNode) {
    if (treeNode.view) {
      return treeNode.view.url;
    }
    const child = find(treeNode.children, getClosestViewUrl);
    if (child) {
      return getClosestViewUrl(child);
    }
  }
  return '';
};
