import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import EditVariableDialog from '../../../components/dialogs/EditVariable';
import {
  getEditVariableDialog,
  closeEditVariableDialog,
} from '../store';

const ConnectedEditVariableDialog = ({
  onSubmitted,
}) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeEditVariableDialog());
  }, [
    dispatch,
  ]);
  const options = useSelector(getEditVariableDialog);
  if (!options) {
    return (
      <EditVariableDialog
        key="no-options"
        visible={false}
      />
    );
  }
  const {
    variableId,
  } = options;
  return (
    <EditVariableDialog
      key="has-options"
      visible
      variableId={variableId}
      onCancel={handleOnCancel}
      onSubmitted={onSubmitted}
    />
  );
};

ConnectedEditVariableDialog.propTypes = {
  onSubmitted: PropTypes.func,
};

ConnectedEditVariableDialog.defaultProps = {
  onSubmitted: null,
};

export default ConnectedEditVariableDialog;
