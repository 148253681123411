import each from 'lodash/each';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  useTranslation,
} from 'react-i18next';
import {
  MoreHoriz,
} from 'styled-icons/material';
import {
  PATIENT_CREATE_PATIENT,
  PROJECT_ATTACH_PARTICIPATION,
} from '../../../../common/permissions';
import Project from '../../../../common/models/Project';
import PatientRecord from '../../../../common/models/PatientRecord';
import Dropdown from '../../../../common/components/Dropdown';
import Menu from '../../../../common/components/Menu';
import Button from '../../../../common/components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import Box from '../../../../common/components/primitives/Box';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Cluster from '../../../../common/components/primitives/Cluster';
import Grid from '../../../../common/components/primitives/Grid';
import Select from '../../../../components/inputs/Select';
import Filters from '../../../../components/inputs/Filters';
import PatientProfile from '../../../../components/patients/PatientProfile';
import {
  SORTING_ORDER__ASCENDING,
  SORTING_ORDER__DESCENDING,
} from '../../../../common/constants';
import Table from '../../../../components/lists/Table';
import Search from '../../../../components/inputs/Search';
import RecruitFiltered, {
  dialog as recruitFilteredDialog,
} from '../../../../components/dialogs/RecruitFiltered';
import branding from '../../../../utils/branding';

const getSortingOptionBy = (sortingOptions, key) => find(sortingOptions, ({
  settings,
}) => settings.id === key);
const getIsSortingOptionInUse = (sortingOptions, key) => !!getSortingOptionBy(sortingOptions, key);

const getColumns = ({
  project,
  projectNames,
  sortingOptions,
  currentSorting,
  currentSortingOrder,
  t,
  i18n,
}) => {
  const columns = [];

  const getIsCurrentSortOrderBy = (key) => {
    const currentSortingOption = getSortingOptionBy(sortingOptions, key);

    if (!currentSortingOption) {
      return false;
    }

    return currentSorting === currentSortingOption.id;
  };

  const getSortOrder = () => {
    if (!currentSortingOrder) {
      return false;
    }

    return currentSortingOrder === SORTING_ORDER__ASCENDING
      ? 'ascend'
      : 'descend';
  };

  columns.push({
    title: t('recipient', {
      context: branding,
    }),
    dataIndex: 'patient',
    key: 'recipientName',
    sorter: getIsSortingOptionInUse(sortingOptions, 'recipientName'),
    sortOrder: getIsCurrentSortOrderBy('recipientName') && getSortOrder(),
    render: (text, record) => <PatientProfile patientRecord={record} />,
    width: 250,
  });

  if (project) {
    forEach(project.variables, (variable) => {
      // NOTE: We skip these variables for now, because they are presented via PatientProfile
      if (
        !variable.isPatientName() &&
        !variable.isPatientGender() &&
        variable.isAtomic() &&
        (variable.isParticipation() || variable.isPatient())
      ) {
        columns.push({
          title: variable.name,
          dataIndex: variable._id,
          key: variable._id,
          render: (text, record) => {
            return variable.getDisplayValue(record.getVariable(variable._id), {
              projectId: project._id,
              language: i18n.language,
            });
          },
          sorter: getIsSortingOptionInUse(sortingOptions, variable._id),
          sortOrder: getIsCurrentSortOrderBy(variable._id) && getSortOrder(),
        });
      }
    });
  } else {
    columns.push({
      title: t('project', {
        count: 0,
      }),
      dataIndex: 'projects',
      key: 'projects',
      render: (text, record) => {
        const projects = [];

        each(
          record.participatedIn,
          ({
            projectId,
          }) => projectNames &&
            projectNames[projectId] &&
            projects.push(projectNames[projectId]),
        );

        return projects.join(', ') || 'None';
      },
    });
  }

  return columns;
};

const PatientsTable = compose(
  withHandlers({
    onSelectSorting: ({
      onSelectSorting,
    }) => (e, item) => onSelectSorting(item.props.value),
    onSelectSortingOrder: ({
      onSelectSortingOrder,
    }) => (e, item) => onSelectSortingOrder(item.props.value),
    handleTableChange: ({
      sortingOptions,
      onSelectSorting,
      onSelectSortingOrder,
    }) => (pagination, filters, sorter) => {
      if (isEmpty(sorter)) {
        onSelectSorting(null);
        onSelectSortingOrder(null);
      }

      if (!isEmpty(sorter)) {
        const {
          column,
          order,
        } = sorter;
        const {
          key,
        } = column;
        const sorting = getSortingOptionBy(sortingOptions, key);

        if (sorting) {
          onSelectSorting(sorting.id);
          onSelectSortingOrder(
            order === 'descend'
              ? SORTING_ORDER__DESCENDING
              : SORTING_ORDER__ASCENDING,
          );
        }
      }
    },
    onRecruitFiltered: ({
      dispatch,
    }) => ({
      patients,
    }) => dispatch(
      recruitFilteredDialog.open({
        patients,
      }),
    ),
  }),
)(
  ({
    patients,
    onAddPatient,
    projects,
    project,
    userNames,
    projectNames,
    sortingOptions,
    currentSorting,
    currentSortingOrder,
    onChangeProject,
    subscriptionsReady,
    handleTableChange,
    validate,
    projectId,
    currentPage,
    nPatients,
    pageSize,
    onPageChange,
    onShowSizeChange,
    onProjectExport,
    hasFilters,
    onRecruitFiltered,
  }) => {
    const {
      t,
      i18n,
    } = useTranslation();

    const columns = useMemo(
      () => getColumns({
        project,
        userNames,
        projectNames,
        sortingOptions,
        currentSorting,
        currentSortingOrder,
        t,
        i18n,
      }),
      [
        project,
        userNames,
        projectNames,
        sortingOptions,
        currentSorting,
        currentSortingOrder,
        t,
        i18n,
      ],
    );
    const projectOptions = useMemo(() => {
      const ALL_PATIENTS_OPTION = {
        value: null,
        label: t('allRecipients', {
          context: branding,
        }),
      };

      return projects
        ? [
          ALL_PATIENTS_OPTION,
          {
            key: 'projects',
            label: t('project', {
              count: 0,
            }),
            options: projects.map(({
              _id,
              name,
            }) => ({
              value: _id,
              label: name,
            })),
          },
        ]
        : [
          ALL_PATIENTS_OPTION,
        ];
    }, [
      projects,
      t,
    ]);

    return (
      <>
        <Stack>
          <Sidebar
            sidebar={(
              <Cluster>
                {projectId && (
                  <Filters
                    projectId={projectId}
                    tags={[
                      'directory',
                    ]}
                  />
                )}
                {hasFilters && (
                  <Button
                    data-testid="button-Recruit Filtered"
                    type="primary"
                    onClick={() => onRecruitFiltered({
                      patients,
                    })}
                  >
                    {t('recruit')}
                  </Button>
                )}
                <Button
                  data-testid="button-add-patient"
                  type="primary"
                  onClick={onAddPatient}
                  disabled={
                    project
                      ? !validate(PROJECT_ATTACH_PARTICIPATION, {
                        relativeTo: project.getDomains(),
                      })
                      : !validate(PATIENT_CREATE_PATIENT)
                  }
                >
                  {project
                    ? t('addToProject')
                    : t('addRecipient', {
                      context: branding,
                    })}
                </Button>
                <Dropdown
                  overlay={(
                    <Menu>
                      <Menu.Item
                        data-testid="submenu-item-download"
                        key="download"
                        onClick={onProjectExport}
                        disabled={!project}
                      >
                        {t('downloadCSV')}
                      </Menu.Item>
                    </Menu>
                  )}
                  trigger={[
                    'click',
                  ]}
                >
                  <Button
                    data-testid="submenu"
                    icon={<MoreHoriz size={20} />}
                  />
                </Dropdown>
              </Cluster>
            )}
          >
            <Grid>
              <Search />
              <Select
                data-testid="select-project"
                className="zedoc-tour__filter"
                options={projectOptions}
                value={project && project._id}
                onChange={onChangeProject}
                showSearch
              />
            </Grid>
          </Sidebar>
          <Box.Primary boxShadow="base">
            <Table
              data-testid="patient-table"
              rowKey="_id"
              title={t('recipient', {
                count: 0,
                context: branding,
              })}
              dataSource={patients}
              columns={columns}
              loading={!subscriptionsReady}
              pagination={{
                total: nPatients,
                current: currentPage,
                onChange: onPageChange,
                pageSizeOptions: [
                  '5',
                  '10',
                  '15',
                ],
                showSizeChanger: true,
                pageSize,
                onShowSizeChange,
                itemRender: (page, type, originalElement) => (
                  <span data-testid={`pagination-${type}-${page}`}>
                    {originalElement}
                  </span>
                ),
              }}
              onChange={handleTableChange}
            />
          </Box.Primary>
        </Stack>
        <RecruitFiltered />
      </>
    );
  },
);

PatientsTable.propTypes = {
  patients: PropTypes.arrayOf(PropTypes.instanceOf(PatientRecord)).isRequired,
  project: PropTypes.instanceOf(Project),
  sortingOptions: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  userNames: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onAddPatient: PropTypes.func,
  onChangeProject: PropTypes.func,
  currentSorting: PropTypes.string,
  currentSortingOrder: PropTypes.string,
  onSelectSorting: PropTypes.func,
  onSelectSortingOrder: PropTypes.func,
};

const noop = () => {};
PatientsTable.defaultProps = {
  project: null,
  sortingOptions: [],
  userNames: {},
  onAddPatient: noop,
  onChangeProject: noop,
  onSelectSorting: noop,
  onSelectSortingOrder: noop,
};

export default PatientsTable;
