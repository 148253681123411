import map from 'lodash/map';
import BaseModel from './BaseModel';
import {
  fromBindingsArray,
} from '../utils/formValues';
import {
  toFormValues,
} from '../utils/responses';

/**
 * Represent an AnswersSheetSession.
 * @class
 * @classdesc
 * The session document stores information about the session owner,
 * last activity timestamp (for detecting inactive sessions). After
 * a longer period of inactivity a session will be flagged as "abandoned"
 * by a dedicated cron job.
 */
class AnswersSheetSession extends BaseModel {
  /**
   * Returns id of the session owner.
   * @public
   * @returns {String}
   */
  getUserId() {
    return this.userId;
  }

  /**
   * Returns id of the corresponding answers sheet.
   * @public
   * @returns {String}
   */
  getAnswersSheetId() {
    return this.answersSheetId;
  }

  /**
   * Tells if the session is active (not abandoned) or not.
   * @public
   * @returns {Boolean}
   */
  isActive() {
    return !this.abandoned;
  }

  /**
   * Tells if the session is abandoned (inactive) or not.
   * @public
   * @returns {Boolean}
   */
  isAbandoned() {
    return !!this.abandoned;
  }

  /**
   * Returns variables object that can be later passed
   * to EvaluationScope constructor.
   * @public
   * @returns {Object}
   */
  getEvaluationScopeVariables() {
    return fromBindingsArray(this.variables);
  }

  /**
   * Returns a simplified version of responses array,
   * only containing the most relevant keys.
   * @public
   * @returns {Array}
   */
  getSimpleResponses() {
    return map(
      this.responses,
      ({
        questionId,
        answer,
        hierarchyKey,
        whyEmpty,
      }) => ({
        questionId,
        answer,
        hierarchyKey,
        whyEmpty,
      }),
    );
  }

  toFormValues(options) {
    return toFormValues(this.responses, options);
  }
}

AnswersSheetSession.collection = 'AnswersSheetSessions';

export default AnswersSheetSession;
