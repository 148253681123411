import React from 'react';
import PropTypes from 'prop-types';
import Switch from './Switch';

const DarkModeToggle = ({
  checked,
  onToggle,
}) => {
  const onChange = bool => onToggle(bool);

  return (
    <Switch
      checked={checked}
      onChange={onChange}
      checkedChildren="On"
      unCheckedChildren="Off"
    />
  );
};

DarkModeToggle.propTypes = {
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
};

DarkModeToggle.defaultProps = {
  checked: false,
  onToggle: () => {},
};

export default DarkModeToggle;
