import React, {
  useMemo,
  useCallback,
} from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import Table from '../../../components/lists/Table';
import {
  apiAdminSearchVariables,
} from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import VariablesSelect from '../../../common/selectors/Variable';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import {
  openEditVariableDialog,
  getFilters,
  getSorter,
} from '../store';

const VariablesTable = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const dispatch = useDispatch();
  const {
    t,
  } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: variables,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: VariablesSelect,
    getSubscription: (currentPage, resultsPerPage) => apiAdminSearchVariables.withParams({
      filters: map(filters, filter => omit(filter, 'meta')),
      sorter,
      controlId: '$meta.id',
      pageIndex: currentPage - 1,
      resultsPerPage,
    }),
  });

  const highlight = useHighlight(filters, [
    'name',
  ]);

  const handleSelectVariable = useCallback(
    (variable) => {
      dispatch(
        openEditVariableDialog({
          variableId: variable._id,
        }),
      );
    },
    [
      dispatch,
    ],
  );

  const columns = useMemo(
    () => [
      {
        title: t('Name'),
        dataIndex: 'name',
        render: (_, variable) => (
          <Highlighter
            text={variable.name}
            highlight={highlight}
          />
        ),
      },
      {
        title: t('forms:resourceType.label'),
        dataIndex: 'scopeName',
        render: (_, variable) => t(`forms:resourceType.options.${variable.getResourceType()}`),
      },
      {
        title: t('forms:belongsTo.label'),
        dataIndex: 'belongsTo',
        render: (_, variable) => variable.ownership.map(({
          domain,
        }) => (
          <>
            <span>{domain}</span>
            <br />
          </>
        )),
      },
      // {
      //   title: 'PII',
      //   dataIndex: 'pii',
      //   render: (_, variable) => (
      //     variable.pii ? 'YES' : 'NO'
      //   ),
      // },
    ],
    [
      t,
      highlight,
    ],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={variables}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={variable => ({
        onClick: () => handleSelectVariable(variable),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default VariablesTable;
