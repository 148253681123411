import compact from 'lodash/compact';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import Text from '../../common/components/base/Text';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Recipient from '../../common/models/Recipient';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../common/permissions';
import usePermission from '../../utils/usePermission';

const RecipientDetailsList = ({
  recipient,
}) => {
  const {
    t,
  } = useTranslation();
  const canSeePII = usePermission([
    PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  ], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const details = useMemo(() => {
    const items = compact([
      recipient.getIdentifier() && {
        title: t('forms:identifier.label'),
        description: recipient.getIdentifier(),
      },
      recipient.getGender() && {
        title: t('forms:gender.label'),
        description: recipient.getGender(),
      },
      recipient.formatEthnicity() && {
        title: t('forms:ethnicity.label'),
        description: recipient.formatEthnicity(),
      },
      recipient.formatDateOfBirth() && {
        title: t('forms:dateOfBirth.label'),
        description: recipient.formatDateOfBirth(),
      },
      recipient.getNationalId() && {
        title: t('forms:identifier.label'),
        description: recipient.getNationalId(),
      },
    ]);

    if (recipient.hasPhoneNumbers()) {
      recipient.getPhoneNumbers().map(({
        number,
        type,
      }) => items.push({
        title: `${type} ${t('forms:phone.label')}`,
        description: <a href={`tel:${number}`}>{number}</a>,
      }));
    }

    if (canSeePII && recipient.hasEmailAddresses()) {
      recipient.getEmailAddresses().map(({
        address,
        type,
      }) => items.push({
        title: type
          ? `${type} ${t('forms:email.label')}`
          : t('forms:email.label'),
        description: <a href={`mailto:${address}`}>{address}</a>,
      }));
    }

    return items;
  }, [
    recipient,
    canSeePII,
    t,
  ]);

  return (
    <Cluster>
      {details.map(({
        title,
        description,
      }, i) => (
        <React.Fragment
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          {i > 0 && <div>&#xb7;</div>}
          <Stack space={0}>
            <Text.Paragraph importance="high">{title}</Text.Paragraph>
            <Text.Paragraph>{description}</Text.Paragraph>
          </Stack>
        </React.Fragment>
      ))}
    </Cluster>
  );
};

RecipientDetailsList.propTypes = {
  recipient: PropTypes.instanceOf(Recipient),
};

RecipientDetailsList.defaultProps = {
  recipient: new Recipient({}),
};

export default RecipientDetailsList;
