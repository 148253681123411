import BaseModel from '../BaseModel';

class Action extends BaseModel {
  constructor(doc) {
    super(doc);
    this.settings = this.settings || {};
  }

  doSelf() {
    this.constructor.logger.warn('Action.doSelf() not implemented');
    return [];
  }

  undoSelf() {
    this.constructor.logger.warn('Action.undoSelf() not implemented');
    return [];
  }

  // eslint-disable-next-line class-methods-use-this
  validate() {
    return true;
  }
}

export default Action;
