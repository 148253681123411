import base64js from 'base64-js';

function strToBase64(str) {
  const uint8 = [];
  const utf8Str = unescape(encodeURIComponent(str));
  for (let i = 0; i < utf8Str.length; i += 1) {
    uint8[i] = utf8Str.charCodeAt(i);
  }
  return base64js.fromByteArray(uint8);
}

function base64ToStr(base64Str) {
  const uint8 = base64js.toByteArray(base64Str);
  let utf8Str = '';
  for (let i = 0; i < uint8.length; i += 1) {
    utf8Str += String.fromCharCode(uint8[i]);
  }
  return decodeURIComponent(escape(utf8Str));
}

export {
  strToBase64,
  base64ToStr,
};
