import {
  createGlobalStyle,
} from 'styled-components';
import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
} from 'react-redux';
import {
  getLanguage,
} from '../store/preferences';

const GlobalStyle = createGlobalStyle`
  body {
    direction: ${props => props.direction};
  }
`;

const LanguageProvider = ({
  children,
}) => {
  const {
    i18n,
  } = useTranslation();
  const [
    direction,
    setDirection,
  ] = useState();
  const language = useSelector(getLanguage);

  useEffect(() => {
    i18n.changeLanguage(language);
    setDirection(i18n.dir(language));
  }, [
    language,
    i18n,
  ]);

  return (
    <>
      <GlobalStyle direction={direction} />
      {children}
    </>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageProvider;
