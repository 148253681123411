import sampleSize from 'lodash/sampleSize';
import {
  ANSWER_VALUE_MAX_LENGTH,
  QUESTION_TYPE__SELECT_MANY,
} from '../../../constants';
import Question from '../Question';

export default class QuestionSelectMany extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SELECT_MANY;
  }

  getRandomAnswer() {
    const nChoices = this.choices ? this.choices.length : 0;
    const choices =
      this.isOptional() && Math.random() < 0.5
        ? []
        : sampleSize(this.choices, 1 + Math.floor(nChoices * Math.random()));
    return {
      value: choices.map(({
        value,
      }) => value),
    };
  }
}

QuestionSelectMany.expectedRawKeys = [
  'value',
  'other',
  'text1',
  'text2',
];

QuestionSelectMany.expectedValueType = {
  type: 'array',
  items: {
    type: 'string',
    maxLength: ANSWER_VALUE_MAX_LENGTH,
  },
};

QuestionSelectMany.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__SELECT_MANY] = QuestionSelectMany;
