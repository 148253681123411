import {
  createQuestionnaireMiddleware,
} from '../common/containers/Questionnaire';
import {
  saveProgress,
  getInitialValues,
} from '../common/api/forms';
import {
  toResponsesArray,
} from '../common/utils/responses';
import {
  callMethod,
} from '../common/utilsClient/ddp/actions';

// eslint-disable-next-line import/prefer-default-export
export function createMiddleware() {
  return createQuestionnaireMiddleware({
    autosaveDelay: 2500,
    getInitialValues: ({
      answersSheetId,
      dispatch,
    }) => dispatch(
      callMethod(getInitialValues, {
        answersSheetId,
      }),
    )
      .then(({
        formValues,
        initialValues,
      }) => {
        // formValuesConflicts,
        // initialValuesConflicts,
        const values = formValues || initialValues;
        // console.log('formValues', formValues);
        // console.log('formValuesConflicts', formValuesConflicts);
        // console.log('initialValues', initialValues);
        // console.log('initialValuesConflicts', initialValuesConflicts);
        return values;
      })
      .catch((err) => {
        console.error(err);
      }),
    handleAutosave: ({
      answersSheetId,
      formValues,
      dispatch,
    }) => {
      // TODO: Find compare against previous values to reduce
      //       the cost of this operation.
      const newResponses = toResponsesArray(formValues);
      return dispatch(
        callMethod(saveProgress, {
          answersSheetId,
          newResponses,
        }),
      );
    },
  });
}
