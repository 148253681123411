import {
  connect,
} from 'react-redux';
import {
  mapProps,
  compose,
} from 'recompose';
import isEqual from 'lodash/isEqual';
import {
  createSelector,
  defaultMemoize,
  createSelectorCreator,
  createStructuredSelector,
} from 'reselect';
import {
  ddp,
} from '@theclinician/ddp-connector';
import CurrentUserSelect from '../selectors/CurrentUser';
import {
  property,
} from '../utilsClient/selectors';
// import Schema, { validatedFunction } from '../utils/Schema';

const constant = x => () => x;
const identity = x => x;
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// const checkPermissions = validatedFunction(new Schema({
//   renderLoader: { type: Function, optional: true },
//   permissions: { type: Schema.oneOf([Function, [Schema.any()]]) },
//   currentUser: { type: Boolean, optional: true },
//   propToSet: { type: String, optional: true },
//   transform: { type: Function, optional: true },
// }), ...);

const checkPermissions = ({
  renderLoader,
  permissions,
  currentUser,
  propToSet = 'currentPermissions',
  transform = identity,
}) => compose(
  ddp({
    subscriptions: () => [
      {
        name: 'Users.current',
        params: [],
      },
      {
        name: 'Roles.current',
        params: [],
      },
    ],
    renderLoader,
  }),
  connect(() => createStructuredSelector({
    waitingForPermissions: createSelector(
      CurrentUserSelect.userId(),
      CurrentUserSelect.isLoggingIn(),
      property('subscriptionsReady'),
      (userId, isLoggingIn, subscriptionsReady) => (!userId && isLoggingIn) || !subscriptionsReady,
    ),
    // create a selector that returns a permissions grant evaluated from "permissions"
    // and current user permissions validator;
    [propToSet]: createDeepEqualSelector(
      CurrentUserSelect.getCurrentPermissionsValidator(),
      typeof permissions === 'function'
        ? permissions
        : constant(permissions),
      (validate, _permissions) => transform(validate && validate(_permissions)),
    ),
    ...(currentUser && {
      currentUser: CurrentUserSelect.user(),
    }),
  })),
  mapProps(({
    subscriptionsReady,
    ...props
  }) => props),
);

export default checkPermissions;
