import isArray from 'lodash/isArray';
import indexOf from 'lodash/indexOf';
import QuestionCursorBase, {
  CURSOR_TYPE__PROLOGUE,
  CURSOR_TYPE__EPILOGUE,
} from './QuestionCursorBase';
import QuestionCursorReversed from './QuestionCursorReversed';

class QuestionCursor extends QuestionCursorBase {
  firstChildQuestionId() {
    const {
      children,
    } = this;
    if (children.length > 0) {
      return children[0].id;
    }
    return undefined;
  }

  firstChildElementId() {
    const value = this.getElementsOrder();
    if (isArray(value) && value.length > 0) {
      return value[0];
    }
    return undefined;
  }

  nextChildElementId(elementId) {
    const value = this.getElementsOrder();
    if (isArray(value)) {
      const index = indexOf(value, elementId);
      if (index >= 0 && index + 1 < value.length) {
        return value[index + 1];
      }
    }
    return undefined;
  }

  nextChildQuestionId(questionId) {
    const {
      children,
    } = this;
    const index = this.getQuestionIndex(questionId);
    if (index + 1 < children.length) {
      return children[index + 1].id;
    }
    return undefined;
  }

  reverse() {
    return new QuestionCursorReversed({
      ...this,
      parentCursor: this.parentCursor && this.parentCursor.reverse(),
    });
  }
}

QuestionCursor.cursorTypeOnSectionEnter = CURSOR_TYPE__PROLOGUE;
QuestionCursor.cursorTypeOnSectionLeave = CURSOR_TYPE__EPILOGUE;

QuestionCursorReversed.prototype.reverse = function reverse() {
  return new QuestionCursor({
    ...this,
    parentCursor: this.parentCursor && this.parentCursor.reverse(),
  });
};

export default QuestionCursor;
