import React from 'react';
import PropTypes from 'prop-types';
import {
  TagCloud,
} from 'react-tagcloud';

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      count: PropTypes.number,
    }),
  ).isRequired,
  handleKeywordClick: PropTypes.func.isRequired,
};

const defaultProps = {};

const colorOptions = {
  // https://github.com/davidmerfield/randomColor#options
  luminosity: 'dark',
  hue: 'orange',
};

const KeywordsCloud = ({
  items,
  handleKeywordClick,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <TagCloud
      minSize={12}
      maxSize={35}
      tags={items}
      colorOptions={colorOptions}
      onClick={handleKeywordClick}
    />
  );
};

KeywordsCloud.propTypes = propTypes;
KeywordsCloud.defaultProps = defaultProps;

export default KeywordsCloud;
