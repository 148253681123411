import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isObject from 'lodash/isObject';
import EvaluationScope from '../../models/EvaluationScope';
import getFormErrors from './getFormErrors';
import evaluateFormValuesAndProperties from './evaluateFormValuesAndProperties';
import {
  ANSWERS_SHEET_NAVIGATION_TYPE__CAT,
} from '../../constants';
import {
  toFormValues,
} from '../responses';

const isAnswersSheet = (object) => {
  if (!object || !isObject(object)) {
    return false;
  }
  if (typeof object.getNavigationType !== 'function') {
    return false;
  }
  if (typeof object.toFormValues !== 'function') {
    return false;
  }
  return true;
};

const validateQuestionnaire = (
  questionnaire,
  answersSheetOrFormValues,
  {
    scopeOptions,
    propertiesOverrides: defaultPropertiesOverrides,
  } = {},
) => {
  let answersSheet;
  let evaluationScope;
  if (isPlainObject(answersSheetOrFormValues)) {
    evaluationScope = new EvaluationScope({
      ...scopeOptions,
      questionnaire,
      answers: answersSheetOrFormValues,
    });
  } else {
    answersSheet = isAnswersSheet(answersSheetOrFormValues)
      ? answersSheetOrFormValues
      : null;
    evaluationScope = new EvaluationScope({
      ...scopeOptions,
      questionnaire,
      answers: answersSheet && answersSheet.toFormValues(),
    });
  }

  let propertiesOverrides = defaultPropertiesOverrides;
  if (
    !propertiesOverrides &&
    answersSheet &&
    answersSheet.getNavigationType() === ANSWERS_SHEET_NAVIGATION_TYPE__CAT
  ) {
    // NOTE: Force all questions to be optional, except those which were already answered.
    propertiesOverrides = toFormValues(answersSheet.responses, {
      pickValues: () => ({
        optional: false,
      }),
    });
    propertiesOverrides['*'] = {
      optional: true,
    };
  }

  const {
    formValues,
    properties,
  } = evaluateFormValuesAndProperties(
    evaluationScope,
    {
      propertiesOverrides,
    },
  );

  const formErrors = getFormErrors(questionnaire, formValues, {
    properties,
    skipHidden: true,
  });

  if (!isEmpty(formErrors)) {
    return formErrors;
  }

  return null;
};

export default validateQuestionnaire;
