import isNil from 'lodash/isNil';
import {
  QUESTION_TYPE__RAW_JSON,
  QUESTION_TYPE__BLUETOOTH_DEVICE,
} from '../../../constants';
import Question from '../Question';
import checkSchema from '../../../utils/checkSchema';

export default class QuestionJSONString extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__RAW_JSON;
  }

  get jsonSchema() {
    let jsonSchema;
    try {
      jsonSchema = JSON.parse(this.settings.jsonSchemaString);
    } catch (err) {
      jsonSchema = false; // means: accept nothing ...
    }
    Object.defineProperty(this, 'jsonSchema', {
      value: jsonSchema,
    });
    return jsonSchema;
  }

  getFieldError(fieldName, value) {
    const error = super.getFieldError(fieldName, value);
    if (error) {
      return error;
    }
    // NOTE: Mimic the original Question.getValueError behavior.
    if (isNil(value) || value === '') {
      return null;
    }
    switch (fieldName) {
      case 'value': {
        let jsonValue;
        try {
          jsonValue = JSON.parse(value);
        } catch (err) {
          return {
            type: 'JSON.parse',
            message: err.toString(),
          };
        }
        return checkSchema(this.jsonSchema, jsonValue);
      }
      default:
        return null;
    }
  }
}

QuestionJSONString.expectedRawKeys = [
  'value',
];
QuestionJSONString.expectedValueType = {
  type: 'string',
};
Question.types[QUESTION_TYPE__RAW_JSON] = QuestionJSONString;
Question.types[QUESTION_TYPE__BLUETOOTH_DEVICE] = QuestionJSONString;
