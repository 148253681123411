import moment from 'moment';
import React from 'react';
import isDate from 'lodash/isDate';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  createStructuredSelector,
  createSelector,
} from 'reselect';
import {
  property,
} from '../../common/utilsClient/selectors';
import ProjectSelect from '../../common/selectors/Project';
import Tooltip from '../../common/components/Tooltip';
import {
  createUtcToLocalTime,
} from '../../common/utils/zone';

const yearMonthDayToLocalTs = (timezone, yearMonthDay) => {
  const utcToLocalTime = createUtcToLocalTime(timezone, {
    // NOTE: What "strict" mean in this case is that we are trying to find an exact
    //       timestamp representation for the clock time, e.g. "YYYY-MM-DDT00:000",
    //       but this may not exist sometimes, e.g. in case of DST switch,
    //       for example: Pacific/Auckland timezone, 2020-09-28T02:30.
    noStrict: true,
  });
  const m = moment.utc(yearMonthDay);
  if (!m.isValid()) {
    return null;
  }
  const utcTs = m.valueOf();
  return utcToLocalTime(utcTs);
};

const LocalDate = connect(() => {
  const selectProject = ProjectSelect.one().whereIdMatchesProp('projectId');
  const selectMoment = createSelector(
    selectProject,
    property('date'),
    (project, date) => {
      if (!project || isNil(date)) {
        return moment.invalid();
      }
      let m;
      if (isDate(date)) {
        m = moment(date);
      } else if (typeof date === 'string') {
        const localTs = yearMonthDayToLocalTs(project.getTimezone(), date);
        if (!localTs) {
          m = moment.invalid();
        } else {
          m = moment(localTs);
        }
      }
      if (m.isValid()) {
        return project.getMomentInLocalTime(m.toDate());
      }
      return moment.invalid();
    },
  );

  return createStructuredSelector({
    project: selectProject,
    localDate: createSelector(property('short'), selectMoment, (short, m) => (m.isValid() ? m.format(short ? 'MMM Do YY' : 'MMMM Do YYYY') : 'N/A')),
  });
})(({
  project,
  localDate,
  showTimezone,
}) => (showTimezone ? (
  <Tooltip title={project && project.getTimezone()}>{localDate}</Tooltip>
) : (
  localDate
)));

LocalDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  projectId: PropTypes.string.isRequired,
  short: PropTypes.bool,
  showTimezone: PropTypes.bool,
};

LocalDate.defaultProps = {
  date: null,
  short: false,
  showTimezone: false,
};

export default LocalDate;
