import React from 'react';
import {
  createStructuredSelector,
  createSelector,
} from 'reselect';
import {
  compose,
} from 'recompose';
import {
  connect,
} from 'react-redux';
import {
  withTranslation,
  Trans,
} from 'react-i18next';
import ConfirmAction from '../../../components/dialogs/ConfirmAction';
import RecipientSelect from '../../../common/selectors/Recipient';
import AnswersSheetSelect from '../../../common/selectors/AnswersSheet';
import {
  notifySuccess,
  notifyError,
} from '../../../utils/notify';
import {
  apiZedocRemoveEmptyAnswersSheet,
} from '../../../common/api/zedoc';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import Text from '../../../common/components/base/Text';
import {
  getActiveAnswersSheetId,
  getActivePatientId,
  getRemoveAnswersSheetDialogVisible,
} from '../selectors';
import {
  closeRemoveAnswersSheetDialog,
} from '../actions';

const RemoveAnswersSheet = compose(
  withTranslation(),
  connect(
    createStructuredSelector({
      open: getRemoveAnswersSheetDialogVisible,
      title: createSelector(
        (_, {
          t,
        }) => t,
        t => t('removeQuestionnaire'),
      ),
      okType: () => 'danger',
      params: createStructuredSelector({
        answersSheetId: getActiveAnswersSheetId,
        recipientId: getActivePatientId,
      }),
      message: createSelector(
        RecipientSelect.one().whereIdEquals(getActivePatientId),
        AnswersSheetSelect.one().whereIdEquals(getActiveAnswersSheetId),
        (_, {
          t,
        }) => t,
        (recipient, answersSheet, t) => (recipient && answersSheet ? (
          <Trans i18nKey="confirmations:removeQuestionnaire.titleWithName">
            <Text.Span importance="high">
              {{
                recipientName: recipient.getFullName(),
              }}
            </Text.Span>
          </Trans>
        ) : (
          t('confirmations:removeQuestionnaire.title')
        )),
      ),
    }),
    (dispatch, {
      t,
    }) => ({
      onCancel: () => dispatch(closeRemoveAnswersSheetDialog()),
      onConfirm: ({
        answersSheetId,
      }) => dispatch(
        callMethod(apiZedocRemoveEmptyAnswersSheet, {
          answersSheetId,
        }),
      )
        .then(notifySuccess(t('confirmations:removeQuestionnaire.success')))
        .catch(notifyError())
        .then(() => dispatch(
          closeRemoveAnswersSheetDialog({
            removed: true,
          }),
        )),
    }),
  ),
)(ConfirmAction);

export default RemoveAnswersSheet;
