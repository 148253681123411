import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import styles from '../styles/form-controls';
import InputWithSuffix from './InputWithSuffix';

const StyledInput = styled.input`
  ${styles};
`;

const Input = React.forwardRef(
  (
    {
      'data-testid': testId,
      id,
      'aria-haspopup': ariaHasPopup,
      'aria-owns': ariaOwns,
      'aria-autocomplete': ariaAutoComplete,
      'aria-controls': ariaControls,
      'aria-activedescendant': ariaActiveDescendant,
      'aria-expanded': ariaExpanded,
      role,
      autoComplete,
      className,
      type,
      inputMode,
      size,
      placeholder,
      autoFocus,
      disabled,
      prefix,
      suffix,
      min,
      max,
      step,
      value,
      onChange,
      onBlur,
    },
    forwardedRef,
  ) => {
    if (prefix || suffix) {
      return (
        <InputWithSuffix
          data-testid={testId}
          id={id}
          className={className}
          type={type}
          inputMode={inputMode}
          size={size}
          placeholder={placeholder}
          autoFocus={autoFocus}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
    }

    return (
      <StyledInput
        ref={forwardedRef}
        data-testid={testId}
        id={id}
        aria-haspopup={ariaHasPopup}
        aria-owns={ariaOwns}
        aria-autocomplete={ariaAutoComplete}
        aria-controls={ariaControls}
        aria-activedescendant={ariaActiveDescendant}
        aria-expanded={ariaExpanded}
        role={role}
        autoComplete={autoComplete}
        className={className}
        type={type}
        inputMode={inputMode}
        size={size}
        placeholder={placeholder}
        autoFocus={autoFocus}
        disabled={disabled}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  },
);

Input.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  'aria-haspopup': PropTypes.string,
  'aria-owns': PropTypes.string,
  'aria-autocomplete': PropTypes.string,
  'aria-controls': PropTypes.string,
  'aria-activedescendant': PropTypes.string,
  'aria-expanded': PropTypes.string,
  role: PropTypes.string,
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  'data-testid': 'input',
  id: null,
  'aria-haspopup': null,
  'aria-owns': null,
  'aria-autocomplete': null,
  'aria-controls': null,
  'aria-activedescendant': null,
  'aria-expanded': null,
  role: null,
  autoComplete: null,
  className: null,
  type: 'text',
  inputMode: null,
  size: 'default',
  placeholder: null,
  autoFocus: false,
  disabled: false,
  prefix: null,
  suffix: null,
  min: null,
  max: null,
  step: null,
  value: '',
  onChange: () => {},
  onBlur: () => {},
};

export default Input;
