import DashboardTimeline from './DashboardTimeline';
import {
  PROJECT_DASHBOARD_TYPE__TIMELINE,
  PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE,
  DASHBOARD_PERSPECTIVE__PATIENTS,
} from '../../../../common/constants';
import {
  registerType,
} from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__TIMELINE, {
  component: DashboardTimeline,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    },
  ],
});

registerType(PROJECT_DASHBOARD_TYPE__MILESTONES_TABLE, {
  component: DashboardTimeline,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
    },
  ],
});

export default DashboardTimeline;
