import Formula from '../Formula';
import {
  FORMULA_TYPE__ELEMENT_ID,
} from '../../../constants';

class FormulaElementId extends Formula {
  // eslint-disable-next-line class-methods-use-this
  validate() {
    return undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  evaluate(scope) {
    const elementId = scope.getElementId();
    if (!elementId) {
      return {
        error: Formula.BadContext,
      };
    }
    return {
      value: elementId,
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }
}

Formula.types[FORMULA_TYPE__ELEMENT_ID] = FormulaElementId;

export default FormulaElementId;
