import {
  connect,
} from 'react-redux';
import {
  compose,
} from 'recompose';
import {
  closeAnswersSheetDialog,
} from '../../../screens/ProjectsDashboard/actions';
import MasterLayout from './MasterLayout';

const Container = compose(
  connect(
    null,
    dispatch => ({
      handleQuestionnaireClose: () => dispatch(closeAnswersSheetDialog()),
    }),
  ),
)(MasterLayout);

export default Container;
