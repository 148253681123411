import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  ADMIN_CREATE_USER,
  ADMIN_UPDATE_USER,
  ADMIN_DELETE_USER,
} from '../../permissions';

export const insert = new ApiSpec({
  name: 'api.collections.Users.insert',
  permissions: [
    ADMIN_CREATE_USER,
  ],
  schema: new Schema({
    name: {
      type: String,
      optional: true,
    },
    phone: {
      type: String,
      optional: true,
    },
    email: {
      type: String,
      regEx: Schema.RegEx.Email,
    },
    locked: {
      type: Boolean,
      optional: true,
    },
    orgId: {
      type: String,
      optional: true,
    },
    roles: {
      type: [
        String,
      ],
      minCount: 1,
    },
    groups: {
      type: [
        String,
      ],
      optional: true,
    },
    rolesDomain: {
      type: String,
      optional: true,
    },
    resetPassword: {
      type: Boolean,
      optional: true,
    },
  }),
});

export const update = new ApiSpec({
  name: 'api.collections.Users.update',
  permissions: [
    ADMIN_UPDATE_USER,
  ],
  schema: new Schema({
    _id: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    name: {
      type: String,
      optional: true,
    },
    phone: {
      type: String,
      optional: true,
    },
    locked: {
      type: Boolean,
      optional: true,
    },
    orgId: {
      type: String,
      optional: true,
    },
    roles: {
      type: [
        String,
      ],
      optional: true,
    },
    rolesDomain: {
      type: String,
      optional: true,
    },
    groups: {
      type: [
        String,
      ],
      optional: true,
    },
    // NOTE: We currently ignore "email" field, i.e. it cannot be modified.
    email: {
      type: String,
      optional: true,
      regEx: Schema.RegEx.Email,
    },
    resetPassword: {
      type: Boolean,
      optional: true,
    },
    // temporaryPassword: {
    //   type: Schema.Password,
    //   optional: true,
    // },
  }),
});

// export const ensureExists = new ApiSpec({
//   host: settings.public.backendUrl,
//   name: 'api.collections.Users.ensureExists',
//   path: '/api/collections/users/ensure',
//   method: 'post',
//   permissions: [
//     ADMIN_CREATE_USER,
//     ADMIN_UPDATE_USER,
//   ],
//   schema: {
//     type: 'object',
//     required: [
//       'identifier',
//     ],
//     properties: {
//       identifier: {
//         type: 'object',
//         properties: {
//           value: {
//             type: 'string',
//           },
//           namespace: {
//             type: 'string',
//           },
//         },
//       },
//       email: {
//         type: 'string',
//       },
//       emailVerified: {
//         type: 'boolean',
//       },
//       username: {
//         type: 'string',
//       },
//       groups: {
//         type: 'array',
//         items: {
//           type: 'string',
//         },
//       },
//     },
//   },
// });

export const lockBatch = new ApiSpec({
  name: 'api.collections.Users.lockBatch',
  permissions: [
    ADMIN_UPDATE_USER,
  ],
  schema: new Schema({
    usersIds: {
      type: [
        String,
      ],
      regEx: Schema.RegEx.Id,
      maxCount: 20,
    },
  }),
});

export const remove = new ApiSpec({
  name: 'api.collections.Users.remove',
  permissions: [
    ADMIN_DELETE_USER,
    ADMIN_UPDATE_USER,
  ],
  schema: new Schema({
    userId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});

export const removeBatch = new ApiSpec({
  name: 'api.collections.Users.removeBatch',
  permissions: [
    ADMIN_DELETE_USER,
    ADMIN_UPDATE_USER,
  ],
  schema: new Schema({
    usersIds: {
      type: [
        String,
      ],
      regEx: Schema.RegEx.Id,
    },
  }),
});
