import BaseModel from './BaseModel';

class ExportSelection extends BaseModel {
  getOrder() {
    // TODO: Compute default order if no explict order is present.
    return this.order;
  }

  getProjectId() {
    return this.projectId;
  }
}

ExportSelection.collection = 'ExportSelections';

export default ExportSelection;
