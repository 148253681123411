/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import FormFieldWrapper from './FormFieldWrapper';
import Input from './Input';
import TextArea from './TextArea';
import {
  formFieldPropTypesInput,
  formFieldPropTypesMeta,
} from '../utilsClient/propTypes';

const propTypes = {
  input: formFieldPropTypesInput.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  onNextQuestion: PropTypes.func,
  big: PropTypes.bool,
};

const defaultProps = {
  label: '',
  disabled: false,
  required: false,
  autoFocus: false,
  placeholder: null,
  tooltip: null,
  onNextQuestion: null,
  big: false,
};

const FormFieldText = forwardRef(
  (
    {
      id,
      label,
      required,
      disabled,
      autoFocus,
      meta,
      input,
      big,
      placeholder,
      tooltip,
    },
    ref,
  ) => (
    <FormFieldWrapper
      label={label}
      htmlFor={id}
      required={required}
      meta={meta}
      tooltip={tooltip}
    >
      {big && (
        <TextArea
          data-testid="form-field-text-area"
          id={id}
          ref={ref}
          style={{
            resize: 'none',
          }}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          {...input}
        />
      )}
      {!big && (
        <Input
          id={id}
          ref={ref}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          {...input}
        />
      )}
    </FormFieldWrapper>
  ),
);

FormFieldText.propTypes = propTypes;
FormFieldText.defaultProps = defaultProps;

export default FormFieldText;
