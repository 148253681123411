import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React from 'react';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';
import Skeleton from '../Skeleton';
import Divider from '../Divider';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const Component = styled.div`
  background-color: ${theme('color.surface')};
  padding: ${theme('space.3')};
  border-radius: ${theme('border.radius.2')};
  box-shadow: ${props => props.theme.boxShadow[props.boxShadow]};
  overflow: hidden;
`;

/**
 * A card can be used to display content related to a single subject. The content can consist of multiple elements of varying types and sizes.
 */
const Card = ({
  children,
  title,
  extra,
  footer,
  loading,
  bordered,
}) => {
  if (!children && !loading) {
    return null;
  }

  return (
    <Component boxShadow={bordered ? 'base' : 'none'}>
      <Stack>
        {(title || extra) && (
          <>
            <Cluster justify="space-between">
              {title &&
                (loading ? (
                  <Skeleton width={200} />
                ) : (
                  <Text.Paragraph
                    size="large"
                    importance="high"
                  >
                    {title}
                  </Text.Paragraph>
                ))}
              {extra &&
                (loading ? <Skeleton width={100} /> : <div>{extra}</div>)}
            </Cluster>
            <Divider />
          </>
        )}
        {loading ? <Skeleton height="4rem" /> : children}
        {footer && (
          <>
            <Divider />
            {loading ? <Skeleton height="2rem" /> : footer}
          </>
        )}
      </Stack>
    </Component>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  extra: PropTypes.node,
  footer: PropTypes.node,
  loading: PropTypes.bool,
  bordered: PropTypes.bool,
};

Card.defaultProps = {
  title: null,
  extra: null,
  footer: null,
  loading: false,
  bordered: true,
};

export default Card;
