import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import {
  theme,
} from '../utilsClient/cssHelpers';
import {
  formFieldPropTypesMeta,
} from '../utilsClient/propTypes';

const Wrapper = styled.div`
  /* margin: -${theme('padding.md')}px; */
`;

const StyledIframe = styled.iframe`
  flex: 1;
  width: 100%;
  /* margin-top: -50px; */
  /* margin-bottom: -5px; */
`;

// TODO: Later on add support for onChange by listening
//       to window events emitted from the iframe.
const FormFieldIframe = ({
  meta: {
    touched,
    error,
  },
  title,
  src,
}) => {
  return (
    <Wrapper>
      {touched && error && <span>{error}</span>}
      <StyledIframe
        height="300"
        scrolling="yes"
        title={title}
        src={src}
        frameBorder="no"
        allowTransparency="true"
        allowFullScreen="true"
      />
    </Wrapper>
  );
};

FormFieldIframe.propTypes = {
  src: PropTypes.string.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  title: PropTypes.string,
};

FormFieldIframe.defaultProps = {
  title: '',
};

export default FormFieldIframe;
