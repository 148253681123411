import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import {
  connect,
} from 'react-redux';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  compose,
  withProps,
  withHandlers,
} from 'recompose';
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  submit,
} from 'redux-form';
import {
  QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS,
  ANSWERS_SHEET_NAVIGATION_TYPE_OPTIONS,
} from '../../../common/constants';
import Schema from '../../../common/utils/Schema';
import Stack from '../../../common/components/primitives/Stack';
import Modal from '../Modal';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldList from '../../forms/FormFieldList';

const selectMemberField = name => (state, {
  form,
  member,
}) => formValueSelector(form)(state, `${member}.${name}`);

const QuestionnaireProperties = connect(() => createStructuredSelector({
  navigationTypeOptions: createSelector(
    selectMemberField('identifier'),
    (state, props) => props.questionnaireOptions,
    (identifier, questionnaireOptions) => {
      const option = find(questionnaireOptions, {
        value: identifier,
      });
      if (!option) {
        return [];
      }
      return filter(ANSWERS_SHEET_NAVIGATION_TYPE_OPTIONS, ({
        value,
      }) => includes(option.navigationTypes, value));
    },
  ),
}))(({
  member,
  questionnaireOptions,
  navigationTypeOptions,
}) => (
  <Stack>
    <Field
      data-testid="field-identifier"
      component={FormFieldSelect}
      name={`${member}.identifier`}
      type="text"
      label="Questionnaire"
      options={questionnaireOptions}
      showSearch
    />
    {navigationTypeOptions.length > 1 && (
      <Field
        data-testid="field-navigation-type"
        component={FormFieldSelect}
        name={`${member}.navigationType`}
        type="text"
        label="Navigation Type"
        options={navigationTypeOptions}
        showSearch
      />
    )}
    <Field
      data-testid="field-assignee-type"
      component={FormFieldSelect}
      name={`${member}.assigneeType`}
      type="text"
      label="Assignee Type"
      options={QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS}
      showSearch
    />
  </Stack>
));

const QuestionnairesList = props => (
  <FormFieldList
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    properties={QuestionnaireProperties}
  />
);

const DEFAULT_FORM = 'AddAnswersSheets';
const AddAnswersSheetsForm = reduxForm({
  form: DEFAULT_FORM,
  validate: new Schema({
    questionnaires: {
      type: [
        new Schema({
          identifier: {
            type: String,
            label: 'Questionnaire',
          },
          assigneeType: {
            type: String,
            label: 'Assignee type',
          },
          navigationType: {
            type: String,
            label: 'Navigation type',
          },
        }),
      ],
      minCount: 1,
    },
  }).validator({
    noException: true,
  }),
})(({
  form,
  error,
  handleSubmit,
  onSubmit,
  questionnaireOptions,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    {error && <span>{error}</span>}
    <FieldArray
      name="questionnaires"
      label="Questionnaires"
      component={QuestionnairesList}
      props={{
        form,
        questionnaireOptions,
      }}
    />
  </form>
));

const AddAnswersSheets = compose(
  withProps(({
    milestone,
  }) => ({
    milestone,
    initialValues: {
      questionnaires: [
        {},
      ],
    },
  })),
  connect(null, (dispatch, {
    form,
  }) => ({
    doSubmit: () => dispatch(submit(form)),
  })),
  withHandlers({
    onSubmit: ({
      onSubmit,
      params,
    }) => data => onSubmit({
      ...params,
      ...data,
    }),
  }),
)(
  ({
    form,
    open,
    onCancel,
    onSubmit,
    doSubmit,
    initialValues,
    questionnaireOptions,
  }) => (
    <Modal
      title="Add Questionnaires"
      visible={open}
      // confirmLoading={isConfirmLoading}
      okText="Submit"
      onOk={doSubmit}
      onCancel={onCancel}
    >
      <AddAnswersSheetsForm
        form={form}
        onSubmit={onSubmit}
        initialValues={initialValues}
        questionnaireOptions={questionnaireOptions}
      />
    </Modal>
  ),
);

AddAnswersSheets.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  params: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  questionnaireOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      navigationTypes: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

AddAnswersSheets.defaultProps = {
  form: DEFAULT_FORM,
  params: {},
  onCancel: () => {},
  onSubmit: () => {},
};

export default AddAnswersSheets;
