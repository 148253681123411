import map from 'lodash/map';
import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDDPCall,
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  saveAs,
} from 'file-saver';
import {
  ArrowBack,
} from 'styled-icons/material';
import AnswersSheetSelect from '../../common/selectors/AnswersSheet';
import QuestionnaireSelect from '../../common/selectors/Questionnaire';
import DocumentSelect from '../../common/selectors/Document';
import {
  PROJECT_DASHBOARD_TYPE__DOCUMENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
} from '../../common/constants';
import {
  one as oneAnswersSheet,
} from '../../common/api/collections/AnswersSheets';
import {
  one as oneQuestionnaire,
} from '../../common/api/collections/Questionnaires2';
import {
  getResumeToken,
} from '../../common/utilsClient/ddp/selectors';
import {
  apiDocumentsAllForAnswersSheet,
  apiDocumentsGenerateForAnswersSheet,
} from '../../common/api/documents';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Text from '../../common/components/base/Text';
import Button from '../../common/components/Button';
import Select, {
  StyledSelect,
} from '../../common/components/Select';
import settings from '../../common/settings';
import {
  toYearMonthDay,
} from '../../common/utils/date';

const {
  backendUrl,
} = settings.public;

// TODO: Create a PrP specific PDFPreview component that lazy load this component?
const PDFPreview = React.lazy(() => import(
  /* webpackChunkName: "PDFPreview" */ '../../common/components/PDFPreview'
));

const PatientDocument = ({
  dashboardId,
  answersSheetId,
  presetNo,
  openTab,
}) => {
  const dispatch = useDispatch();

  useDDPSubscription(
    apiDocumentsAllForAnswersSheet.withParams({
      dashboardId,
      answersSheetId,
    }),
  );

  const documents = useSelector(
    DocumentSelect.all()
      .where({
        answersSheetId,
        dashboardId,
        dashboardPerspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      })
      .sort({
        createdAt: -1,
      }),
  );

  const [
    documentId,
    setDocumentId,
  ] = useState(null);

  const [
    documentError,
    setDocumentError,
  ] = useState(null);

  // We subscribe / fetch for answers sheet / questionnaire only to get Q name
  // Is there a more efficient way to do so?
  useDDPSubscription(
    answersSheetId && oneAnswersSheet.withParams(answersSheetId),
  );

  const answersSheet = useSelector(
    AnswersSheetSelect.one().whereIdEquals(answersSheetId),
  );
  const questionnaireId = answersSheet && answersSheet.getQuestionnaireId();
  const recipientId = answersSheet.getRecipientId();

  useDDPSubscription(
    questionnaireId && oneQuestionnaire.withParams(questionnaireId),
  );

  const questionnaire = useSelector(
    QuestionnaireSelect.one().whereIdEquals(questionnaireId),
  );
  const resumeToken = useSelector(getResumeToken);

  const {
    ddpCall,
    ddpIsPending,
  } = useDDPCall();

  const handleDocumentsGenerate = useCallback(
    (
      {
        regenerate,
      } = {
        regenerate: false,
      },
    ) => {
      ddpCall(
        apiDocumentsGenerateForAnswersSheet.withParams({
          regenerate,
          dashboardId,
          answersSheetId,
        }),
      )
        .then((result) => {
          setDocumentId(result.documentId);
        })
        .catch((err) => {
          // TODO: Show this err in ui somehow.
          setDocumentError(err);
        });
    },
    [
      ddpCall,
      dashboardId,
      answersSheetId,
    ],
  );

  useEffect(() => {
    if (!documentId && !documentError && !ddpIsPending) {
      handleDocumentsGenerate();
    }
  }, [
    documentId,
    documentError,
    ddpIsPending,
    handleDocumentsGenerate,
  ]);

  const handleDocumentsRegenerate = useCallback(
    () => handleDocumentsGenerate({
      regenerate: true,
    }),
    [
      handleDocumentsGenerate,
    ],
  );

  const url = documentId
    ? `${backendUrl}/api/documents/${documentId}?t=${resumeToken}`
    : null;

  const onBack = () => {
    dispatch(
      openTab({
        recipientId,
        presetNo,
        type: PROJECT_DASHBOARD_TYPE__DOCUMENTS,
        perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
        projectId: answersSheet.getProjectId(),
        settings: {
          patientId: recipientId,
        },
      }),
    );
  };

  return (
    <Stack>
      <Cluster justify="space-between">
        <Cluster>
          <Button
            icon={<ArrowBack />}
            onClick={onBack}
          />
          <Text.Paragraph token="title2">
            {questionnaire && questionnaire.getName()}
            {' '}
            {/* TODO: Translate. */}
            PDF Preview
          </Text.Paragraph>
        </Cluster>
        <Cluster space={2}>
          <div>
            <StyledSelect
              value={documentId}
              onChange={setDocumentId}
            >
              {map(documents, (document) => {
                return (
                  <Select.Option
                    key={document._id}
                    value={document._id}
                  >
                    {toYearMonthDay(document.createdAt)}
                  </Select.Option>
                );
              })}
            </StyledSelect>
          </div>
          {/* TODO: Translate. Better syntax? */}
          <Button
            type="primary"
            onClick={handleDocumentsRegenerate}
          >
            Regenerate
          </Button>
          <Button
            type="primary"
            onClick={() => saveAs(url)}
          >
            Download
          </Button>
        </Cluster>
      </Cluster>
      <PDFPreview url={url} />
    </Stack>
  );
};

PatientDocument.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  answersSheetId: PropTypes.string.isRequired,
  presetNo: PropTypes.number.isRequired,
  openTab: PropTypes.func.isRequired,
};

export default PatientDocument;
