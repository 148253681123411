import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import Pagination from '../common/components/Pagination';
import CountSelect from '../common/selectors/Count';

const getNumberOfPages = (pageSize, totalItems) => {
  if (!pageSize || !totalItems) {
    return 0;
  }
  return Math.ceil(totalItems / pageSize);
};

const getVisiblePage = (currentPage, nPages) => {
  if (!currentPage || !nPages) {
    return 1;
  }
  return Math.max(1, Math.min(currentPage, nPages));
};

const pageSizeOptions = [
  '10',
  '15',
  '20',
];

const constant = x => () => x;
const nothing = constant(null);

const usePagination = ({
  selector,
  mapSelector,
  getSubscription,
  debounceMs = 250,
}) => {
  const [
    subscriptionId,
    setSubscriptionId,
  ] = useState(null);
  const [
    currentPage,
    setCurrentPage,
  ] = useState(1);
  const [
    pageSize,
    setPageSize,
  ] = useState(parseInt(pageSizeOptions[0], 10));
  const subscription = getSubscription(currentPage, pageSize);
  const total = useSelector(
    subscription
      ? CountSelect.one().whereIdEquals(
        `${subscription.name}.${subscriptionId}`,
      )
      : nothing,
  );
  const {
    id,
    ready,
  } = useDDPSubscription(subscription, {
    debounceMs,
  });
  useEffect(() => {
    if (id && ready) {
      setSubscriptionId(id);
    }
  }, [
    id,
    ready,
  ]);
  const paginationKey = `_pagination_${subscriptionId}`;
  const selectItems = useMemo(() => {
    if (!selector) {
      return constant([]);
    }
    const nextSelector = selector
      .all()
      .satisfying(
        item => item[paginationKey] &&
          item[paginationKey].pageIndex === currentPage - 1,
      )
      .sort({
        [`${paginationKey}.index`]: 1,
      });
    if (mapSelector) {
      return mapSelector(nextSelector);
    }
    return nextSelector;
  }, [
    selector,
    mapSelector,
    currentPage,
    paginationKey,
  ]);
  const items = useSelector(selectItems);
  const totalItems = total ? total.count : 0;
  const nPages = getNumberOfPages(pageSize, totalItems);
  const visiblePage = getVisiblePage(currentPage, nPages);
  useEffect(() => {
    if (visiblePage < currentPage && setCurrentPage) {
      setCurrentPage(visiblePage);
    }
  }, [
    visiblePage,
    currentPage,
    setCurrentPage,
  ]);
  const onPageSizeChange = useCallback((_, value) => setPageSize(value), [
    setPageSize,
  ]);
  const getPaginationProps = () => ({
    total: totalItems,
    current: visiblePage,
    onChange: setCurrentPage,
    pageSize,
    pageSizeOptions,
    size: 'small',
    showSizeChanger: true,
    onShowSizeChange: onPageSizeChange,
  });
  const renderPagination = () => {
    const defaultProps = getPaginationProps();
    return (
      <Pagination
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...defaultProps}
      />
    );
  };
  return {
    ready,
    items,
    nPages,
    totalItems,
    paginationKey,
    currentPage,
    pageSize,
    setCurrentPage,
    renderPagination,
    getPaginationProps,
  };
};

export default usePagination;
