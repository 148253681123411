import map from 'lodash/map';
import {
  PROJECT_DASHBOARD_TYPE__ANALYTICS,
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
} from '../common/constants';

const cleanDashboard = (dashboard) => {
  switch (dashboard.type) {
    case PROJECT_DASHBOARD_TYPE__ANALYTICS: {
      if (!dashboard.perspective) {
        return {
          ...dashboard,
          type: PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
        };
      }
      break;
    }
    default: {
      // ...
    }
  }
  return dashboard;
};

const cleanProjectProperties = (properties) => {
  const {
    editorVersion,
    dashboards,
    ...other
  } = properties;
  return {
    ...other,
    dashboards: map(dashboards, cleanDashboard),
  };
};

export default cleanProjectProperties;
