/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const DashboardsForm = ({
  projectId,
  variables,
  questionnaire,
}) => {
  const collectionId = 'k_dashboards';

  const questionsIds = [
    collectionId,
  ];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach(question => questionsIds.push(question.id));

  questionsIds.forEach(questionId => questionnaire
    .getChildQuestions(questionId)
    .forEach(question => questionsIds.push(question.id)));

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={questionsIds}
      nameId="ak2_title"
      hasOrder
    />
  );
};

export default DashboardsForm;
