/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import Select, {
  StyledSelect,
} from '../../../common/components/Select';
import {
  selectOptionsPropTypes,
} from '../../forms/propTypes';

const {
  Option,
  OptGroup,
} = Select;

const StyledStyledSelect = styled(StyledSelect)`
  width: 100%;

  .ant-select-selection__clear {
    opacity: 1;
  }

  ${props => props.styles &&
    props.styles.isValueRemoved &&
    `
    .ant-select-selection-item {
      text-decoration: line-through;
    }
  `}
`;

const renderOption = ({
  value,
  label,
  disabled,
}) => label && (
<Option
  data-testid={`select-option-${label}`}
  key={value}
  value={value}
  disabled={disabled}
>
  {label}
</Option>
);

const SelectComponent = ({
  multiple,
  optionFilterProp,
  shouldWrapText,
  options,
  children,
  input,
  ...rest
}) => (
  <StyledStyledSelect
    mode={multiple ? 'multiple' : 'default'}
    optionFilterProp={optionFilterProp}
    dropdownClassName={shouldWrapText && 'should-wrap-text'}
    {...input}
    {...rest}
  >
    {options
      ? options.map(option => (option.options ? (
        <OptGroup
          key={option.key}
          label={option.label}
        >
          {option.options.map(childOption => renderOption(childOption))}
        </OptGroup>
      ) : (
        renderOption(option)
      )))
      : children}
  </StyledStyledSelect>
);

SelectComponent.propTypes = {
  options: selectOptionsPropTypes,
  children: PropTypes.node,
  multiple: PropTypes.bool,
  optionFilterProp: PropTypes.string,
};

SelectComponent.defaultProps = {
  options: null,
  children: null,
  multiple: false,
  optionFilterProp: 'children',
};

export default SelectComponent;
