import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useStore,
  useDispatch,
} from 'react-redux';
// import filter from 'lodash/filter';

import {
  apiAdminOneRole,
  apiAdminUpdateRole,
} from '../../common/api/admin';
import RoleSelect from '../../common/selectors/Role';
import {
  setSelectedRole,
} from '../SettingsRoles/store';
import RoleForm from './components/RoleForm';

const SettingsRole = ({
  match,
}) => {
  const {
    t,
  } = useTranslation();
  const store = useStore();
  const dispatch = useDispatch();

  const {
    roleId,
  } = match.params;

  useDDPSubscription(
    apiAdminOneRole.withParams({
      roleId,
    }),
    {
      onReady: () => {
        const selectRole = RoleSelect.one().whereIdEquals(roleId);
        dispatch(setSelectedRole(selectRole(store.getState())));
      },
    },
  );

  const buttons = [
    'save',
    'copy',
    'open',
  ];

  return (
    <RoleForm
      buttons={buttons}
      method={apiAdminUpdateRole}
      successMessage={t('confirmations:editRole.success')}
    />
  );
};

SettingsRole.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      roleId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SettingsRole;
