import map from 'lodash/map';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

const transformMetaFields = (request, meta) => ({
  ...request,
  params: map(request.params, fields => (isPlainObject(fields)
    ? mapValues(fields, (value) => {
      if (typeof value === 'string') {
        const match = /^\$meta\.(.*)/.exec(value);
        if (match) {
          return get(meta, match[1]);
        }
      }
      return value;
    })
    : fields)),
});

export default transformMetaFields;
