import BaseModel from './BaseModel';

class SearchTag extends BaseModel {
  isString() {
    return this.type === 'string';
  }

  isInteger() {
    return this.type === 'integer';
  }

  isDate() {
    return this.type === 'date';
  }

  isBoolean() {
    return this.type === 'boolean';
  }

  isCoding() {
    return this.type === 'coding';
  }

  getValue() {
    if (this.isString()) {
      return this.valueString;
    }
    if (this.isInteger()) {
      return this.valueInteger;
    }
    if (this.isBoolean()) {
      return this.valueBoolean;
    }
    if (this.isDate()) {
      return this.valueDate;
    }
    if (this.isCoding()) {
      return this.valueCoding && this.valueCoding.code;
    }
    return null;
  }

  getLabel() {
    if (this.isCoding()) {
      if (this.valueCoding && this.valueCoding.display) {
        return this.valueCoding.display;
      }
    }
    return this.getValue();
  }

  toOption() {
    const value = this.getValue();
    const label = this.getLabel();
    return {
      value,
      label,
    };
  }

  cleanTag() {
    const {
      name,
      type,
      description,
    } = this;
    const clean = {
      name,
      type,
      description,
    };
    if (this.isString()) {
      clean.valueString = this.valueString;
    }
    if (this.isInteger()) {
      clean.valueInteger = this.valueInteger;
    }
    if (this.isBoolean()) {
      clean.valueBoolean = this.valueBoolean;
    }
    if (this.isDate()) {
      clean.valueBoolean = this.valueBoolean;
    }
    if (this.isDate()) {
      clean.valueDate = this.valueDate;
    }
    if (this.isCoding()) {
      clean.valueCoding = this.valueCoding;
    }
    return clean;
  }

  static getValueKey(type) {
    switch (type) {
      case 'string':
        return 'valueString';
      case 'integer':
        return 'valueInteger';
      case 'boolean':
        return 'valueBoolean';
      case 'date':
        return 'valueDate';
      case 'coding':
        return 'valueCoding.code';
      default:
        return undefined;
    }
  }

  static getLabelKey(type) {
    switch (type) {
      case 'string':
        return 'valueString';
      case 'integer':
        return 'valueInteger';
      case 'boolean':
        return 'valueBoolean';
      case 'date':
        return 'valueDate';
      case 'coding':
        return 'valueCoding.display';
      default:
        return undefined;
    }
  }
}

export default SearchTag;
