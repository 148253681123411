import PropTypes from 'prop-types';

// See redux-form documentation here:
// http://redux-form.com/6.5.0/docs/api/Field.md/#usage
export const formFieldPropTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    checked: PropTypes.checked,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    autofilled: PropTypes.bool,
    asyncValidating: PropTypes.bool,
    dirty: PropTypes.bool,
    dispatch: PropTypes.func,
    error: PropTypes.string,
    form: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitFailed: PropTypes.bool,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    visited: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
};
