import React from 'react';
import PropTypes from 'prop-types';
import {
  useSelector,
} from 'react-redux';
import Highlighter from '../../../common/components/Highlighter';
import {
  selectQueryParam,
} from '../../../store/router';

const SearchHighlighter = ({
  text,
}) => {
  const highlight = useSelector(selectQueryParam('search', ''));

  return (
    <Highlighter
      text={text}
      highlight={highlight}
    />
  );
};

SearchHighlighter.propTypes = {
  text: PropTypes.string,
};

SearchHighlighter.defaultProps = {
  text: '',
};

export default SearchHighlighter;
