import {
  saveAs,
} from 'file-saver';
import {
  connect,
} from 'react-redux';
import {
  withHandlers,
  compose,
} from 'recompose';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  apiZedocGetPDFForAnswersSheet,
} from '../../../common/api/zedoc';
import {
  notifyError,
} from '../../../utils/notify';

import PatientDocuments from './PatientDocuments';

const Container = compose(
  connect(),
  withHandlers({
    onDownload: ({
      dispatch,
    }) => answersSheetId => dispatch(
      callMethod(apiZedocGetPDFForAnswersSheet, {
        answersSheetId,
      }),
    )
      .then((url) => {
        const blob = new Blob([
          url,
        ], {
          type: 'application/pdf',
        });
        saveAs(blob, `zedoc-answers-sheet-${answersSheetId}.pdf`);
      })
      .catch(notifyError()),
  }),
)(PatientDocuments);

export default Container;
