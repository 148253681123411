import reduce from 'lodash/reduce';
import BaseModel from './BaseModel';
import {
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
} from '../constants';
import {
  NOTIFICATION_EVENT_TYPE__CREATE,
  NOTIFICATION_EVENT_TYPE__CANCEL,
  NOTIFICATION_EVENT_TYPE__ATTEMPT,
  NOTIFICATION_EVENT_TYPE__SEND,
  NOTIFICATION_EVENT_TYPE__ENQUEUE,
  NOTIFICATION_EVENT_TYPE__DELIVER,
  NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE,
  NOTIFICATION_EVENT_TYPE__RECEIVE,
  NOTIFICATION_EVENT_TYPE__OPEN,
  NOTIFICATION_EVENT_TYPE__CLICK,
  NOTIFICATION_LOG_STATE__INITIAL,
  NOTIFICATION_LOG_STATE__ABORTED,
  NOTIFICATION_LOG_STATE__SENDING,
  NOTIFICATION_LOG_STATE__ENQUEUED,
  NOTIFICATION_LOG_STATE__SENT,
  NOTIFICATION_LOG_STATE__FAILED,
  NOTIFICATION_LOG_STATE__DELIVERED,
  NOTIFICATION_LOG_STATE__RECEIVED,
  NOTIFICATION_LOG_STATE__OPENED,
  NOTIFICATION_LOG_STATE__CLICKED,
} from '../constants/collections/NotificationsLogs';

const reducer = (current = {}, event) => {
  switch (event.type) {
    case NOTIFICATION_EVENT_TYPE__CREATE:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__INITIAL,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__CANCEL: {
      if (current.state === NOTIFICATION_LOG_STATE__SENDING) {
        return {
          ...current,
          state: NOTIFICATION_LOG_STATE__FAILED,
          error: event.payload,
          meta: event.meta,
        };
      }
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__ABORTED,
        error: event.payload,
        meta: event.meta,
      };
    }
    case NOTIFICATION_EVENT_TYPE__ATTEMPT:
      return {
        ...current,
        deliveryType: event.payload.deliveryType,
        deliveryAddress: event.payload.deliveryAddress,
        state: NOTIFICATION_LOG_STATE__SENDING,
        attemptedAt: event.meta && event.meta.timestamp,
        sentAt: null,
        error: null,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__SEND:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__SENT,
        sentAt: event.meta && event.meta.timestamp,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__DELIVER:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__DELIVERED,
        deliveredAt: event.meta && event.meta.timestamp,
        meta: event.meta,
        numberOfMessageParts:
          event.payload && event.payload.numberOfMessageParts,
        priceInUSD: event.payload && event.payload.priceInUSD,
        dwellTimeMsUntilDeviceAck:
          event.payload && event.payload.dwellTimeMsUntilDeviceAck,
      };
    case NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__FAILED,
        error: event.payload || {
          message: "We couldn't deliver the message",
        },
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__RECEIVE:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__RECEIVED,
        receivedAt: event.meta && event.meta.timestamp,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__ENQUEUE:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__ENQUEUED,
        enqueuedAt: event.meta && event.meta.timestamp,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__OPEN:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__OPENED,
        openedAt: event.meta && event.meta.timestamp,
        meta: event.meta,
      };
    case NOTIFICATION_EVENT_TYPE__CLICK:
      return {
        ...current,
        state: NOTIFICATION_LOG_STATE__CLICKED,
        clickedAt: event.meta && event.meta.timestamp,
        meta: event.meta,
      };
    default:
      return current;
  }
};

class NotificationLog extends BaseModel {
  get current() {
    const current = reduce(
      this.events,
      reducer,
      reducer(
        {
          deliveryType: this.deliveryType,
          deliveryAddress: this.deliveryAddress,
        },
        {
          type: '@INIT',
        },
      ),
    );
    Object.defineProperty(this, 'current', {
      value: current,
    });
    return current;
  }

  getAddress() {
    return this.current.deliveryAddress;
  }

  getLastDeliveryAttemptTime() {
    return this.current.attemptedAt;
  }

  getTimestamp() {
    return this.current.meta && this.current.meta.timestamp;
  }

  getType() {
    return this.current.deliveryType;
  }

  getIcon() {
    switch (this.current.deliveryType) {
      case NOTIFICATION_DELIVERY_TYPE_SMS:
        return 'mobile';
      case NOTIFICATION_DELIVERY_TYPE_EMAIL:
        return 'mail';
      default:
        return null;
    }
  }

  getState() {
    return this.current.state;
  }

  getNumberOfMessageParts() {
    return this.current.numberOfMessageParts;
  }

  getPriceInUSD() {
    return this.current.priceInUSD;
  }

  getDwellTimeMsUntilDeviceAck() {
    return this.current.dwellTimeMsUntilDeviceAck;
  }

  isAborted() {
    return this.getState() === NOTIFICATION_LOG_STATE__ABORTED;
  }

  isSent() {
    return this.getState() === NOTIFICATION_LOG_STATE__SENT;
  }

  isDelivered() {
    return this.getState() === NOTIFICATION_LOG_STATE__DELIVERED;
  }

  isReceived() {
    return this.getState() === NOTIFICATION_LOG_STATE__RECEIVED;
  }
}

NotificationLog.collection = 'NotificationsLogs';

export default NotificationLog;
