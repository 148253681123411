import startCase from 'lodash/startCase';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../utilsClient/cssHelpers';

const Component = styled.div`
  background-color: ${props => theme(`color.${props.type}`)};
  color: ${props => theme(`color.on${startCase(props.type)}`)};
  font-size: ${theme('font.size.small')};
  padding-right: ${theme('space.2')};
  padding-left: ${theme('space.2')};
  border-radius: 1em;
  display: inline-block;
`;

const Tag = ({
  type,
  children,
}) => (children ? <Component type={type}>{children}</Component> : null);

Tag.propTypes = {
  type: PropTypes.oneOf([
    'danger',
    'warning',
    'success',
    'info',
    'neutral',
  ]),
  children: PropTypes.node,
};

Tag.defaultProps = {
  type: 'neutral',
  children: null,
};

export default Tag;
