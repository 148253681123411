import React, {
  useMemo,
  useCallback,
} from 'react';
import {
  compose,
} from 'recompose';
import map from 'lodash/map';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  apiZedocSearchQuestionnaireRecordsTags,
  apiZedocSearchQuestionnaireRecordsFilterOptions,
} from '../../common/api/zedoc';
import QuestionnaireTagSelect from '../../common/selectors/QuestionnaireTag';
import QuestionnaireFilterOptionSelect from '../../common/selectors/QuestionnaireFilterOption';
import Filters from '../../components/Filters';
import defaultFilters, {
  tagToFilter,
} from './defaultFilters';
import {
  setFilters,
  getFilters,
} from './store';

const selectTags = QuestionnaireTagSelect.all().sort({
  count: -1,
});

const ConnectedFilters = compose()(() => {
  const filters = useSelector(getFilters);
  const tags = useSelector(selectTags);
  const {
    ready,
  } = useDDPSubscription(
    apiZedocSearchQuestionnaireRecordsTags.withParams({}),
  );
  const presets = useMemo(() => {
    return [
      ...defaultFilters,
      ...map(tags, tagToFilter),
    ];
  }, [
    tags,
  ]);
  const dispatch = useDispatch();
  const handleOnChange = useCallback(
    (newFilters) => {
      dispatch(setFilters(newFilters));
    },
    [
      dispatch,
    ],
  );
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      currentFilters,
      type,
      condition,
      state,
      settings,
    }) => {
      return apiZedocSearchQuestionnaireRecordsFilterOptions.withParams({
        controlId: '$meta.id',
        searchText,
        filters: currentFilters,
        filter: {
          type,
          condition,
          state,
          settings,
        },
      });
    },
    [],
  );
  if (!ready) {
    return <div>loading ...</div>;
  }
  return (
    <Filters
      presets={presets}
      value={filters}
      onChange={handleOnChange}
      optionsSelector={QuestionnaireFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
});

export default ConnectedFilters;
