import {
  PARTICIPANT_STATE_TO_PROP_NAME,
  PARTICIPANT_STATES,
} from '../constants';
import BaseModel from './BaseModel';

// NOTE: All these values reflects the possible states of a Participant object.
const allNames = [];
for (const state of PARTICIPANT_STATES) {
  const property = PARTICIPANT_STATE_TO_PROP_NAME[state];
  allNames.push(property);
}

class ProjectTotals extends BaseModel {
  constructor(doc) {
    super(doc);
    for (const state of PARTICIPANT_STATES) {
      const name = this.constructor.stateToProperty(state);
      if (name) {
        this[name] = this[name] || 0;
      }
    }
  }

  getName() {
    return this.name;
  }

  getCompletedRate() {
    const totalBatchSize = this.getTotalBatchSize();
    if (!totalBatchSize) return 0;
    // console.log(`100 * ${this.totalCompleted} / ${totalBatchSize}`);
    return Math.floor((100 * this.totalCompleted) / totalBatchSize);
  }

  getTotalAssigned() {
    return this.totalAssigned;
  }

  getTotalNotYetAssigned() {
    return this.totalDraft + this.totalWaiting;
  }

  getTotalWaiting() {
    return this.totalWaiting;
  }

  getRejectedRate() {
    const totalBatchSize = this.getTotalBatchSize();
    if (!totalBatchSize) return 0;
    return Math.floor((100 * this.getTotalRejected()) / totalBatchSize);
  }

  getTotalInProgress() {
    return this.totalInProgress;
  }

  getTotalOngoing() {
    return this.totalInProgress + this.totalPaused + this.totalToReassign;
  }

  getTotalNotResolved() {
    return (
      this.totalAssigned +
      this.totalInProgress +
      this.totalNoAnswer +
      this.totalPaused +
      this.totalToReassign
    );
  }

  getTotalNotContacted() {
    return this.totalAssigned + this.totalNoAnswer;
  }

  getTotalCompleted() {
    return this.totalCompleted;
  }

  getTotalDeclined() {
    return this.totalDeclined;
  }

  getTotalNoAnswer() {
    return this.totalNoAnswer;
  }

  getTotalDeceased() {
    return this.totalDeceased;
  }

  getTotalBlacklisted() {
    return this.totalBlacklisted;
  }

  getTotalRejected() {
    return (
      this.totalDeclined +
      this.totalBlacklisted +
      this.totalWrongNumber +
      this.totalDeceased
    );
  }

  getTotalResolved() {
    return this.getTotalCompleted() + this.getTotalRejected();
  }

  getTotalBatchSize() {
    return this.getTotalResolved() + this.getTotalNotResolved();
  }

  getTotal() {
    let total = 0;
    for (const name of allNames) {
      total += this[name];
    }
    return total;
  }

  addToState(state, total) {
    const name = this.constructor.stateToProperty(state);
    if (name) {
      this[name] += total;
    }
  }

  add(projectTotals) {
    for (const name of allNames) {
      this[name] += projectTotals[name];
    }
  }

  static getFields() {
    const fields = {};
    for (const name of allNames) {
      fields[name] = 1;
    }
    return fields;
  }

  static stateToProperty(state) {
    return PARTICIPANT_STATE_TO_PROP_NAME[state];
  }

  static getSchemaDef() {
    const schemaDef = {};
    for (const name of allNames) {
      schemaDef[name] = {
        type: Number,
        optional: true,
      };
    }
    return schemaDef;
  }
}

export default ProjectTotals;
