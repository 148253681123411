import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  push,
} from 'connected-react-router';
import ProjectProfileDialog from '../../../components/dialogs/ProjectProfile';
import {
  getProjectProfileDialog,
} from '../selectors';
import {
  closeProjectProfileDialog,
} from '../actions';

const ConnectedProjectProfileDialog = ({
  projectId: defaultProjectId,
  onSubmitted,
}) => {
  const options = useSelector(getProjectProfileDialog);
  const {
    projectId,
    recipientId,
    participationId,
  } = options || {};
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeProjectProfileDialog());
    if (!projectId && recipientId) {
      dispatch(push(`/patients/${recipientId}`));
    }
  }, [
    dispatch,
    projectId,
    recipientId,
  ]);
  if (!options) {
    return (
      <ProjectProfileDialog
        key="no-options"
        visible={false}
        projectId={defaultProjectId}
      />
    );
  }
  return (
    <ProjectProfileDialog
      key="has-options"
      visible
      allowOtherProjects={!projectId}
      projectId={projectId || defaultProjectId}
      recipientId={recipientId}
      participationId={participationId}
      onCancel={handleOnCancel}
      onSubmitted={onSubmitted}
    />
  );
};

ConnectedProjectProfileDialog.propTypes = {
  projectId: PropTypes.string,
  onSubmitted: PropTypes.func,
};

ConnectedProjectProfileDialog.defaultProps = {
  projectId: null,
  onSubmitted: null,
};

export default ConnectedProjectProfileDialog;
