import map from 'lodash/map';
import range from 'lodash/range';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';
import Skeleton from '../Skeleton';
import Divider from '../Divider';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const Component = styled.ul`
  list-style: none;

  > * + * {
    margin-top: ${theme('space.3')};
  }
`;

// TODO: Improve loading state with better Skeleton placement

const List = ({
  'data-testid': testId,
  title,
  dataSource,
  pageSize,
  loading,
  bordered,
  renderItem,
  renderPagination,
}) => {
  return (
    <Stack>
      {(title || renderPagination) && (
        <>
          <Cluster justify="space-between">
            {title && (
              <Text.Paragraph importance="high">{title}</Text.Paragraph>
            )}
            {renderPagination && renderPagination()}
          </Cluster>
          <Divider type="dashed" />
        </>
      )}
      <Component data-testid={testId}>
        {loading
          ? range(pageSize).map(index => (
            <Stack
              key={index}
              as="li"
            >
              {index !== 0 && bordered && <Divider type="dashed" />}
              <Stack space={0}>
                <Skeleton width="50%" />
                <Skeleton />
              </Stack>
            </Stack>
          ))
          : map(dataSource, (item, index) => (
            <Stack
              key={index}
              as="li"
            >
              {index !== 0 && bordered && <Divider type="dashed" />}
              {renderItem && renderItem(item, index)}
            </Stack>
          ))}
      </Component>
    </Stack>
  );
};

List.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  bordered: PropTypes.bool,
  renderItem: PropTypes.func,
  renderPagination: PropTypes.func,
};

List.defaultProps = {
  'data-testid': 'list',
  title: null,
  dataSource: [],
  pageSize: 2,
  loading: false,
  bordered: true,
  renderItem: null,
  renderPagination: null,
};

export default List;
