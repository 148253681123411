import {
  MinusSquareOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import {
  useTranslation,
} from 'react-i18next';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Collapse from '../../../common/components/Collapse';
import Divider from '../../../common/components/Divider';
import Box from '../../../common/components/primitives/Box';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Stack from '../../../common/components/primitives/Stack';
import {
  Element,
  Field,
} from '../../../common/containers/Questionnaire';
import {
  theme,
} from '../../../common/utilsClient/cssHelpers';
import FormFieldError from '../FormFieldError';
import {
  slugify,
} from '../../../common/utils/formatting';

const StyledCollectionWrapper = styled.div`
  > div[class^='Stack'] {
    border: 1px solid #e8e8e8;
    border-radius: ${theme('border.radius.2')};
    padding: ${theme('space.3')};
  }
`;

const FormCollection = ({
  title,
  description,
  elements,
  onAppend,
  children,
  disabled,
}) => {
  const {
    t,
  } = useTranslation();
  return (
    <Collapse
      data-testid={`details-${title}`}
      items={[
        {
          key: '1',
          title,
          content: (
            <Stack>
              {description && (
                <Text.Paragraph importance="low">{description}</Text.Paragraph>
              )}
              <Box.Primary>
                <Stack
                  data-testid={`list-${slugify(title)}`}
                  space={4}
                >
                  {map(elements, (elementId, i) => (
                    <Element
                      key={elementId}
                      id={elementId}
                    >
                      {({
                        handleRemove,
                        handleMoveUp,
                        handleMoveDown,
                      }) => (
                        <StyledCollectionWrapper>
                          <Stack>
                            <Sidebar
                              sidebar={(
                                <Button.Group>
                                  {i !== 0 && (
                                  <Button
                                    icon={<CaretUpOutlined />}
                                    size="small"
                                    onClick={handleMoveUp}
                                    ghost
                                    disabled={disabled}
                                  />
                                  )}
                                  {i !== elements.length - 1 && (
                                  <Button
                                    icon={<CaretDownOutlined />}
                                    size="small"
                                    onClick={handleMoveDown}
                                    ghost
                                    disabled={disabled}
                                  />
                                  )}
                                  <Button
                                    type="danger"
                                    data-testid={`button-remove-${slugify(
                                      title,
                                    )}`}
                                    size="small"
                                    icon={<MinusSquareOutlined />}
                                    onClick={handleRemove}
                                    ghost
                                    disabled={disabled}
                                  />
                                </Button.Group>
                              )}
                            >
                              <Text.Paragraph importance="high">
                                {pluralize(title, 1)}
                                {' '}
                                {i + 1}
                              </Text.Paragraph>
                            </Sidebar>
                            <Divider />
                            {children({
                              disabled,
                              elementId,
                            })}
                          </Stack>
                        </StyledCollectionWrapper>
                      )}
                    </Element>
                  ))}
                  {isEmpty(elements) && 'None'}
                </Stack>
              </Box.Primary>
              <Sidebar
                sidebar={(
                  <Button
                    data-testid={`button-add-${slugify(title)}`}
                    type="primary"
                    onClick={onAppend}
                    disabled={disabled}
                  >
                    {t('add')}
                    {' '}
                    {pluralize(title.toLowerCase(), 1)}
                  </Button>
                )}
              >
                <Field
                  component={FormFieldError}
                  name="value"
                />
              </Sidebar>
            </Stack>
          ),
        },
      ]}
    />
  );
};

FormCollection.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onAppend: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FormCollection.defaultProps = {
  description: null,
  elements: [],
  disabled: false,
};

export default FormCollection;
