import React from 'react';

export default () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
  >
    <title>Projects</title>
    <path
      d="M18.195 1.875h-3.527A2.81 2.81 0 0012.023 0a2.81 2.81 0 00-2.645 1.875H5.805A2.812 2.812 0 003 4.688v16.5A2.812 2.812 0 005.805 24h12.393A2.813 2.813 0 0021 21.187v-16.5a2.812 2.812 0 00-2.805-2.812zM11.088 3.75v-.938c0-.516.42-.937.935-.937.516 0 .935.42.935.938v.937h1.87v1.828h-5.61V3.75h1.87zm8.042 17.438c0 .516-.42.936-.933.937H5.805a.937.937 0 01-.935-.938v-16.5c0-.516.42-.937.935-.937h1.543v3.703h9.35V3.75h1.497c.515 0 .935.42.935.938v16.5zm-3.544-10.405l1.383 1.262-5.988 6.598-3.956-3.836 1.3-1.348 2.57 2.492 4.691-5.168z"
      fillRule="nonzero"
    />
  </svg>
);
