import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

export const getValues = (filters) => {
  if (isEmpty(filters)) {
    return [];
  }

  return reduce(
    filters,
    (res, filter) => {
      if (filter && filter.include) {
        res.push(...filter.include);
      }

      return res;
    },
    [],
  );
};
