import PropTypes from 'prop-types';
import {
  createSelector,
} from 'reselect';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import NLPKeywordSelect from '../../../../../../common/selectors/NLPKeyword';
import {
  apiNlpTopKeywords,
} from '../../../../../../common/api/nlp';
import KeywordsCloud from './KeywordsCloud';
import {
  property,
} from '../../../../../../common/utilsClient/selectors';

const Container = compose(
  ddp({
    subscriptions: createSelector(
      property('projectId'),
      property('questionnaireId'),
      property('questionId'),
      (projectId, questionnaireId, questionId) => ({
        items: apiNlpTopKeywords.withParams({
          projectId,
          questionnaireId,
          questionId,
          nResults: 100,
        }),
      }),
    ),
    selectors: () => ({
      items: NLPKeywordSelect.all()
        .where({
          projectId: property('projectId'),
          questionnaireId: property('questionnaireId'),
          questionId: property('questionId'),
        })
        .sort({
          prevalence: -1,
        })
        .limit(100),
    }),
  }),
  withHandlers({
    handleKeywordClick: () => value => console.log('CLICKED', value),
  }),
)(KeywordsCloud);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
};

export default Container;
