import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  createStructuredSelector,
} from 'reselect';
import {
  useTranslation,
} from 'react-i18next';
import Questionnaire from '../../../common/containers/Questionnaire';
import Button from '../../../common/components/Button';

const SubmitButton = compose(
  Questionnaire.connect(select => createStructuredSelector({
    context: select.context(),
  })),
  withHandlers({
    handleSubmit: ({
      onSubmit,
      onSubmissionError,
      dispatch,
      context,
    }) => () => Promise.resolve()
      .then(() => dispatch(context.validate()))
      .then(() => dispatch(context.forceAutosave()))
      .then(onSubmit)
      .catch(onSubmissionError),
  }),
)(({
  handleSubmit,
}) => {
  const {
    t,
  } = useTranslation();

  return (
    <Button
      data-testid="button-submit"
      type="primary"
      onClick={handleSubmit}
      size="large"
      icon={<CheckOutlined />}
    >
      {t('complete')}
    </Button>
  );
});

SubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmissionError: PropTypes.func.isRequired,
};

SubmitButton.defaultProps = {};

export default SubmitButton;
