import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useSelector,
} from 'react-redux';
import ProjectDashboardSelect from '../../../../common/selectors/ProjectDashboard';
import ChartModel from '../../../../common/models/Chart';
import {
  apiAnalyticsDataProvidersForDashboard,
} from '../../../../common/api/analytics';
import Stack from '../../../../common/components/primitives/Stack';
import ChartGrid from '../../../../common/components/Chart/Grid';
import ConnectedFilters from '../DashboardAnalytics/ConnectedFilters';
import {
  FILTER_TYPE__PROPERTY,
} from '../../../../common/constants';
import Chart from './Chart';
import {
  createGetFilters,
} from './store';

// NOTE: Even though this uses the same data source as DashboardAnalytics,
//       we still need to have separate component for ChartBuilder charts
//       because chart.settings and overlay graphs are handled differently
//       in each case. In the future these "legacy" charts will be
//       transformed into analytics charts at Project Wizard level,
//       in which case we will no longer need to maintain a separate
//       dashboard component.
const DashboardChartBuilder = ({
  dashboardId,
  recipientId,
  projectId,
}) => {
  const rawFilters = useSelector(
    createGetFilters(`dashboards.${dashboardId}.filters`),
  );
  const filters = useMemo(
    () => map(rawFilters, filter => omit(filter, 'meta')),
    [
      rawFilters,
    ],
  );
  const {
    id: subscriptionId,
    ready: subscriptionReady,
  } = useDDPSubscription(
    apiAnalyticsDataProvidersForDashboard.withParams({
      projectId,
      dashboardId,
      controlId: '$meta.id',
      filters,
    }),
  );
  const {
    id: recipientSubscriptionId,
    ready: recipientSubscriptionReady,
  } = useDDPSubscription(
    recipientId &&
      apiAnalyticsDataProvidersForDashboard.withParams({
        projectId,
        dashboardId,
        controlId: '$meta.id',
        filters: [
          {
            type: FILTER_TYPE__PROPERTY,
            settings: {
              id: 'recipientId',
            },
            state: {
              include: [
                recipientId,
              ],
            },
          },
        ],
      }),
  );
  const dashboard = useSelector(
    ProjectDashboardSelect.one().whereIdEquals(dashboardId),
  );
  return (
    <Stack>
      <ConnectedFilters
        storeKey={`dashboards.${dashboardId}`}
        projectId={projectId}
        dashboardId={dashboardId}
      />
      <ChartGrid>
        {map(dashboard && dashboard.cards, (card) => {
          const chart = ChartModel.fromCard(card);
          const chartId = card.id;
          return (
            <Chart
              loading={!(subscriptionReady && recipientSubscriptionReady)}
              key={chartId}
              chart={chart}
              chartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${subscriptionId}.${chartId}`}
              recipientChartDataProviderId={`${apiAnalyticsDataProvidersForDashboard.getName()}.${recipientSubscriptionId}.${chartId}`}
            />
          );
        })}
      </ChartGrid>
    </Stack>
  );
};

DashboardChartBuilder.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
};

DashboardChartBuilder.defaultProps = {
  recipientId: null,
};

export default DashboardChartBuilder;
