import PropTypes from 'prop-types';
import React, {
  useMemo,
  useCallback,
} from 'react';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import {
  useSelector,
} from 'react-redux';
import ProjectSelect from '../../../../common/selectors/Project';
import ParticipationAndQuestionnaireFilterOption from '../../../../common/models/ParticipationAndQuestionnaireFilterOption';
import {
  apiZedocSearchParticipationAndQuestionnaireFilterOptions,
} from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters, {
  columnsToPresets,
} from './defaultFilters';
import {
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../../../../common/constants';
import FiltersToolbar from '../FiltersToolbar';

const ParticipationAndQuestionnaireFilterOptionSelect = createSelectors(
  ParticipationAndQuestionnaireFilterOption,
  {},
);

const ConnectedFilters = ({
  storeKey,
  // eslint-disable-next-line react/prop-types
  columns,
  projectId,
  questionnaireId,
}) => {
  const timezone = useSelector(
    ProjectSelect.one().whereIdEquals(projectId).toTimezone(),
  );
  const presets = useMemo(() => {
    const allPresets = map(defaultFilters, (preset) => {
      switch (preset.condition) {
        case FILTER_CONDITION__DATE_EQUALS:
        case FILTER_CONDITION__DATE_AFTER:
        case FILTER_CONDITION__DATE_BEFORE:
        case FILTER_CONDITION__DATE_SAME_OR_AFTER:
        case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
          return {
            ...preset,
            state: {
              ...preset.state,
              timezone,
            },
          };
        default:
          return preset;
      }
    });
    forEach(columns, (column) => {
      forEach(columnsToPresets(column), (preset) => {
        allPresets.push(preset);
      });
    });
    return allPresets;
  }, [
    columns,
    timezone,
  ]);
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      currentFilters,
      type,
      condition,
      state,
      settings,
    }) => {
      return apiZedocSearchParticipationAndQuestionnaireFilterOptions.withParams(
        {
          projectId,
          questionnaireId,
          controlId: '$meta.id',
          searchText,
          filters: currentFilters,
          filter: {
            type,
            condition,
            state,
            settings,
          },
        },
      );
    },
    [
      projectId,
      questionnaireId,
    ],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      presets={presets}
      optionsSelector={ParticipationAndQuestionnaireFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  questionnaireId: PropTypes.string.isRequired,
};

export default ConnectedFilters;
