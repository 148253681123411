import {
  SaveOutlined,
  CopyOutlined,
  FolderOpenOutlined,
} from '@ant-design/icons';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components/macro';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import {
  saveAs,
} from 'file-saver';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import PermissionsDomainSelect from '../../../common/selectors/PermissionsDomain';
import {
  all as allPermissionsDomains,
} from '../../../common/api/collections/PermissionsDomains';
import {
  ADMIN_CREATE_ROLE,
} from '../../../common/permissions';
import {
  PERMISSIONS_GROUPS,
} from '../../../common/permissions/new';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import Button from '../../../common/components/Button';
import Divider from '../../../common/components/Divider';
import FormItem from '../../../common/components/FormItem';
import Input from '../../../common/components/Input';
// import InputSearch from '../../common/components/InputSearch';
import Center from '../../../common/components/primitives/Center';
import Stack from '../../../common/components/primitives/Stack';
import PageBar from '../../../components/Layout/PageBar';
import Select from '../../../components/inputs/Select';
import Collapse from '../../../common/components/Collapse';
import {
  setSelectedRole,
  getSelectedRole,
} from '../../SettingsRoles/store';
import PermissionsGroup from './PermissionsGroup';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const SettingsRoleForm = ({
  buttons,
  successMessage,
  method,
}) => {
  const dispatch = useDispatch();
  const {
    t,
  } = useTranslation();

  const [
    role,
    setRole,
  ] = useState({});

  const [
    roleName,
    setRoleName,
  ] = useState('');

  const selectedRole = useSelector(getSelectedRole);
  useEffect(() => {
    if (selectedRole) {
      setRole(selectedRole);
      setRoleName(selectedRole.name);
    }
  }, [
    selectedRole,
  ]);

  const groups = useSelector(
    PermissionsDomainSelect.all().whereUserHasPermission(
      CurrentUserSelect.user(),
      ADMIN_CREATE_ROLE,
    ),
  );
  const groupOptions = useMemo(
    () => map(groups, group => ({
      label: group.name,
      value: group._id,
    })),
    [
      groups,
    ],
  );

  useDDPSubscription(allPermissionsDomains.withParams());

  const onGroupsChange = useCallback(
    (params) => {
      const roleCopy = cloneDeep(role);
      roleCopy.belongsTo = params;
      dispatch(setSelectedRole(roleCopy));
    },
    [
      role,
      dispatch,
    ],
  );

  const onRoleNameBlur = useCallback(
    (e) => {
      const selectedRoleCopy = cloneDeep(selectedRole);
      selectedRoleCopy.name = e.target.value;
      dispatch(setSelectedRole(selectedRoleCopy));
    },
    [
      dispatch,
      selectedRole,
    ],
  );

  const onCopy = useCallback(() => {
    const selectedRoleCopy = {
      belongsTo: selectedRole.belongsTo,
      name: selectedRole.name,
      tier: selectedRole.tier,
      permissions: selectedRole.permissions,
    };
    const blob = new Blob([
      JSON.stringify(selectedRoleCopy, null, 2),
    ], {
      type: 'application/json',
    });
    saveAs(blob, `role-${selectedRoleCopy.name}.json`);
  }, [
    selectedRole,
  ]);

  const onOpen = useCallback(
    (rawFileContent) => {
      Promise.resolve()
        .then(() => {
          let properties;
          try {
            properties = JSON.parse(rawFileContent);
          } catch (err) {
            throw new Error('We are sorry, this is not a valid JSON file');
          }

          // prevent overriding name and belongsTo
          properties.name = role.name;
          properties.belongsTo = role.belongsTo;
          properties._id = role._id;

          return dispatch(setSelectedRole(properties));
        })
        .catch(notifyError());
    },
    [
      dispatch,
      role,
    ],
  );

  const inputRef = useRef();
  const handleFileInputOnChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      const reader = new FileReader();
      reader.onload = (e) => {
        onOpen(e.target.result);
      };
      reader.readAsText(file);
    },
    [
      onOpen,
    ],
  );

  const onSaveCurrentRole = useCallback(() => {
    dispatch(
      callMethod(method, {
        roleId: selectedRole._id,
        name: selectedRole.name,
        belongsTo: selectedRole.belongsTo,
        permissions: selectedRole.permissions,
      }),
    )
      .then(notifySuccess(successMessage))
      .catch(notifyError());
  }, [
    selectedRole,
    dispatch,
    method,
    successMessage,
  ]);

  const showOpenButton = buttons.indexOf('open') > -1;
  const showCopyButton = buttons.indexOf('copy') > -1;

  const handleRoleChange = useCallback(
    (newRole) => {
      dispatch(setSelectedRole(newRole));
    },
    [
      dispatch,
    ],
  );

  return (
    <Stack>
      <HiddenInput
        ref={inputRef}
        type="file"
        accept="application/json"
        onChange={handleFileInputOnChange}
      />
      <PageBar
        title="Roles"
        subTitle={role && role.name}
        backUrl="/settings/roles"
      />
      <Center>
        <Stack space={4}>
          <Divider>
            <Button.Group>
              <Button
                onClick={onSaveCurrentRole}
                type="primary"
                icon={<SaveOutlined />}
              >
                {t('save')}
              </Button>
              {showCopyButton && (
                <Button
                  icon={<CopyOutlined />}
                  onClick={onCopy}
                >
                  Copy
                </Button>
              )}
              {showOpenButton && (
                <Button
                  icon={<FolderOpenOutlined />}
                  onClick={() => {
                    if (inputRef.current) {
                      inputRef.current.click();
                    }
                  }}
                >
                  Open
                </Button>
              )}
            </Button.Group>
          </Divider>
          <Stack space={5}>
            <Stack>
              <FormItem label={t('name')}>
                <Input
                  value={roleName}
                  onChange={e => setRoleName(e.target.value)}
                  onBlur={onRoleNameBlur}
                />
              </FormItem>
              <FormItem label={t('domain')}>
                <StyledSelect
                  value={role && role.belongsTo}
                  onChange={onGroupsChange}
                  options={groupOptions}
                />
              </FormItem>
            </Stack>
            <Collapse
              items={map(PERMISSIONS_GROUPS, (group, index) => ({
                key: `${index}`,
                title: t(group.title),
                content: (
                  <PermissionsGroup
                    role={role}
                    options={group.permissions}
                    onChange={handleRoleChange}
                  />
                ),
              }))}
            />
          </Stack>
          <Divider>
            <Button
              onClick={onSaveCurrentRole}
              type="primary"
              icon={<SaveOutlined />}
            >
              {t('save')}
            </Button>
          </Divider>
        </Stack>
      </Center>
    </Stack>
  );
};

SettingsRoleForm.propTypes = {
  successMessage: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  method: PropTypes.object.isRequired,
};

export default SettingsRoleForm;
