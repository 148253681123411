import moment from 'moment';
import BaseModel from './BaseModel';

class UsersGroup extends BaseModel {
  getName() {
    return this.name;
  }

  getDomain() {
    return this.belongsTo;
  }

  formatCreatedAt() {
    // eslint-disable-next-line
    return !this.createdAt
      ? ''
      : moment(this.createdAt).format('MMM Do YYYY hh:mm A');
  }

  formatUpdatedAt() {
    // eslint-disable-next-line
    return !this.updatedAt
      ? ''
      : moment(this.updatedAt).format('MMM Do YYYY hh:mm A');
  }

  getReference() {
    const {
      _id: id,
      name,
      type,
    } = this;
    return {
      id,
      name,
      type,
      appliesTo: this.getDomain(),
    };
  }
}

UsersGroup.collection = 'UsersGroups';
export default UsersGroup;
