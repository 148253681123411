import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import find from 'lodash/find';
import NotificationLog from '../../../../common/models/NotificationLog';
import {
  NotificationStatus,
} from '../../../../components/NotificationLog';
import PatientRecord from '../../../../common/models/PatientRecord';

const ActivityStatus = ({
  activityId,
  patientRecord,
  onClick,
}) => {
  const activity = find(patientRecord.activities, {
    activityId,
  });

  let notification;
  if (activity && activity.notification) {
    notification = new NotificationLog(activity.notification);
  }

  return notification ? (
    <NotificationStatus
      deliveryType={notification.getType()}
      state={notification.getState()}
      sentAt={
        notification.getTimestamp()
          ? moment(notification.getTimestamp()).format('DD/MM/YYYY HH:mm')
          : ''
      }
      onClick={onClick}
    />
  ) : null;
};

ActivityStatus.propTypes = {
  activityId: PropTypes.string.isRequired,
  patientRecord: PropTypes.instanceOf(PatientRecord).isRequired,
  onClick: PropTypes.func,
};

ActivityStatus.defaultProps = {
  onClick: null,
};

export default ActivityStatus;
