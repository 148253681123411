import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  activeStylesOutset,
} from '../styles/form-controls';

const Component = styled.audio`
  border-radius: ${theme('border.radius.2')};
  outline: none;

  &:focus {
    ${activeStylesOutset};
  }
`;

const AudioPlayer = ({
  src,
  controls,
  loop,
  muted,
}) => {
  return (
    <Component
      src={src}
      controls={controls}
      loop={loop}
      muted={muted}
      controlsList="nodownload"
    />
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  controls: true,
  loop: false,
  muted: false,
};

export default AudioPlayer;
