import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  PROJECT_REVIEW_PROJECT,
} from '../../permissions';

// eslint-disable-next-line import/prefer-default-export
export const forProject = new ApiSpec({
  name: 'api.collections.ProjectDashboards.forProject',
  permissions: [
    PROJECT_REVIEW_PROJECT,
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});
