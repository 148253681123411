import {
  BEHAVIOR_ACTION_TYPE__UNKNOWN,
} from '../../../constants';
import QuestionBehavior from '../QuestionBehavior';

export default class Unknown extends QuestionBehavior.Action {
  doSelf() {
    this.constructor.logger.warn('Unknown action', this.type);
    return [];
  }

  undoSelf() {
    this.constructor.logger.warn('Unknown action', this.type);
    return [];
  }
}

QuestionBehavior.actions[BEHAVIOR_ACTION_TYPE__UNKNOWN] = Unknown;
