import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import every from 'lodash/every';
import {
  YEAR_MONTH_DAY,
  ACTIVITY_STATE__ACTIVE,
  NOTIFICATION_AUTOMATIC_REMINDER,
  NOTIFICATION_MANUAL_REMINDER,
  NOTIFICATION_ACTIVITY_COMPLETION,
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
  NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY,
  NOTIFICATION_DELIVERY_METHOD__SMS_ONLY,
  NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL,
  NOTIFICATION_DELIVERY_METHOD__PREFER_SMS,
  NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION,
  ACTIVITY_ACTION__COMPLETE,
  ACTIVITY_ACTION__COMPLETE_STEP,
  ACTIVITY_ACTION__START,
  ACTIVITY_STATES_AFTER_START,
  NOTIFICATION_CUSTOM_EVENT,
  PARTICIPANT_STATE__COMPLETED,
  PARTICIPANT_STATE__CANCELED,
} from '../constants';
import isSubset from '../utils/isSubset';
import BaseModel from './BaseModel';
import Project from './Project';
import {
  createUtcToLocalTime,
} from '../utils/zone';

const completionActionTypes = [
  ACTIVITY_ACTION__COMPLETE,
  ACTIVITY_ACTION__COMPLETE_STEP,
];

const findCompletionIndex = (actions, assigneeTypes, startAfter = -1) => {
  return findIndex(actions, (action, i) => {
    if (i <= startAfter) {
      return false;
    }
    if (!includes(completionActionTypes, action.type)) {
      return false;
    }
    return isSubset(
      assigneeTypes,
      action.payload &&
        action.payload.updated &&
        action.payload.updated.completedSteps,
    );
  });
};

class Notification extends BaseModel {
  appliesToAnswersSheets(answersSheets) {
    if (
      this.type === NOTIFICATION_AUTOMATIC_REMINDER ||
      this.type === NOTIFICATION_MANUAL_REMINDER
    ) {
      // NOTE: Reminders are only sent if there's at least one
      //       non-completed answers sheet of a given type.
      if (every(this.assigneeTypes, (assigneeType) => {
        return every(answersSheets, (answersSheet) => {
          return answersSheet.assigneeType !== assigneeType ||
            answersSheet.state === PARTICIPANT_STATE__COMPLETED ||
            answersSheet.state === PARTICIPANT_STATE__CANCELED;
        });
      })) {
        return false;
      }
    }
    return true;
  }

  appliesToActivity(activity) {
    if (
      this.type !== NOTIFICATION_MANUAL_REMINDER &&
      this.createdAt &&
      includes(ACTIVITY_STATES_AFTER_START, activity.state)
    ) {
      // NOTE: If activity already started, only notifications created prior
      //       to start, apply to this activity. This is a safety belt to
      //       prevent sending a lot of notifications to ACTIVE activities
      //       when project configuration changes. We may rethink this approach
      //       at a later stage, when project versioning is finally introduced.
      const start = find(activity.actions, {
        type: ACTIVITY_ACTION__START,
      });
      if (
        !start ||
        !start.meta ||
        !start.meta.timestamp ||
        this.createdAt > start.meta.timestamp
      ) {
        return false;
      }
    }
    if (
      this.selectedMilestonesOnly &&
      !includes(this.selectedMilestones, activity.milestoneId)
    ) {
      return false;
    }
    if (
      this.selectedTracksOnly &&
      !includes(this.selectedTracks, activity.trackId)
    ) {
      return false;
    }
    if (this.type === NOTIFICATION_ACTIVITY_COMPLETION) {
      const i = findCompletionIndex(activity.actions, this.assigneeTypes);
      if (i < 0) {
        return false;
      }
      // NOTE: The intention here is to determine whether there were any additional steps completed
      //       after the first matching complete action. If so, then it is already too late to send.
      const j = findCompletionIndex(activity.actions, [], i);
      return j < 0;
    }
    if (
      this.type === NOTIFICATION_AUTOMATIC_REMINDER ||
      this.type === NOTIFICATION_MANUAL_REMINDER
    ) {
      if (activity.state !== ACTIVITY_STATE__ACTIVE) {
        return false;
      }
      // NOTE: It is discussable what should happen if assigneeTypes = [].
      //       Logically, such an activity is always considered completed
      //       so notification will never be sent. On the other hand, this
      //       behavior is pretty useless and it might be worth interpreting
      //       it as "any of". However, if this is ever needed
      //       we can also introduce assigneeTypeOperator which would
      //       explicitly dictate what needs to be done.
      // if (isEmpty(this.assigneeTypes)) {
      //   return isEmpty(activity.completedSteps);
      // }
      return !isSubset(this.assigneeTypes, activity.completedSteps);
    }
    return true;
  }

  getReferenceTimestamp(activity, timezone = Project.getDefaultTimezone()) {
    switch (this.type) {
      case NOTIFICATION_CUSTOM_EVENT: {
        return new Date();
      }
      case NOTIFICATION_ACTIVITY_COMPLETION: {
        const i = findCompletionIndex(activity.actions, this.assigneeTypes);
        const complete = i >= 0 ? activity.actions[i] : null;
        return complete && complete.meta && complete.meta.timestamp;
      }
      default: {
        let {
          dateStart,
        } = activity;
        if (!dateStart) {
          dateStart = Project.getMomentInTimezone(
            timezone,
            activity.createdAt,
          ).format(YEAR_MONTH_DAY);
        }
        // const {
        //   timeStart = '00:00',
        // } = activity;
        // TODO: Use additional setting to allow using activity.timeStart
        //       as the reference point. For now, we continue with 00:00
        //       to mimic the previous behavior.
        const timeStart = '00:00';
        const timestamp = new Date(`${dateStart}T${timeStart}Z`);
        const utcToLocalTime = createUtcToLocalTime(timezone, {
          // NOTE: What "strict" mean in this case is that we are trying to find an exact
          //       timestamp representation for the clock time, e.g. "YYYY-MM-DDT00:000",
          //       but this may not exist sometimes, e.g. in case of DST switch,
          //       for example: Pacific/Auckland timezone, 2020-09-28T02:30.
          noStrict: true,
        });
        return utcToLocalTime(timestamp);
      }
    }
  }

  getTriggerAt(activity, timezone = Project.getDefaultTimezone()) {
    const referenceTs = this.getReferenceTimestamp(activity, timezone);
    let triggerAt;
    if (referenceTs) {
      let ts = referenceTs.getTime();
      const {
        delayDays,
        delayMinutes,
      } = this;
      if (delayDays) {
        ts += delayDays * 24 * 3600 * 1000;
      }
      if (delayMinutes) {
        ts += delayMinutes * 60 * 1000;
      }
      triggerAt = new Date(ts);
    }
    return triggerAt;
  }

  getDestinations({
    smsNumber,
    emailAddress,
  }) {
    let destinations = [];
    switch (this.delivery) {
      case NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY: {
        if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__SMS_ONLY: {
        if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL: {
        if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        } else if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__PREFER_SMS: {
        if (smsNumber) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_SMS,
            address: smsNumber,
          });
        } else if (emailAddress) {
          destinations.push({
            type: NOTIFICATION_DELIVERY_TYPE_EMAIL,
            address: emailAddress,
          });
        }
        break;
      }
      case NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION: {
        if (this.destinations) {
          destinations = this.destinations;
        }
        break;
      }
      default:
      // pass
    }
    return destinations;
  }
}

Notification.collection = 'Notifications';

export default Notification;
