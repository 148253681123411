import QuestionCursor from './QuestionCursor';

function getQuestionsIdsBetween(
  questionnaire,
  firstQuestionId,
  lastQuestionId,
) {
  const questionIds = [];
  let cursor = QuestionCursor.begin({
    questionnaire,
    questionId: firstQuestionId,
  });
  while (
    cursor.isValid() &&
    (!cursor.questionId || cursor.questionId !== lastQuestionId)
  ) {
    if (cursor.questionId && cursor.questionId !== firstQuestionId) {
      questionIds.push(cursor.questionId);
    }
    cursor = cursor.next();
  }
  return questionIds;
}

export default getQuestionsIdsBetween;
