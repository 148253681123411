import has from 'lodash/has';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
// import mergeWith from 'lodash/mergeWith';
import {
  setAtKey,
  pushAtKey,
  // createModifyAtKey,
} from '../utilsClient/immutable';
import BaseModel from './BaseModel';
import cleanValue from '../utils/cleanValue';
import checkSchema from '../utils/checkSchema';
// import Activity from './Activity';
// import Recipient from './Recipient';
import {
  CSV_BINDING_TYPE__VARIABLE,
} from '../constants';

const evaluate = variables => (formula) => {
  if (typeof formula === 'string') {
    if (formula.charAt(0) === '$') {
      const identifier = formula.substr(1);
      return get(variables, identifier);
    }
    return formula;
  }
  if (isPlainObject(formula)) {
    if (has(formula, '=')) {
      return formula['='];
    }
    return mapValues(formula, evaluate(variables));
  }
  if (isArray(formula)) {
    return map(formula, evaluate(variables));
  }
  return null;
};

const clean = (value) => {
  if (isPlainObject(value)) {
    const object = {};
    forEach(value, (v, k) => {
      if (k !== '_id') {
        object[k] = clean(v);
      }
    });
    return object;
  }
  if (isArray(value)) {
    return map(value, clean);
  }
  return value;
};

// const identity = x => x;

// NOTE: Merges arrays by concatenating them. If array elements are plain objects
//       containing _id field, it is used to match elements to be merged
//       in the recursive step.

/*

const customizer = (objValue, srcValue) => {
  if (isArray(srcValue)) {
    if (isArray(objValue)) {
      const indexes = {};
      const result = [];
      forEach(objValue, (v, i) => {
        if (isPlainObject(v) && v._id) {
          if (typeof indexes[v._id] === 'number') {
            result[indexes[v._id]] = mergeWith(
              result[indexes[v._id]],
              v,
              customizer,
            );
          } else {
            indexes[v._id] = i;
          }
        }
        result.push(v);
      });
      forEach(srcValue, (v) => {
        if (isPlainObject(v) && v._id && typeof indexes[v._id] === 'number') {
          result[indexes[v._id]] = mergeWith(
            result[indexes[v._id]],
            v,
            customizer,
          );
        } else {
          result.push(v);
        }
      });
      return result;
    }
    return srcValue;
  }
  return undefined;
};

*/

/*

const pushAtKeyOrMerge = createModifyAtKey((currentValue, valueToAdd) => {
  if (isNil(currentValue)) {
    return [
      valueToAdd,
    ];
  }
  if (isArray(currentValue)) {
    // NOTE: We need to pass objects here because "customizer" is ignored at
    //       the first level if I pass two arrays.
    const merged = mergeWith(
      {
        array: currentValue,
      },
      {
        array: [
          valueToAdd,
        ],
      },
      customizer,
    );
    return merged.array;
  }
  return currentValue;
});

*/

/*

// NOTE: FHIR bindings are currently broken. They will not work at CSV level at this point.

const processFhir = (rawMessage, scopeName, value, fhir) => {
  const {
    key,
    value: formula = '$value',
    operator,
  } = fhir;
  const context = {
    value,
  };
  let mutate;
  switch (operator) {
    case 'set':
      mutate = setAtKey;
      break;
    case 'push':
      mutate = pushAtKeyOrMerge;
      break;
    default:
      mutate = identity;
  }
  switch (scopeName) {
    case Recipient.scopeName:
      return mutate(rawMessage, `patient.${key}`, evaluate(context)(formula));
    case Activity.scopeName:
      return mutate(rawMessage, `encounter.${key}`, evaluate(context)(formula));
    default:
      return rawMessage;
  }
};

*/

const processOneValue = (
  rawMessage,
  bindingType,
  rawKey,
  rawValue,
  operator,
  variablesDb,
  options,
) => {
  let nextMessage = rawMessage;
  let key;
  let value = rawValue;
  if (options.ignoreNilValues && isNil(value)) {
    return nextMessage;
  }
  switch (bindingType) {
    case CSV_BINDING_TYPE__VARIABLE: {
      const variableId = rawKey;
      const variable = variablesDb && variablesDb[variableId];
      if (variable) {
        const jsonSchema = variable.getJsonSchema();
        if (jsonSchema) {
          value = cleanValue(jsonSchema, value);
        }
        if (jsonSchema) {
          const errors = checkSchema(jsonSchema, value);
          if (errors) {
            // NOTE: Null and empty strings are allowed even if they don't match
            //       the schema, because they will be interpreted as field removal.
            if (value !== '' && value !== null) {
              return nextMessage;
            }
          }
        }
        // if (fhir) {
        //   nextMessage = processFhir(nextMessage, scopeName, value, fhir);
        // }
        if (options.trackNameVariableId === variableId) {
          nextMessage = setAtKey(nextMessage, 'zedoc.trackName', value);
        }
        key = `zedoc.variables.${rawKey}`;
      }
      break;
    }
    default:
      key = rawKey;
  }
  if (!key) {
    return nextMessage;
  }
  switch (operator) {
    case 'set':
      nextMessage = setAtKey(nextMessage, key, value);
      break;
    case 'push':
      nextMessage = pushAtKey(nextMessage, key, value);
      break;
    default:
    // do nothing
  }
  return nextMessage;
};

class CSVInputSchema extends BaseModel {
  getVariableIds() {
    const variableIds = [];
    const processBinding = ({
      key,
      type,
    }) => {
      if (type === CSV_BINDING_TYPE__VARIABLE) {
        variableIds.push(key);
      }
    };
    forEach(this.bindings, processBinding);
    forEach(this.additionalBindings, processBinding);
    return uniq(variableIds);
  }

  getMessage(
    record,
    variablesDb,
    {
      trackNameVariableId,
      ignoreNilValues,
      actionType = this.actionType,
    } = {},
  ) {
    let rawMessage = {
      zedoc: {
        projectId: this.projectId,
      },
    };
    if (actionType) {
      rawMessage.actionType = actionType;
    }
    const variables = {};
    const options = {
      trackNameVariableId,
      ignoreNilValues,
    };
    forEach(
      this.bindings,
      ({
        value = '$value',
        operator = 'set',
        key,
        variable,
        type,
      }, index) => {
        const finalValue = evaluate({
          ...variables,
          value: record[index],
        })(value);
        if (variable) {
          variables[variable] = finalValue;
        }
        if (!key) {
          return;
        }
        rawMessage = processOneValue(
          rawMessage,
          type,
          key,
          finalValue,
          operator,
          variablesDb,
          options,
        );
      },
    );
    forEach(
      this.additionalBindings,
      ({
        value = null,
        operator = 'set',
        key,
        type,
      }) => {
        const finalValue = evaluate(variables)(value);
        if (!key) {
          return;
        }
        rawMessage = processOneValue(
          rawMessage,
          type,
          key,
          finalValue,
          operator,
          variablesDb,
          options,
        );
      },
    );
    return clean(rawMessage);
  }
}

CSVInputSchema.collection = 'CSVInputSchemas';

export default CSVInputSchema;
