import {
  registerStore,
} from '../../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.header',
  initialState: {
    isVisible: false,
    isConfirmLoading: false,
  },
});

export default store;
