export const NOTIFICATION_AUTOMATIC = 'automatic';
export const NOTIFICATION_AUTOMATIC_REMINDER = 'automatic_reminder';
export const NOTIFICATION_MANUAL_REMINDER = 'manual_reminder';
export const NOTIFICATION_ACTIVITY_COMPLETION = 'activity_completion';
export const NOTIFICATION_CUSTOM_EVENT = 'custom_event';

export const NOTIFICATION_TYPES = [
  NOTIFICATION_AUTOMATIC,
  NOTIFICATION_AUTOMATIC_REMINDER,
  NOTIFICATION_MANUAL_REMINDER,
  NOTIFICATION_ACTIVITY_COMPLETION,
  NOTIFICATION_CUSTOM_EVENT,
];

export const NOTIFICATION_DELIVERY_METHOD__SMS_ONLY = 'sms_only';
export const NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY = 'email_only';
export const NOTIFICATION_DELIVERY_METHOD__PREFER_SMS = 'prefer_sms';
export const NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL = 'prefer_email';
export const NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION =
  'custom_destination';
export const NOTIFICATION_DELIVERY_METHODS = [
  NOTIFICATION_DELIVERY_METHOD__SMS_ONLY,
  NOTIFICATION_DELIVERY_METHOD__EMAIL_ONLY,
  NOTIFICATION_DELIVERY_METHOD__PREFER_SMS,
  NOTIFICATION_DELIVERY_METHOD__PREFER_EMAIL,
  NOTIFICATION_DELIVERY_METHOD__CUSTOM_DESTINATION,
];

export const NOTIFICATION_INTENT_STATE__SCHEDULED = 'SCHEDULED';
export const NOTIFICATION_INTENT_STATE__CANCELED = 'CANCELED';
export const NOTIFICATION_INTENT_STATE__PENDING = 'PENDING';
export const NOTIFICATION_INTENT_STATE__FULFILLED = 'FULFILLED';
export const NOTIFICATION_INTENT_STATES = [
  NOTIFICATION_INTENT_STATE__SCHEDULED,
  NOTIFICATION_INTENT_STATE__CANCELED,
  NOTIFICATION_INTENT_STATE__PENDING,
  NOTIFICATION_INTENT_STATE__FULFILLED,
];
