import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import Spacer from '../primitives/Spacer';
import {
  theme,
} from '../../utilsClient/cssHelpers';

// TODO: Add ARIA role="separator"?

const Component = styled.div`
  display: flex;
  align-items: center;

  &:before,
  &:after {
    content: '';
    height: 0px;
    flex-grow: 1;
    border-top: 1px ${props => props.type} ${theme('color.border')};
  }

  &:before {
    /* background: linear-gradient(to right, transparent, #e8e8e8); */
  }

  &:after {
    /* background: linear-gradient(to left, transparent, #e8e8e8); */
  }
`;

const Divider = ({
  className,
  children,
  type,
}) => (
  <Component
    className={className}
    type={type}
  >
    {children && <Spacer>{children}</Spacer>}
  </Component>
);

Divider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'solid',
    'dashed',
  ]),
};

Divider.defaultProps = {
  className: null,
  children: null,
  type: 'solid',
};

export default Divider;
