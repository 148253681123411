/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import {
  useTranslation,
} from 'react-i18next';
import Milestone from './Milestone';
// import { theme } from '../../utilsClient/cssHelpers';

const StyledMilestone = styled(Milestone)`
  background-color: #fafafa;
`;

const MilestoneRejected = ({
  ...props
}) => {
  const {
    t,
  } = useTranslation('components');

  return (
    <StyledMilestone
      status={t('Milestone.rejected')}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default MilestoneRejected;
