import styled from 'styled-components';
import React, {
  useMemo,
} from 'react';
import {
  propTypes,
  defaultProps,
} from './Text';
import {
  absoluteStyles,
  colorStyles,
} from './styles';

const TextHeading = ({
  level = 1,
  ...props
}) => {
  const Heading = useMemo(
    () => styled(`h${level}`)`
      ${colorStyles};
      ${absoluteStyles};
    `,
    [
      level,
    ],
  );

  return <Heading {...props} />;
};

TextHeading.propTypes = propTypes;
TextHeading.defaultProps = defaultProps;

export default React.memo(TextHeading);
