import {
  QUESTION_TYPE__UNKNOWN,
} from '../../../constants';
import Question from '../Question';

export default class QuestionUnknown extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__UNKNOWN;
  }
}

QuestionUnknown.expectedRawKeys = [];
QuestionUnknown.expectedValueType = {};
QuestionUnknown.editorSettings = {};

Question.types[QUESTION_TYPE__UNKNOWN] = QuestionUnknown;
