import createGetAtKey from '../createGetAtKey';

const resolveOnSelector = (store, selector) => new Promise((resolve) => {
  let check;
  if (typeof selector === 'string') {
    check = createGetAtKey(selector);
  } else if (typeof selector === 'function') {
    check = selector;
  } else {
    throw new Error(
      'Expected string or function as the second argument for resolveOnSelector()',
    );
  }
  let unsubscribe;
  const handleStateChange = () => {
    const state = store.getState();
    const value = check(state);
    if (value) {
      resolve(value);
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
      }
    }
  };
  unsubscribe = store.subscribe(handleStateChange);
  handleStateChange();
});

export default resolveOnSelector;
