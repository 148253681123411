import {
  withRouter,
} from 'react-router-dom';
import {
  compose,
  lifecycle,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import filter from 'lodash/filter';
import map from 'lodash/map';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  connect,
} from 'react-redux';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../common/selectors/Project';
import CurrentUserProfile from './CurrentUserProfile';
import {
  getIsVisible,
} from '../../../store/ui/header/selectors';
import {
  closeEditUserDialog,
} from '../../../store/ui/header/actions';
import {
  USERS_GROUP_TYPE__PROJECT,
} from '../../../common/constants';
import {
  apiZedocMyProjects,
} from '../../../common/api/zedoc';
import {
  userDetails,
} from '../../../common/api/currentUser';

const Container = compose(
  withRouter,
  ddp({
    subscriptions: () => [
      userDetails.withParams(),
      apiZedocMyProjects.withParams({}),
    ],
    renderLoader: null,
  }),
  connect(
    createStructuredSelector({
      visible: getIsVisible,
      user: CurrentUserSelect.user(),
      rolesNames: createSelector(
        CurrentUserSelect.user(),
        user => (user && user.getRolesNames()) || [],
      ),
      groupsNames: createSelector(
        CurrentUserSelect.user(),
        user => map(
          filter(
            user && user.getGroups(),
            group => group.type !== USERS_GROUP_TYPE__PROJECT,
          ),
          'name',
        ),
      ),
      assignedProjects: ProjectSelect.all().whereOneOfMembersIs(
        CurrentUserSelect.user(),
      ),
    }),
    dispatch => ({
      onCancel: () => dispatch(closeEditUserDialog()),
    }),
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        location,
        dispatch,
      } = this.props;

      if (location.key !== prevProps.location.key) {
        dispatch(closeEditUserDialog());
      }
    },
  }),
)(CurrentUserProfile);

export default Container;
