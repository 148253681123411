import DashboardChartBuilder from './DashboardChartBuilder';
import {
  PROJECT_DASHBOARD_TYPE__CHART_BUILDER,
  DASHBOARD_PERSPECTIVE__PATIENTS,
  DASHBOARD_PERSPECTIVE__RESPONSES,
} from '../../../../common/constants';
import {
  registerType,
} from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__CHART_BUILDER, {
  component: DashboardChartBuilder,
  perspectives: [
    {
      genericView: true,
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      specializations: [
        DASHBOARD_PERSPECTIVE__PATIENTS,
      ],
    },
  ],
});

export default DashboardChartBuilder;
