/* eslint-disable */

import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import styled, { css } from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { MoreHoriz } from 'styled-icons/material';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';
import Dropdown from '../Dropdown';
import { theme, media } from '../../utilsClient/cssHelpers';

const activeStyles = css`
  color: ${theme('color.primary')};
  font-weight: ${theme('font.weight.bold')};
`;

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  font-size: ${theme('font.size.small')};
  text-align: center;
  white-space: nowrap;

  a {
    color: inherit;
    display: block;
    padding: ${theme('space.2')} 0;
  }

  svg {
    height: 1.75rem;
    width: 1.75rem;
  }

  a:hover,
  a:focus {
    color: ${theme('color.primary')};
    text-decoration: none;
  }

  ${(props) => props.active && activeStyles};
`;

const MAX_NUMBER_OF_ITEMS = 4;

const Overlay = styled.div`
  background: #001529;
  border-radius: ${theme('border.radius.2')};
  margin: ${theme('space.1')};
  overflow: hidden;
`;

const Component = styled.aside`
  padding: ${theme('space.3')};

  ${media.lessThan('mobile')`
    /* TODO: Not yet sure if it's smart to keep layout based styling on component level */
    order: 1;

    > ul {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      align-items: center;

      li {
        &:not(:nth-child(-n + ${MAX_NUMBER_OF_ITEMS})):not(:last-child) {
          display: none;
        }
      }

      /* Hide extra items list */

      + ul {
        display: none;
      }
    }

    img {
      display: none;
    }
  `}

  ${media.greaterThan('mobile')`
    display: flex;
    flex-direction: column;

    > ul {
      margin-top: ${theme('space.5')};
    }

    ul:last-child:not(:first-child) {
      margin-top: auto;

      > li a {
        padding-bottom: 0;
      }
    }

    /* Hide 'More' button item */
    ul:nth-of-type(1) li {
      &:last-child {
        display: none;
      }
    }
  `}
`;

const StyledParagraph = styled(Text.Paragraph)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledLogo = styled.div`
  max-width: 7rem;
`;

const NavigationItemLayout = ({ type, children }) =>
  type === 'horizontal' ? (
    <Cluster space={2} wrap="nowrap">
      {children}
    </Cluster>
  ) : (
    <Stack space={1}>{children}</Stack>
  );

const NavigationItem = React.forwardRef(
  ({ id, to, href, onClick, icon, label, type, currentPath }, forwardedRef) => {
    return (
      <ListItem
        ref={forwardedRef}
        title={label}
        data-testid={`menu-item-${id}`}
        active={startsWith(currentPath, to)}
      >
        <Text.Link
          to={to}
          href={href}
          target={href ? '_blank' : null}
          onClick={onClick}
        >
          <NavigationItemLayout type={type}>
            {icon}
            <StyledParagraph>{label}</StyledParagraph>
          </NavigationItemLayout>
        </Text.Link>
      </ListItem>
    );
  },
);

const Navigation = ({ logo, items, extra, currentPath }) => {
  const overlay = (
    <Overlay>
      <List>
        {map(
          [...items.slice(MAX_NUMBER_OF_ITEMS), ...extra],
          (item) =>
            item && (
              <NavigationItem
                key={item.id}
                currentPath={currentPath}
                type="horizontal"
                {...item}
              />
            ),
        )}
      </List>
    </Overlay>
  );

  return (
    <Component>
      {logo && (
        <Text.Link to={logo.to}>
          {/* TODO: Wrapped in a <div /> because logo inherits width / height from <Text /> */}
          <StyledLogo>{logo.children}</StyledLogo>
        </Text.Link>
      )}
      <List>
        {map(
          items,
          (item) =>
            item && (
              <NavigationItem
                key={item.id}
                currentPath={currentPath}
                type="horizontal"
                {...item}
              />
            ),
        )}
        <Dropdown overlay={overlay} trigger={['click']}>
          <NavigationItem id="more" label="More" icon={<MoreHoriz />} />
        </Dropdown>
      </List>
      {extra && (
        <List>
          {map(
            extra,
            (item) =>
              item && (
                <NavigationItem
                  key={item.id}
                  currentPath={currentPath}
                  type="horizontal"
                  {...item}
                />
              ),
          )}
        </List>
      )}
    </Component>
  );
};

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      to: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

Navigation.defaultProps = {
  items: [],
  extra: [],
};

export default Navigation;
