import sample from 'lodash/sample';
import {
  QUESTION_TYPE__SELECT_ONE,
  ANSWER_VALUE_MAX_LENGTH,
} from '../../../constants';
import Question from '../Question';
import QuestionText from './QuestionText';

export default class QuestionSelectOne extends QuestionText {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__SELECT_ONE;
  }

  getRandomAnswer() {
    const choice =
      this.isOptional() && Math.random() < 0.5 ? null : sample(this.choices);
    return choice
      ? {
        value: choice.value,
      }
      : {};
  }
}

QuestionSelectOne.expectedValueType = {
  type: 'string',
  maxLength: ANSWER_VALUE_MAX_LENGTH,
};

QuestionSelectOne.editorSettings = {
  limits: false,
  choices: true,
  snippets: false,
  additional: true,
};

Question.types[QUESTION_TYPE__SELECT_ONE] = QuestionSelectOne;
