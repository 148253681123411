import React, {
  Suspense,
  lazy,
} from 'react';

const LazyCodeMirror = lazy(() => import(/* webpackChunkName: "CodeMirror" */ './CodeMirror'));

export default props => (
  <Suspense fallback={<div>loading...</div>}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <LazyCodeMirror {...props} />
  </Suspense>
);
