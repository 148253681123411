import range from 'lodash/range';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Empty from '../base/Empty';
import Text from '../base/Text';
import Skeleton from '../Skeleton';
import Pagination from '../Pagination';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  renderColumn,
} from './helpers';
import {
  selectable as selectableStyles,
} from './styles';

const StyledTableWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;

  thead > tr > th {
    font-size: ${theme('font.size.small')};
    color: ${theme('color.onSurface')};
    border-color: ${theme('color.border')};
    white-space: nowrap;
  }

  tbody > tr > td {
    border-color: ${theme('color.border')};
    padding-top: ${theme('space.1')};
    padding-bottom: ${theme('space.1')};
  }

  tbody > tr:first-child > td {
    border-top: 1px solid ${theme('color.border')};
  }

  tbody > tr:not(:last-child) > td {
    border-bottom: 1px solid ${theme('color.border')};
  }

  th {
    font-weight: ${theme('font.weight.bold')};
  }

  th,
  td {
    padding: ${theme('space.2')};
  }

  tr > th:first-child,
  tr > td:first-child {
    padding-left: 0;
  }

  tr > th:last-child,
  tr > td:last-child {
    padding-right: 0;
  }
`;

const StyledRow = styled.tr`
  ${props => props.isSelectable && selectableStyles}
`;

const TableBody = ({
  children,
  columns,
  loading,
  pageSize,
}) => {
  return (
    <tbody>
      {loading
        ? range(pageSize).map(index => (
          <tr key={index}>
            {map(columns, column => (
              <td key={column.key}>
                <Skeleton />
              </td>
            ))}
          </tr>
        ))
        : children}
    </tbody>
  );
};

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
  pageSize: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  loading: PropTypes.bool,
};

TableBody.defaultProps = {
  columns: [],
  loading: false,
};

const TableRow = ({
  columns,
  item,
  index,
  onRow,
}) => {
  const rowHandlers = onRow(item);
  const isSelectable = rowHandlers && rowHandlers.onClick;
  const handleOnRowClick = () => rowHandlers && rowHandlers.onClick && rowHandlers.onClick();

  return (
    <StyledRow
      onClick={handleOnRowClick}
      isSelectable={isSelectable}
    >
      {map(columns, column => (
        <td key={column.key}>
          {renderColumn({
            column,
            item,
            index,
          })}
        </td>
      ))}
    </StyledRow>
  );
};

TableRow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRow: PropTypes.func.isRequired,
};

// TODO: Add 'onChange'?

const Table = ({
  'data-testid': testId,
  title,
  className,
  dataSource,
  columns,
  rowKey,
  loading,
  pagination,
  pageSize,
  onRow,
}) => {
  return (
    <Stack space={1}>
      {(title || pagination) && (
        <Cluster justify="space-between">
          <Text.Paragraph importance="high">{title}</Text.Paragraph>
          {pagination && (
            <Pagination
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...pagination}
            />
          )}
        </Cluster>
      )}
      <StyledTableWrapper
        data-testid={testId}
        className={className}
      >
        <StyledTable>
          <colgroup>
            {map(
              columns,
              (column, index) => column.width && (
              <col
                key={`col_${index}`}
                style={{
                  width: column.width,
                  minWidth: column.width,
                }}
              />
              ),
            )}
          </colgroup>
          <thead>
            <tr>
              {map(columns, column => (
                <th key={column.key}>{column.title}</th>
              ))}
            </tr>
          </thead>
          <TableBody
            columns={columns}
            loading={loading}
            pageSize={pageSize}
          >
            {isEmpty(dataSource) ? (
              <tr>
                <td colSpan={columns.length}>
                  <Empty />
                </td>
              </tr>
            ) : (
              map(dataSource, (item, index) => (
                <TableRow
                  key={rowKey ? item[rowKey] : index}
                  columns={columns}
                  item={item}
                  index={index}
                  onRow={onRow}
                />
              ))
            )}
          </TableBody>
        </StyledTable>
      </StyledTableWrapper>
    </Stack>
  );
};

Table.propTypes = {
  'data-testid': PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
  rowKey: PropTypes.string,
  loading: PropTypes.bool,
  pagination: PropTypes.objectOf(PropTypes.any),
  pageSize: PropTypes.number,
  onRow: PropTypes.func,
};

Table.defaultProps = {
  'data-testid': 'table',
  title: null,
  className: null,
  dataSource: [],
  columns: [],
  rowKey: null,
  loading: false,
  pagination: null,
  pageSize: 10,
  onRow: () => {},
};

export default Table;
