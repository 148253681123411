import {
  ENGLISH_VALUE,
  initI18next,
} from '../common/utils/i18next';

// eslint-disable-next-line import/prefer-default-export
export const supportedLanguages = process.env.REACT_APP_LANGUAGES
  ? process.env.REACT_APP_LANGUAGES.split(',')
  : [
    ENGLISH_VALUE,
  ];

const supportedNamespaces = [
  'brand',
  'common',
  'confirmations',
  'eapp',
  'entry',
  'components',
  'forms',
  'permissions',
  'projects',
  'projectWizard',
  'tour',
  'states',
  'stateMachines',
];

initI18next({
  supportedLanguages,
  supportedNamespaces,
});
