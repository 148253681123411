import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  reset,
} from './styles';

const Component = styled.button`
  ${reset};

  cursor: pointer;
  color: ${props => theme(`color.${props.type}`)};
  transition: color ${theme('motion.duration.fast')}
    ${theme('motion.easing.standard')};

  &:hover,
  &:focus {
    color: ${props => theme(`color.${props.type}Hover`)};
    text-decoration: underline;
  }
`;

const ButtonLink = ({
  type,
  children,
  onClick,
}) => {
  return (
    <Component
      type={type}
      onClick={onClick}
    >
      {children}
    </Component>
  );
};

ButtonLink.propTypes = {
  type: PropTypes.oneOf([
    'primary',
    'danger',
  ]),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

ButtonLink.defaultProps = {
  type: 'primary',
  children: null,
  onClick: () => {},
};

export default ButtonLink;
