export const BEHAVIOR_ACTION_TYPE__HIDE_QUESTION = 'hideQuestion';
export const BEHAVIOR_ACTION_TYPE__SHOW_QUESTION = 'showQuestion';
export const BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK = 'hideQuestionChunk';
export const BEHAVIOR_ACTION_TYPE__SHOW_QUESTION_CHUNK = 'showQuestionChunk';
export const BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION = 'skipToQuestion';
export const BEHAVIOR_ACTION_TYPE__UNKNOWN = 'unknown';

export const BEHAVIOR_ACTION_TYPES = [
  BEHAVIOR_ACTION_TYPE__HIDE_QUESTION,
  BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
  BEHAVIOR_ACTION_TYPE__HIDE_QUESTION_CHUNK,
  BEHAVIOR_ACTION_TYPE__SHOW_QUESTION_CHUNK,
  BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
  BEHAVIOR_ACTION_TYPE__UNKNOWN,
];

export const BEHAVIOR_ACTION_TYPE_OPTIONS = [
  {
    value: BEHAVIOR_ACTION_TYPE__HIDE_QUESTION,
    label: 'Hide THIS question',
  },
  {
    value: BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
    label: 'Hide THIS question by default and reveal it conditionally',
  },
  {
    value: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    label: 'Jump to ANOTHER question',
  },
];

export const BEHAVIOR_CONDITION_TYPE__ALWAYS = 'always';
export const BEHAVIOR_CONDITION_TYPE__NEVER = 'never';
export const BEHAVIOR_CONDITION_TYPE__CUSTOM = 'custom';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF =
  'thisAnswerIsOneOf';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF =
  'anotherAnswerIsOneOf';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF =
  'thisAnswerIsNotOneOf';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF =
  'anotherAnswerIsNotOneOf';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS = 'thisAnswerExists';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS =
  'anotherAnswerExists';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST =
  'thisAnswerDoesNotExist';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST =
  'anotherAnswerDoesNotExist';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF =
  'thisAnswerMissingOrOneOf';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF =
  'anotherAnswerMissingOrOneOf';
export const BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF =
  'thisAnswerMissingOrNotOneOf';
export const BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF =
  'anotherAnswerMissingOrNotOneOf';

export const BEHAVIOR_CONDITION_TYPES = [
  BEHAVIOR_CONDITION_TYPE__ALWAYS,
  BEHAVIOR_CONDITION_TYPE__NEVER,
  BEHAVIOR_CONDITION_TYPE__CUSTOM,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
  BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
];

export const BEHAVIOR_CONDITION_TYPE_OPTIONS = [
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_ONE_OF,
    label: 'When ANOTHER answer is present and contains any of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_ONE_OF,
    label: 'When ANOTHER answer is missing or contains any of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_NOT_ONE_OF,
    label: 'When ANOTHER answer is present but contains none of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
    label: 'When ANOTHER answer is missing or present but contains none of',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_EXISTS,
    label: 'When ANOTHER answer is present',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__ANOTHER_ANSWER_DOES_NOT_EXIST,
    label: 'When ANOTHER answer is missing',
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_ONE_OF,
    label: 'When THIS answer is present and contains any of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_ONE_OF,
    label: 'When THIS answer is missing or contains any of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_NOT_ONE_OF,
    label: 'When THIS answer is present but contains none of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_IS_MISSING_OR_IS_NOT_ONE_OF,
    label: 'When THIS answer is missing or present but contains none of',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_EXISTS,
    label: 'When THIS answer is present',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__THIS_ANSWER_DOES_NOT_EXIST,
    label: 'When THIS answer is missing',
    only: {
      actionType: BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
    },
  },
  {
    value: BEHAVIOR_CONDITION_TYPE__CUSTOM,
    label: 'When formula evaluates to TRUE',
  },
];
