// NOTE: There does not need to be a 1-1 correspondence between data models
//       and resource types. For example, "patient" is in fact represented
//       by Recipients collection and "patient note" is a sub-document of
//       Recipient. This loose coupling is intentional because the permissions
//       are supposed to be expressed in business domain language and unfortunately
//       our current data model does not follow it precisely.

export const RESOURCE_TYPE__PROJECT = 'PROJECT';
export const RESOURCE_TYPE__PATIENT = 'PATIENT';
export const RESOURCE_TYPE__QUESTIONNAIRE = 'QUESTIONNAIRE';
export const RESOURCE_TYPE__PATIENT_NOTE = 'PATIENT_NOTE';
export const RESOURCE_TYPE__DASHBOARD = 'DASHBOARD';
export const RESOURCE_TYPE__MILESTONE = 'MILESTONE';
export const RESOURCE_TYPE__PARTICIPATION = 'PARTICIPATION';
export const RESOURCE_TYPE__ACTIVITY = 'ACTIVITY';
export const RESOURCE_TYPE__ANSWERS_SHEET = 'ANSWERS_SHEET';
export const RESOURCE_TYPE__REPORT = 'REPORT';
export const RESOURCE_TYPE__WORKFLOW = 'WORKFLOW';
export const RESOURCE_TYPE__AUDIT_LOG = 'AUDIT_LOG';
export const RESOURCE_TYPE__USER = 'USER';
export const RESOURCE_TYPE__DOMAIN = 'DOMAIN';
export const RESOURCE_TYPE__GROUP = 'GROUP';
export const RESOURCE_TYPE__ROLE = 'ROLE';
export const RESOURCE_TYPE__VARIABLE = 'VARIABLE';
export const RESOURCE_TYPE__EAPP = 'EAPP';

// ORGANIZATION

export const ORGANIZATION_SEARCH_QUESTIONNAIRE =
  'organization.QUESTIONNAIRE.search';
export const ORGANIZATION_REVIEW_QUESTIONNAIRE =
  'organization.QUESTIONNAIRE.review';
export const ORGANIZATION_UPDATE_QUESTIONNAIRE =
  'organization.QUESTIONNAIRE.update';
export const ORGANIZATION_ACCESS_VARIABLE = 'organization.VARIABLE.access';
export const ORGANIZATION_ACCESS_USER_PII = 'organization.USER.access_pii';

export const ORGANIZATION_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__QUESTIONNAIRE,
    value: ORGANIZATION_SEARCH_QUESTIONNAIRE,
    label: 'search',
  },
  {
    resourceType: RESOURCE_TYPE__QUESTIONNAIRE,
    value: ORGANIZATION_REVIEW_QUESTIONNAIRE,
    label: 'review',
  },
  {
    // fetch latest version from repository
    resourceType: RESOURCE_TYPE__QUESTIONNAIRE,
    value: ORGANIZATION_UPDATE_QUESTIONNAIRE,
    label: 'update',
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ORGANIZATION_ACCESS_VARIABLE,
    label: 'permissions:organization.accessVariable',
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ORGANIZATION_ACCESS_USER_PII,
    label: 'permissions:organization.accessUserPii',
  },
];

// PATIENT

export const PATIENT_SEARCH_PATIENT = 'patient.PATIENT.search';
export const PATIENT_REVIEW_PATIENT = 'patient.PATIENT.review';
export const PATIENT_CREATE_PATIENT = 'patient.PATIENT.create';
export const PATIENT_UPDATE_PATIENT = 'patient.PATIENT.update';
export const PATIENT_DELETE_PATIENT = 'patient.PATIENT.delete';
export const PATIENT_ATTACH_PARTICIPATION =
  'patient.PATIENT.attach.PARTICIPATION';
export const PATIENT_REVIEW_PATIENT_NOTE = 'patient.PATIENT_NOTE.review';
export const PATIENT_CREATE_PATIENT_NOTE = 'patient.PATIENT_NOTE.create';
export const PATIENT_UPDATE_PATIENT_NOTE = 'patient.PATIENT_NOTE.update';
export const PATIENT_DELETE_PATIENT_NOTE = 'patient.PATIENT_NOTE.delete';
export const PATIENT_VIEW_PATIENT_DETAILS = 'patient.PATIENT.view_details';
export const PATIENT_VIEW_PATIENT_NOTES = 'patient.PATIENT.view_notes';
export const PATIENT_VIEW_PATIENT_DOCUMENTS = 'patient.PATIENT.view_documents';
export const PATIENT_VIEW_PATIENT_RESULTS = 'patient.PATIENT.view_results';
export const PATIENT_ACCESS_PATIENT_PII_VARIABLES =
  'patient.PATIENT.access_pii_variables';

export const PATIENT_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_SEARCH_PATIENT,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_REVIEW_PATIENT,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_CREATE_PATIENT,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_UPDATE_PATIENT,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_DELETE_PATIENT,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT,
    relatedResourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PATIENT_ATTACH_PARTICIPATION,
    label: 'permissions:patient.enroll',
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT_NOTE,
    value: PATIENT_REVIEW_PATIENT_NOTE,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT_NOTE,
    value: PATIENT_CREATE_PATIENT_NOTE,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT_NOTE,
    value: PATIENT_UPDATE_PATIENT_NOTE,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PATIENT_NOTE,
    value: PATIENT_DELETE_PATIENT_NOTE,
    label: 'delete',
    showInTable: true,
  },
  {
    // it seems redundant, but it's not; user may be able to access patient data through other means,
    // but not see this ui element
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_VIEW_PATIENT_DETAILS,
    label: 'permissions:patient.viewDetails',
  },
  {
    // again, this refers to ui only; the notes themselves may be accessible from other part of the app
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_VIEW_PATIENT_NOTES,
    label: 'permissions:patient.viewNotes',
  },
  {
    // will be scoped to answersSheet.ownership
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_VIEW_PATIENT_DOCUMENTS,
    label: 'permissions:patient.viewDocuments',
  },
  {
    // will be scoped to answersSheet.ownership
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_VIEW_PATIENT_RESULTS,
    label: 'permissions:patient.viewResults',
  },
  {
    // the following permissions propagates to all related participations,
    // as they can also contain pii variables activities and answers sheets
    resourceType: RESOURCE_TYPE__PATIENT,
    value: PATIENT_ACCESS_PATIENT_PII_VARIABLES,
    label: 'permissions:patient.accessPiiVariables',
  },
];

// PROJECT

export const PROJECT_SEARCH_PROJECT = 'project.PROJECT.search';
export const PROJECT_REVIEW_PROJECT = 'project.PROJECT.review';
export const PROJECT_VIEW_COLLABORATORS = 'project.PROJECT.view_collaborators';
export const PROJECT_REVIEW_DASHBOARD = 'project.DASHBOARD.review';
export const PROJECT_VIEW_TIMELINE = 'project.PROJECT.view_timeline';
export const PROJECT_VIEW_ANALYTICS = 'project.PROJECT.view_analytics';
export const PROJECT_VIEW_NLP_ANALYTICS = 'project.PROJECT.view_nlp_analytics';
export const PROJECT_ATTACH_MILESTONE = 'project.PROJECT.attach.MILESTONE';
export const PROJECT_REVIEW_MILESTONE = 'project.MILESTONE.review';
export const PROJECT_CREATE_MILESTONE = 'project.MILESTONE.create';
export const PROJECT_UPDATE_MILESTONE = 'project.MILESTONE.update';
export const PROJECT_DELETE_MILESTONE = 'project.MILESTONE.delete';
export const PROJECT_IMPORT_PARTICIPANTS =
  'project.PROJECT.import_participants';
export const PROJECT_EXPORT_PARTICIPANTS =
  'project.PROJECT.export_participants';
export const PROJECT_FORCE_SYNCHRONIZATION =
  'project.PROJECT.force_synchronization';
export const PROJECT_ATTACH_PARTICIPATION =
  'project.PROJECT.attach.PARTICIPATION';
export const PROJECT_REVIEW_EAPP = 'project.EAPP.review';
export const PROJECT_SEARCH_EAPP = 'project.EAPP.search';
export const PROJECT_UPDATE_EAPP = 'project.EAPP.update';

export const PROJECT_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_SEARCH_PROJECT,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_REVIEW_PROJECT,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: PROJECT_VIEW_COLLABORATORS,
    label: 'permissions:project.viewCollaborators',
  },
  {
    resourceType: RESOURCE_TYPE__DASHBOARD,
    value: PROJECT_REVIEW_DASHBOARD,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_VIEW_TIMELINE,
    label: 'permissions:project.viewTimeline',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_VIEW_ANALYTICS,
    label: 'permissions:project.viewAnalytics',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_VIEW_NLP_ANALYTICS,
    label: 'permissions:project.viewNlpAnalytics',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    relatedResourceType: RESOURCE_TYPE__MILESTONE,
    value: PROJECT_ATTACH_MILESTONE,
    label: 'addMilestone',
  },
  {
    // some milestones can be scoped to a project's sub-domain
    resourceType: RESOURCE_TYPE__MILESTONE,
    value: PROJECT_REVIEW_MILESTONE,
    label: 'review',
    showInTable: true,
  },
  {
    // dynamically add milestone (custom) to existing project
    resourceType: RESOURCE_TYPE__MILESTONE,
    value: PROJECT_CREATE_MILESTONE,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__MILESTONE,
    value: PROJECT_UPDATE_MILESTONE,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__MILESTONE,
    value: PROJECT_DELETE_MILESTONE,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_IMPORT_PARTICIPANTS,
    label: 'permissions:project.importParticipants',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_EXPORT_PARTICIPANTS,
    label: 'permissions:project.exportParticipants',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: PROJECT_FORCE_SYNCHRONIZATION,
    label: 'permissions:project.forceSynchronization',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    relatedResourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_ATTACH_PARTICIPATION,
    label: 'permissions:project.addParticipant',
  },
  {
    resourceType: RESOURCE_TYPE__EAPP,
    value: PROJECT_SEARCH_EAPP,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__EAPP,
    value: PROJECT_REVIEW_EAPP,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__EAPP,
    value: PROJECT_UPDATE_EAPP,
    label: 'update',
    showInTable: true,
  },
];

// PROJECT_PROFILE

export const PROJECT_PROFILE_SEARCH_PARTICIPATION =
  'project_profile.PARTICIPATION.search';
export const PROJECT_PROFILE_REVIEW_PARTICIPATION =
  'project_profile.PARTICIPATION.review';
export const PROJECT_PROFILE_CREATE_PARTICIPATION =
  'project_profile.PARTICIPATION.create';
export const PROJECT_PROFILE_UPDATE_PARTICIPATION =
  'project_profile.PARTICIPATION.update';
export const PROJECT_PROFILE_DELETE_PARTICIPATION =
  'project_profile.PARTICIPATION.delete';
export const PROJECT_PROFILE_ATTACH_ACTIVITY =
  'project_profile.PARTICIPATION.attach.ACTIVITY';
export const PROJECT_PROFILE_VIEW_ANALYTICS =
  'project_profile.PARTICIPATION.view_analytics';

export const PROJECT_PROFILE_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_SEARCH_PARTICIPATION,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_REVIEW_PARTICIPATION,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_CREATE_PARTICIPATION,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_UPDATE_PARTICIPATION,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_DELETE_PARTICIPATION,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_ATTACH_ACTIVITY,
    label: 'permissions:projectProfile.addActivity',
  },
  {
    resourceType: RESOURCE_TYPE__PARTICIPATION,
    value: PROJECT_PROFILE_VIEW_ANALYTICS,
    label: 'permissions:projectProfile.viewAnalytics',
  },
];

// PATIENT_MILESTONE

export const PATIENT_MILESTONE_REVIEW_ACTIVITY =
  'patient_milestone.ACTIVITY.review';
export const PATIENT_MILESTONE_SEARCH_ACTIVITY =
  'patient_milestone.ACTIVITY.search';
export const PATIENT_MILESTONE_CREATE_ACTIVITY =
  'patient_milestone.ACTIVITY.create';
export const PATIENT_MILESTONE_DELETE_ACTIVITY =
  'patient_milestone.ACTIVITY.delete';
export const PATIENT_MILESTONE_UPDATE_ACTIVITY =
  'patient_milestone.ACTIVITY.update';
export const PATIENT_MILESTONE_VIEW_ACTIVITY_NOTIFICATIONS =
  'patient_milestone.ACTIVITY.view_notifications';
export const PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS =
  'patient_milestone.ACTIVITY.send_notifications';
export const PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK =
  'patient_milestone.ACTIVITY.obtain_link';
export const PATIENT_MILESTONE_OBTAIN_ACTIVITY_QR_CODE =
  'patient_milestone.ACTIVITY.obtain_qr_code';
export const PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET =
  'patient_milestone.ACTIVITY.attach.ANSWERS_SHEET';
export const PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.search';
export const PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.review';
export const PATIENT_MILESTONE_DELETE_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.delete';
export const PATIENT_MILESTONE_CREATE_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.create';
export const PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.update';
export const PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.complete';
export const PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET =
  'patient_milestone.ANSWERS_SHEET.annotate';
export const PATIENT_MILESTONE_FORCE_SYNCHRONIZATION =
  'patient_milestone.ACTIVITY.force_synchronization';

export const PATIENT_MILESTONE_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_REVIEW_ACTIVITY,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_SEARCH_ACTIVITY,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_CREATE_ACTIVITY,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_DELETE_ACTIVITY,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_UPDATE_ACTIVITY,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_VIEW_ACTIVITY_NOTIFICATIONS,
    label: 'permissions:patientMilestone.viewNotifications',
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_SEND_ACTIVITY_NOTIFICATIONS,
    label: 'permissions:patientMilestone.sendNotifications',
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_OBTAIN_ACTIVITY_LINK,
    label: 'permissions:patientMilestone.obtainLink',
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_OBTAIN_ACTIVITY_QR_CODE,
    label: 'permissions:patientMilestone.obtainQrCode',
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    relatedResourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_ATTACH_ANSWERS_SHEET,
    label: 'permissions:patientMilestone.addQuestionnaire',
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_SEARCH_ANSWERS_SHEET,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_DELETE_ANSWERS_SHEET,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_CREATE_ANSWERS_SHEET,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
    label: 'permissions:patientMilestone.complete',
  },
  {
    resourceType: RESOURCE_TYPE__ANSWERS_SHEET,
    value: PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET,
    label: 'permissions:patientMilestone.annotate',
  },
  {
    resourceType: RESOURCE_TYPE__ACTIVITY,
    value: PATIENT_MILESTONE_FORCE_SYNCHRONIZATION,
    label: 'permissions:patientMilestone.forceSynchronization',
  },
];

// ADMIN

export const ADMIN_SEARCH_WORKFLOW = 'admin.WORKFLOW.search';
export const ADMIN_SEARCH_AUDIT_LOGS = 'admin.AUDIT_LOG.search';
export const ADMIN_SEARCH_REPORT = 'admin.REPORT.search';
export const ADMIN_REVIEW_REPORT = 'admin.REPORT.review';
export const ADMIN_CREATE_REPORT = 'admin.REPORT.create';
export const ADMIN_UPDATE_REPORT = 'admin.REPORT.update';
export const ADMIN_DELETE_REPORT = 'admin.REPORT.delete';
export const ADMIN_SEARCH_USER = 'admin.USER.search';
export const ADMIN_REVIEW_USER = 'admin.USER.review';
export const ADMIN_CREATE_USER = 'admin.USER.create';
export const ADMIN_UPDATE_USER = 'admin.USER.update';
export const ADMIN_DELETE_USER = 'admin.USER.delete';
export const ADMIN_UPDATE_USER_EMAILS = 'admin.USER.update_emails';
export const ADMIN_UPDATE_USER_PHONES = 'admin.USER.update_phones';
export const ADMIN_SEARCH_PROJECT = 'admin.PROJECT.search';
export const ADMIN_REVIEW_PROJECT = 'admin.PROJECT.review';
export const ADMIN_CREATE_PROJECT = 'admin.PROJECT.create';
export const ADMIN_UPDATE_PROJECT = 'admin.PROJECT.update';
export const ADMIN_DELETE_PROJECT = 'admin.PROJECT.delete';
export const ADMIN_SEARCH_DOMAIN = 'admin.DOMAIN.search';
export const ADMIN_REVIEW_DOMAIN = 'admin.DOMAIN.review';
export const ADMIN_CREATE_DOMAIN = 'admin.DOMAIN.create';
export const ADMIN_UPDATE_DOMAIN = 'admin.DOMAIN.update';
export const ADMIN_DELETE_DOMAIN = 'admin.DOMAIN.delete';
export const ADMIN_SEARCH_GROUP = 'admin.GROUP.search';
export const ADMIN_REVIEW_GROUP = 'admin.GROUP.review';
export const ADMIN_CREATE_GROUP = 'admin.GROUP.create';
export const ADMIN_UPDATE_GROUP = 'admin.GROUP.update';
export const ADMIN_DELETE_GROUP = 'admin.GROUP.delete';
export const ADMIN_SEARCH_ROLE = 'admin.ROLE.search';
export const ADMIN_REVIEW_ROLE = 'admin.ROLE.review';
export const ADMIN_CREATE_ROLE = 'admin.ROLE.create';
export const ADMIN_UPDATE_ROLE = 'admin.ROLE.update';
export const ADMIN_DELETE_ROLE = 'admin.ROLE.delete';
export const ADMIN_SEARCH_VARIABLE = 'admin.VARIABLE.search';
export const ADMIN_REVIEW_VARIABLE = 'admin.VARIABLE.review';
export const ADMIN_CREATE_VARIABLE = 'admin.VARIABLE.create';
export const ADMIN_UPDATE_VARIABLE = 'admin.VARIABLE.update';
export const ADMIN_DELETE_VARIABLE = 'admin.VARIABLE.delete';

export const ADMIN_PERMISSIONS = [
  {
    resourceType: RESOURCE_TYPE__WORKFLOW,
    value: ADMIN_SEARCH_WORKFLOW,
    label: 'search',
  },
  {
    resourceType: RESOURCE_TYPE__AUDIT_LOG,
    value: ADMIN_SEARCH_AUDIT_LOGS,
    label: 'search',
  },
  {
    resourceType: RESOURCE_TYPE__REPORT,
    value: ADMIN_SEARCH_REPORT,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__REPORT,
    value: ADMIN_REVIEW_REPORT,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__REPORT,
    value: ADMIN_CREATE_REPORT,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__REPORT,
    value: ADMIN_UPDATE_REPORT,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__REPORT,
    value: ADMIN_DELETE_REPORT,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_SEARCH_USER,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_REVIEW_USER,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_CREATE_USER,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_UPDATE_USER,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_DELETE_USER,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_UPDATE_USER_EMAILS,
    label: 'permissions:admin.updateEmails',
  },
  {
    resourceType: RESOURCE_TYPE__USER,
    value: ADMIN_UPDATE_USER_PHONES,
    label: 'permissions:admin.updatePhones',
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: ADMIN_SEARCH_PROJECT,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: ADMIN_REVIEW_PROJECT,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: ADMIN_CREATE_PROJECT,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: ADMIN_UPDATE_PROJECT,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__PROJECT,
    value: ADMIN_DELETE_PROJECT,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__DOMAIN,
    value: ADMIN_SEARCH_DOMAIN,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__DOMAIN,
    value: ADMIN_REVIEW_DOMAIN,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__DOMAIN,
    value: ADMIN_CREATE_DOMAIN,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__DOMAIN,
    value: ADMIN_UPDATE_DOMAIN,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__DOMAIN,
    value: ADMIN_DELETE_DOMAIN,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__GROUP,
    value: ADMIN_SEARCH_GROUP,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__GROUP,
    value: ADMIN_REVIEW_GROUP,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__GROUP,
    value: ADMIN_CREATE_GROUP,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__GROUP,
    value: ADMIN_UPDATE_GROUP,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__GROUP,
    value: ADMIN_DELETE_GROUP,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ROLE,
    value: ADMIN_SEARCH_ROLE,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ROLE,
    value: ADMIN_REVIEW_ROLE,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ROLE,
    value: ADMIN_CREATE_ROLE,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ROLE,
    value: ADMIN_UPDATE_ROLE,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__ROLE,
    value: ADMIN_DELETE_ROLE,
    label: 'delete',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ADMIN_SEARCH_VARIABLE,
    label: 'search',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ADMIN_REVIEW_VARIABLE,
    label: 'review',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ADMIN_CREATE_VARIABLE,
    label: 'create',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ADMIN_UPDATE_VARIABLE,
    label: 'update',
    showInTable: true,
  },
  {
    resourceType: RESOURCE_TYPE__VARIABLE,
    value: ADMIN_DELETE_VARIABLE,
    label: 'delete',
    showInTable: true,
  },
];

export const PERMISSIONS_GROUPS = [
  {
    title: 'permissions:organization.title',
    permissions: ORGANIZATION_PERMISSIONS,
  },
  {
    title: 'permissions:patient.title',
    permissions: PATIENT_PERMISSIONS,
  },
  {
    title: 'permissions:project.title',
    permissions: PROJECT_PERMISSIONS,
  },
  {
    title: 'permissions:projectProfile.title',
    permissions: PROJECT_PROFILE_PERMISSIONS,
  },
  {
    title: 'permissions:patientMilestone.title',
    permissions: PATIENT_MILESTONE_PERMISSIONS,
  },
  {
    title: 'permissions:admin.title',
    permissions: ADMIN_PERMISSIONS,
  },
];
