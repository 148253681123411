import isNil from 'lodash/isNil';
import map from 'lodash/map';
import {
  PROJECT_MILESTONE_TYPE__SCHEDULED,
  PROJECT_MILESTONE_TYPE__CUSTOM,
} from '../constants';
import {
  slugify,
} from '../utils/formatting';
import {
  yearMonthDayShift,
} from '../utils/date';
import BaseModel from './BaseModel';

class ProjectMilestone extends BaseModel {
  getDomains() {
    return map(this.ownership, 'domain');
  }

  getProjectId() {
    return this.projectId;
  }

  getName() {
    return this.name || '';
  }

  getSlug() {
    return slugify(this.getName()).replace(/-/g, '_');
  }

  isCustom() {
    return this.type === PROJECT_MILESTONE_TYPE__CUSTOM;
  }

  isScheduled() {
    return this.type === PROJECT_MILESTONE_TYPE__SCHEDULED;
  }

  getSchedule(isoString) {
    const schedule = {};
    if (isoString) {
      const yearMonthDay = isoString.substr(0, 10);
      // NOTE: Negative value will be interpreted as "infinity", i.e. dateStart will not be set.
      if (isNil(this.minusDays) || this.minusDays >= 0) {
        schedule.dateStart = yearMonthDayShift(
          yearMonthDay,
          -(this.minusDays || 0),
        );
      }
      if (isNil(this.plusDays) || this.plusDays >= 0) {
        schedule.dateEnd = yearMonthDayShift(yearMonthDay, this.plusDays || 0);
      }
      if (this.timeStart) {
        schedule.timeStart = this.timeStart;
      }
      if (this.timeEnd) {
        schedule.timeEnd = this.timeEnd;
      }
    }
    return schedule;
  }

  getBaselineSchedule(yearMonthDay) {
    if (!this.isScheduled() || !yearMonthDay) {
      return {};
    }
    return this.getSchedule(
      yearMonthDayShift(yearMonthDay, this.daysSinceBaseline || 0),
    );
  }
}

ProjectMilestone.scopeName = '@milestone';
ProjectMilestone.collection = 'ProjectMilestones';

export default ProjectMilestone;
