import {
  registerStore,
} from '../../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.dialogs',
  initialState: {},
});

export default store;
