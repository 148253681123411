export const AUDIT_EVENT_TYPE__LOGIN = 'login';
export const AUDIT_EVENT_TYPE__LOGOUT = 'logout';
export const AUDIT_EVENT_TYPE__FORM_STATE_CHANGE = 'form_state_change';
export const AUDIT_EVENT_TYPE__FINALIZE = 'finalize';
export const AUDIT_EVENT_TYPE__AMEND = 'amend';
export const AUDIT_EVENT_TYPE__MANUAL_DISCHARGE = 'manual_discharge';
export const AUDIT_EVENT_TYPE__MANUAL_REVERT_DISCHARGE =
  'manual_revert_discharge';
export const AUDIT_EVENT_TYPE__GENERIC_ACTION = 'generic_action';
export const AUDIT_EVENT_TYPE__GENERIC_MUTATION = 'generic_mutation';
export const AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION =
  'generic_state_transition';
export const AUDIT_EVENT_TYPE__FORM_SAVE = 'form_save';
export const AUDIT_EVENT_TYPE__FORM_DISCARD = 'form_discard';
export const AUDIT_EVENT_TYPE__FORM_SUBSCRIBE = 'form_subscribe';
export const AUDIT_EVENT_TYPE__FORM_FETCH = 'form_fetch';
export const AUDIT_EVENT_TYPE__SEARCH = 'search';
export const AUDIT_EVENT_TYPE__REVIEW = 'review';

export const AUDIT_EVENT_TYPES = [
  AUDIT_EVENT_TYPE__LOGIN,
  AUDIT_EVENT_TYPE__LOGOUT,
  AUDIT_EVENT_TYPE__FORM_STATE_CHANGE,
  AUDIT_EVENT_TYPE__FINALIZE,
  AUDIT_EVENT_TYPE__AMEND,
  AUDIT_EVENT_TYPE__MANUAL_DISCHARGE,
  AUDIT_EVENT_TYPE__MANUAL_REVERT_DISCHARGE,
  AUDIT_EVENT_TYPE__GENERIC_ACTION,
  AUDIT_EVENT_TYPE__GENERIC_MUTATION,
  AUDIT_EVENT_TYPE__GENERIC_STATE_TRANSITION,
  AUDIT_EVENT_TYPE__FORM_SAVE,
  AUDIT_EVENT_TYPE__FORM_DISCARD,
  AUDIT_EVENT_TYPE__FORM_SUBSCRIBE,
  AUDIT_EVENT_TYPE__FORM_FETCH,
  AUDIT_EVENT_TYPE__SEARCH,
  AUDIT_EVENT_TYPE__REVIEW,
];
