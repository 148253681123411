import get from 'lodash/get';
import {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  createSelector,
} from 'reselect';
import useSelectorsHub from '../../../common/containers/Questionnaire/useSelectorsHub';
import createQuestionnaire from './createQuestionnaire';

const useForm = (name, schema) => {
  const questionnaire = useMemo(() => {
    return createQuestionnaire(schema);
  }, [
    schema,
  ]);

  const selectorsHub = useSelectorsHub({
    name,
    questionnaire,
  });

  return {
    useValue: (path) => {
      return useSelector(
        useMemo(
          () => createSelector(
            selectorsHub.select.dynamicVariables(),
            variables => get(variables, path),
          ),
          [
            path,
          ],
        ),
      );
    },
  };
};

export default useForm;
