import keyBy from 'lodash/keyBy';
import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';

const identity = x => x;

/**
 * Create a function that's useful for merging two arrays of elements
 * that can be identified by a key. New elements simply overwrite the old ones,
 * but original order is maintained.
 * @param {Object} options
 * @param {Function} options.getKey
 * @param {Function} options.filter
 * @returns {Function} merge function
 */
export default function createMerge({
  getKey,
  filter = identity,
}) {
  const merge = (oldElements, newElements, ...rest) => {
    if (!newElements) {
      // skip if newElements is null
      if (rest.length > 0) {
        return merge(oldElements, ...rest);
      }
      return oldElements;
    }

    const newElementsByKey = keyBy(newElements, (element) => {
      if (typeof element === 'function') {
        return getKey(element());
      }
      return getKey(element);
    });
    const oldElementsByKey = {};
    const mappings = {};
    const merged = [];

    // 1. copy old responses and provide relevant index mappings
    forEach(oldElements, (element, index) => {
      const key = getKey(element);
      merged.push(element);
      if (newElementsByKey[key]) {
        if (!mappings[key]) {
          mappings[key] = [];
        }
        mappings[key].push(index);
      }
      oldElementsByKey[key] = cloneDeep(element);
    });

    // 2. either push a new answer or overwrite the last one with the same questionId
    forEach(newElementsByKey, (element, key) => {
      const mapping = mappings[key];
      // NOTE: We are creating a deep copy for two reasons:
      //       - Elements may sometimes be instances of some model, e.g. AnswersSheetSessionResponse
      //       - When used in a mongo modifier those objects may be accidentally
      //         mutated by SimpleSchema.clean(), which can result in bugs that
      //         are very hard to track down.
      let getNewElement;
      if (typeof element === 'function') {
        getNewElement = element;
      } else {
        getNewElement = () => cloneDeep({
          ...element,
        }); // we want a plain object
      }
      if (!mapping) {
        merged.push(getNewElement());
      } else {
        const index = mapping[mapping.length - 1];
        merged[index] = getNewElement(merged[index]);
      }
    });

    // 3. determine which elements should be present in the result; e.g.
    //    in case of AnswersSheet.responses when parent responses was removed,
    //    all children should be removed as well
    const filtered = filter(merged);

    //------------------------------
    return merge(filtered, ...rest);
  };

  return merge;
}
