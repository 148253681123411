import {
  UserAddOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  reduxForm,
} from 'redux-form';
import Schema from '../../common/utils/Schema';
import {
  SCHEMA_RX__EMAIL,
} from '../../common/constants/schema';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import TextField from '../../components/forms/FormFieldText';
import Error from '../../components/forms/Error';
import useDocumentTitle from '../../utils/useDocumentTitle';

const propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  error: '',
};

const SignUp = ({
  error,
  handleSubmit,
  onSubmit,
}) => {
  useDocumentTitle([
    'Register',
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card
        title="Register"
        footer={(
          <Cluster justify="center">
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              htmlType="submit"
            >
              Register
            </Button>
          </Cluster>
        )}
        bordered={false}
      >
        <Stack>
          {error && <Error>{error}</Error>}
          <Field
            key="1"
            component={TextField}
            name="firstName"
            type="text"
            label="First Name"
          />
          <Field
            key="2"
            component={TextField}
            name="lastName"
            type="text"
            label="Last Name"
          />
          <Field
            key="3"
            component={TextField}
            name="email"
            type="text"
            label="Email"
          />
          <Field
            key="4"
            component={TextField}
            name="password"
            type="password"
            label="Password"
          />
          <Field
            key="5"
            component={TextField}
            name="repeatPassword"
            type="password"
            label="Confirm Password"
          />
        </Stack>
      </Card>
    </form>
  );
};

SignUp.propTypes = propTypes;
SignUp.defaultProps = defaultProps;

export default reduxForm({
  form: 'entry',
  validate: new Schema({
    firstName: {
      type: String,
      label: 'First name',
    },
    lastName: {
      type: String,
      label: 'Last name',
    },
    email: {
      type: String,
      label: 'Email',
      regEx: SCHEMA_RX__EMAIL,
    },
    password: Schema.Password,
    repeatPassword: Schema.Password,
  }).validator(
    {
      noException: true,
    },
    (value, error) => {
      if (value.password !== value.repeatPassword) {
        return {
          repeatPassword: 'Should match password',
          ...error,
        };
      }
      return error;
    },
  ),
})(SignUp);
