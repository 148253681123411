import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router';
import Cluster from '../../common/components/primitives/Cluster';
import PageHeader from '../../common/components/PageHeader';
import settings from '../../common/settings';
import Tour from '../Tour';

const {
  features: {
    disableTour,
  },
} = settings.public;

const PageBar = ({
  // eslint-disable-next-line react/prop-types
  history,
  title,
  subTitle,
  description,
  backUrl,
  children,
  extra,
}) => {
  const props = {
    onBack: null,
  };

  if (backUrl) {
    // eslint-disable-next-line react/prop-types
    props.onBack = () => history.push(backUrl);
  }

  return (
    <PageHeader
      title={title}
      subTitle={subTitle}
      description={description}
      extra={(
        <Cluster justify="flex-end">
          {extra || (disableTour ? null : <Tour />)}
        </Cluster>
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </PageHeader>
  );
};

PageBar.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  backUrl: PropTypes.string,
  children: PropTypes.node,
  extra: PropTypes.node,
};

PageBar.defaultProps = {
  subTitle: null,
  description: null,
  backUrl: null,
  children: null,
  extra: null,
};

export default withRouter(PageBar);
