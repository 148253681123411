import styled from 'styled-components';
import React from 'react';
import {
  propTypes,
  defaultProps,
} from './Text';

const Component = styled.blockquote`
  font-style: italic;
  text-align: center;

  &::before,
  &::after {
    /* font-size: 2em; */
  }

  &::before {
    content: '“ ';
  }

  &::after {
    content: ' „';
  }
`;

const TextBlockquote = ({
  ...props
}) => <Component {...props} />;

TextBlockquote.propTypes = propTypes;
TextBlockquote.defaultProps = defaultProps;

export default React.memo(TextBlockquote);
