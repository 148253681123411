import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

export const getSubtractedValue = ({
  value,
  max,
  min,
  step,
  precision,
}) => {
  const base = 10 ** (precision || 0);

  const getValue = () => {
    if (!isNil(value) && !isNil(max) && value > max) {
      return max;
    }

    return (value || max) - step;
  };

  return Math.round(Math.max(getValue(), min || -Infinity) * base) / base;
};

export const getIncreasedValue = ({
  value,
  max,
  min,
  step,
  precision,
}) => {
  const base = 10 ** (precision || 0);

  const getValue = () => {
    if (!isNil(value) && !isNil(min) && value < min) {
      return min;
    }

    return (value || min) + step;
  };

  return Math.round(Math.min(getValue(), max || Infinity) * base) / base;
};

const withPrecision = (value, precision) => (precision ? Number(value).toFixed(precision) : value);

const isNotCompleteValue = value => isNaN(value) || isNil(value) || value === '';

export const formatToString = (value, precision) => (isNotCompleteValue(value) ? '' : withPrecision(value, precision).toString());

export const formatToNumber = (value, precision) => (value ? Number(withPrecision(value, precision)) : null);
