import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  mapProps,
} from 'recompose';
import {
  connect,
} from 'react-redux';
import {
  submit,
} from 'redux-form';
import {
  useTranslation,
} from 'react-i18next';
import Stack from '../../../common/components/primitives/Stack';
import Modal from '../Modal';
import EditNoteForm, {
  form,
} from './EditNoteForm';

const EditNote = compose(
  connect(
    () => ({}),
    dispatch => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
  mapProps(
    ({
      id,
      noteContent: initialContent,
      answersSheetId,
      eappId,
      onCreate,
      onUpdate,
      ...rest
    }) => ({
      ...rest,
      id,
      initialValues: {
        noteContent: initialContent,
      },
      onSubmit: ({
        noteContent,
      }) => (id
        ? onUpdate({
          answersSheetId,
          eappId,
          noteContent,
          id,
        })
        : onCreate({
          answersSheetId,
          eappId,
          noteContent,
        })),
    }),
  ),
)(
  ({
    id,
    open,
    onCancel,
    onSubmit,
    doSubmit,
    title,
    message,
    initialValues,
  }) => {
    const {
      t,
    } = useTranslation();

    return (
      <Modal
        title={title || (id ? t('editNote') : t('addNote'))}
        visible={open}
        // confirmLoading={isConfirmLoading}
        onOk={doSubmit}
        onCancel={onCancel}
      >
        <Stack>
          {message}
          <EditNoteForm
            onSubmit={onSubmit}
            initialValues={initialValues}
          />
        </Stack>
      </Modal>
    );
  },
);

EditNote.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.node,
  answersSheetId: PropTypes.string,
  eappId: PropTypes.string,
  id: PropTypes.string,
  noteContent: PropTypes.string,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

EditNote.defaultProps = {
  id: null,
  title: null,
  message: null,
  answersSheetId: null,
  eappId: null,
  noteContent: '',
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};

export default EditNote;
