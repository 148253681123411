import {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  apiZedocProjectVariables,
} from '../common/api/zedoc';
import VariableSelect from '../common/selectors/Variable';

const constant = x => () => x;

const useProjectVariables = (projectId) => {
  const {
    ready: variablesReady,
  } = useDDPSubscription(
    projectId &&
      apiZedocProjectVariables.withParams({
        projectId,
      }),
  );

  const empty = useMemo(() => [], []);

  const variables = useSelector(
    useMemo(() => {
      if (!projectId) {
        return constant([]);
      }
      return VariableSelect.all()
        .satisfying((variable) => {
          return !!(
            variable &&
            variable[`_project_${projectId}`] &&
            variable[`_project_${projectId}`].index >= 0
          );
        })
        .sort({
          [`_project_${projectId}.index`]: 1,
        });
    }, [
      projectId,
    ]),
  );

  if (!variablesReady) {
    return empty;
  }

  return variables;
};

export default useProjectVariables;
