import some from 'lodash/some';
import Schema from '../../utils/Schema';
import {
  AUDIT_EVENT_TYPE__LOGIN,
  AUDIT_EVENT_TYPE__LOGOUT,
  AUDIT_EVENT_TYPE__FINALIZE,
  AUDIT_EVENT_TYPE__AMEND,
  AUDIT_EVENT_TYPE__MANUAL_DISCHARGE,
  AUDIT_EVENT_TYPE__MANUAL_REVERT_DISCHARGE,
  AUDIT_EVENT_TYPE__GENERIC_ACTION,
  AUDIT_EVENT_TYPE__FORM_SAVE,
  AUDIT_EVENT_TYPE__FORM_DISCARD,
  AUDIT_EVENT_TYPE__FORM_SUBSCRIBE,
  AUDIT_EVENT_TYPE__FORM_FETCH,
  AUDIT_EVENT_TYPE__SEARCH,
  AUDIT_EVENT_TYPE__REVIEW,
} from '../../constants';
import {
  ACTION_RESULTS,
  ACTION_TYPES,
  ACTION_TYPE__LOGIN,
  ACTION_TYPE__LOGOUT,
  ACTION_TYPE__FINALIZE,
  ACTION_TYPE__AMEND,
  ACTION_TYPE__MANUAL_DISCHARGE,
  ACTION_TYPE__MANUAL_REVERT_DISCHARGE,
  ACTION_TYPE__FORM_SAVE,
  ACTION_TYPE__FORM_DISCARD,
  ACTION_TYPE__FORM_SUBSCRIBE,
  ACTION_TYPE__FORM_FETCH,
  ACTION_TYPE__SEARCH,
  ACTION_TYPE__REVIEW,
  MESSAGE_TYPE__ACTION,
} from '../constants';
import Message from '../Message';

class MessageAction extends Message {
  constructor(doc) {
    super({
      ...doc,
      type: MESSAGE_TYPE__ACTION,
    });
  }

  isOfType(actionType) {
    if (typeof actionType === 'string') {
      return this.actionType === actionType;
    }
    if (Array.isArray(actionType)) {
      return some(actionType, type => type === this.actionType);
    }
    return false;
  }

  isModel(Model) {
    return this.model === Model.name;
  }

  getDocId() {
    return this.docId;
  }

  getAuditLogType() {
    switch (this.actionType) {
      case ACTION_TYPE__LOGIN:
        return AUDIT_EVENT_TYPE__LOGIN;
      case ACTION_TYPE__LOGOUT:
        return AUDIT_EVENT_TYPE__LOGOUT;
      case ACTION_TYPE__FINALIZE:
        return AUDIT_EVENT_TYPE__FINALIZE;
      case ACTION_TYPE__AMEND:
        return AUDIT_EVENT_TYPE__AMEND;
      case ACTION_TYPE__MANUAL_DISCHARGE:
        return AUDIT_EVENT_TYPE__MANUAL_DISCHARGE;
      case ACTION_TYPE__MANUAL_REVERT_DISCHARGE:
        return AUDIT_EVENT_TYPE__MANUAL_REVERT_DISCHARGE;
      case ACTION_TYPE__FORM_SAVE:
        return AUDIT_EVENT_TYPE__FORM_SAVE;
      case ACTION_TYPE__FORM_DISCARD:
        return AUDIT_EVENT_TYPE__FORM_DISCARD;
      case ACTION_TYPE__FORM_SUBSCRIBE:
        return AUDIT_EVENT_TYPE__FORM_SUBSCRIBE;
      case ACTION_TYPE__FORM_FETCH:
        return AUDIT_EVENT_TYPE__FORM_FETCH;
      case ACTION_TYPE__SEARCH:
        return AUDIT_EVENT_TYPE__SEARCH;
      case ACTION_TYPE__REVIEW:
        return AUDIT_EVENT_TYPE__REVIEW;
      default:
        return AUDIT_EVENT_TYPE__GENERIC_ACTION;
    }
  }

  toAuditLog(previousAuditLog) {
    const auditLog = super.toAuditLog(previousAuditLog);
    const meta = {
      ...auditLog.meta,
    };
    switch (this.actionType) {
      case ACTION_TYPE__SEARCH:
        if (this.meta) {
          Object.assign(meta, this.meta);
        }
        break;
      case ACTION_TYPE__LOGIN: {
        if (this.meta && this.meta.attempt) {
          meta.attempt = this.meta.attempt;
        }
        break;
      }
      default:
      // ...
    }
    return {
      ...auditLog,
      type: this.getAuditLogType(),
      event: {
        ...auditLog.event,
        code: this.actionType,
        result: this.actionResult,
      },
      // NOTE: If docId is missing, do not create subject field because objectId is always required
      ...(this.docId && {
        subject: {
          objectId: this.docId,
          model: this.model,
        },
      }),
      meta,
    };
  }
}

MessageAction.schema = new Schema({
  type: MESSAGE_TYPE__ACTION,
  actionType: {
    type: String,
    allowedValues: ACTION_TYPES,
  },
  actionResult: {
    type: String,
    optional: true,
    allowedValues: ACTION_RESULTS,
  },
  error: {
    type: Schema.Blackbox,
    optional: true,
  }, // if actionResult is "failure", error should explain the reason
  docId: {
    type: String,
    optional: true,
  },
  doc: {
    type: Schema.Blackbox,
    optional: true,
  },
  model: {
    type: String,
    optional: true,
  },
  relatedDocId: {
    type: String,
    optional: true,
  },
  relatedDoc: {
    type: Schema.Blackbox,
    optional: true,
  },
  relatedModel: {
    type: String,
    optional: true,
  },
  meta: {
    type: Schema.Blackbox,
    optional: true,
  },
  context: {
    type: Schema.Blackbox,
    optional: true,
  },
});

Message.types[MESSAGE_TYPE__ACTION] = MessageAction;

export default MessageAction;
