import {
  BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION,
} from '../../../constants';
import QuestionBehavior from '../QuestionBehavior';
import {
  getQuestionsIdsBetween,
} from '../../QuestionCursor';
import {
  showQuestion,
  hideQuestion,
} from './helpers';

export default class SkipToQuestion extends QuestionBehavior.Action {
  validate(context) {
    const {
      questionnaire,
    } = context;
    return (
      questionnaire.getClosestCollectionQuestionId(this.target) ===
      questionnaire.getClosestCollectionQuestionId(this.settings.questionId)
    );
  }

  doSelf(context) {
    const {
      questionnaire,
    } = context;
    return getQuestionsIdsBetween(
      questionnaire,
      this.target,
      this.settings.questionId,
    ).map(id => ({
      questionId: id,
      transform: hideQuestion,
    }));
  }

  undoSelf(context) {
    const {
      questionnaire,
    } = context;
    return getQuestionsIdsBetween(
      questionnaire,
      this.target,
      this.settings.questionId,
    ).map(id => ({
      questionId: id,
      transform: showQuestion,
    }));
  }
}

QuestionBehavior.actions[
  BEHAVIOR_ACTION_TYPE__SKIP_TO_QUESTION
] = SkipToQuestion;
