import omit from 'lodash/omit';
import {
  getMultiKey,
} from '../selectors';
import {
  setAtKey,
  delAtKey,
} from '../immutable';

export const STORAGE__SET = '@STORAGE/SET';
export const STORAGE__DEL = '@STORAGE/DEL';
export const STORAGE__CLEAN = '@STORAGE/CLEAN';

export const setStorageKey = (key, value, meta) => ({
  type: STORAGE__SET,
  payload: value,
  meta: {
    ...meta,
    key,
  },
});

export const delStorageKey = (key, meta) => ({
  type: STORAGE__DEL,
  meta: {
    ...meta,
    key,
  },
});

export const cleanStorage = () => ({
  type: STORAGE__CLEAN,
});

export const getStorageKey = (...args) => getMultiKey(...args)(state => state.storage);

const empty = {};

export const reducer = (state = {}, action) => {
  switch (action.type) {
    case STORAGE__SET: {
      if (action.meta.flat) {
        return {
          ...state,
          [action.meta.key]: action.payload,
        };
      }
      return setAtKey(state, action.meta.key, action.payload);
    }
    case STORAGE__DEL: {
      if (action.meta.flat) {
        return omit(state, action.meta.key);
      }
      return delAtKey(state, action.meta.key, {
        cascade: true,
      });
    }
    case STORAGE__CLEAN:
      return empty;
    default:
      return state;
  }
};

export const createAsyncStorage = (store, ready = Promise.resolve()) => ({
  get: (key) => {
    const selector = getStorageKey(key);
    return ready.then(() => selector(store.getState()));
  },
  set: (key, value) => ready.then(() => store.dispatch(setStorageKey(key, value, {
    flat: true,
  }))),
  del: key => ready.then(() => store.dispatch(delStorageKey(key, {
    flat: true,
  }))),
});
