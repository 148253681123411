/* eslint-disable react/prop-types */
import {
  FlagOutlined,
  TeamOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import React, {
  useMemo,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withState,
} from 'recompose';
import {
  createSelector,
} from 'reselect';
import {
  ddp,
} from '@theclinician/ddp-connector';
import Project from '../../common/models/Project';
import ProjectMilestoneSelect from '../../common/selectors/ProjectMilestone';
import {
  getUserNames,
} from '../../common/api/aggregations/Projects';
import {
  projectMilestones,
} from '../../common/api/zedoc';
import ProjectIconText from './ProjectIconText';
import Empty from '../../common/components/base/Empty';
import Cluster from '../../common/components/primitives/Cluster';
import branding from '../../utils/branding';

const Content = ({
  items,
  itemKey,
}) => (items && items.length > 0 ? (
  <ul>
    {map(items, (item, i) => (
      <li
        key={i}
        style={{
          listStyle: 'inside',
        }}
      >
        {itemKey ? item[itemKey] : item}
      </li>
    ))}
  </ul>
) : (
  <Empty
    imageStyle={{
      height: 60,
    }}
  />
));

const getNumberOfMilestones = (project) => {
  return (project && project.summary && project.summary.milestones) || 0;
};

const getNumberOfParticipations = (project) => {
  return (project && project.summary && project.summary.participations) || 0;
};

const getNumberOfUsers = (project) => {
  return (project && project.summary && project.summary.users) || 0;
};

const ProjectSummary = compose(
  withState('showMilestoneDetails', 'setShowMilestoneDetails', false),
  withState('showUsersDetails', 'setShowUsersDetails', false),
  withProps(({
    project,
  }) => ({
    projectId: project && project._id,
  })),
  ddp({
    subscriptions: (state, {
      projectId,
      showMilestoneDetails,
    }) => [
      showMilestoneDetails &&
        projectId &&
        projectMilestones.withParams({
          projectId,
        }),
    ],
    queries: (state, {
      projectId,
      showUsersDetails,
    }) => ({
      userNames:
        showUsersDetails &&
        getUserNames.withParams({
          projectId,
        }),
    }),
    selectors: () => ({
      milestones: createSelector(
        ProjectMilestoneSelect.all().forProject(
          (state, {
            projectId,
          }) => projectId,
        ),
        milestones => sortBy(milestones, [
          'daysSinceBaseline',
          'createdAt',
        ]),
      ),
    }),
    renderLoader: null,
  }),
)(
  ({
    project,
    milestones,
    userNames,
    setShowMilestoneDetails,
    setShowUsersDetails,
  }) => {
    const {
      t,
    } = useTranslation();

    const userNamesItems = useMemo(() => values(userNames), [
      userNames,
    ]);

    return (
      <Cluster>
        <ProjectIconText
          title={t('milestone', {
            count: 0,
          })}
          content={(
            <Content
              items={milestones}
              itemKey="name"
            />
)}
          icon={<FlagOutlined />}
          text={getNumberOfMilestones(project)}
          onVisibleChange={visible => setShowMilestoneDetails(visible)}
        />
        <ProjectIconText
          title={t('recipientInProject', {
            count: 0,
            context: branding,
          })}
          content={getNumberOfParticipations(project)}
          icon={<TeamOutlined />}
          text={getNumberOfParticipations(project)}
        />
        <ProjectIconText
          title={t('collaborator', {
            count: 0,
          })}
          content={<Content items={userNamesItems} />}
          icon={<ContactsOutlined />}
          text={getNumberOfUsers(project)}
          onVisibleChange={visible => setShowUsersDetails(visible)}
        />
      </Cluster>
    );
  },
);

ProjectSummary.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
};

export default ProjectSummary;
