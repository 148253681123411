import DashboardCAT from './DashboardCAT';
import {
  PROJECT_DASHBOARD_TYPE__CAT,
  DASHBOARD_PERSPECTIVE__RESPONSES,
} from '../../../../common/constants';
import {
  registerType,
} from '../../dashboards';

registerType(PROJECT_DASHBOARD_TYPE__CAT, {
  component: DashboardCAT,
  perspectives: [
    {
      genericView: false,
      perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
      specializations: [
        DASHBOARD_PERSPECTIVE__RESPONSES,
      ],
    },
  ],
});

export default DashboardCAT;
