import map from 'lodash/map';
import PropTypes from 'prop-types';
import {
  compose,
} from 'recompose';
import {
  createStructuredSelector,
  createSelector,
} from 'reselect';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  connect,
} from 'react-redux';
import {
  reset,
} from 'redux-form';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  addAnswersSheets,
} from '../../../common/api/collections/Recipients';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import AddAnswersSheets from '../../../components/dialogs/AddAnswersSheets';
import {
  getActiveMilestoneId,
  getActivePatientId,
  getActiveActivityId,
  getAddAnswersSheetsDialogVisible,
} from '../selectors';
import {
  closeAddAnswersSheetsDialog,
} from '../actions';

const form = 'screens_projects_dashboard_addAnswersSheets';

const toOption = ({
  version,
  identifier,
  navigationTypes,
}) => ({
  navigationTypes,
  value: identifier,
  label: `${identifier}@${version}`,
});

const Container = compose(
  connect(
    createStructuredSelector({
      form: () => form,
      open: getAddAnswersSheetsDialogVisible,
      params: createStructuredSelector({
        recipientId: getActivePatientId,
        activityId: getActiveActivityId,
      }),
      questionnaireOptions: createSelector(
        ProjectSelect.one().whereIdMatchesProp('projectId'),
        ProjectMilestoneSelect.one().whereIdEquals(getActiveMilestoneId),
        (project, milestone) => {
          if (!milestone || milestone.isCustom()) {
            return map(project && project.questionnaires, toOption);
          }
          return map(
            milestone &&
              project &&
              project.applyQuestionnaireDefaults(milestone.questionnaires),
            toOption,
          );
        },
      ),
    }),
    dispatch => ({
      onSubmit: data => dispatch(
        callMethod(addAnswersSheets, {
          ...data,
        }),
      )
        .then(notifySuccess('Questionnaire added'))
        .catch(notifyError())
        .then(() => dispatch(closeAddAnswersSheetsDialog())),

      onCancel: () => {
        dispatch(closeAddAnswersSheetsDialog());
        dispatch(reset(form));
      },
    }),
  ),
  // FIXME: Add subscriptions which are really needed here
  ddp({
    renderLoader: null,
  }),
)(AddAnswersSheets);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;
