import {
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/components/Button';
import {
  isValidYearMonthDay,
} from '../../common/utils/date';
import {
  InputGroup,
} from '../../common/components/Input';
import Select, {
  StyledSelect,
} from '../../common/components/Select';
import {
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__MINIMUM,
  FILTER_CONDITION__EXCLUSIVE_MINIMUM,
  FILTER_CONDITION__MAXIMUM,
  FILTER_CONDITION__EXCLUSIVE_MAXIMUM,
  FILTER_CONDITION__EMPTY,
  FILTER_CONDITION__NON_EMPTY,
} from '../../common/constants';
import FilterInput from './FilterInput';

const SelectOption = Select.Option;

const conditionNeedsValue = (condition) => {
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
    case FILTER_CONDITION__INCLUDE:
    case FILTER_CONDITION__EXCLUDE:
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      return true;
    default:
      return false;
  }
};

const Filter = ({
  id,
  name,
  type,
  condition,
  meta,
  state,
  settings,
  active,
  onStartEdit,
  onDelete,
  onChange,
  onSubmit,
  optionsSelector,
  optionsSubscription,
}) => {
  let value;
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
      value = state && state.text;
      break;
    case FILTER_CONDITION__INCLUDE:
      value = state && state.include;
      break;
    case FILTER_CONDITION__EXCLUDE:
      value = state && state.exclude;
      break;
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      value = state && state.threshold;
      break;
    default:
    // ...
  }
  let display;
  switch (condition) {
    case FILTER_CONDITION__TEXT:
    case FILTER_CONDITION__SEARCH_TERMS:
      display = value;
      break;
    case FILTER_CONDITION__DATE_EQUALS:
    case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
    case FILTER_CONDITION__DATE_BEFORE:
    case FILTER_CONDITION__DATE_SAME_OR_AFTER:
    case FILTER_CONDITION__DATE_AFTER:
      display = value ? new Date(value).toLocaleDateString() : 'N/A';
      break;
    case FILTER_CONDITION__MINIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
    case FILTER_CONDITION__MAXIMUM:
    case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
      display = value && value.toString();
      break;
    case FILTER_CONDITION__EXCLUDE:
    case FILTER_CONDITION__INCLUDE:
      display = map(value, (code) => {
        if (meta && meta.labels) {
          return meta.labels[code] || code;
        }
        return code;
      }).join(', ');
      break;
    default:
    // ...
  }
  const handleOnChange = useCallback(
    (
      newValue,
      {
        newCondition = condition,
        newLabels = meta && meta.labels,
      } = {},
    ) => {
      switch (newCondition) {
        case FILTER_CONDITION__TEXT:
        case FILTER_CONDITION__SEARCH_TERMS:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              text: typeof newValue === 'string' ? newValue : '',
            },
          });
          break;
        case FILTER_CONDITION__MINIMUM:
        case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
        case FILTER_CONDITION__MAXIMUM:
        case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              threshold: typeof newValue === 'number' ? newValue : null,
            },
          });
          break;
        case FILTER_CONDITION__DATE_EQUALS:
        case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
        case FILTER_CONDITION__DATE_BEFORE:
        case FILTER_CONDITION__DATE_SAME_OR_AFTER:
        case FILTER_CONDITION__DATE_AFTER:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              threshold: isValidYearMonthDay(newValue) ? newValue : null,
            },
          });
          break;
        case FILTER_CONDITION__INCLUDE:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              include: isArray(newValue) ? newValue : [],
            },
            meta: {
              ...meta,
              labels: newLabels,
            },
          });
          break;
        case FILTER_CONDITION__EXCLUDE:
          onChange(id, {
            condition: newCondition,
            state: {
              ...state,
              exclude: isArray(newValue) ? newValue : [],
            },
            meta: {
              ...meta,
              labels: newLabels,
            },
          });
          break;
        default: {
          if (newCondition !== condition) {
            onChange(id, {
              condition: newCondition,
            });
          }
        }
      }
    },
    [
      id,
      state,
      meta,
      condition,
      onChange,
    ],
  );
  const handleOnStartEdit = useCallback(() => onStartEdit(id), [
    id,
    onStartEdit,
  ]);
  const handleOnDelete = useCallback(() => onDelete(id), [
    id,
    onDelete,
  ]);
  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (onSubmit) {
        onSubmit();
      }
    },
    [
      onSubmit,
    ],
  );
  const handleOnSelectCondition = useCallback(
    (newCondition) => {
      handleOnChange(value, {
        newCondition,
      });
      if ((!active || !conditionNeedsValue(newCondition)) && onSubmit) {
        onSubmit();
      }
    },
    [
      value,
      handleOnChange,
      active,
      onSubmit,
    ],
  );
  return (
    <form onSubmit={handleOnSubmit}>
      <InputGroup>
        <StyledSelect
          data-testid="filters-filter-select-condition"
          value={condition}
          onSelect={handleOnSelectCondition}
          showArrow={false}
          compact
        >
          {map(meta && meta.conditions, (anotherCondition) => {
            let operator;
            switch (anotherCondition) {
              case FILTER_CONDITION__INCLUDE:
              case FILTER_CONDITION__DATE_EQUALS:
                operator = '=';
                break;
              case FILTER_CONDITION__EXCLUDE:
                operator = '\u2260';
                break;
              case FILTER_CONDITION__TEXT:
                operator = '^=';
                break;
              case FILTER_CONDITION__DATE_SAME_OR_AFTER:
              case FILTER_CONDITION__MINIMUM:
                operator = '\u2265';
                break;
              case FILTER_CONDITION__DATE_AFTER:
              case FILTER_CONDITION__EXCLUSIVE_MINIMUM:
                operator = '>';
                break;
              case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
              case FILTER_CONDITION__MAXIMUM:
                operator = '\u2264';
                break;
              case FILTER_CONDITION__DATE_BEFORE:
              case FILTER_CONDITION__EXCLUSIVE_MAXIMUM:
                operator = '<';
                break;
              case FILTER_CONDITION__SEARCH_TERMS:
                operator = '\u2248';
                break;
              case FILTER_CONDITION__EXISTS:
              case FILTER_CONDITION__NON_EMPTY:
                operator = '\u2203';
                break;
              case FILTER_CONDITION__DOES_NOT_EXIST:
              case FILTER_CONDITION__EMPTY:
                operator = '\u2204';
                break;
              default:
                operator = ':';
            }
            return (
              <SelectOption key={anotherCondition}>
                {name}
                &nbsp;
                {operator}
              </SelectOption>
            );
          })}
        </StyledSelect>
        {active && (
          <FilterInput
            optionsSelector={optionsSelector}
            optionsSubscription={optionsSubscription}
            type={type}
            condition={condition}
            state={state}
            meta={meta}
            settings={settings}
            onChange={handleOnChange}
          />
        )}
        {!active && conditionNeedsValue(condition) && (
          <Button
            data-testid="filters-filter-start-edit"
            onClick={handleOnStartEdit}
          >
            {display}
          </Button>
        )}
        {!active && (
          <Button
            onClick={handleOnDelete}
            icon={<DeleteOutlined />}
            data-testid="filters-filter-delete"
          />
        )}
        {active && (
          <Button
            data-testid="filters-filter-submit"
            type="primary"
            htmlType="submit"
            icon={<SearchOutlined />}
          />
        )}
      </InputGroup>
    </form>
  );
};

Filter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  condition: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.objectOf(PropTypes.string),
  }),
  state: PropTypes.objectOf(PropTypes.any),
  settings: PropTypes.objectOf(PropTypes.any),
  active: PropTypes.bool,
  onStartEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
};

Filter.defaultProps = {
  meta: null,
  state: null,
  settings: null,
  active: false,
  optionsSubscription: null,
  optionsSelector: null,
};

export default Filter;
