import Chart from '../Chart';
import {
  CHART_TYPE__UNKNOWN,
} from '../../../constants';

class ChartUnknown extends Chart {
  constructor(doc) {
    super(doc);
    this.type = this.type || CHART_TYPE__UNKNOWN;
  }
}

Chart.types[CHART_TYPE__UNKNOWN] = ChartUnknown;

export default ChartUnknown;
