import moment from 'moment';
import map from 'lodash/map';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import React, {
  useMemo,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import FormFieldDate from '../../../common/components/FormFieldDate';
import Switcher from '../../../common/components/primitives/Switcher';
import Spacer from '../../../common/components/primitives/Spacer';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import {
  PROJECT_MILESTONE_TYPE__SCHEDULED,
} from '../../../common/constants';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldContext from './FormFieldContext';

const getOption = ({
  _id,
  name,
}) => ({
  value: _id,
  label: name,
});

const emptyObject = {};

const FormFieldRelativeDate = React.forwardRef(
  ({
    expiredMilestoneWarning,
    ...otherProps
  }, forwardedRef) => {
    const [
      milestoneId,
      setMilestoneId,
    ] = useState();
    const {
      t,
    } = useTranslation();

    const {
      trackId,
      projectId,
    } = useContext(FormFieldContext);

    const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

    const allMilestones = useSelector(
      ProjectMilestoneSelect.all()
        .where({
          projectId,
          type: PROJECT_MILESTONE_TYPE__SCHEDULED,
        })
        .sort({
          index: 1,
        }),
    );

    const milestones = useMemo(() => {
      if (!trackId) {
        return allMilestones;
      }
      return filter(allMilestones, (ms) => {
        return !ms.selectedTracksOnly || includes(ms.selectedTracks, trackId);
      });
    }, [
      allMilestones,
      trackId,
    ]);

    const milestoneOptions = useMemo(() => {
      return map(milestones, getOption);
    }, [
      milestones,
    ]);

    let milestone = useSelector(
      ProjectMilestoneSelect.one().whereIdEquals(milestoneId),
    );

    if (!milestone) {
      milestone = find(milestones, ms => !ms.daysSinceBaseline);
    }

    const schedule = milestone
      ? milestone.getBaselineSchedule(otherProps.input.value)
      : {};

    const isExpired = !!(
      project &&
      schedule.dateEnd &&
      project.getCurrentYearMonthDay() > schedule.dateEnd
    );
    const milestoneDescription = [
      schedule.dateStart
        ? `${t('forms:dateStart.label')}: ${moment(schedule.dateStart).format(
          'DD/MM/YYYY',
        )}`
        : `(${t('forms:dateStart.none')})`,
      '-',
      schedule.dateEnd
        ? `${t('forms:dateEnd.label')}: ${moment(schedule.dateEnd).format(
          'DD/MM/YYYY',
        )}`
        : `(${t('forms:dateEnd.none')})`,
    ].join(' ');

    let plusDays;
    if (milestone && milestone.daysSinceBaseline) {
      plusDays = -milestone.daysSinceBaseline;
    }

    return (
      <Stack space={1}>
        <Switcher>
          <FormFieldDate
            ref={forwardedRef}
            plusDays={plusDays}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
          <FormFieldSelect
            data-testid={`${otherProps['data-testid']}/milestone`}
            label=" "
            disabled={otherProps.disabled}
            input={{
              value: milestone && milestone._id,
              onChange: setMilestoneId,
            }}
            meta={emptyObject}
            options={milestoneOptions}
            showSearch
          />
        </Switcher>
        <Spacer space={2}>
          <Text.Paragraph
            size="small"
            importance="low"
          >
            {milestoneDescription}
          </Text.Paragraph>
        </Spacer>
        {isExpired && expiredMilestoneWarning && (
          <Spacer space={2}>
            <Text.Paragraph
              size="small"
              type="danger"
              importance="high"
            >
              {expiredMilestoneWarning}
            </Text.Paragraph>
          </Spacer>
        )}
      </Stack>
    );
  },
);

FormFieldRelativeDate.propTypes = {
  trackId: PropTypes.string,
  projectId: PropTypes.string,
  expiredMilestoneWarning: PropTypes.string,
};

FormFieldRelativeDate.defaultProps = {
  trackId: null,
  projectId: null,
  expiredMilestoneWarning: null,
};

export default FormFieldRelativeDate;
