import {
  registerStore,
} from '../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.screens.PatientCard',
  initialState: {
    dialogs: {},
  },
});

export const getProjectProfileDialog = store.get('dialogs.projectProfile');

export const openProjectProfileDialog = (options = {}) => {
  return store.set('dialogs.projectProfile', options);
};

export const closeProjectProfileDialog = () => {
  return store.del('dialogs.projectProfile');
};

export const getEditPatientDialog = store.get('dialogs.editPatient');

export const openEditPatientDialog = (options = {}) => {
  return store.set('dialogs.editPatient', options);
};

export const closeEditPatientDialog = () => {
  return store.del('dialogs.editPatient');
};

export default store;
