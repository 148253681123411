import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import Cluster from '../../common/components/primitives/Cluster';
import Stack from '../../common/components/primitives/Stack';
import Text from '../../common/components/base/Text';
import Icon from '../../common/components/Icon';
import Avatar from '../../common/components/Avatar';
import Loading from '../../common/components/Loading';
import {
  theme,
} from '../../common/utilsClient/cssHelpers';

const getSize = (settings) => {
  if (!settings) {
    return null;
  }

  if (settings.legend && settings.legend.position === 'bottom') {
    return 'grid-row: span 2';
  }

  if (settings.rotate) {
    return 'grid-row: span 2';
  }

  return null;
};

const getIcon = (chartType) => {
  switch (chartType) {
    default:
      return 'area-chart';
  }
};

const Component = styled.div`
  position: relative;
  background-color: ${theme('color.surface')};
  padding: ${theme('space.3')};
  display: flex;
  flex-direction: column;

  ${props => props.size};
`;

const ChartCard = ({
  // isExpanded,
  // toggleIsExpanded,
  children,
  // TODO: Should we bring queriesReady back?
  // queriesReady,
  type,
  title,
  description,
  // eslint-disable-next-line react/prop-types
  settings,
  // TODO: Should it be spinner or skeleton? Currently it's a spinner.
  loading,
}) => {
  return (
    <Component size={getSize(settings, type)}>
      <Cluster
        justify="space-between"
        align="flex-start"
      >
        <Cluster space={2}>
          <Avatar>
            <Icon type={getIcon(type)} />
          </Avatar>
          <Stack space={1}>
            <Text.Paragraph importance="high">{title}</Text.Paragraph>
            {description}
          </Stack>
        </Cluster>
      </Cluster>
      {loading ? <Loading /> : children}
    </Component>
  );
};

ChartCard.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  // TODO: Make it string
  description: PropTypes.node,
  loading: PropTypes.bool,
};

ChartCard.defaultProps = {
  type: null,
  title: null,
  description: null,
  loading: false,
};

export default ChartCard;
