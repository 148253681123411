import React, {
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createSelector,
} from 'reselect';
import {
  setDisplayName,
} from 'recompose';
import {
  compose,
} from 'redux';
import {
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import ProjectSelect from '../../common/selectors/Project';
import ProjectTabs from './components/ProjectTabs';
import ActivityDialog from './components/ActivityDialog';
import NotesModal from './components/NotesModal';
import EditNote from './components/EditNote';
import ReassignAnswersSheet from './components/ReassignAnswersSheet';
// import ClearAnswersSheet from './components/ClearAnswersSheet';
import EditMilestone from './components/EditMilestone';
import AddAnswersSheets from './components/AddAnswersSheets';
import RemoveAnswersSheet from './components/RemoveAnswersSheet';
import RemoveMilestone from './components/RemoveMilestone';
import PreviewPatientResponse from './components/PreviewPatientResponse';
// import AddToProject from '../../components/dialogs/AddToProject';
import ConnectedProjectProfileDialog from './components/ConnectedProjectProfileDialog';
import ConnectedEditActivityDialog from './components/ConnectedEditActivityDialog';
import ConnectedEditPatientDialog from './components/ConnectedEditPatientDialog';
import {
  forProject,
} from '../../common/api/collections/ProjectDashboards';
import {
  property,
} from '../../common/utilsClient/selectors';
import {
  replaceTabUrl,
  createTabIfMissing,
} from './actions';
import {
  setCurrentMode,
  setCurrentProjectId,
} from '../../store/ui/search/actions';
import {
  selectCurrentViewParams,
  selectCurrentViewParamsFromUrl,
} from './selectors';
import {
  apiZedocOneProject,
} from '../../common/api/zedoc';
import useDocumentTitle from '../../utils/useDocumentTitle';

const selectProjectId = property('match.params.projectId');

const DecoratedDashboard = compose(
  ddp({
    subscriptions: createSelector(selectProjectId, projectId => [
      projectId &&
        apiZedocOneProject.withParams({
          projectId,
        }),
      projectId &&
        forProject.withParams({
          projectId,
        }),
    ]),
    selectors: () => ({
      project: ProjectSelect.one().whereIdEquals(selectProjectId),
      projectId: selectProjectId,
      currentViewParamsFromUrl: selectCurrentViewParamsFromUrl,
      currentViewParams: selectCurrentViewParams,
    }),
  }),
  // withHandlers({
  //   handleOnAdded: ({
  //     dispatch,
  //   }) => ({
  //     recipientId,
  //     milestoneId,
  //     commenceSurveyNow,
  //   }) => {
  //     if (commenceSurveyNow) {
  //       dispatch(
  //         openMilestoneDialog({
  //           recipientId,
  //           milestoneId,
  //         }),
  //       );
  //     }
  //   },
  // }),
  setDisplayName('Dashboard'),
)(
  ({
    project,
    projectId,
    currentViewParams,
    currentViewParamsFromUrl,
    subscriptionsReady,
  }) => {
    const {
      t,
    } = useTranslation();
    useDocumentTitle([
      t('project', {
        count: 0,
      }),
      project && project.getName(),
    ]);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(setCurrentMode('dashboard'));
    }, [
      dispatch,
    ]);

    useEffect(() => {
      dispatch(setCurrentProjectId(projectId));
    }, [
      dispatch,
      projectId,
    ]);

    useEffect(() => {
      if (subscriptionsReady) {
        dispatch(createTabIfMissing(currentViewParams));
      }
    }, [
      dispatch,
      currentViewParams,
      subscriptionsReady,
    ]);

    useEffect(() => {
      if (
        !every(
          currentViewParamsFromUrl,
          (value, key) => !isEqual(value, currentViewParams[key]),
        )
      ) {
        dispatch(replaceTabUrl(currentViewParams));
      }
    }, [
      dispatch,
      currentViewParamsFromUrl,
      currentViewParams,
    ]);
    return (
      <>
        <EditMilestone projectId={projectId} />
        <ActivityDialog projectId={projectId} />
        <NotesModal projectId={projectId} />
        <EditNote projectId={projectId} />
        <ReassignAnswersSheet projectId={projectId} />
        {/* <ClearAnswersSheet projectId={projectId} /> */}
        <AddAnswersSheets projectId={projectId} />
        <RemoveAnswersSheet projectId={projectId} />
        <RemoveMilestone projectId={projectId} />
        <PreviewPatientResponse />
        <ConnectedEditActivityDialog projectId={projectId} />
        <ConnectedProjectProfileDialog projectId={projectId} />
        <ConnectedEditPatientDialog />
        <ProjectTabs
          projectId={projectId}
          title={t('project', {
            count: 0,
          })}
          subTitle={project && project.getName()}
          description={project && project.getProjectDescription()}
        />
      </>
    );
  },
);

DecoratedDashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default DecoratedDashboard;
