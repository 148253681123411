import styled from 'styled-components';
import React from 'react';
import {
  propTypes,
  defaultProps,
} from './Text';
import {
  absoluteStyles,
  colorStyles,
} from './styles';

const Paragraph = styled.p`
  ${colorStyles};
  ${absoluteStyles};
`;

const TextParagraph = ({
  ...props
}) => <Paragraph {...props} />;

TextParagraph.propTypes = propTypes;
TextParagraph.defaultProps = defaultProps;

export default React.memo(TextParagraph);
