import forEach from 'lodash/forEach';
import copyToClipboard from '../common/utilsClient/copyToClipboard';

const copyResponsesToClipboard = (meta, responses) => {
  const results = [];

  forEach(meta, ({
    label,
    value,
  }) => {
    if (value) {
      results.push(`${label}: ${value} \n`);
    }
  });

  results.push('\nRESULTS:\n');

  forEach(responses, ({
    title,
    description,
  }, i) => {
    results.push(`
Q${i + 1}. ${title}
ANS: ${description}
`);
  });

  copyToClipboard(results.join(''));
};

export default copyResponsesToClipboard;
