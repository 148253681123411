import styled from 'styled-components/macro';
import React from 'react';
import Alert from 'antd/es/alert';
import 'antd/es/alert/style/css';
import {
  theme,
} from '../utilsClient/cssHelpers';

const StyledAlert = styled(Alert)`
  font-size: ${theme('font.size.small')};

  .ant-alert-icon {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Component = ({
  ...props
}) => <StyledAlert {...props} />;

export default Component;
