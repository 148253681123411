import {
  registerStore,
} from '../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.screens.SettingsReports',
  initialState: {
    reports: [],
  },
});

export default store;
