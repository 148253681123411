export default class Matomo {
  constructor(options) {
    this.options = options || {};

    if (!this.options.url || !this.options.siteId) {
      this.disabled = true;
    }

    this.init();
  }

  init() {
    if (this.disabled) {
      return;
    }

    const {
      options,
    } = this;
    let {
      url,
    } = options;

    if (url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1) {
      url = `${url}/`;
    } else {
      url =
        document.location.protocol === 'https:'
          ? `https://${url}/`
          : `http://${url}/`;
    }

    window._paq = window._paq || [];

    this.push([
      'setSiteId',
      options.siteId,
    ]);
    this.push([
      'setTrackerUrl',
      `${`${url}matomo.php`}`,
    ]);
    this.push([
      'trackPageView',
    ]);
    this.push([
      'enableLinkTracking',
    ]);

    // Fetch tracker script

    const scriptElement = document.createElement('script');
    const refElement = document.getElementsByTagName('script')[0];

    scriptElement.type = 'text/javascript';
    scriptElement.defer = true;
    scriptElement.async = true;
    scriptElement.src = `${`${url}matomo.js`}`;

    refElement.parentNode.insertBefore(scriptElement, refElement);
  }

  push(args) {
    if (this.disabled) {
      return;
    }

    window._paq.push(args);
  }

  connectToHistory(history) {
    if (this.disabled) {
      return history;
    }

    const prevLoc =
      typeof history.getCurrentLocation === 'undefined'
        ? history.location
        : history.getCurrentLocation();

    this.previousPath =
      prevLoc.path || (prevLoc.pathname + prevLoc.search).replace(/^\//, '');

    history.listen((location) => {
      this.track(location);
    });

    return history;
  }

  track(location) {
    const basePath = window.location.origin;
    const currentPath =
      location.path || (location.pathname + location.search).replace(/^\//, '');

    if (this.previousPath === currentPath) {
      return;
    }

    this.push([
      'setCustomUrl',
      `${basePath}/${currentPath}`,
    ]);
    this.push([
      'setDocumentTitle',
      document.title,
    ]);
    this.push([
      'trackPageView',
    ]);

    this.previousPath = currentPath;
  }

  setUser(userId) {
    if (!userId) {
      return;
    }

    this.push([
      'setUserId',
      userId,
    ]);
    this.push([
      'trackPageView',
    ]);
  }

  resetUser() {
    this.push([
      'resetUserId',
    ]);
    // this.push([
    //   'appendToTrackingUrl',
    //   'new_visit=1',
    // ]);
    this.push([
      'trackPageView',
    ]);
    // this.push([
    //   'appendToTrackingUrl',
    //   '',
    // ]);
  }

  trackEvent(category, action, name, value) {
    this.push([
      'trackEvent',
      category,
      action,
      name,
      value,
    ]);
  }
}
