/* eslint-disable react/prop-types */
import styled from 'styled-components/macro';
import React from 'react';
import Grid from '../primitives/Grid';
import Cluster from '../primitives/Cluster';
import {
  theme,
  media,
} from '../../utilsClient/cssHelpers';

const Component = styled.div`
  flex: 1;
  position: relative;
  z-index: 1;

  ${media.lessThan('tablet')`
    min-width: ${theme('measure.5')};
  `};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: hsla(${theme('color.primaryBase')}, 0.5);
    z-index: -1;
  }
`;

const ComponentBefore = styled.div`
  align-self: flex-start;
`;

const ComponentAfter = styled.div`
  align-self: flex-end;
`;

const StyledGrid = styled(Grid)`
  flex: 1;
`;

const Timeline = ({
  beforeNode,
  items,
  afterNode,
  'data-test': test,
  'data-testid': testId,
}) => {
  return (
    <Component
      data-test={test}
      data-testid={testId}
    >
      <Cluster
        overflow="initial"
        space={0}
      >
        {beforeNode && <ComponentBefore>{beforeNode}</ComponentBefore>}
        <StyledGrid
          min={0}
          space={5}
        >
          {items.map(item => item)}
        </StyledGrid>
        {afterNode && <ComponentAfter>{afterNode}</ComponentAfter>}
      </Cluster>
    </Component>
  );
};

export default Timeline;
