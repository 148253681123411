import React from 'react';
import PropTypes from 'prop-types';
import FormItem from '../FormItem';
import {
  formFieldPropTypesMeta,
} from '../../utilsClient/propTypes';

const getHelpText = (touched, submitFailed, error, formulaError) => {
  if ((touched || submitFailed) && error) {
    return error;
  }
  if (formulaError) {
    return `[#ERR] ${formulaError.message}`;
  }
  return null;
};

const getValidateStatus = (
  touched,
  submitFailed,
  error,
  formulaError,
  required,
) => {
  if ((touched || submitFailed) && error) {
    return 'danger';
  }
  if (!error && required) {
    return 'success';
  }
  if (formulaError) {
    return 'warning';
  }
  return 'default';
};

const FormFieldWrapper = ({
  'data-testid': testId,
  htmlFor,
  label,
  required,
  meta: {
    touched,
    error,
    formulaError,
    submitFailed,
  },
  extra,
  tooltip,
  colon,
  size,
  children,
  isFullHeight,
}) => {
  return (
    <FormItem
      data-testid={testId}
      htmlFor={htmlFor}
      label={label}
      required={required}
      validateStatus={getValidateStatus(
        touched,
        submitFailed,
        error,
        formulaError,
        required,
      )}
      help={getHelpText(touched, submitFailed, error, formulaError)}
      extra={extra}
      tooltip={tooltip}
      colon={colon}
      size={size}
      isFullHeight={isFullHeight}
    >
      {children}
    </FormItem>
  );
};

FormFieldWrapper.propTypes = {
  'data-testid': PropTypes.string,
  meta: formFieldPropTypesMeta,
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  required: PropTypes.bool,
  children: PropTypes.node,
  extra: PropTypes.node,
  tooltip: PropTypes.string,
  colon: PropTypes.bool,
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  isFullHeight: PropTypes.bool,
};

FormFieldWrapper.defaultProps = {
  'data-testid': 'form-field-wrapper',
  meta: {},
  htmlFor: null,
  label: null,
  required: false,
  children: null,
  extra: null,
  tooltip: null,
  colon: false,
  size: 'default',
  isFullHeight: false,
};

export default FormFieldWrapper;
