import map from 'lodash/map';
import PropTypes from 'prop-types';
import {
  compose,
} from 'recompose';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  connect,
} from 'react-redux';
import {
  reset,
} from 'redux-form';
import ProjectSelect from '../../../common/selectors/Project';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  createMilestone,
  updateMilestone,
} from '../../../common/api/collections/Projects';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import EditMilestone from '../../../components/dialogs/EditMilestone';
import {
  getActiveMilestoneId,
  getEditMilestoneDialogVisible,
} from '../selectors';
import {
  closeEditMilestoneDialog,
} from '../actions';

const form = 'screens_projects_dashboard_editMilestone';
const Container = compose(
  connect(
    createStructuredSelector({
      form: () => form,
      open: getEditMilestoneDialogVisible,
      milestone: ProjectMilestoneSelect.one().whereIdEquals(
        getActiveMilestoneId,
      ),
      questionnaireOptions: createSelector(
        ProjectSelect.one().whereIdMatchesProp('projectId'),
        project => map(project && project.questionnaires, ({
          version,
          identifier,
        }) => ({
          value: identifier,
          label: `${identifier}@${version}`,
        })),
      ),
    }),
    (dispatch, {
      projectId,
    }) => ({
      onCreate: data => dispatch(
        callMethod(createMilestone, {
          ...data,
          projectId,
        }),
      )
        .then(
          notifySuccess('Milestone created', {
            renderOutsideModal: true,
          }),
        )
        .catch(notifyError())
        .then(() => dispatch(closeEditMilestoneDialog())),

      onUpdate: data => dispatch(callMethod(updateMilestone, data))
        .then(
          notifySuccess('Milestone updated', {
            renderOutsideModal: true,
          }),
        )
        .catch(notifyError())
        .then(() => dispatch(closeEditMilestoneDialog())),

      onCancel: () => {
        dispatch(closeEditMilestoneDialog());
        dispatch(reset(form));
      },
    }),
  ),
  // FIXME: Add subscriptions which are really needed.
  ddp({
    renderLoader: null,
  }),
)(EditMilestone);

Container.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default Container;
