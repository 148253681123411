import map from 'lodash/map';
import forEach from 'lodash/forEach';
import Formula from '../Formula';
import {
  FORMULA_TYPE__AVERAGE_NUMERIC_VALUE,
} from '../../../constants';
import Schema from '../../../utils/Schema';

const settingsSchema = new Schema(
  {
    operands: [
      new Schema({
        id: {
          type: String,
        },
      }),
    ],
  },
  {
    additionalProperties: true,
  },
);

class FormulaAverageNumericValues extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    let total = 0;
    let n = 0;
    forEach(this.settings.operands, ({
      id,
    }) => {
      const answers = scope.pickAllAnswers(id);
      forEach(answers, (answer) => {
        if (answer) {
          const result = scope.evaluateAsNumber(answer.value, id);
          if (!result.error) {
            total += result.value;
            n += 1;
          }
        }
      });
    });
    if (n === 0) {
      return {
        error: this.constructor.NoData,
      };
    }
    return {
      value: total / n,
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {},
    };
    if (this.meta && this.meta.sectionId) {
      compiled.settings = {
        ...compiled.settings,
        operands: questionsHierarchy.mapQuestions(
          q => ({
            id: q.id,
          }),
          {
            sectionId: this.meta.sectionId,
          },
        ),
      };
    } else if (this.meta && this.meta.questionIds) {
      compiled.settings = {
        ...compiled.settings,
        operands: map(this.meta.questionIds, questionId => ({
          id: questionId,
        })),
      };
    }
    return compiled;
  }

  toMongoExpression() {
    return {
      $avg: {
        $map: {
          input: '$responses',
          in: this.constructor.createAggregationExpression(
            this.settings.operands,
          ),
        },
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'operands':
          return map(value, operand => ({
            ...operand,
            id: mapQuestionId(operand.id),
          }));
        default:
          return value;
      }
    };
  }

  static createMapMeta(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'sectionId':
          return mapQuestionId(value);
        case 'questionIds':
          return map(value, mapQuestionId);
        default:
          return value;
      }
    };
  }

  static createAggregationExpression(operands) {
    if (!operands || !operands[0]) {
      return null;
    }
    return {
      $cond: {
        if: {
          $eq: [
            '$$this.questionId',
            {
              $literal: operands[0].id,
            },
          ],
        },
        then: '$$this.meta.number',
        else: this.createAggregationExpression(operands.slice(1)),
      },
    };
  }
}

Formula.types[
  FORMULA_TYPE__AVERAGE_NUMERIC_VALUE
] = FormulaAverageNumericValues;

export default FormulaAverageNumericValues;
