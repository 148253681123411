import store from './store';

export const setVisible = store.create.set('isVisible');
export const setConfirmLoading = store.create.set('isConfirmLoading');

export const openEditUserDialog = () => (dispatch) => {
  dispatch(setVisible(true));
};

export const closeEditUserDialog = () => (dispatch) => {
  dispatch(setVisible(false));
};
