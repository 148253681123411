import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';

export const createMask = (value) => {
  if (isPlainObject(value)) {
    return mapValues(value, createMask);
  }
  return true;
};

const isFalsy = x => !x;

export const intersectMasks = (mask1, mask2) => {
  if (isPlainObject(mask1) && isPlainObject(mask2)) {
    const newMask = omitBy(
      mapValues(mask1, (v, k) => intersectMasks(v, mask2 && mask2[k])),
      isFalsy,
    );
    if (isEmpty(newMask)) {
      return null;
    }
    return newMask;
  }
  if (mask1 && isPlainObject(mask2)) {
    return mask2;
  }
  if (mask2 && isPlainObject(mask1)) {
    return mask1;
  }
  return !!(mask1 && mask2) || null;
};
