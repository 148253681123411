import styled from 'styled-components/macro';
import React from 'react';
import {
  withProps,
} from 'recompose';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import Select from '../../inputs/Select';
import {
  formFieldPropTypes,
} from '../../propTypes';
import {
  selectOptionsPropTypes,
} from '../propTypes';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const FormFieldSelect = withProps(({
  mode,
  input,
  onSelect,
}) => ({
  // in case of antd, the component already supports
  // onSelect event, so we don't need to emulate it inside onChange
  onSelect: (value) => {
    if (onSelect) {
      // Our custom hook
      onSelect({
        value,
      });
    }
  },
  onClear: () => {
    input.onChange(null);
  },
  input: {
    ...input,
    value: mode === 'multiple' ? input.value || [] : input.value,
    onChange: (value) => {
      input.onChange(value);
    },
  },
}))(
  ({
    label,
    required,
    meta,
    input: {
      value,
      onChange,
    },
    onClear,
    extra,
    ...rest
  }) => (
    <FormFieldWrapper
      label={label}
      required={required}
      meta={meta}
      extra={extra}
    >
      <StyledSelect
        value={value}
        onChange={onChange}
        onClear={onClear}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
    </FormFieldWrapper>
  ),
);

FormFieldSelect.propTypes = {
  ...formFieldPropTypes,
  options: selectOptionsPropTypes.isRequired,
};

export default FormFieldSelect;
