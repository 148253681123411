/* eslint-disable react/prop-types */
import styled from 'styled-components';
import React from 'react';
import Avatar from '../../common/components/Avatar';
import Popover from '../../common/components/Popover';
import Text from '../../common/components/base/Text';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const StatContainer = styled.div`
  margin-left: 1rem;
`;

const StatHeading = styled(Text.Heading)`
  margin-top: 0.3em;
`;

const ProjectIconText = ({
  title,
  content,
  icon,
  text,
  onVisibleChange,
  kind,
}) => (content && onVisibleChange ? (
  <Popover
    title={title}
    content={content}
    onVisibleChange={onVisibleChange}
  >
    <IconContainer>
      <div>
        <Avatar kind={kind}>{icon}</Avatar>
      </div>
      <StatContainer>
        <StatHeading
          data-testid={`project-text-${title}-number`}
          level={3}
        >
          {text}
        </StatHeading>
        <Text.Span
          size="small"
          importance="low"
          data-testid={`project-text-${title}`}
        >
          {title}
        </Text.Span>
      </StatContainer>
    </IconContainer>
  </Popover>
) : (
  <IconContainer>
    <div>
      <Avatar kind={kind}>{icon}</Avatar>
    </div>
    <StatContainer>
      <StatHeading
        data-testid={`project-text-${title}-number`}
        level={3}
      >
        {text}
      </StatHeading>
      <Text.Span
        size="small"
        importance="low"
        data-testid={`project-text-${title}`}
      >
        {title}
      </Text.Span>
    </StatContainer>
  </IconContainer>
));

export default ProjectIconText;
