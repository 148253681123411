import isArray from 'lodash/isArray';
import indexOf from 'lodash/indexOf';
import QuestionCursorBase, {
  CURSOR_TYPE__PROLOGUE,
  CURSOR_TYPE__EPILOGUE,
} from './QuestionCursorBase';

class QuestionCursorReversed extends QuestionCursorBase {
  firstChildQuestionId() {
    const {
      children,
    } = this;
    if (children.length > 0) {
      return children[children.length - 1].id;
    }
    return undefined;
  }

  firstChildElementId() {
    const value = this.getElementsOrder();
    if (isArray(value) && value.length > 0) {
      return value[value.length - 1];
    }
    return undefined;
  }

  nextChildElementId(elementId) {
    const value = this.getElementsOrder();
    if (isArray(value)) {
      const index = indexOf(value, elementId);
      if (index > 0) {
        return value[index - 1];
      }
    }
    return undefined;
  }

  nextChildQuestionId(questionId) {
    const {
      children,
    } = this;
    const index = this.getQuestionIndex(questionId);
    if (index > 0) {
      return children[index - 1].id;
    }
    return undefined;
  }
}

QuestionCursorReversed.cursorTypeOnSectionEnter = CURSOR_TYPE__EPILOGUE;
QuestionCursorReversed.cursorTypeOnSectionLeave = CURSOR_TYPE__PROLOGUE;

export default QuestionCursorReversed;
