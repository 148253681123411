export default {
  blue: {
    10: 'hsl(210, 90%, 94%)',
    20: 'hsl(210, 90%, 86%)',
    30: 'hsl(210, 90%, 60%)',
    40: 'hsl(210, 90%, 55%)',
    50: 'hsl(210, 90%, 50%)',
    60: 'hsl(210, 90%, 45%)',
    70: 'hsl(210, 90%, 40%)',
    80: 'hsl(210, 90%, 22%)',
    90: 'hsl(210, 90%, 15%)',
    100: 'hsl(210, 90%, 9%)',
  },
  red: {
    30: 'hsl(0, 90%, 60%)',
    40: 'hsl(0, 90%, 55%)',
    50: 'hsl(0, 90%, 50%)',
    70: 'hsl(0, 90%, 40%)',
    60: 'hsl(0, 90%, 45%)',
    80: 'hsl(0, 90%, 35%)',
  },
  green: {
    40: 'hsl(120, 50%, 65%)',
    50: 'hsl(120, 50%, 50%)',
  },
  yellow: {
    40: 'hsl(45, 100%, 65%)',
    50: 'hsl(45, 100%, 50%)',
  },
  gray: {
    10: 'hsl(210, 15%, 96%)',
    20: 'hsl(210, 15%, 88%)',
    30: 'hsl(210, 15%, 78%)',
    40: 'hsl(210, 15%, 66%)',
    50: 'hsl(210, 15%, 55%)',
    60: 'hsl(210, 15%, 44%)',
    70: 'hsl(210, 15%, 32%)',
    80: 'hsl(210, 15%, 22%)',
    90: 'hsl(210, 15%, 15%)',
    100: 'hsl(210, 15%, 9%)',
  },
  white: {
    0: 'hsl(210, 15%, 100%)',
  },
};
