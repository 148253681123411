import {
  reducer as formReducer,
} from 'redux-form';
import {
  ddpReducer,
} from '@theclinician/ddp-connector';
import {
  connectRouter,
} from 'connected-react-router';
import {
  reducer as storageReducer,
} from '../common/utilsClient/redux/storage';
import {
  clockReducer,
} from '../common/utils/clock';
import {
  reducer as questionnaire,
} from '../common/containers/Questionnaire';
import {
  createMultiReducer,
} from '../common/utilsClient/multiReducer';

export const createReducer = history => createMultiReducer({
  useRegisteredStores: true,
  sections: {
    clock: clockReducer,
    form: formReducer,
    ddp: ddpReducer,
    router: connectRouter(history),
    storage: storageReducer,
    questionnaire,
  },
});
