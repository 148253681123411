import moment from 'moment';
import {
  createSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import Project from '../models/Project';
import {
  escapeRegExp,
} from '../utils/text';
import toSelector from '../utils/toSelector';
import {
  USERS_GROUP_TYPE__PROJECT,
} from '../constants';

const constant = x => () => x;

const Select = createSelectors(Project, {
  whereSearchPhraseMatches: getSearchPhrase => select => select.where(
    createSelector(
      createSelector(getSearchPhrase, search => (search ? new RegExp(escapeRegExp(search), 'i') : null)),
      re => project => !re || re.test(project.name),
    ),
  ),
  whereOneOfMembersIs: getUser => select => select.where(
    createSelector(getUser, (user) => {
      if (!user) {
        return constant(false);
      }
      return project => user.isMemberOf(project.getDomains(), USERS_GROUP_TYPE__PROJECT);
    }),
  ),
  toMomentInLocalTime: selectTimestamp => select => select.map(
    createSelector(toSelector(selectTimestamp), timestamp => (project) => {
      if (project) {
        return project.getMomentInLocalTime(timestamp);
      }
      return moment(timestamp);
    }),
  ),
  toTimezone: () => select => select.map(constant(project => project.getTimezone())),
});

export default Select;
