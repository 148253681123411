import reverse from 'lodash/reverse';
import {
  withProps,
} from 'recompose';
import {
  compose,
} from 'redux';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  connect,
} from 'react-redux';
import {
  reset,
  formValueSelector,
} from 'redux-form';
import {
  property,
} from '@theclinician/selectors';
import {
  callMethod,
} from '../../../../common/utilsClient/ddp/actions';
import {
  insertNote,
  updateNote,
  removeNote,
} from '../../../../common/api/collections/Recipients';
import {
  getUserNames,
} from '../../../../common/api/aggregations/Users';
import {
  notifyError,
  notifySuccess,
} from '../../../../utils/notify';
import PatientNotes from './PatientNotes';
import {
  getActivePatientNoteId,
  getEditNoteDialogVisible,
} from '../../../PatientsDirectory/selectors';
import {
  openEditNoteDialog,
  closeEditNoteDialog,
} from '../../../PatientsDirectory/actions';

const form = 'patients_directory_addToProject';
const Container = compose(
  withProps(() => ({
    form,
    fieldSelector: formValueSelector(form),
  })),
  connect(
    createStructuredSelector({
      editing: getEditNoteDialogVisible,
      activeNoteId: getActivePatientNoteId,
      recipientId: property('recipient._id'),
      notes: createSelector(
        property('recipient.signedNotes'),
        reverse,
      ),
    }),
    dispatch => ({
      onCreate: data => dispatch(callMethod(insertNote, data))
        .then(notifySuccess('Note created'))
        .catch(notifyError())
        .then(() => dispatch(closeEditNoteDialog())),

      onUpdate: data => dispatch(callMethod(updateNote, data))
        .then(notifySuccess('Note updated'))
        .catch(notifyError())
        .then(() => dispatch(closeEditNoteDialog())),

      onRemove: data => dispatch(callMethod(removeNote, data))
        .then(notifySuccess('Note removed'))
        .catch(notifyError())
        .then(() => dispatch(closeEditNoteDialog())),

      onCancel: () => {
        dispatch(closeEditNoteDialog(false));
        reset(form);
      },
      onEdit: id => dispatch(openEditNoteDialog(id)),
    }),
  ),
  ddp({
    queries: () => ({
      userNames: getUserNames.withParams(),
    }),
    renderLoader: null,
  }),
)(PatientNotes);

Container.propTypes = {};

Container.defaultProps = {};

export default Container;
