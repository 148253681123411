import {
  registerStore,
} from '../../../common/utilsClient/multiReducer';

const store = registerStore({
  path: 'ui.entry',
  initialState: {
    isLoading: false,
  },
});

export default store;
