import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET,
  PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
} from '../../permissions';

// SUBSCRIPTIONS

export const one = new ApiSpec({
  name: 'api.collections.AnswersSheets.one',
  permissions: [
    PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  ],
  schema: new Schema(String, {
    regEx: Schema.RegEx.Id,
  }),
});

export const amendmentHistory = new ApiSpec({
  name: 'api.collections.AnswersSheets.amendmentHistory',
  permissions: [
    PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      optional: true,
    },
  }),
});

// MUTATIONS/ QUERIES

export const reassignOne = new ApiSpec({
  name: 'api.collections.AnswersSheets.reassignOne',
  permissions: [
    PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    noteContent: {
      type: String,
      optional: true,
    },
  }),
});

export const clearOne = new ApiSpec({
  name: 'api.collections.AnswersSheets.clearOne',
  permissions: [
    PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    noteContent: {
      type: String,
    },
  }),
});

export const insertNote = new ApiSpec({
  name: 'api.collections.AnswersSheets.insertNote',
  permissions: [
    PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    noteContent: {
      type: String,
      optional: true,
    },
    noteType: {
      type: String,
      optional: true,
    },
  }),
});

export const updateNote = new ApiSpec({
  name: 'api.collections.AnswersSheets.updateNote',
  permissions: [
    PATIENT_MILESTONE_ANNOTATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    id: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    noteContent: {
      type: String,
    },
  }),
});

export const snooze = new ApiSpec({
  name: 'api.collections.AnswersSheet.snooze',
  permissions: [
    PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    projectId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    recipientId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    snoozeEnd: {
      type: Date,
    },
  }),
});

export const snoozeOne = new ApiSpec({
  name: 'api.collections.AnswersSheet.snoozeOne',
  permissions: [
    PATIENT_MILESTONE_UPDATE_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
    snoozeEnd: {
      type: Date,
    },
  }),
});

export const generatePdf = new ApiSpec({
  name: 'api.collections.AnswersSheets.generatePdf',
  permissions: [
    PATIENT_MILESTONE_REVIEW_ANSWERS_SHEET,
  ],
  schema: new Schema({
    answersSheetId: {
      type: String,
      regEx: Schema.RegEx.Id,
    },
  }),
});
