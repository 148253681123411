/* eslint-disable react/prop-types */
import React from 'react';
import Box from '../../../common/components/primitives/Box';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';

const Layout = ({
  title,
  subTitle,
  extra,
  children,
}) => {
  return (
    <Box>
      <Stack>
        <Cluster justify="space-between">
          <Stack space={0}>
            <Text.Heading level={1}>{title}</Text.Heading>
            <Text.Heading level={2}>{subTitle}</Text.Heading>
          </Stack>
          {extra}
        </Cluster>
        {children}
      </Stack>
    </Box>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
