import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  patientDetails,
} from '../../../../common/api/zedoc';
import PatientDetails from '../../../../components/patients/PatientDetails';
import RecipientSelect from '../../../../common/selectors/Recipient';
import ParticipationSelect from '../../../../common/selectors/Participation';
import {
  PROJECT_DASHBOARD_TYPE__DETAILS,
  DASHBOARD_PERSPECTIVE__PATIENTS,
} from '../../../../common/constants';
import {
  registerType,
} from '../../dashboards';
import Loading from '../../../../common/components/Loading';
import {
  openEditPatientDialog,
} from '../../actions';

const DashboardDetails = ({
  projectId,
  recipientId,
}) => {
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  const participations = useSelector(
    ParticipationSelect.all().where({
      projectId,
      recipientId,
    }),
  );

  const {
    ready,
  } = useDDPSubscription(
    patientDetails.withParams({
      recipientId,
      projectId,
    }),
  );

  const dispatch = useDispatch();
  const handleOnEdit = useCallback(() => {
    dispatch(
      openEditPatientDialog({
        recipientId,
      }),
    );
  }, [
    recipientId,
    dispatch,
  ]);

  if (!ready) {
    return <Loading />;
  }

  return (
    <PatientDetails
      recipient={recipient}
      participations={participations}
      onEdit={handleOnEdit}
    />
  );
};

DashboardDetails.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
};

registerType(PROJECT_DASHBOARD_TYPE__DETAILS, {
  component: DashboardDetails,
  perspectives: [
    {
      genericView: false,
      perspective: DASHBOARD_PERSPECTIVE__PATIENTS,
      specializations: [
        DASHBOARD_PERSPECTIVE__PATIENTS,
      ],
    },
  ],
});

export default DashboardDetails;
