import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';

const isSubset = (a, b) => {
  if (isEmpty(a)) {
    return true;
  }
  if (isEmpty(b)) {
    return false;
  }
  return intersection(a, b).length === uniq(a).length;
};

export default isSubset;
