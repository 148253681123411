import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React from 'react';
import flatMap from 'lodash/flatMap';
import {
  useDDPSubscription,
  useDDPQuery,
} from '@theclinician/ddp-connector';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
} from 'react-redux';
import {
  getQuestionnaireNames,
} from '../../common/api/aggregations/Projects';
import {
  apiZedocOneProject,
  patientDetails,
} from '../../common/api/zedoc';
import Stack from '../../common/components/primitives/Stack';
import {
  PARTICIPANT_STATE__COMPLETED,
} from '../../common/constants';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_VIEW_PATIENT_RESULTS,
} from '../../common/permissions';
import PatientRecordSelect from '../../common/selectors/PatientRecord';
import ProjectSelect from '../../common/selectors/Project';
import RecipientSelect from '../../common/selectors/Recipient';
import PageBar from '../../components/Layout/PageBar';
import branding from '../../utils/branding';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePermission from '../../utils/usePermission';
import PatientResults from './components/PatientResults';

const PatientProject = ({
  match: {
    params: {
      projectId,
      recipientId,
    },
  },
}) => {
  const {
    t,
  } = useTranslation();

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );
  const {
    value: questionnaireNames,
  } = useDDPQuery(
    projectId &&
      getQuestionnaireNames.withParams({
        projectId,
      }),
  );

  const canSeePII = usePermission([
    PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  ], {
    relativeTo: recipient && recipient.getDomains(),
  });
  const canViewPatientResults = usePermission([
    PATIENT_VIEW_PATIENT_RESULTS,
  ], {
    relativeTo: recipient && recipient.getDomains(),
  });

  useDocumentTitle([
    t('recipient', {
      count: 0,
      context: branding,
    }),
    canSeePII
      ? recipient && recipient.getFullName()
      : recipient && recipient.getTruncatedId(),
    project ? project.getName() : '',
  ]);

  useDDPSubscription(
    projectId &&
      apiZedocOneProject.withParams({
        projectId,
      }),
  );
  useDDPSubscription(
    projectId &&
      recipientId &&
      patientDetails.withParams({
        projectId,
        recipientId,
      }),
  );

  const patientRecords = useSelector(
    PatientRecordSelect.all().where({
      projectId,
      recipientId,
    }),
  );
  const answersSheets = flatMap(patientRecords, (patientRecord) => {
    return filter(patientRecord.questionnaires, {
      state: PARTICIPANT_STATE__COMPLETED,
    });
  });
  const questionnaires = groupBy(answersSheets, 'questionnaireId');
  return canViewPatientResults ? (
    <Stack>
      <PageBar
        title={t('recipient', {
          count: 0,
          context: branding,
        })}
        subTitle={
          canSeePII
            ? recipient && recipient.getFullName()
            : recipient && recipient.getTruncatedId()
        }
        description={project && project.name}
        backUrl={`/patients/${recipientId}`}
      />
      <PatientResults
        projectId={projectId}
        recipientId={recipientId}
        questionnaires={questionnaires}
        questionnaireNames={questionnaireNames}
      />
    </Stack>
  ) : null;
};

PatientProject.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
      recipientId: PropTypes.string,
    }),
  }).isRequired,
};

export default PatientProject;
