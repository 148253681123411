/* eslint-disable react/prop-types */
import map from 'lodash/map';
import find from 'lodash/find';
import React, {
  useRef,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import {
  ImportOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  all as allPermissionsDomains,
} from '../../../common/api/collections/PermissionsDomains';
import {
  apiZedocOneProject,
} from '../../../common/api/zedoc';
import settings from '../../../common/settings';
import ProjectSelect from '../../../common/selectors/Project';
import PermissionsDomainSelect from '../../../common/selectors/PermissionsDomain';
import Stack from '../../../common/components/primitives/Stack';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import Switch from '../../../common/components/Switch';
import Button from '../../../common/components/Button';
import Question from './Question';

const {
  features: {
    enableEApp,
  },
} = settings.public;

const HiddenInput = styled.input`
  display: none;
`;

const ProjectVariablesForm = ({
  projectId,
  variables,
  questionnaire,
  questionsIds,

  shouldUseTemplateBindings,
  setShouldUseTemplateBindings,
  canDisableTemplateBindings,
  shouldUseCSV,
  setShouldUseCSV,
  canDisableCSV,
  shouldUseTracks,
  setShouldUseTracks,
  canDisableTracks,
  shouldUseEAPPRules,
  setShouldUseEAPPRules,
  canDisableEAPPRules,

  handleOpen,
  handleCopy,
}) => {
  const {
    t,
  } = useTranslation();
  const uploadInputRef = useRef();
  const handleFileInputOnChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      const reader = new FileReader();
      reader.onload = (e) => {
        handleOpen(e.target.result);
      };
      reader.readAsText(file);
    },
    [
      handleOpen,
    ],
  );

  // All below logic is to get project domain names to show in UI
  useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );
  useDDPSubscription(allPermissionsDomains.withParams());
  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
  const projectDomainsIds = project?.getDomains();
  const allDomains = useSelector(PermissionsDomainSelect.all());
  const projectDomainsNames = useMemo(() => {
    return map(projectDomainsIds, (projectDomainId) => {
      const currentProjectDomain = find(
        allDomains,
        domain => domain._id === projectDomainId,
      );

      return currentProjectDomain?.name;
    });
  }, [
    projectDomainsIds,
    allDomains,
  ]);

  return (
    <>
      <HiddenInput
        ref={uploadInputRef}
        type="file"
        accept="application/json"
        onChange={handleFileInputOnChange}
      />
      <Stack>
        {/*  */}
        <FormFieldWrapper label={t('forms:belongsTo.label')}>
          {projectDomainsNames.join(', ')}
        </FormFieldWrapper>
        <Question
          projectId={projectId}
          variables={variables}
          questionnaire={questionnaire}
          questionsIds={questionsIds}
        />
        {/* FIXME: Translate those labels */}
        <FormFieldWrapper label="Use Template Bindings">
          <Switch
            data-testid="switch-Use Template Bindings"
            checked={shouldUseTemplateBindings}
            onChange={checked => setShouldUseTemplateBindings(checked)}
            disabled={!canDisableTemplateBindings}
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Use CSV">
          <Switch
            data-testid="switch-Use CSV"
            checked={shouldUseCSV}
            onChange={checked => setShouldUseCSV(checked)}
            disabled={!canDisableCSV}
          />
        </FormFieldWrapper>
        <FormFieldWrapper label="Use Tracks">
          <Switch
            data-testid="switch-Use Tracks"
            checked={shouldUseTracks}
            onChange={checked => setShouldUseTracks(checked)}
            disabled={!canDisableTracks}
          />
        </FormFieldWrapper>
        {enableEApp && (
          <FormFieldWrapper label="Use EAPP Rules">
            <Switch
              data-testid="switch-Use EAPP Rules"
              checked={shouldUseEAPPRules}
              onChange={checked => setShouldUseEAPPRules(checked)}
              disabled={!canDisableEAPPRules}
            />
          </FormFieldWrapper>
        )}
        <Button
          data-testid="button-Import"
          onClick={() => {
            if (uploadInputRef.current) {
              uploadInputRef.current.click();
            }
          }}
        >
          <ImportOutlined />
          {' '}
          Import
        </Button>
        &nbsp;&nbsp;
        <Button
          data-testid="button-Export"
          onClick={handleCopy}
        >
          <ExportOutlined />
          {' '}
          Export
        </Button>
      </Stack>
    </>
  );
};

export default ProjectVariablesForm;
