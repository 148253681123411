import PropTypes from 'prop-types';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createSelector,
} from 'reselect';
import {
  compose,
  withHandlers,
} from 'recompose';
import RecipientSelect from '../../../common/selectors/Recipient';
import {
  notifySuccess,
  notifyError,
} from '../../../utils/notify';
import {
  snooze,
  snoozeOne,
} from '../../../common/api/collections/AnswersSheets';
import {
  one,
} from '../../../common/api/collections/Recipients';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import Snooze from './Snooze';
import createDialog from '../../../store/ui/dialogs/create';

const dialog = createDialog('Components | Dialogs | Snooze', {
  propTypes: {
    recipientId: PropTypes.string,
    projectId: PropTypes.string,
    answersSheetId: PropTypes.string,
    snoozeEnd: PropTypes.date,
  },
});

const Container = compose(
  dialog.connect({
    selectors: {
      message: createSelector(
        RecipientSelect.one().whereIdEquals(dialog.getProp('recipientId')),
        recipient => (recipient
          ? `Kindly confirm that you want to snooze current milestone for ${recipient.getFullName()}.`
          : 'Kindly confirm that you want to snooze current questionnaire.'),
      ),
    },
  }),
  withHandlers({
    onCancel: ({
      dispatch,
    }) => () => dispatch(dialog.close()),
    onSubmit: ({
      dispatch,
      projectId,
      recipientId,
      answersSheetId,
    }) => ({
      snoozeEnd,
    }) => {
      if (answersSheetId) {
        dispatch(
          callMethod(snoozeOne, {
            answersSheetId,
            snoozeEnd,
          }),
        )
          .then(notifySuccess('Current questionnaire snoozed'))
          .catch(notifyError())
          .then(() => dispatch(dialog.close()));
      } else {
        dispatch(
          callMethod(snooze, {
            recipientId,
            projectId,
            snoozeEnd,
          }),
        )
          .then(notifySuccess('Current milestone snoozed'))
          .catch(notifyError())
          .then(() => dispatch(dialog.close()));
      }
    },
  }),
  ddp({
    subscriptions: ({
      recipientId,
    }) => [
      recipientId &&
        one.withParams({
          recipientId,
        }),
    ],
    renderLoader: null,
  }),
)(Snooze);

export default Container;
export {
  dialog,
};
