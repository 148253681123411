/* eslint-disable react/prop-types */
import {
  DeleteOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  EditOutlined,
  // EyeOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import find from 'lodash/find';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, {
  useState,
} from 'react';
import {
  compose,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  useTranslation,
} from 'react-i18next';
import {
  expandValueSet,
} from '../../../common/api/terminology';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Box from '../../../common/components/primitives/Box';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import {
  Question,
  Element,
  Field,
} from '../../../common/containers/Questionnaire';
import QuestionModel from '../../../common/models/Question';
import QuestionnaireModel from '../../../common/models/Questionnaire';
import Collection from '../../../common/components/Collection';
import {
  CollapseItem,
} from '../../../common/components/Collapse';
import palette from '../../../common/utilsClient/palette';
// import FormFieldError from './FormFieldError';

const Title = ({
  input: {
    value,
  },
  title,
  expandQueryResult,
}) => {
  if (!value) {
    return <div data-testid={`label-${title}`}>{title}</div>;
  }

  if (!expandQueryResult) {
    return value;
  }

  const {
    contains,
  } = expandQueryResult.expansion;

  const res = find(contains, item => item.code === value);

  const titleText = res ? res.display : value;

  return <div data-testid={`label-${titleText}`}>{titleText}</div>;
};

const CollectionTitle = compose(
  ddp({
    queries: (state, {
      valueSetId,
    }) => ({
      expandQueryResult: valueSetId
        ? expandValueSet.withParams({
          id: valueSetId,
          // FIXME: Performance
          limit: 0,
        })
        : null,
    }),
    renderLoader: null,
  }),
)(({
  expandQueryResult,
  title,
  nameId,
}) => {
  return (
    <Question id={nameId}>
      {({
        valueSet,
      }) => {
        return (
          <Field
            data-testid={`collapse-${title}`}
            component={Title}
            valueSet={valueSet}
            expandQueryResult={expandQueryResult}
            title={title}
          />
        );
      }}
    </Question>
  );
});

const Content = ({
  title,
  questionnaire,
  question,
  questionsIds,
  Section,
  handleRemove,
  isPristine,
  valueSetId,
  nameId,
}) => {
  const {
    t,
  } = useTranslation();

  const [
    isEditing,
    setIsEditing,
  ] = useState(isPristine);

  const handleEdit = () => setIsEditing(!isEditing);

  return (
    <Stack>
      <Cluster space={1}>
        {/* <Button
          data-testid={`button-preview-${title}`}
          type="primary"
          icon={<EyeOutlined />}
        >
          {t('preview')}
        </Button> */}
        <Button
          data-testid={`button-edit-${title}`}
          type="primary"
          icon={<EditOutlined />}
          onClick={handleEdit}
          disabled={isEditing}
        >
          {t('edit')}
        </Button>
        <Button
          data-testid={`button-remove-${title}`}
          type="primary"
          icon={<DeleteOutlined />}
          onClick={handleRemove}
        >
          {t('remove')}
        </Button>
      </Cluster>
      <Section
        questionnaire={questionnaire}
        sectionId={question.id}
        questionsIds={questionsIds}
        valueSetId={valueSetId}
        nameId={nameId}
        isEditing={isEditing}
        isNested
      />
      {/* <Field
        component={FormFieldError}
        name="value"
      /> */}
    </Stack>
  );
};

const Component = ({
  elements,
  question,
  questionnaire,
  onAppend,
  Section,
  // eslint-disable-next-line react/prop-types
  questionsIds,
  // eslint-disable-next-line react/prop-types
  // eslint-disable-next-line react/prop-types
  valueSetId,
  // eslint-disable-next-line react/prop-types
  nameId,
  hasOrder,
  isTertiary,
}) => {
  const {
    t,
  } = useTranslation();

  const title = question.getTitle();
  const items = map(elements, (elementId, i) => {
    // const elementFormValues = formValues._elements[elementId];

    return {
      key: elementId,
      children: (
        <Element
          key={elementId}
          id={elementId}
        >
          {({
            handleRemove,
            handleMoveUp,
            handleMoveDown,
          }) => (
            <Sidebar
              space={1}
              side="right"
              align="flex-start"
              sidebar={
                hasOrder ? (
                  <Stack space={1}>
                    <Button
                      icon={<ArrowUpOutlined />}
                      disabled={i === 0}
                      onClick={handleMoveUp}
                      ghost
                      block
                    />
                    <Button
                      icon={<ArrowDownOutlined />}
                      disabled={i === elements.length - 1}
                      onClick={handleMoveDown}
                      ghost
                      block
                    />
                  </Stack>
                ) : null
              }
            >
              <Box.Secondary
                boxShadow="base"
                // FIXME: Makes sense to extend Box to add Tertiary with that color?
                style={
                  isTertiary
                    ? {
                      backgroundColor: palette.blue[10],
                    }
                    : {}
                }
              >
                <CollapseItem
                  title={(
                    <CollectionTitle
                      data-testid={`collapse-${title}`}
                      title={`${pluralize(title, 1)} ${i + 1}`}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      elementId={elementId}
                    />
                  )}
                  titleToken="title3"
                  content={(
                    <Content
                      title={title}
                      questionnaire={questionnaire}
                      question={question}
                      questionsIds={questionsIds}
                      valueSetId={valueSetId}
                      nameId={nameId}
                      Section={Section}
                      handleRemove={handleRemove}
                    />
                  )}
                />
              </Box.Secondary>
            </Sidebar>
          )}
        </Element>
      ),
    };
  });

  return (
    <Collection
      data-testid={question.getSlug()}
      title={(
        <Text.Heading
          data-testid={`collection-header-${title}`}
          level={2}
        >
          {title}
        </Text.Heading>
      )}
      items={items}
      addTitle={t('add')}
      onAdd={onAppend}
    />
  );
};

Component.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.instanceOf(QuestionModel).isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  onAppend: PropTypes.func.isRequired,
  Section: PropTypes.elementType.isRequired,
  hasOrder: PropTypes.bool,
  isTertiary: PropTypes.bool,
};

Component.defaultProps = {
  elements: [],
  hasOrder: false,
  isTertiary: false,
};

export default Component;
