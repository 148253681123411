/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
import pick from 'lodash/pick';
import OAuth from './OAuth';

// Allow server to specify a specify subclass of errors. We should come
// up with a more generic way to do this!
// const convertError = function (err) {
//   if (err &&
//       err instanceof Meteor.Error &&
//       err.error === Accounts.LoginCancelledError.numericError
//   ) {
//     return new Accounts.LoginCancelledError(err.reason);
//   }
//   return err;
// };

// For the redirect login flow, the final step is that we're
// redirected back to the application.  The credentialToken for this
// login attempt is stored in the reload migration data, and the
// credentialSecret for a successful login is stored in session
// storage.

// eslint-disable-next-line func-names
export const tryLoginOnStartup = function (ddpConnector, callback) {
  const oauth = OAuth.getDataAfterRedirect();
  if (!oauth) {
    return;
  }

  // We'll only have the credentialSecret if the login completed
  // successfully.  However we still call the login method anyway to
  // retrieve the error if the login was unsuccessful.

  ddpConnector
    .login({
      oauth: pick(oauth, 'credentialToken', 'credentialSecret'),
    })
    .catch(callback);

  // Accounts.callLoginMethod({
  //   methodArguments,
  //   userCallback: function (err) {
  //     // The redirect login flow is complete.  Construct an
  //     // `attemptInfo` object with the login result, and report back
  //     // to the code which initiated the login attempt
  //     // (e.g. accounts-ui, when that package is being used).
  //     // err = convertError(err);
  //     Accounts._pageLoadLogin({
  //       type: oauth.loginService,
  //       allowed: !err,
  //       error: err,
  //       methodName,
  //       methodArguments
  //     });
  //   }
  // });
};

// Send an OAuth login method to the server. If the user authorized
// access in the popup this should log the user in, otherwise
// nothing should happen.
export const tryLoginAfterPopupClosed = function (
  ddpConnector,
  credentialToken,
  callback,
) {
  const credentialSecret =
    OAuth._retrieveCredentialSecret(credentialToken) || null;
  ddpConnector
    .login({
      oauth: {
        credentialToken,
        credentialSecret,
      },
    })
    .then((res) => {
      if (callback) {
        callback(null, res);
      }
    })
    .catch(callback);
};

export const credentialRequestCompleteHandler = function (
  ddpConnector,
  callback,
) {
  return function (credentialTokenOrError) {
    if (credentialTokenOrError && credentialTokenOrError instanceof Error) {
      if (callback) {
        callback(credentialTokenOrError);
      }
    } else {
      tryLoginAfterPopupClosed(ddpConnector, credentialTokenOrError, callback);
    }
  };
};
