export const hideQuestion = question => ({
  ...question,
  votesToHide: (question.votesToHide || 0) + 1,
});

export const showQuestion = question => ({
  ...question,
  votesToHide: (question.votesToHide || 0) - 1,
});

export const makeHideQuestionChunk = id => question => ({
  ...question,
  votesToHideChunk: {
    ...question.votesToHideChunk,
    [id]:
      ((question.votesToHideChunk && question.votesToHideChunk[id]) || 0) + 1,
  },
});

export const makeShowQuestionChunk = id => question => ({
  ...question,
  votesToHideChunk: {
    ...question.votesToHideChunk,
    [id]:
      ((question.votesToHideChunk && question.votesToHideChunk[id]) || 0) - 1,
  },
});
