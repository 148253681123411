import {
  CheckOutlined,
} from '@ant-design/icons';
import styled from 'styled-components/macro';
import React, {
  Suspense,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  pure,
  compose,
  withHandlers,
} from 'recompose';
import {
  connect,
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import Questionnaire, {
  Question,
  withContext,
} from '../../../common/containers/Questionnaire';
import {
  enterFormValues,
} from '../../../common/containers/Questionnaire/actions';
import QuestionnaireModel from '../../../common/models/Questionnaire';
import Markdown from '../../../common/components/Markdown';
import Divider from '../../../common/components/Divider';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import FormMedia from '../../../common/components/FormMedia';
import FormInput from './FormInput';
import Collection from '../../../components/forms/Collection';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';

const StyledWrapper = styled.div`
  .ant-form-item {
    background: none;
    padding: 0 !important;
  }
`;

export const Section = pure(
  ({
    questionnaire,
    sectionId,
    onTransferDataFromWearable,
  }) => {
    return (
      <StyledWrapper>
        <Stack space={4}>
          {questionnaire.mapQuestions(
            ({
              id: questionId,
            }) => (
              <Suspense
                key={questionId}
                fallback={<div>loading...</div>}
              >
                <Question id={questionId}>
                  {({
                    question,
                    iframeUrl,
                    valueSet,
                    elements,
                    handleAppend,
                  }) => {
                    if (question.isForInternalUsage()) {
                      return null;
                    }
                    if (question.shouldHideAndIgnoreAnswer()) {
                      return null;
                    }
                    if (question.isSection() || question.isEmpty()) {
                      return (
                        <Stack space={0}>
                          <Markdown.h2 source={question.getTitle()} />
                          <Markdown source={question.getDescription()} />
                        </Stack>
                      );
                    }
                    if (question.isCollection()) {
                      return (
                        <Collection
                          elements={elements}
                          question={question}
                          questionnaire={questionnaire}
                          onAppend={handleAppend}
                          Section={Section}
                        />
                      );
                    }
                    if (question.isMedia()) {
                      return (
                        <Stack>
                          <Markdown.h2 source={question.getTitle()} />
                          <Markdown source={question.getDescription()} />
                          <FormMedia
                            mediaUrl={question.getMediaUrl()}
                            mediaType={question.getMediaType()}
                          />
                        </Stack>
                      );
                    }
                    return (
                      <Stack>
                        <Markdown source={question.getDescription()} />
                        <FormInput
                          question={question}
                          valueSet={valueSet}
                          onTransferData={onTransferDataFromWearable}
                          iframeUrl={iframeUrl}
                        />
                      </Stack>
                    );
                  }}
                </Question>
              </Suspense>
            ),
            {
              sectionId,
              stopRecursion: q => q.isCollection(),
            },
          )}
        </Stack>
      </StyledWrapper>
    );
  },
);

const SubmitButton = compose(
  connect(),
  withContext(),
  withHandlers({
    handleSubmit: ({
      context,
      dispatch,
    }) => () => dispatch(context.validate())
      .then(notifySuccess('Successfully validated questionnaire!'))
      .catch(notifyError()),
  }),
)(({
  handleSubmit,
}) => {
  const {
    t,
  } = useTranslation();

  return (
    <Cluster justify="center">
      <Button
        type="primary"
        size="large"
        icon={<CheckOutlined />}
        onClick={handleSubmit}
      >
        {t('complete')}
      </Button>
    </Cluster>
  );
});

const SingleScreenQuestionnaire = ({
  questionnaire,
  sectionId,
}) => {
  const name = `sandbox::${questionnaire._id.replace(/\./g, '_')}`;

  const dispatch = useDispatch();
  const onTransferDataFromWearable = useCallback(
    formValues => dispatch(enterFormValues(name, formValues)),
    [
      dispatch,
      name,
    ],
  );

  return (
    <Questionnaire
      name={name}
      questionnaire={questionnaire}
    >
      <Stack space={5}>
        <Section
          questionnaire={questionnaire}
          sectionId={sectionId}
          onTransferDataFromWearable={onTransferDataFromWearable}
        />
        <Divider>
          <SubmitButton />
        </Divider>
      </Stack>
    </Questionnaire>
  );
};

SingleScreenQuestionnaire.propTypes = {
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  sectionId: PropTypes.string,
};

SingleScreenQuestionnaire.defaultProps = {
  sectionId: null,
};

export default SingleScreenQuestionnaire;
