import {
  QUESTION_TYPE__EMAIL,
  ANSWER_VALUE_MAX_LENGTH,
} from '../../../constants';
import Question from '../Question';

export default class QuestionEmail extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__EMAIL;
  }
}

QuestionEmail.expectedValueType = {
  type: 'string',
  format: 'email',
  maxLength: ANSWER_VALUE_MAX_LENGTH,
};

QuestionEmail.editorSettings = {};

Question.types[QUESTION_TYPE__EMAIL] = QuestionEmail;
