export const VARIABLE_TYPE__STRING = 'string';
export const VARIABLE_TYPE__NUMBER = 'number';
export const VARIABLE_TYPE__BOOLEAN = 'boolean';
export const VARIABLE_TYPE__DATE = 'date';
export const VARIABLE_TYPE__TIME = 'time';
export const VARIABLE_TYPE__DATE_TIME = 'dateTime';
export const VARIABLE_TYPE__VALUE_SET = 'valueSet'; // similar to FHIR/ValueSet
export const VARIABLE_TYPE__LOOKUP_TABLE = 'lookupTable'; // similar to FHIR/ValueSet
export const VARIABLE_TYPE__CUSTOM = 'custom';

export const VARIABLE_TYPES = [
  VARIABLE_TYPE__STRING,
  VARIABLE_TYPE__NUMBER,
  VARIABLE_TYPE__BOOLEAN,
  VARIABLE_TYPE__DATE,
  VARIABLE_TYPE__TIME,
  VARIABLE_TYPE__DATE_TIME,
  VARIABLE_TYPE__VALUE_SET,
  VARIABLE_TYPE__LOOKUP_TABLE,
  VARIABLE_TYPE__CUSTOM,
];

export const VARIABLE_TYPE_OPTIONS = [
  {
    value: VARIABLE_TYPE__STRING,
    label: 'String',
  },
  {
    value: VARIABLE_TYPE__NUMBER,
    label: 'Number',
  },
  {
    value: VARIABLE_TYPE__BOOLEAN,
    label: 'Boolean',
  },
  {
    value: VARIABLE_TYPE__DATE,
    label: 'Date',
  },
  {
    value: VARIABLE_TYPE__TIME,
    label: 'Time',
  },
  {
    value: VARIABLE_TYPE__DATE_TIME,
    label: 'Date Time',
  },
  {
    value: VARIABLE_TYPE__VALUE_SET,
    label: 'Value Set',
  },
  {
    value: VARIABLE_TYPE__LOOKUP_TABLE,
    label: 'Lookup Table',
  },
  {
    value: VARIABLE_TYPE__CUSTOM,
    label: 'Custom (JSON)',
  },
];
