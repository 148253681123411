import PropTypes from 'prop-types';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import React from 'react';
import ChartCard from '../../../../components/plates/ChartCard';
import Table from '../../../../components/lists/Table';
import {
  CATChartConfig1,
} from './config';

const AmCharts = React.lazy(() => import(
  /* webpackChunkName: "AmCharts" */ '../../../../common/components/AmCharts'
));

const columns = [
  {
    title: 'Theta',
    dataIndex: 'theta',
  },
  {
    title: 'SEM',
    dataIndex: 'sem',
  },
  {
    title: 'Score Estimate',
    dataIndex: 'score',
  },
];

const cleanDataProvider = dataProvider => map(dataProvider, entry => mapValues(entry, value => value.toPrecision(2)));

const Chart1 = ({
  dataProvider,
  dataSource,
}) => (
  <ChartCard
    title="Log Likelihood of Theta (Score)"
    queriesReady
  >
    <AmCharts
      options={{
        // eslint-disable-next-line react/jsx-props-no-spreading
        ...CATChartConfig1,
        dataProvider: cleanDataProvider(dataProvider),
      }}
    />
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      hasMinWidth={false}
    />
  </ChartCard>
);

Chart1.propTypes = {
  dataProvider: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  dataSource: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

Chart1.defaultProps = {
  dataProvider: null,
  dataSource: null,
};

export default Chart1;
