/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const TracksForm = ({
  projectId,
  variables,
  questionnaire,
}) => {
  const collectionId = 'ai_tracks';

  const questionsIds = [
    collectionId,
  ];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach(question => questionsIds.push(question.id));

  questionsIds.forEach(questionId => questionnaire
    .getChildQuestions(questionId)
    .forEach(question => questionsIds.push(question.id)));

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={[
        ...questionsIds,
      ]}
      nameId="ai2_name"
      hasOrder
    />
  );
};

export default TracksForm;
