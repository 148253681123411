import PropTypes from 'prop-types';
import React from 'react';
import FormFieldWrapper from '../../common/components/FormFieldWrapper';
import Switch from '../../common/components/Switch';
import {
  formFieldPropTypesMeta,
  formFieldPropTypesInput,
} from '../../common/utilsClient/propTypes';

const FormFieldSwitch = ({
  'data-testid': datatestid,
  label,
  required,
  tooltip,
  meta,
  input,
  disabled,
}) => (
  <FormFieldWrapper
    label={label}
    required={required}
    tooltip={tooltip}
    meta={meta}
  >
    <Switch
      // NOTE: When working with redux-form, adding input.onBlur breaks
      //       the component interaction for some reason.
      data-testid={datatestid}
      name={input.name}
      onChange={input.onChange}
      checked={!!input.value}
      disabled={disabled}
    />
  </FormFieldWrapper>
);

FormFieldSwitch.propTypes = {
  'data-testid': PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
  meta: formFieldPropTypesMeta.isRequired,
  input: formFieldPropTypesInput.isRequired,
  disabled: PropTypes.bool,
};

FormFieldSwitch.defaultProps = {
  'data-testid': 'form-field-switch',
  label: null,
  required: false,
  tooltip: null,
  disabled: false,
};

export default FormFieldSwitch;
