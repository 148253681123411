import isNaN from 'lodash/isNaN';
import logger from '../index';
import createDefaultLogger from '../createDefaultLogger';
import {
  parse,
} from '../../utils/querystring';
import settings from '../../settings';

const query = parse(window.location.search);

const levelsColors = {
  alert: 'background: red, color: white',
  critical: 'background: yellow, color: black',
  error: 'color: red',
  warn: 'color: orange',
  info: 'color: green',
  verbose: 'color: blue',
  debug: 'color: navy',
};

const defaultLogger = createDefaultLogger(levelsColors);

logger.setProxy(defaultLogger);
if (query.logLevel) {
  logger.setLevel(query.logLevel);
  logger.info(`Setting log level to: "${query.logLevel}"`);
}

const {
  branchName,
  commitTime,
  commitSha,
} = settings.public;

const formatCommitSha = (value) => {
  if (typeof value === 'string') {
    return value.substr(0, 7);
  }
  return '';
};

const formatCommitTime = (value) => {
  if (typeof value !== 'number' || isNaN(value)) {
    return '';
  }
  return new Date(value * 1000).toISOString();
};

logger.info(`Commit Hash = ${formatCommitSha(commitSha)}`);
logger.info(`Commit Time = ${formatCommitTime(commitTime)}`);
logger.info(`Branch Name = ${branchName}`);

logger.info('Settings are ...', {
  ...settings.public,
});

export default defaultLogger;
