import map from 'lodash/map';
import Formula from '../Formula';
import {
  isEmptyAnswer,
} from '../../../utils/question';
import {
  FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE,
} from '../../../constants';
import Schema from '../../../utils/Schema';

const settingsSchema = new Schema(
  {
    references: [
      new Schema({
        questionId: {
          type: String,
        },
        field: {
          type: String,
          optional: true,
        },
      }),
    ],
  },
  {
    additionalProperties: true,
  },
);

class FormulaFirstNonEmptyAnswerValue extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const n = this.settings.references.length;
    for (let i = 0; i < n; i += 1) {
      const {
        questionId,
        field = 'value',
      } = this.settings.references[i];
      const data = scope.lookupAnswer(questionId);
      if (!isEmptyAnswer(data)) {
        return {
          value: data[field],
        };
      }
    }
    return {
      error: this.constructor.NoData,
    };
  }

  compile(questionsHierarchy) {
    const compiled = {
      ...this,
      settings: {},
    };
    if (this.settings.references) {
      compiled.settings.references = this.settings.references;
    } else if (this.meta && this.meta.sectionId) {
      compiled.settings = {
        ...compiled.settings,
        references: questionsHierarchy.mapQuestions(
          q => ({
            questionId: q.id,
          }),
          {
            sectionId: this.meta.sectionId,
          },
        ),
      };
    }
    return compiled;
  }

  toMongoExpression() {
    return {
      $reduce: {
        input: '$responses',
        initialValue: 0,
        in: this.constructor.createAggregationExpression(
          this.settings.references,
        ),
      },
    };
  }

  static createAggregationExpression(references) {
    if (!references || !references[0]) {
      return '$$value';
    }
    return {
      $cond: {
        if: {
          $eq: [
            '$$this.questionId',
            {
              $literal: references[0].id,
            },
          ],
        },
        then: '$$value',
        else: this.createAggregationExpression(references.slice(1)),
      },
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'references':
          return map(value, fields => ({
            ...fields,
            questionId: mapQuestionId(fields.questionId),
          }));
        default:
          return value;
      }
    };
  }

  static createMapMeta(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'sectionId':
          return mapQuestionId(value);
        default:
          return value;
      }
    };
  }
}

Formula.types[
  FORMULA_TYPE__FIRST_NON_EMPTY_ANSWER_VALUE
] = FormulaFirstNonEmptyAnswerValue;

export default FormulaFirstNonEmptyAnswerValue;
