import findIndex from 'lodash/findIndex';
import {
  push,
} from 'connected-react-router';
import {
  selectAllVisibleViews,
  getCurrentPage,
  getPageSize,
  selectViewsTree,
} from './selectors';
import {
  getViewUrl,
  getLocationObject,
} from './dashboards';
import store from './store';
import createTabActions from './createTabActions';
import {
  transitionTo,
} from '../../store/router';
import {
  forEachNode,
  findParentOf,
  getClosestViewUrl,
} from './viewsTree';

const createTab = (params) => {
  const {
    projectId,
    perspective,
    type,
    presetNo,
    settings,
  } = params;
  return store.push('tabs', {
    projectId,
    perspective,
    type,
    presetNo,
    settings,
    url: getViewUrl(params),
  });
};

const deleteTab = tab => store.pullWhere('tabs', {
  url: tab.url,
});

const {
  openTab,
  createTabIfMissing,
  switchTabBeforeDeleting,
  switchAndDeleteTab,
} = createTabActions({
  createTab,
  deleteTab,
  selectAllTabs: selectAllVisibleViews,
  getTabUrl: getViewUrl,
  canCreate: params => !!getViewUrl(params),
});

export const switchAndDeleteTabAndSubTabs = ({
  perspective,
  perspectiveId,
}) => (dispatch, getState) => {
  const viewsTree = selectViewsTree(getState());
  const subTree = findParentOf(
    viewsTree,
    node => node.perspective === perspective && node.perspectiveId === perspectiveId,
  );
  if (subTree) {
    const index = findIndex(subTree.children, {
      perspective,
      perspectiveId,
    });
    if (index >= 0) {
      const sibling = subTree.children[index - 1];
      const siblingUrl = getClosestViewUrl(sibling);
      if (siblingUrl) {
        dispatch(push(siblingUrl));
      }
      forEachNode(subTree, (node) => {
        if (node.view) {
          dispatch(deleteTab(node.view));
        }
      });
    }
  }
};

export {
  openTab,
  createTabIfMissing,
  switchTabBeforeDeleting,
  switchAndDeleteTab,
};

export const replaceTabUrl = params => (dispatch) => {
  const location = getLocationObject(params);
  if (location) {
    const {
      query,
      pathname,
    } = location;
    dispatch(
      transitionTo({
        query,
        pathname,
        replaceState: true,
      }),
    );
  }
};

export const setMilestoneDialogVisible = store.create.set(
  'milestoneDialogVisible',
);
export const setMilestoneDialogActiveKey = store.create.set(
  'milestoneDialogActiveKey',
);
export const setActivePatientId = store.create.set('activePatientId');
export const setActiveMilestoneId = store.create.set('activeMilestoneId');
export const setActiveActivityId = store.create.set('activeActivityId');
export const setActiveAnswersSheetId = store.create.set('activeAnswersSheetId');
export const setActiveEAPPId = store.create.set('activeEAPPId');
export const setActiveNoteId = store.create.set('activeNoteId');
export const setEditNoteDialogVisible = store.create.set(
  'editNoteDialogVisible',
);
export const setEditMilestoneDialogVisible = store.create.set(
  'editMilestoneDialogVisible',
);
export const setAnswersSheetDialogVisible = store.create.set(
  'answersSheetDialogVisible',
);
export const setEAPPDialogVisible = store.create.set('EAPPDialogVisible');
export const setAddAnswersSheetsDialogVisible = store.create.set(
  'addAnswersSheetsDialogVisible',
);
export const setRemoveAnswersSheetDialogVisible = store.create.set(
  'removeAnswersSheetDialogVisible',
);
export const setPreviewPatientResponseDialogVisible = store.create.set(
  'previewPatientResponseDialogVisible',
);
export const setChartVariant = ({
  id,
  value,
}) => store.set(`cards.${id}.chart`, value);

// Dialogs

export const openMilestoneDialog = ({
  recipientId,
  milestoneId,
  activityId,
  activeKey = 'questionnaires',
}) => (dispatch) => {
  dispatch(setActivePatientId(recipientId));
  dispatch(setActiveMilestoneId(milestoneId));
  dispatch(setActiveActivityId(activityId));
  dispatch(setMilestoneDialogVisible(true));
  dispatch(setMilestoneDialogActiveKey(activeKey));
};

export const closeMilestoneDialog = () => (dispatch) => {
  dispatch(setActivePatientId(null));
  dispatch(setActiveMilestoneId(null));
  dispatch(setActiveActivityId(null));
  dispatch(setMilestoneDialogVisible(false));
  dispatch(setMilestoneDialogActiveKey('questionnaires'));
};

export const openAnswersSheetDialog = ({
  answersSheetId,
}) => (dispatch) => {
  dispatch(setActiveAnswersSheetId(answersSheetId));
  dispatch(setAnswersSheetDialogVisible(true));
};

export const openEAPPDialog = ({
  eappId,
}) => (dispatch) => {
  dispatch(setActiveEAPPId(eappId));
  dispatch(setEAPPDialogVisible(true));
};

export const closeAnswersSheetDialog = () => (dispatch) => {
  dispatch(setActiveAnswersSheetId(null));
  dispatch(setAnswersSheetDialogVisible(false));
};

export const closeEAPPDialog = () => (dispatch) => {
  dispatch(setActiveEAPPId(null));
  dispatch(setEAPPDialogVisible(false));
};

export const openPreviewPatientResponseDialog = ({
  answersSheetId,
}) => (
  dispatch,
) => {
  dispatch(setActiveAnswersSheetId(answersSheetId));
  dispatch(setPreviewPatientResponseDialogVisible(true));
};

export const closePreviewPatientResponseDialog = () => (dispatch) => {
  dispatch(setActiveAnswersSheetId(null));
  dispatch(setPreviewPatientResponseDialogVisible(false));
};

export const openEditNoteDialog = ({
  noteId,
}) => (dispatch) => {
  dispatch(setActiveNoteId(noteId));
  dispatch(setEditNoteDialogVisible(true));
};

export const closeEditNoteDialog = () => (dispatch) => {
  dispatch(setActiveNoteId(null));
  dispatch(setEditNoteDialogVisible(false));
};

export const openEditMilestoneDialog = ({
  milestoneId,
}) => (dispatch) => {
  dispatch(setActiveMilestoneId(milestoneId));
  dispatch(setEditMilestoneDialogVisible(true));
};

export const closeEditMilestoneDialog = () => (dispatch) => {
  dispatch(setActiveMilestoneId(null));
  dispatch(setEditMilestoneDialogVisible(false));
};

export const openAddAnswersSheetsDialog = () => (dispatch) => {
  dispatch(setAddAnswersSheetsDialogVisible(true));
};

export const closeAddAnswersSheetsDialog = () => (dispatch) => {
  dispatch(setAddAnswersSheetsDialogVisible(false));
};

export const openRemoveAnswersSheetDialog = () => (dispatch) => {
  dispatch(setRemoveAnswersSheetDialogVisible(true));
};

export const closeRemoveAnswersSheetDialog = ({
  removed = false,
} = {}) => (
  dispatch,
) => {
  dispatch(setRemoveAnswersSheetDialogVisible(false));
  if (removed) {
    dispatch(setActiveAnswersSheetId(null));
    dispatch(setAnswersSheetDialogVisible(false));
  }
};

// Dialogs

export const openProjectProfileDialog = (options = {}) => {
  return store.set('dialogs.projectProfile', options);
};

export const closeProjectProfileDialog = () => {
  return store.del('dialogs.projectProfile');
};

export const openEditActivityDialog = (options = {}) => {
  return store.set('dialogs.editActivity', options);
};

export const closeEditActivityDialog = () => {
  return store.del('dialogs.editActivity');
};

export const openEditPatientDialog = (options = {}) => {
  return store.set('dialogs.editPatient', options);
};

export const closeEditPatientDialog = () => {
  return store.del('dialogs.editPatient');
};

// Table

export const setCurrentPage = store.create.set('currentPage');
export const setPageSize = store.create.set('pageSize');
export const setNumberOfPatients = value => (dispatch, getState) => {
  const state = getState();
  const currentPage = getCurrentPage(state);
  const pageSize = getPageSize(state);
  const nPages = Math.ceil(value / pageSize);
  if (currentPage > nPages) {
    // make sure we don't set the value to 0
    dispatch(setCurrentPage(Math.max(1, nPages)));
  }
  dispatch(store.set('nPatients', value));
};
