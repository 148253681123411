import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  PROJECT_REVIEW_PROJECT,
} from '../../permissions';
import {
  FILTER_TYPE__VARIABLE,
} from '../../constants';

// SUBSCRIPTIONS

export const currentReports = new ApiSpec({
  name: 'api.aggregations.Filters.currentReports',
  permissions: [
    PROJECT_REVIEW_PROJECT,
  ],
  schema: new Schema({
    projectId: {
      type: String,
    },
  }),
});

// QUERIES/ MUTATIONS

export const getValues = new ApiSpec({
  name: 'api.aggregations.Filters.getValues',
  permissions: [
    PROJECT_REVIEW_PROJECT,
  ],
  schema: new Schema({
    versionId: {
      type: String,
      optional: true,
    },
    projectId: {
      type: String,
    },
    type: {
      type: String,
      allowedValues: [
        FILTER_TYPE__VARIABLE,
      ],
    },
    variableId: {
      type: String,
      optional: true,
    },
  }),
});

export const getMultipleValues = new ApiSpec({
  name: 'api.aggregations.Filters.getMultipleValues',
  permissions: [
    PROJECT_REVIEW_PROJECT,
  ],
  schema: new Schema({
    versionId: {
      type: String,
      optional: true,
    },
    projectId: {
      type: String,
    },
    type: {
      type: String,
      allowedValues: [
        FILTER_TYPE__VARIABLE,
      ],
    },
    variableIds: {
      type: [
        String,
      ],
      optional: true,
    },
  }),
});
