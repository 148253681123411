import findIndex from 'lodash/findIndex';
import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  motion,
} from 'framer-motion';
import {
  useTranslation,
} from 'react-i18next';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const StyledWrapper = styled.div`
  overflow: hidden;
  margin-top: ${theme('space.3')};
`;

const StyledRail = styled(motion.div)`
  display: flex;
  width: ${props => `${props.length * 100}%`};
  will-change: opacity, transform;

  > section {
    position: relative;
    padding-right: ${theme('space.3')};
    padding-left: ${theme('space.3')};
    width: ${props => `${100 / props.length}%`};
  }
`;

const Rail = ({
  activeKey,
  tabs,
  children,
}) => {
  const {
    i18n,
  } = useTranslation();

  const direction = i18n.dir(i18n.language);

  const index = findIndex(tabs, tab => tab.value === activeKey);

  return (
    <StyledWrapper>
      <StyledRail
        length={tabs.length}
        animate={{
          x: `${direction === 'ltr' ? '-' : ''}${(100 / tabs.length) * index}%`,
        }}
        transition={{
          ease: 'easeOut',
        }}
      >
        {children}
      </StyledRail>
    </StyledWrapper>
  );
};

Rail.propTypes = {
  activeKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tabs: PropTypes.array,
};

Rail.defaultProps = {
  tabs: [],
};

export default Rail;
