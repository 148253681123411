import {
  LoginOutlined,
  MailOutlined,
  LockOutlined,
} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Field,
  reduxForm,
} from 'redux-form';
import {
  useTranslation,
} from 'react-i18next';
import Schema from '../../common/utils/Schema';
import Stack from '../../common/components/primitives/Stack';
import Cluster from '../../common/components/primitives/Cluster';
import Spacer from '../../common/components/primitives/Spacer';
import Button from '../../common/components/Button';
import Text from '../../common/components/base/Text';
import TextField from '../../components/forms/FormFieldText';
import Error from '../../components/forms/Error';
import useDocumentTitle from '../../utils/useDocumentTitle';

const QRImage = styled.img`
  display: block;
  max-width: 100px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid;
`;

const propTypes = {
  showQRForm: PropTypes.bool,
  qrImage: PropTypes.string,
  qrCode: PropTypes.string,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  license: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isLoggingIn: PropTypes.bool.isRequired,
};

const defaultProps = {
  qrCode: null,
  showQRForm: false,
  qrImage: null,
  error: '',
  license: null,
};

const SignIn = ({
  error,
  handleSubmit,
  onSubmit,
  license,
  isLoggingIn,
  showQRForm,
  qrCode,
  qrImage,
}) => {
  const {
    t,
  } = useTranslation();

  useDocumentTitle([
    t('entry:signIn'),
  ]);

  let message = null;
  if (license) {
    if (license.hasExpired) {
      if (license.isInGracePeriod) {
        message = (
          <Text.Paragraph>
            There are
            {' '}
            {license.gracePeriodLeft}
            {' '}
            days left for your subscription
            to Zedoc. Kindly notify your administrator for subscription
            renewals.
          </Text.Paragraph>
        );
      } else {
        message = (
          <Text.Paragraph>Your subscription has expired</Text.Paragraph>
        );
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Spacer>
        <Stack>
          {error ? <Error>{error}</Error> : message}
          <Field
            data-testid="textfield-testid-email"
            component={TextField}
            name="email"
            type="text"
            label={t('forms:email.label')}
            placeholder={t('forms:email.placeholder')}
            prefix={<MailOutlined />}
            required
          />
          <Field
            data-testid="textfield-testid-password"
            component={TextField}
            name="password"
            type="password"
            label={t('forms:password.label')}
            placeholder={t('forms:password.placeholder')}
            prefix={<LockOutlined />}
            required
          />
          {qrImage && (
            <div>
              <p>
                Scan code or enter key for Google Authenticator available for
                free at your smart phone app store
              </p>
              <QRImage
                src={qrImage}
                alt="QR Code"
              />
              <p>{qrCode}</p>
            </div>
          )}
          {(showQRForm || qrImage) && (
            <Field
              component={TextField}
              name="code"
              label="Code Validation"
              placeholder="Please enter your code"
              prefix={<LockOutlined />}
              required
            />
          )}
          <Stack space={1}>
            <Cluster justify="center">
              <Button
                data-testid="button-sign-in"
                type="primary"
                icon={<LoginOutlined />}
                htmlType="submit"
                loading={isLoggingIn}
                block
              >
                {t('entry:signIn')}
              </Button>
            </Cluster>
            <Cluster justify="center">
              <Text.Link
                to="/entry/forgot"
                size="small"
              >
                {t('forms:password.reset')}
              </Text.Link>
            </Cluster>
          </Stack>
        </Stack>
      </Spacer>
    </form>
  );
};

SignIn.propTypes = propTypes;
SignIn.defaultProps = defaultProps;

export default reduxForm({
  form: 'entry',
  validate: new Schema({
    email: {
      type: String,
      label: 'Email',
    },
    password: {
      type: String,
      label: 'Password',
      min: 6,
    },
    code: {
      type: String,
      label: 'Code',
      optional: true,
    },
  }).validator({
    noException: true,
  }),
})(SignIn);
