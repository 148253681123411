import forEach from 'lodash/forEach';
import some from 'lodash/some';
import map from 'lodash/map';
import {
  useMemo,
} from 'react';
import {
  useSelector,
} from 'react-redux';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  apiCurrentUserPermissionsRealm,
} from '../common/api/currentUser';
import PermissionsDomain from '../common/models/PermissionsDomain';
import PermissionsDomainSelect from '../common/selectors/PermissionsDomain';
import useReconcile from './useReconcile';

const getCondition = (permissions) => {
  const condition = {};
  forEach(permissions, (key) => {
    const encodedKey = PermissionsDomain.encodePermissionKey(key);
    condition[encodedKey] = true;
  });
  return condition;
};

const usePermissionsRealm = (permissions, options) => {
  const params = {
    permissions,
  };
  const scope = useReconcile(options && options.scope);
  if (scope) {
    params.scope = scope;
  }
  const {
    ready: domainsReady,
  } = useDDPSubscription(
    apiCurrentUserPermissionsRealm.withParams(params),
  );
  const condition = useReconcile(getCondition(permissions));
  const permissionsDomains = useSelector(
    useMemo(() => {
      return (
        PermissionsDomainSelect.all()
          .where(condition)
          // NOTE: We need to filter by scope, because it is possible that the there was
          //       another subscription with different "scope" parameter, which returned
          //       the given permissions domain.
          .satisfying((doc) => {
            if (!scope) {
              return true;
            }
            return some(scope, domain => PermissionsDomain.contains(domain, doc._id));
          })
      );
    }, [
      scope,
      condition,
    ]),
  );
  return {
    domains: map(permissionsDomains, '_id'),
    domainsReady,
    permissionsDomains,
  };
};

export default usePermissionsRealm;
