/* eslint-disable react/prop-types */
import filter from 'lodash/filter';
import styled from 'styled-components/macro';
import React from 'react';
import Cluster from '../../../../common/components/primitives/Cluster';
import Stack from '../../../../common/components/primitives/Stack';
import Table from '../../../../common/components/Table';
import Icon from '../../../../common/components/Icon';
import {
  VARIABLE_ID__PARTICIPATION_EMR_SYNC,
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__BIRTH_YEAR,
  VARIABLE_ID__PARTICIPATION_CATEGORY,
  VARIABLE_ID__NAME,
  VARIABLE_ID__PARTICIPATION_BED_NUMBER,
} from '../../../../common/constants';
import {
  theme,
} from '../../../../common/utilsClient/cssHelpers';
import Modal from '../../../../components/dialogs/Modal';
import DashboardVariableItem from './DashboardVariableItem';
import {
  labels,
} from './helpers';

const StyledTable = styled(Table)`
  th {
    font-size: ${theme('font.size.small')};
  }
`;

const DashboardPatientModal = ({
  dataSource,
  tableColumns,
  visible,
  onCancel,
  getVariable,
}) => {
  const historicalTableColumns = filter(
    tableColumns,
    col => col.key !== 'patientColumn1' && col.key !== 'patientColumn2' && col,
  );

  const record = dataSource && dataSource[0];
  const variables = record && record.variables;

  return (
    <Modal
      title={getVariable(variables, VARIABLE_ID__NAME)}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
      width="95%"
      centered
    >
      <Stack>
        {dataSource && dataSource[0] && (
          <Cluster space={0}>
            <DashboardVariableItem
              label={labels[VARIABLE_ID__PARTICIPATION_EMR_SYNC]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_EMR_SYNC,
              )}
            />
            <DashboardVariableItem
              value={(
                <Icon
                  type={
                    getVariable(record.variables, VARIABLE_ID__GENDER) ||
                    getVariable(record.variables, VARIABLE_ID__GENDER_SINGAPORE)
                  }
                />
              )}
            />
            <DashboardVariableItem
              label={labels[VARIABLE_ID__BIRTH_DATE]}
              value={
                getVariable(record.variables, VARIABLE_ID__BIRTH_DATE) ||
                getVariable(record.variables, VARIABLE_ID__BIRTH_YEAR)
              }
            />
            <DashboardVariableItem
              label={labels[VARIABLE_ID__PARTICIPATION_CATEGORY]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_CATEGORY,
              )}
            />
            <DashboardVariableItem
              label={labels[VARIABLE_ID__PARTICIPATION_BED_NUMBER]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_BED_NUMBER,
              )}
            />
          </Cluster>
        )}
        <StyledTable
          dataSource={dataSource}
          columns={historicalTableColumns}
          pagination={false}
          scroll={{
            x: true,
          }}
        />
      </Stack>
    </Modal>
  );
};

DashboardPatientModal.propTypes = {};

DashboardPatientModal.defaultProps = {};

export default DashboardPatientModal;
