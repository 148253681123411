import Milestone from './Milestone';
import Add from './MilestoneAdd';
import Canceled from './MilestoneCanceled';
import Completed from './MilestoneCompleted';
import Custom from './MilestoneCustom';
import Due from './MilestoneDue';
import Empty from './MilestoneEmpty';
import Incomplete from './MilestoneIncomplete';
import InProgress from './MilestoneInProgress';
import Omitted from './MilestoneOmitted';
import Rejected from './MilestoneRejected';
import Suspended from './MilestoneSuspended';
import Upcoming from './MilestoneUpcoming';

Milestone.Add = Add;
Milestone.Canceled = Canceled;
Milestone.Completed = Completed;
Milestone.Custom = Custom;
Milestone.Due = Due;
Milestone.Empty = Empty;
Milestone.Incomplete = Incomplete;
Milestone.InProgress = InProgress;
Milestone.Omitted = Omitted;
Milestone.Rejected = Rejected;
Milestone.Suspended = Suspended;
Milestone.Upcoming = Upcoming;

export default Milestone;
