import PropTypes from 'prop-types';
import React, {
  useMemo,
  useCallback,
} from 'react';
import map from 'lodash/map';
import {
  useSelector,
} from 'react-redux';
import ProjectSelect from '../../../../common/selectors/Project';
import EAPPFilterOption from '../../../../common/models/EAPPFilterOption';
import {
  apiZedocSearchEAPPsFilterOptions,
} from '../../../../common/api/zedoc';
import createSelectors from '../../../../common/selectors/createSelectors';
import defaultFilters from './defaultFilters';
import {
  FILTER_CONDITION__DATE_EQUALS,
  FILTER_CONDITION__DATE_AFTER,
  FILTER_CONDITION__DATE_BEFORE,
  FILTER_CONDITION__DATE_SAME_OR_AFTER,
  FILTER_CONDITION__DATE_SAME_OR_BEFORE,
} from '../../../../common/constants';
import FiltersToolbar from '../FiltersToolbar';

const EAPPFilterOptionSelect = createSelectors(EAPPFilterOption, {});

const ConnectedFilters = ({
  storeKey,
  projectId,
  recipientId,
  participationId,
  activityId,
  answersSheetId,
}) => {
  const timezone = useSelector(
    ProjectSelect.one().whereIdEquals(projectId).toTimezone(),
  );
  const presets = useMemo(() => {
    const allPresets = map(defaultFilters, (preset) => {
      switch (preset.condition) {
        case FILTER_CONDITION__DATE_EQUALS:
        case FILTER_CONDITION__DATE_AFTER:
        case FILTER_CONDITION__DATE_BEFORE:
        case FILTER_CONDITION__DATE_SAME_OR_AFTER:
        case FILTER_CONDITION__DATE_SAME_OR_BEFORE:
          return {
            ...preset,
            state: {
              ...preset.state,
              timezone,
            },
          };
        default:
          return preset;
      }
    });
    return allPresets;
  }, [
    timezone,
  ]);
  const getOptionsSubscription = useCallback(
    ({
      searchText,
      currentFilters,
      type,
      condition,
      state,
      settings,
    }) => {
      return apiZedocSearchEAPPsFilterOptions.withParams({
        projectId,
        recipientId,
        participationId,
        activityId,
        answersSheetId,
        controlId: '$meta.id',
        searchText,
        filters: currentFilters,
        filter: {
          type,
          condition,
          state,
          settings,
        },
      });
    },
    [
      projectId,
      recipientId,
      participationId,
      activityId,
      answersSheetId,
    ],
  );
  return (
    <FiltersToolbar
      storeKey={storeKey}
      presets={presets}
      optionsSelector={EAPPFilterOptionSelect}
      optionsSubscription={getOptionsSubscription}
    />
  );
};

ConnectedFilters.propTypes = {
  storeKey: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string,
  participationId: PropTypes.string,
  activityId: PropTypes.string,
  answersSheetId: PropTypes.string,
};

ConnectedFilters.defaultProps = {
  recipientId: null,
  participationId: null,
  activityId: null,
  answersSheetId: null,
};

export default ConnectedFilters;
