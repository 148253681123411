import React from 'react';
import Text from '../../common/components/base/Text';
import {
  formFieldPropTypesMeta,
} from '../../common/utilsClient/propTypes';

const FormFieldError = ({
  meta: {
    error,
    touched,
    submitFailed,
  },
}) => {
  if (touched && submitFailed && error) {
    return (
      <Text.Paragraph size="small">{error}</Text.Paragraph>
    );
  }
  return null;
};

FormFieldError.propTypes = {
  meta: formFieldPropTypesMeta.isRequired,
};

export default FormFieldError;
