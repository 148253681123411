import { createSelector } from 'reselect';
import { registerStore } from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_CONDITION__TEXT,
} from '../../common/constants';
import { getSearchPhrase } from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.ProjectsList',
  initialState: {
    filters: [],
  },
});

const constant = x => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: 'name',
  },
});

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(getSearchPhrase, text => {
  return [
    {
      id: 'filter_by_project_name',
      type: FILTER_TYPE__PROPERTY,
      condition: FILTER_CONDITION__TEXT,
      settings: {
        id: 'name',
      },
      state: {
        text,
      },
    },
  ];
});

export default store;
