import get from 'lodash/get';
import React from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  compose,
  withHandlers,
  withProps,
} from 'recompose';
import {
  Field,
  FieldArray,
  reduxForm,
  submit,
} from 'redux-form';
import {
  useTranslation,
} from 'react-i18next';
import {
  QUESTION_TYPE__FREE_TEXT,
  QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS,
} from '../../../common/constants';
import Schema from '../../../common/utils/Schema';
import ProjectMilestone from '../../../common/models/ProjectMilestone';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import Modal from '../Modal';
import FormFieldText from '../../forms/FormFieldText';
import FormFieldSelect from '../../forms/FormFieldSelect';
import FormFieldList from '../../forms/FormFieldList';

const QuestionnaireProperties = connect()(
// ...
  ({
    member,
    questionnaireOptions,
  }) => {
    const {
      t,
    } = useTranslation('forms');

    return (
      <Stack>
        <Field
          data-testid="form-field-questionnaire-label"
          component={FormFieldSelect}
          name={`${member}.identifier`}
          type="text"
          label={t('questionnaire.label')}
          options={questionnaireOptions}
          showSearch
        />
        <Field
          data-testid="form-field-assigneeType-label"
          component={FormFieldSelect}
          name={`${member}.assigneeType`}
          type="text"
          label={t('assigneeType.label')}
          options={QUESTIONNAIRE_ASSIGNEE_TYPE_OPTIONS}
          showSearch
        />
      </Stack>
    );
  },
);

const QuestionnairesList = props => (
  <FormFieldList
    {...props}
    properties={QuestionnaireProperties}
  />
);

const DEFAULT_FORM = 'EditMilestone';
const EditMilestoneForm = reduxForm({
  form: DEFAULT_FORM,
  validate: new Schema({
    name: {
      type: String,
      label: 'Name',
    },
    description: {
      type: String,
      optional: true,
      label: 'Description',
    },
    questionnaires: {
      type: [
        new Schema({
          identifier: {
            type: String,
            label: 'Questionnaire',
          },
          assigneeType: {
            type: String,
            label: 'Assignee type',
          },
          delegatedToPatient: {
            type: Boolean,
            optional: true,
            label: 'Delegate to patient',
          },
        }),
      ],
      minCount: 1,
    },
  }).validator({
    noException: true,
  }),
})(({
  form,
  error,
  handleSubmit,
  onSubmit,
  questionnaireOptions,
}) => {
  const {
    t,
  } = useTranslation('forms');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        {error && <Text.Paragraph type="danger">{error}</Text.Paragraph>}
        <Field
          component={FormFieldText}
          type="text"
          name="name"
          label={t('name.label')}
        />
        <Field
          component={FormFieldText}
          type={QUESTION_TYPE__FREE_TEXT}
          name="description"
          label={t('description.label')}
        />
        <FieldArray
          name="questionnaires"
          label={t('questionnaire.label', {
            count: 0,
          })}
          component={QuestionnairesList}
          props={{
            form,
            questionnaireOptions,
          }}
        />
      </Stack>
    </form>
  );
});

const EditMilestone = compose(
  withProps(({
    milestone,
  }) => ({
    milestone,
    initialValues: {
      ...(milestone && {
        name: milestone.name || '',
        description: milestone.description || '',
        questionnaires: milestone.questionnaires || [],
      }),
    },
  })),
  withHandlers({
    onSubmit: ({
      milestone,
      onCreate,
      onUpdate,
    }) => data => (milestone
      ? onUpdate({
        ...data,
        milestoneId: milestone._id,
      })
      : onCreate(data)),
  }),
  connect(
    () => ({}),
    (dispatch, {
      form,
    }) => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
)(
  ({
    form,
    open,
    milestone,
    onCancel,
    onSubmit,
    doSubmit,
    initialValues,
    questionnaireOptions,
  }) => {
    const {
      t,
    } = useTranslation();

    return (
      <Modal
        title={milestone ? t('editMilestone') : t('addMilestone')}
        visible={open}
        onOk={doSubmit}
        onCancel={onCancel}
      >
        <EditMilestoneForm
          form={form}
          onSubmit={onSubmit}
          initialValues={initialValues}
          questionnaireOptions={questionnaireOptions}
        />
      </Modal>
    );
  },
);

EditMilestone.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  milestone: PropTypes.instanceOf(ProjectMilestone),
  questionnaireOptions: get(FormFieldSelect.propTypes, 'options'),
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
};

EditMilestone.defaultProps = {
  form: DEFAULT_FORM,
  milestone: null,
  onCancel: () => {},
  onCreate: () => {},
  onUpdate: () => {},
};

export default EditMilestone;
