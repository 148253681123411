import styled from 'styled-components';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  propTypes,
  defaultProps,
} from './Text';
import {
  relativeStyles,
} from './styles';

const StyledLink = styled(Link)`
  ${relativeStyles};
`;

const StyledA = styled.a`
  ${relativeStyles};
`;

const TextLink = ({
  to,
  ...props
}) => (to ? (
  <StyledLink
    to={to}
    {...props}
  />
) : <StyledA {...props} />);

TextLink.propTypes = propTypes;
TextLink.defaultProps = defaultProps;

export default React.memo(TextLink);
