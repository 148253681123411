import React from 'react';

export default () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
  >
    <title>Recipient</title>
    <path
      d="M17.294 8.203C17.294 5.334 14.945 3 12.058 3 9.17 3 6.82 5.334 6.82 8.203c0 1.777.902 3.349 2.274 4.288a9.03 9.03 0 00-3.442 2.145A8.912 8.912 0 003 21h1.415c0-4.187 3.429-7.594 7.643-7.594 2.887 0 5.236-2.334 5.236-5.203zm-9 .091A3.71 3.71 0 0112 4.588a3.71 3.71 0 013.706 3.706A3.71 3.71 0 0112 12a3.71 3.71 0 01-3.706-3.706zM21 13.06v4.24h-1.406v-1.727L15.73 19.49l-2.003-2.022L10.365 21l-1.012-.99 4.353-4.573 2.021 2.04 2.952-2.993h-1.896v-1.425H21z"
      fillRule="nonzero"
    />
  </svg>
);
