import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  compose,
  mapProps,
  withProps,
} from 'recompose';
import {
  Field,
  reduxForm,
  submit,
} from 'redux-form';
import {
  createStructuredSelector,
} from 'reselect';
import FormFieldDate from '../../../common/components/FormFieldDate';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import ProjectMilestoneSelect from '../../../common/selectors/ProjectMilestone';
import {
  YEAR_MONTH_DAY,
} from '../../../common/constants/date';
import {
  toYearMonthDay,
} from '../../../common/utils/date';
import Schema from '../../../common/utils/Schema';
import {
  formValue,
  property,
} from '../../../common/utilsClient/selectors';
import FormFieldSelect from '../../forms/FormFieldSelect';
import {
  selectOptionsPropTypes,
} from '../../forms/propTypes';
import Modal from '../Modal';
import {
  isParticipant,
} from './utils';

const Form = compose(
  withProps(({
    patients,
  }) => ({
    initialValues: {
      patients,
      milestoneDate: moment().format(YEAR_MONTH_DAY),
    },
  })),
  reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: new Schema({
      projectId: {
        type: String,
        label: 'Project',
      },
      milestoneId: {
        type: String,
        label: 'Milestone',
      },
      milestoneDate: {
        type: String,
        label: 'Milestone date',
      },
      clinicianId: {
        type: String,
        optional: true,
        label: 'Clinician',
      },
      caseManagerId: {
        type: String,
        optional: true,
        label: 'Case manager',
      },
    }).validator({
      noException: true,
    }),
  }),
  connect(() => createStructuredSelector({
    currentProjectId: formValue(property('form'))('projectId'),
    currentDate: formValue(property('form'))('date'),
    currentMilestone: ProjectMilestoneSelect.one().whereIdEquals(
      formValue(property('form'))('milestoneId'),
    ),
  })),
)(
  ({
    error,
    handleSubmit,
    milestoneOptions,
    currentProjectId,
    currentMilestone,
    currentDate,
    onSelectProject,
    onSubmit,
    projects,
  }) => {
    const pOptions = projects.map(p => ({
      value: p._id,
      label: p.name,
    }));

    let milestoneGracePeriod = {};
    if (currentMilestone && currentDate) {
      milestoneGracePeriod = currentMilestone.getSchedule(currentDate);
    }

    const milestoneDescription = [
      milestoneGracePeriod.dateStart
        ? `Start Date: ${moment(milestoneGracePeriod.dateStart).format(
          'DD/MM/YYYY',
        )}`
        : '(no start date)',
      '-',
      milestoneGracePeriod.dateEnd
        ? `End Date: ${moment(milestoneGracePeriod.dateEnd).format(
          'DD/MM/YYYY',
        )}`
        : '(no end date)',
    ].join(' ');

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && <p>{error}</p>}
        <Field
          component={FormFieldSelect}
          name="projectId"
          type="text"
          label="Project"
          options={pOptions}
          onSelect={onSelectProject}
          showSearch
        />
        {currentProjectId && (
          <React.Fragment>
            {milestoneOptions.length !== 0 && (
              <Field
                component={FormFieldSelect}
                key="milestoneId"
                name="milestoneId"
                label="Milestone"
                options={milestoneOptions}
                showSearch
              />
            )}
            <Stack>
              <Box.Primary>
                <Field
                  component={FormFieldDate}
                  key="milestoneDate"
                  name="milestoneDate"
                  label="Milestone Date"
                  extra={milestoneDescription}
                />
              </Box.Primary>
            </Stack>
          </React.Fragment>
        )}
      </form>
    );
  },
);

const RecruitFiltered = connect(null, (dispatch, {
  form,
}) => ({
  doSubmit: () => dispatch(submit(form)),
}))(({
  open,
  onCancel,
  doSubmit,
  isConfirmLoading,
  ...rest
}) => (
  <Modal
    title="Recruit Filtered"
    visible={open}
    confirmLoading={isConfirmLoading}
    onOk={doSubmit}
    onCancel={onCancel}
    maskClosable={false}
  >
    <Form
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  </Modal>
));

RecruitFiltered.propTypes = {
  form: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isConfirmLoading: PropTypes.bool,
  defaultProjectId: PropTypes.string,
  projectOptions: selectOptionsPropTypes,
};

RecruitFiltered.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isConfirmLoading: false,
  defaultProjectId: null,
  projectOptions: [],
};

export default mapProps(props => ({
  ...props,
  initialValues: {
    milestoneDate: toYearMonthDay(new Date()),
    projectId: props.patient
      ? !isParticipant(props.patient, props.defaultProjectId) &&
        props.defaultProjectId
      : props.defaultProjectId,
  },
}))(RecruitFiltered);
