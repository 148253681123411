import styled from 'styled-components/macro';
import React from 'react';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import LanguageSelector from './LanguageSelector';

const StyledLanguageSelector = styled(LanguageSelector)`
  && {
    .ant-select-selector {
      background: none !important;
      border: 0 !important;
      color: ${theme('color.primary')};
    }
  }
`;

const LanguageSelectorSimple = (props) => {
  return (
    <StyledLanguageSelector
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default LanguageSelectorSimple;
