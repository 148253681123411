import PropTypes from 'prop-types';
import {
  withHandlers,
  compose,
  branch,
  renderNothing,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  push,
} from 'connected-react-router';
import {
  property,
} from '../../common/utilsClient/selectors';
import {
  patientDetails,
} from '../../common/api/zedoc';
import RecipientSelect from '../../common/selectors/Recipient';
import ParticipationSelect from '../../common/selectors/Participation';
import PatientRecordSelect from '../../common/selectors/PatientRecord';
import {
  getPatientCurrentTab,
} from '../PatientsDirectory/selectors';
import {
  setPatientCurrentTab,
  setRemovePatientModalVisible,
  openEditNoteDialog,
} from '../PatientsDirectory/actions';
import {
  openMilestoneDialog,
} from '../ProjectsDashboard/actions';
import PatientCard from './PatientCard';
import {
  openProjectProfileDialog,
} from './store';

const getRecipientId = property('match.params.recipientId');

const Container = compose(
  ddp({
    selectors: () => {
      return {
        recipientId: getRecipientId,
        recipient: RecipientSelect.one().whereIdEquals(getRecipientId),
        participations: ParticipationSelect.all().where({
          recipientId: getRecipientId,
        }),
        patientRecords: PatientRecordSelect.all().where({
          recipientId: getRecipientId,
        }),
        patientCurrentTab: getPatientCurrentTab,
      };
    },
    subscriptions: (
      _,
      {
        match: {
          params: {
            recipientId,
          },
        },
      },
    ) => [
      patientDetails.withParams({
        recipientId,
      }),
    ],
  }),
  withHandlers({
    onTabChange: ({
      dispatch,
    }) => activeKey => dispatch(setPatientCurrentTab(activeKey)),
    onDangerouslyRemovePatient: ({
      dispatch,
      recipient,
    }) => () => recipient && dispatch(setRemovePatientModalVisible(true)),
    onAddNote: ({
      dispatch,
    }) => () => dispatch(openEditNoteDialog()),
    handleOnAdded: ({
      dispatch,
    }) => ({
      projectId,
      recipientId,
      milestoneId,
      commenceSurveyNow,
    }) => {
      if (commenceSurveyNow) {
        dispatch(push(`/projects/${projectId}`));
        dispatch(
          openMilestoneDialog({
            recipientId,
            milestoneId,
          }),
        );
      }
    },
    onAddToProject: ({
      dispatch,
      match: {
        params: {
          recipientId,
        },
      },
    }) => () => dispatch(
      openProjectProfileDialog({
        recipientId,
      }),
    ),
  }),
  branch(
    ({
      recipient,
    }) => !!recipient,
    x => x,
    renderNothing,
  ),
)(PatientCard);

Container.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      recipientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Container;
