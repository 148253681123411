import React, {
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import FormFieldWrapper from '../../../common/components/FormFieldWrapper';
import {
  formFieldPropTypesInput,
  formFieldPropTypesMeta,
} from '../../../common/utilsClient/propTypes';
import CodeMirror from '../CodeMirror';

const codeMirrorOptions = {
  mode: {
    name: 'javascript',
    json: true,
  },
  theme: 'xq-light',
  lineNumbers: true,
};

const FormFieldJson = forwardRef(
  ({
    id,
    label,
    required,
    disabled,
    meta,
    input,
    tooltip,
  }, forwardedRef) => {
    const {
      onChange,
    } = input;
    const handleOnBeforeChange = useCallback(
      (editor, data, value) => {
        if (onChange) {
          onChange(value);
        }
      },
      [
        onChange,
      ],
    );
    return (
      <FormFieldWrapper
        label={label}
        htmlFor={id}
        required={required}
        meta={meta}
        tooltip={tooltip}
      >
        <CodeMirror
          ref={forwardedRef}
          value={input.value || ''}
          options={{
            ...codeMirrorOptions,
            readOnly: disabled,
          }}
          onBeforeChange={handleOnBeforeChange}
          disabled
        />
      </FormFieldWrapper>
    );
  },
);

FormFieldJson.propTypes = {
  input: formFieldPropTypesInput.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  tooltip: PropTypes.string,
};

FormFieldJson.defaultProps = {
  label: '',
  disabled: false,
  required: false,
  tooltip: null,
};

export default FormFieldJson;
