import {
  DeleteOutlined,
  PlusOutlined,
  CaretUpFilled,
  CaretDownFilled,
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import {
  useTranslation,
} from 'react-i18next';
import Text from '../../common/components/base/Text';
import Button from '../../common/components/Button';
import Collapse from '../../common/components/Collapse';
import Divider from '../../common/components/Divider';
import Box from '../../common/components/primitives/Box';
import Sidebar from '../../common/components/primitives/Sidebar';
import Stack from '../../common/components/primitives/Stack';
import {
  Element,
  Field,
} from '../../common/containers/Questionnaire';
import QuestionModel from '../../common/models/Question';
import QuestionnaireModel from '../../common/models/Questionnaire';
import {
  theme,
} from '../../common/utilsClient/cssHelpers';
import FormFieldError from './FormFieldError';

const StyledCollectionWrapper = styled.div`
  > div[class^='Stack'] {
    border: 1px solid #e8e8e8;
    border-radius: ${theme('border.radius.2')};
    padding: ${theme('space.3')};
  }
`;

const Collection = ({
  elements,
  question,
  questionnaire,
  onAppend,
  Section,
}) => {
  const {
    t,
  } = useTranslation();

  const title = question.getTitle();
  const description = question.getDescription();
  const path = question.getSlug();
  return (
    <Collapse
      data-testid={`details-${title}`}
      items={[
        {
          title,
          key: '1',
          content: (
            <Stack>
              <Text.Paragraph importance="low">{description}</Text.Paragraph>
              <Box.Primary>
                <Stack
                  data-testid={`list-${title}`}
                  space={4}
                >
                  {map(elements, (elementId, i) => (
                    <Element
                      key={elementId}
                      id={elementId}
                    >
                      {({
                        handleRemove,
                        handleMoveUp,
                        handleMoveDown,
                      }) => (
                        <StyledCollectionWrapper>
                          <Stack>
                            <Sidebar
                              sidebar={(
                                <Button.Group>
                                  <Button
                                    icon={<CaretUpFilled />}
                                    size="small"
                                    disabled={i === 0}
                                    onClick={handleMoveUp}
                                  />
                                  <Button
                                    icon={<CaretDownFilled />}
                                    size="small"
                                    disabled={i === elements.length - 1}
                                    onClick={handleMoveDown}
                                  />
                                  <Button
                                    data-testid={`button-remove-${title}`}
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={handleRemove}
                                  />
                                </Button.Group>
                              )}
                            >
                              <Text.Paragraph importance="high">
                                {pluralize(title, 1)}
                                {' '}
                                {i + 1}
                              </Text.Paragraph>
                            </Sidebar>
                            <Divider />
                            <Section
                              questionnaire={questionnaire}
                              sectionId={question.id}
                            />
                          </Stack>
                        </StyledCollectionWrapper>
                      )}
                    </Element>
                  ))}
                  {isEmpty(elements) && 'None'}
                </Stack>
              </Box.Primary>
              <Sidebar
                sidebar={(
                  <Button
                    data-testid={`button-add-${path}`}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onAppend}
                  >
                    {t('add')}
                    {' '}
                    {pluralize(title.toLowerCase(), 1)}
                  </Button>
                )}
              >
                <Field
                  component={FormFieldError}
                  name="value"
                />
              </Sidebar>
            </Stack>
          ),
        },
      ]}
    />
  );
};

Collection.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.string),
  question: PropTypes.instanceOf(QuestionModel).isRequired,
  questionnaire: PropTypes.instanceOf(QuestionnaireModel).isRequired,
  onAppend: PropTypes.func.isRequired,
  Section: PropTypes.elementType.isRequired,
};

Collection.defaultProps = {
  elements: [],
};

export default Collection;
