import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '../../../../common/components/primitives/Box';
import Collapse from '../../../../common/components/Collapse';
import Empty from '../../../../common/components/base/Empty';
import {
  getIdentifier,
} from '../../../../common/utils/versions';
import PatientResultsTable from '../PatientResultsTable';

const PatientResults = ({
  projectId,
  recipientId,
  questionnaires,
  questionnaireNames,
}) => {
  const items = map(questionnaires, (questionnaire, questionnaireId) => {
    const identifier = getIdentifier(questionnaireId);
    const questionnaireName = questionnaireNames[identifier] || `${identifier}`;

    return {
      key: questionnaireId,
      title: questionnaireName,
      content: (
        <PatientResultsTable
          projectId={projectId}
          recipientId={recipientId}
          questionnaireId={questionnaireId}
          activities={questionnaire}
        />
      ),
    };
  });

  return (
    <Box.Primary>
      {!isEmpty(items) ? <Collapse items={items} /> : <Empty />}
    </Box.Primary>
  );
};

PatientResults.propTypes = {
  projectId: PropTypes.string.isRequired,
  recipientId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  questionnaires: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  questionnaireNames: PropTypes.object,
};

PatientResults.defaultProps = {
  questionnaires: {},
  questionnaireNames: {},
};

export default PatientResults;
