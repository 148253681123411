import {
  ddp,
} from '@theclinician/ddp-connector';
import map from 'lodash/map';
import filter from 'lodash/filter';
import React, {
  useState,
} from 'react';
import {
  compose,
} from 'recompose';
import {
  useDispatch,
} from 'react-redux';
import {
  createSelector,
} from 'reselect';
import Button from '../../../common/components/Button';
import Checkbox from '../../../common/components/Checkbox';
import Text from '../../../common/components/base/Text';
import Cluster from '../../../common/components/primitives/Cluster';
import Grid from '../../../common/components/primitives/Grid';
import Stack from '../../../common/components/primitives/Stack';
import UserSelect from '../../../common/selectors/User';
import ReportScheduleSelect from '../../../common/selectors/ReportSchedule';
import Modal from '../../../components/dialogs/Modal';
import Select from '../../../components/inputs/Select';
import {
  withModal,
} from '../../../utils/modal';
import {
  notifySuccess,
} from '../../../utils/notify';

import {
  property,
} from '../../../common/utilsClient/selectors';

import {
  REPORT_SCHEDULE_PERIODS,
} from '../../../common/constants';

import {
  apiReportsUpsertReportSchedule,
  apiReportsGetReport,
} from '../../../common/api/reportSchedule';

import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const daysValues = days.map(day => ({
  value: day,
  label: day,
}));
const periodValues = REPORT_SCHEDULE_PERIODS.map(period => ({
  value: period,
  label: period,
}));

const ShareReport = compose(
  ddp({
    subscriptions: (state, {
      powerBIReportId,
    }) => [
      {
        name: 'Users.all',
      },
      apiReportsGetReport.withParams({
        powerBIReportId,
      }),
    ],
    selectors: () => ({
      users: createSelector(
        UserSelect.all(),
        users => map(
          filter(users, ({
            emails,
          }) => Array.isArray(emails)),
          ({
            _id,
            name,
            emails,
          }) => ({
            value: _id,
            label: `${name} \u00B7 ${emails[0].address}`,
          }),
        ),
      ),
      reportSchedule: ReportScheduleSelect.one().where({
        powerBIReportId: property('powerBIReportId'),
      }),
    }),
  }),
  withModal(),
)(({
  users,
  powerBIReportId,
  reportSchedule,
  modal,
}) => {
  const [
    isAutomated,
    setAutomate,
  ] = useState(
    reportSchedule && reportSchedule.isAutomated,
  );
  const [
    userId,
    setUserId,
  ] = useState(
    reportSchedule && reportSchedule.recipientUserId.map(row => row.id),
  );
  const [
    period,
    setPeriod,
  ] = useState(
    (reportSchedule &&
      reportSchedule.isAutomated &&
      reportSchedule.schedule.period) ||
      'Daily',
  );
  const [
    day,
    setDay,
  ] = useState(
    (reportSchedule &&
      reportSchedule.isAutomated &&
      reportSchedule.schedule.day) ||
      'Monday',
  );

  const dispatch = useDispatch();
  const onCheckboxChange = ({
    target: {
      checked,
    },
  }) => setAutomate(checked);

  const onSendReport = () => {
    const recipientUserId = userId.map(id => ({
      id,
    }));

    const params = {
      powerBIReportId,
      recipientUserId,
      isAutomated,
    };

    if (isAutomated) {
      params.schedule = {
        period,
        day,
      };
    }

    dispatch(callMethod(apiReportsUpsertReportSchedule, params)).then(() => {
      notifySuccess('Report sent and settings updated.')();
      modal.cancel();
    });
  };

  return (
    <Modal
      title="Share Report"
      onCancel={modal.cancel}
      footer={(
        <>
          <Button onClick={modal.cancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={onSendReport}
          >
            Send
          </Button>
        </>
)}
      visible
    >
      <Stack space={1}>
        <Cluster>
          <Checkbox
            onChange={onCheckboxChange}
            defaultChecked={isAutomated}
          >
            Automate
          </Checkbox>
          {isAutomated && (
            <>
              <Text.Paragraph
                size="small"
                importance="high"
              >
                every
              </Text.Paragraph>
              <Select
                options={periodValues}
                onChange={value => setPeriod(value)}
                defaultValue={
                  (reportSchedule &&
                    reportSchedule.schedule &&
                    reportSchedule.schedule.period) ||
                  'Daily'
                }
              />
              <Text.Paragraph
                size="small"
                importance="high"
              >
                import Checkbox from
                '../../../../../common/components/Checkbox'; on
              </Text.Paragraph>
              <Select
                options={daysValues}
                onChange={value => setDay(value)}
                defaultValue={
                  (reportSchedule &&
                    reportSchedule.schedule &&
                    reportSchedule.schedule.day) ||
                  'Monday'
                }
              />
            </>
          )}
        </Cluster>
        <Grid>
          <Select
            mode="multiple"
            options={users}
            defaultValue={
              reportSchedule &&
              reportSchedule.recipientUserId.map(row => row.id)
            }
            onChange={value => setUserId(value)}
            showSearch
          />
        </Grid>
      </Stack>
    </Modal>
  );
});

ShareReport.propTypes = {};

ShareReport.defaultProps = {};

export default ShareReport;
