import React from 'react';
import PropTypes from 'prop-types';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  useSelector,
} from 'react-redux';
import Participation from '../../../../common/models/Participation';
import ProjectSelect from '../../../../common/selectors/Project';
import ProjectProfile from './ProjectProfile';
import {
  apiZedocOneProject,
} from '../../../../common/api/zedoc';

const Container = ({
  participation,
}) => {
  const {
    _id: participationId,
    projectId,
    recipientId,
    state,
  } = participation;

  const {
    ready,
  } = useDDPSubscription(
    apiZedocOneProject.withParams({
      projectId,
    }),
  );

  const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));

  if (!ready || !project) {
    return null;
  }

  return (
    <ProjectProfile
      recipientId={recipientId}
      projectId={projectId}
      projectName={project.name}
      participationId={participationId}
      participationState={state}
    />
  );
};

Container.propTypes = {
  participation: PropTypes.instanceOf(Participation).isRequired,
};

export default Container;
