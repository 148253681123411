import React from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import Modal from '../../../components/dialogs/Modal';

const AuditLogDetails = ({
  rawLogEntry,
  onCancel,
  visible,
}) => {
  const {
    t,
  } = useTranslation();
  return (
    <Modal
      title={t('log', {
        count: 1,
      })}
      onOk={onCancel}
      onCancel={onCancel}
      visible={visible}
    >
      <pre>{JSON.stringify(rawLogEntry, null, 2)}</pre>
    </Modal>
  );
};

AuditLogDetails.propTypes = {
  rawLogEntry: PropTypes.objectOf(PropTypes.any).isRequired,
  onCancel: PropTypes.func,
  visible: PropTypes.bool,
};

AuditLogDetails.defaultProps = {
  onCancel: null,
  visible: true,
};

export default AuditLogDetails;
