let savedHash;

if (typeof window !== 'undefined') {
  savedHash = window.location.hash;
}

/**
 * Extract login token from location hash.
 * @param {String} [hash=savedHash]
 */
export const matchToken = (hash = savedHash) => {
  // The token will consist of the following characters
  // var BASE64_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_";
  // See: https://github.com/meteor/meteor/blob/master/packages/random/random.js#L224
  const match = /#\/t\/([a-zA-Z0-9-_.]+)/.exec(hash);
  return match && match[1];
};

export const clearHash = (history, {
  replace = false,
  state = {},
} = {}) => {
  const {
    pathname,
    search,
  } = history.location;
  const path = pathname + search;
  if (replace) {
    history.replace(path, state);
  } else {
    history.push(path, state);
  }
};
