import React from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';
import {
  Field,
  reduxForm,
  submit,
} from 'redux-form';
import Schema from '../../../common/utils/Schema';
import FormFieldText from '../../forms/FormFieldText';
import Modal from '../Modal';

const Form = reduxForm({
  form: 'resetPassword',
  validate: new Schema({
    newPassword: Schema.Password,
  }).validator({
    noException: true,
  }),
})(({
  error,
  handleSubmit,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Field
      component={FormFieldText}
      name="newPassword"
      type="password"
      label="New password"
    />
    {error && (
      <p>
        <strong>{error}</strong>
      </p>
    )}
  </form>
));

const ResetPassword = connect(
  () => ({}),
  dispatch => ({
    doSubmit: () => dispatch(submit('resetPassword')),
  }),
)(({
  open,
  onCancel,
  onSubmit,
  doSubmit,
}) => (
  <Modal
    title="Reset password"
    visible={open}
    onOk={doSubmit}
    onCancel={onCancel}
  >
    <p>Please enter a new password.</p>
    <Form onSubmit={onSubmit} />
  </Modal>
));

ResetPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

ResetPassword.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
};

export default ResetPassword;
