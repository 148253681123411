import {
  createSelector,
} from 'reselect';
import {
  registerStore,
} from '../../common/utilsClient/multiReducer';
import {
  SORTER_TYPE__PROPERTY,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__INCLUDE,
  SORTING_ORDER__DESCENDING,
} from '../../common/constants';
import {
  getSearchPhrase,
} from '../../store/ui/search/selectors';

const store = registerStore({
  path: 'ui.screens.SettingsGroups',
  initialState: {
    filters: [],
    activeDomain: null,
    editUsersGroupDialogVisible: false,
    createNewUsersGroup: false,
    currentGroup: {
      type: '',
      name: '',
      belongsTo: '',
      roles: [],
    },
  },
});

const constant = x => () => x;

export const getSorter = constant({
  type: SORTER_TYPE__PROPERTY,
  order: SORTING_ORDER__DESCENDING,
  settings: {
    id: 'createdAt',
  },
});

const toBoolean = x => !!x;
export const getUsersGroupDialogVisible = store.get(
  'editUsersGroupDialogVisible',
  toBoolean,
  false,
);

export const getGroup = createSelector(
  store.get('currentGroup'),
  group => group,
);

export const getCreateUsersGroupFlag = store.get(
  'createNewUsersGroup',
  toBoolean,
  false,
);

export const getActiveDomain = createSelector(
  store.get('activeDomain'),
  activeDomain => activeDomain || '', // NOTE: '' is considered valid domain, other value like null, is not
);

// export const getFilters = store.get('filters');
// export const setFilters = store.create.set('filters');

export const getFilters = createSelector(
  getSearchPhrase,
  getActiveDomain,
  (text, domain) => {
    return [
      {
        id: 'filter_by_type_or_name',
        type: FILTER_TYPE__ANY_OF,
        condition: FILTER_CONDITION__TEXT,
        settings: {
          filters: [
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'type',
              },
            },
            {
              type: FILTER_TYPE__PROPERTY,
              settings: {
                id: 'name',
              },
            },
          ],
        },
        state: {
          text,
        },
      },
      domain && {
        id: 'filter_by_domain',
        type: FILTER_TYPE__PROPERTY,
        condition: FILTER_CONDITION__INCLUDE,
        settings: {
          id: 'belongsTo',
        },
        state: {
          include: [
            domain,
          ],
        },
      },
    ];
  },
);

export const setGroup = store.create.set('currentGroup');
export const setActiveDomain = store.create.set('activeDomain');
export const setUsersGroupDialogVisible = store.create.set(
  'editUsersGroupDialogVisible',
);

export const setCreateUsersGroupFlag = store.create.set('createNewUsersGroup');

export default store;
