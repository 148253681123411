import React, {
  useMemo,
} from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  useHistory,
} from 'react-router-dom';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import Table from '../../../components/lists/Table';
import {
  apiAdminSearchRoles,
} from '../../../common/api/admin';
import Highlighter from '../../../common/components/Highlighter';
import RoleSelect from '../../../common/selectors/Role';
import usePagination from '../../../utils/usePagination';
import useHighlight from '../../../utils/useHighlight';
import {
  getFilters,
  getSorter,
} from '../store';

const UsersGroupsTable = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const {
    t,
  } = useTranslation();
  const {
    ready: subscriptionsReady,
    items: roles,
    getPaginationProps,
  } = usePagination({
    debounceMs: 500,
    selector: RoleSelect,
    getSubscription: (currentPage, resultsPerPage) => apiAdminSearchRoles.withParams({
      filters: map(filters, filter => omit(filter, 'meta')),
      sorter,
      controlId: '$meta.id',
      pageIndex: currentPage - 1,
      resultsPerPage,
    }),
  });

  const highlight = useHighlight(filters, [
    'name',
    'belongsTo',
  ]);

  const history = useHistory();
  const handleSelectRole = (role) => {
    history.push(`/settings/roles/${role._id}`);
  };

  const columns = useMemo(
    () => [
      {
        title: t('Name'),
        dataIndex: 'name',
        render: (_, role) => (
          <Highlighter
            text={role.getName()}
            highlight={highlight}
          />
        ),
      },
      {
        title: t('forms:belongsTo.label'),
        dataIndex: 'belongsTo',
        render: (_, role) => (
          <Highlighter
            text={role.getDomain()}
            highlight={highlight}
          />
        ),
      },
      {
        title: t('forms:date.label'),
        dataIndex: 'createdAt',
        render: (_, role) => role.formatCreatedAt(),
      },
    ],
    [
      t,
      highlight,
    ],
  );

  return (
    <Table
      rowKey="_id"
      dataSource={roles}
      columns={columns}
      loading={!subscriptionsReady}
      onRow={role => ({
        onClick: () => handleSelectRole(role),
      })}
      pagination={getPaginationProps()}
    />
  );
});

export default UsersGroupsTable;
