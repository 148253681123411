import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  ADMIN_SEARCH_ROLE,
} from '../../permissions';

// eslint-disable-next-line import/prefer-default-export
export const apiCollectionsRolesAll = new ApiSpec({
  name: 'api.collections.Roles.all',
  resources: [
    {
      variables: {
        scope: ADMIN_SEARCH_ROLE,
      },
    },
  ],
  schema: new Schema(),
});
