import Schema from '../../utils/Schema';
import ApiSpec from '../ApiSpec';
import {
  ORGANIZATION_ACCESS_USER_PII,
} from '../../permissions';

// eslint-disable-next-line import/prefer-default-export
export const getUserNames = new ApiSpec({
  name: 'api.aggregations.Users.getUserNames',
  permissions: [
    ORGANIZATION_ACCESS_USER_PII,
  ],
  schema: new Schema(),
});
