import React, {
  useState,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import PreviewTabularData from '../../../components/dialogs/PreviewTabularData';
import Button from '../../../common/components/Button';
import Cluster from '../../../common/components/primitives/Cluster';
import TranslationsSelect from '../../../components/inputs/TranslationsSelect';
import usePresentableAnswersSheet from '../../../utils/usePresentableAnswersSheet';
import copyResponsesToClipboard from '../../../utils/copyResponsesToClipboard';
import {
  getActiveAnswersSheet,
  getPreviewPatientResponseDialogVisible,
} from '../selectors';
import {
  closePreviewPatientResponseDialog,
} from '../actions';

const PreviewPatientResponse = () => {
  const {
    t,
  } = useTranslation();
  const dispatch = useDispatch();
  const answersSheet = useSelector(getActiveAnswersSheet);
  const open = useSelector(getPreviewPatientResponseDialogVisible);

  const [
    currentLanguage,
    setCurrentLanguagea,
  ] = useState();

  const {
    meta,
    responses,
  } = usePresentableAnswersSheet(
    answersSheet && answersSheet._id,
    currentLanguage,
  );

  const title = t('previewAnswers');
  const handleCopyResponses = () => copyResponsesToClipboard(meta, responses);
  const onClose = () => dispatch(closePreviewPatientResponseDialog());
  const footer = (
    <Cluster>
      <TranslationsSelect
        questionnaireId={answersSheet?.questionnaireId}
        onChange={setCurrentLanguagea}
        value={currentLanguage}
        compact
      />
      <Cluster>
        <Button
          data-testid="button-copy-responses"
          onClick={handleCopyResponses}
        >
          {t('copyResponses')}
        </Button>
        <Button
          data-testid="button-close-preview"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
      </Cluster>
    </Cluster>
  );

  return (
    <PreviewTabularData
      dataSource={responses}
      open={open}
      title={title}
      onClose={onClose}
      footer={footer}
    />
  );
};

export default PreviewPatientResponse;
