import React from 'react';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  Redirect,
} from 'react-router-dom';
import {
  compose,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import Loading from '../common/components/Loading';
import CurrentUserSelect from '../common/selectors/CurrentUser';
import ProjectSelect from '../common/selectors/Project';
import {
  apiZedocMyProjects,
} from '../common/api/zedoc';
import {
  userDetails,
} from '../common/api/currentUser';

const HomePageRedirect = compose(
  ddp({
    subscriptions: [
      apiZedocMyProjects.withParams({}),
      userDetails.withParams(),
    ],
    renderLoader: null,
    selectors: () => createStructuredSelector({
      currentUser: CurrentUserSelect.user(),
      redirectTo: createSelector(
        (state, props) => props.location,
        CurrentUserSelect.isOnboarded(),
        ProjectSelect.all().whereOneOfMembersIs(CurrentUserSelect.user()),
        (location, isCurrentUserOnboarded, projects) => {
          if (location && location.hash) {
            return null;
          }
          if (!isCurrentUserOnboarded) {
            return '/welcome';
          }
          if (projects.length === 1) {
            return `/projects/${projects[0]._id}`;
          }
          return '/projects';
        },
      ),
    }),
  }),
)(({
  redirectTo,
  subscriptionsReady,
}) => {
  if (!subscriptionsReady) {
    return <Loading />;
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return null;
});

export default HomePageRedirect;
