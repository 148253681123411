import omitBy from 'lodash/omitBy';
import {
  createStructuredSelector,
} from 'reselect';
import createSelectors from './createSelectors';
import Participation from '../models/Participation';
import Variable from '../models/Variable';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../permissions';
import PermissionsDomain from '../models/PermissionsDomain';
import CurrentUserSelect from './CurrentUser';
import VariableSelect from './Variable';

const Select = createSelectors(
  Participation,
  {},
  {
    transformContextSelector: createStructuredSelector({
      piiRealm: CurrentUserSelect.getPermissionsRealm(
        PATIENT_ACCESS_PATIENT_PII_VARIABLES,
      ),
      variablesDb: VariableSelect.all().byId(),
    }),
    transform: (rawParticipation, {
      piiRealm,
      variablesDb,
    } = {}) => {
      if (
        PermissionsDomain.belongsToRealm(rawParticipation.ownership, piiRealm)
      ) {
        return rawParticipation;
      }
      const newParticipation = omitBy(rawParticipation, (value, key) => {
        const variable = new Variable({
          scopeName: Participation.scopeName,
          nativeKey: key,
        });
        return variable.isPII();
      });
      if (newParticipation.variables) {
        newParticipation.variables = omitBy(
          newParticipation.variables,
          (value, key) => {
            const variable = variablesDb && variablesDb[key];
            return !variable || variable.isPII();
          },
        );
      }
      return newParticipation;
    },
  },
);

export default Select;
