import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import EditActivityDialog from '../../../components/dialogs/EditActivity';
import {
  getEditActivityDialog,
} from '../selectors';
import {
  closeEditActivityDialog,
} from '../actions';

const ConnectedEditActivityDialog = ({
  projectId,
}) => {
  const dispatch = useDispatch();
  const handleOnCancel = useCallback(() => {
    dispatch(closeEditActivityDialog());
  }, [
    dispatch,
  ]);
  const options = useSelector(getEditActivityDialog);
  if (!options) {
    return (
      <EditActivityDialog
        visible={false}
        projectId={projectId}
      />
    );
  }
  const {
    participationId,
    activityId,
    recipientId,
  } = options;
  return (
    <EditActivityDialog
      visible
      projectId={projectId}
      recipientId={recipientId}
      participationId={participationId}
      activityId={activityId}
      onCancel={handleOnCancel}
    />
  );
};

ConnectedEditActivityDialog.propTypes = {
  projectId: PropTypes.string,
};

ConnectedEditActivityDialog.defaultProps = {
  projectId: null,
};

export default ConnectedEditActivityDialog;
