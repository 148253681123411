/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const BindingsForm = ({
  projectId,
  variables,
  questionnaire,
}) => {
  const collectionId = 'g_message_template_bindings';

  const questionsIds = [
    collectionId,
  ];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach(question => questionsIds.push(question.id));

  questionsIds.forEach(questionId => questionnaire
    .getChildQuestions(questionId)
    .forEach(question => questionsIds.push(question.id)));

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={questionsIds}
      valueSetId="zedoc/variables@x.x.x"
      nameId="g1_template_variable"
      isTertiary
    />
  );
};

export default BindingsForm;
