import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import find from 'lodash/find';
import React, {
  useRef,
  useMemo,
  useCallback,
} from 'react';
import {
  useDDPCall,
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import PropTypes from 'prop-types';
import {
  createSelector,
} from 'reselect';
import {
  useSelector,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  one as oneRecipient,
  insert,
  update,
} from '../../../common/api/collections/Recipients';
import {
  apiZedocIdentifierVariables,
} from '../../../common/api/zedoc';
import {
  PATIENT_CREATE_PATIENT,
  PATIENT_UPDATE_PATIENT,
  PATIENT_ATTACH_PARTICIPATION,
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
} from '../../../common/permissions';
import {
  EMAIL_TYPE_OPTIONS,
  PHONE_TYPE_OPTIONS,
} from '../../../common/constants';
import RecipientSelect from '../../../common/selectors/Recipient';
import VariableSelect from '../../../common/selectors/Variable';
import Modal from '../Modal';
import Stack from '../../../common/components/primitives/Stack';
import Loading from '../../../common/components/Loading';
import Button from '../../../common/components/Button';
import FormFieldSelect from '../../forms/FormFieldSelect';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import branding from '../../../utils/branding';
import Form, {
  useForm,
} from '../../forms/Form';
import usePermissionsRealm from '../../../utils/usePermissionsRealm';
import usePermission from '../../../utils/usePermission';

const toChoices = (options, t) => {
  return map(options, ({
    value,
    label,
  }) => {
    return {
      const: value,
      title: t(label),
    };
  });
};

const renderSelect = ({
  // eslint-disable-next-line react/prop-types
  forwardedRef,
  ...props
}) => {
  return (
    <FormFieldSelect
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

const EditPatient = ({
  recipientId,
  onCancel,
  onSubmitted,
  visible,
}) => {
  const {
    t,
  } = useTranslation();

  const recipientProperties = useRef();

  const recipient = useSelector(
    RecipientSelect.one().whereIdEquals(recipientId),
  );

  const identifierVariables = useSelector(
    useMemo(() => {
      return createSelector(
        VariableSelect.all().where({
          nativeKey: 'identifiers.$.value',
        }),
        variables => uniqBy(variables, variable => variable.getIdentifierNamespace()),
      );
    }, []),
  );

  const {
    ready: recipientReady,
  } = useDDPSubscription(
    recipientId &&
      oneRecipient.withParams({
        recipientId,
      }),
  );

  const {
    ready: variablesReady,
  } = useDDPSubscription(
    apiZedocIdentifierVariables.withParams({}),
  );

  const allReady = variablesReady && recipientReady;

  const {
    ddpCall,
    ddpIsPending,
  } = useDDPCall();

  const handleOnSave = useCallback(
    (options) => {
      if (ddpIsPending) {
        return;
      }
      if (recipientId) {
        recipientProperties.current
          .submit()
          .then((formValues) => {
            return ddpCall(
              update.withParams({
                recipientId,
                ...formValues,
              }),
            )
              .then(() => {
                if (onSubmitted) {
                  return onSubmitted(recipientId, options);
                }
                return undefined;
              })
              .then(
                notifySuccess(
                  t('confirmations:editRecipient.success', {
                    context: branding,
                  }),
                ),
              );
          })
          .then(onCancel)
          .catch(notifyError());
      } else {
        recipientProperties.current
          .submit()
          .then((formValues) => {
            return ddpCall(insert.withParams(omitBy(formValues, isNil)))
              .then((newRecipientId) => {
                if (onSubmitted) {
                  return onSubmitted(newRecipientId, options);
                }
                return undefined;
              })
              .then(
                notifySuccess(
                  t('confirmations:addToProject.success', {
                    context: branding,
                  }),
                ),
              );
          })
          .then(onCancel)
          .catch(notifyError());
      }
    },
    [
      recipientProperties,
      recipientId,
      onCancel,
      onSubmitted,
      t,
      ddpCall,
      ddpIsPending,
    ],
  );

  const handleOnSaveAndAddToProject = useCallback(() => {
    return handleOnSave({
      addToProject: true,
    });
  }, [
    handleOnSave,
  ]);

  const {
    permissionsDomains,
  } = usePermissionsRealm([
    PATIENT_CREATE_PATIENT,
  ]);

  const canSeePII = usePermission([
    PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  ], {
    relativeTo: recipient && recipient.getDomains(),
  });

  const hasStructuredName = recipient && recipient.hasStructuredName();
  const schema = useMemo(() => {
    const newSchema = {
      type: 'object',
      required: [
        'domains',
      ],
      properties: {
        domains: {
          type: 'array',
          items: {
            type: 'string',
            anyOf: map(permissionsDomains, (domain) => {
              return {
                const: domain._id,
                title: domain.name,
              };
            }),
          },
        },
        name: {
          type: 'object',
          properties: {},
        },
        identifiers: {
          type: 'array',
          items: {
            type: 'object',
            required: [
              'value',
              'namespace',
            ],
            properties: {
              value: {
                type: 'string',
              },
              namespace: {
                oneOf: map(identifierVariables, (variable) => {
                  return {
                    const: variable.getIdentifierNamespace(),
                    title: variable.getTitle(),
                  };
                }),
              },
            },
          },
        },
        emails: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                oneOf: toChoices(EMAIL_TYPE_OPTIONS, t),
              },
              address: {
                type: 'string',
                format: 'email',
              },
            },
          },
        },
        phones: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                oneOf: toChoices(PHONE_TYPE_OPTIONS, t),
              },
              number: {
                type: 'string',
                format: 'phone',
              },
            },
          },
        },
      },
    };
    if (!canSeePII) {
      newSchema.properties.name.properties.text = false;
      newSchema.properties.identifiers = false;
      newSchema.properties.emails = false;
      newSchema.properties.phones = false;
    } else if (hasStructuredName) {
      Object.assign(newSchema.properties.name.properties, {
        given: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        family: {
          type: 'string',
        },
      });
    } else {
      Object.assign(newSchema.properties.name.properties, {
        text: {
          type: 'string',
        },
      });
    }
    return newSchema;
  }, [
    t,
    permissionsDomains,
    identifierVariables,
    hasStructuredName,
    canSeePII,
  ]);

  const initialValues = useMemo(() => {
    if (!allReady) {
      return null;
    }
    const allVariables = {
      domains: [],
    };
    if (recipient) {
      allVariables.domains = recipient.getDomains();
      allVariables.name = recipient.name;
      allVariables.phones = recipient.phones;
      allVariables.emails = recipient.emails;
      allVariables.identifiers = filter(
        recipient.identifiers,
        // NOTE: Only include those identifiers, whose namespaces are represented by a variable, which is
        //       accessible to the current user. The insert/ update method will take care to take this into
        //       account, i.e. the "hidden" identifiers will not be remove from the recipient.
        identifier => !!find(
          identifierVariables,
          variable => identifier.namespace === variable.getIdentifierNamespace(),
        ),
      );
    }
    return allVariables;
  }, [
    allReady,
    recipient,
    identifierVariables,
  ]);

  const fields = useMemo(() => {
    const newFields = {
      '': {
        children: [
          'domains',
          'name',
          'identifiers',
          'phones',
          'emails',
        ],
      },
      domains: {
        label: t('forms:belongsTo.label'),
      },
      name: {
        label: '',
      },
      'name.text': {
        label: t('forms:name.label'),
      },
      'name.given': {
        label: t('forms:givenName.label', {
          count: 0,
        }),
      },
      'name.family': {
        label: t('forms:familyName.label'),
      },
      identifiers: {
        label: t('forms:identifier.label', {
          count: 0,
        }),
        children: [
          'namespace',
          'value',
        ],
      },
      'identifiers.namespace': {
        label: '',
        render: renderSelect,
      },
      'identifiers.value': {
        label: '',
      },
      phones: {
        label: t('forms:phone.label', {
          count: 0,
        }),
      },
      'phones.number': {
        label: '',
      },
      'phones.type': {
        label: '',
        render: renderSelect,
      },
      emails: {
        label: t('forms:email.label', {
          count: 0,
        }),
      },
      'emails.address': {
        label: '',
      },
      'emails.type': {
        label: '',
        render: renderSelect,
      },
    };
    return newFields;
  }, [
    t,
  ]);

  const formName = recipientId ? `edit_patient_${recipientId}` : 'add_patient';
  const form = useForm(formName, schema);
  const currentDomains = form.useValue('domains');
  const canEnrollPatient = usePermission(PATIENT_ATTACH_PARTICIPATION, {
    relativeTo: currentDomains,
  });
  const canUpdatePatient = usePermission(PATIENT_UPDATE_PATIENT, {
    relativeTo: recipient && recipient.getDomains(),
  });

  const confirmLoading = !allReady || ddpIsPending;

  return (
    <Modal
      title={
        recipient || !allReady
          ? t('editRecipient', {
            context: branding,
          })
          : t('addRecipient', {
            context: branding,
          })
      }
      onCancel={onCancel}
      visible={visible}
      footer={(
        <>
          <Button
            data-testid="button-cancel"
            onClick={onCancel}
            hasBorder={false}
          >
            {t('cancel')}
          </Button>
          <Button
            data-testid="button-save"
            loading={confirmLoading}
            onClick={handleOnSave}
            disabled={recipient && !canUpdatePatient}
          >
            {t('save')}
          </Button>
          {!recipient && (
            <Button
              data-testid="add-patient-save-add-to-project"
              type="primary"
              loading={confirmLoading}
              onClick={handleOnSaveAndAddToProject}
              disabled={!canEnrollPatient}
            >
              {t('saveAndAddToProject')}
            </Button>
          )}
        </>
      )}
    >
      <Stack space={4}>
        <Form
          data-testid="project-profile-form"
          ref={recipientProperties}
          name={formName}
          initialValues={initialValues}
          schema={schema}
          fields={fields}
        />
        {!allReady && <Loading />}
      </Stack>
    </Modal>
  );
};

EditPatient.propTypes = {
  recipientId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmitted: PropTypes.func,
  visible: PropTypes.bool,
};

EditPatient.defaultProps = {
  recipientId: null,
  onCancel: null,
  onSubmitted: null,
  visible: true,
};

export default EditPatient;
