import {
  Check,
  ExclamationTriangle,
} from 'styled-icons/fa-solid';
import {
  DotFill,
} from 'styled-icons/octicons';
import styled, {
  css,
} from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Text from '../base/Text';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const STATUS_FINISH = 'finish';
const FANCY_COLOR = '#577496';
const BAR_HEIGHT = 4;
const DOT_SIZE = 2;

const statusStyles = {
  default: css`
    > div:first-child {
      background-color: ${FANCY_COLOR};
      transform: scale(0.3);
    }
    > div:first-child > span {
      color: ${FANCY_COLOR};
    }
  `,
  error: css`
    > div:first-child {
      background-color: ${theme('color.error')};
    }
  `,
  process: css`
    > div:first-child {
      background-color: ${theme('color.primary')};
    }
  `,
  [STATUS_FINISH]: css`
    > div:first-child {
      background-color: ${theme('color.primary')};
    }
    + li::after {
      background-color: ${theme('color.primary')};
    }
  `,
};

const Component = styled.li`
  cursor: pointer;
  list-style-type: none;
  float: left;
  position: relative;
  text-align: center;
  padding: 0 ${theme('space.2')};

  > div:first-child {
    position: relative;
    width: ${DOT_SIZE}rem;
    height: ${DOT_SIZE}rem;
    margin: 0 auto ${theme('space.2')} auto;
    border-radius: 50%;
    z-index: 1;

    svg {
      height: 100%;
      width: 100%;
      padding: 0.5em;
      color: ${theme('color.onPrimary')};
    }
  }

  &::after {
    width: 100%;
    height: ${BAR_HEIGHT}px;
    content: '';
    position: absolute;
    background-color: ${FANCY_COLOR};
    top: ${`calc(${DOT_SIZE / 2}rem - ${BAR_HEIGHT / 2}px)`};
    left: -50%;
  }

  &:first-child:after {
    content: none;
  }

  ${props => statusStyles[props.status]};
`;

const icons = {
  default: null,
  error: <ExclamationTriangle />,
  process: <DotFill />,
  [STATUS_FINISH]: <Check />,
};

const IconContainer = styled.div`
  height: 2rem;
  width: 2rem;
`;

const Milestone = ({
  title,
  status,
  onClick,
}) => {
  return (
    <Component
      status={status}
      onClick={onClick}
    >
      <IconContainer data-testid={`icon-${title}-${status}`}>
        {icons[status]}
      </IconContainer>
      <Text.Span
        data-testid={`label-step-${title}`}
        token="title6"
        isClipped
      >
        {title}
      </Text.Span>
    </Component>
  );
};

Milestone.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  onClick: PropTypes.func,
};

Milestone.defaultProps = {
  title: null,
  status: 'default',
  onClick: () => {},
};

export default Milestone;
