import {
  QUESTION_TYPE__EMPTY,
  QUESTION_TYPE__MEDIA,
} from '../../../constants';
import Question from '../Question';

export default class QuestionEmpty extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__EMPTY;
  }
}

QuestionEmpty.expectedRawKeys = [];
QuestionEmpty.expectedValueType = false;
QuestionEmpty.editorSettings = {};

Question.types[QUESTION_TYPE__EMPTY] = QuestionEmpty;
Question.types[QUESTION_TYPE__MEDIA] = QuestionEmpty;
