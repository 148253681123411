/* eslint-disable react/prop-types */
import {
  DeleteOutlined,
} from '@ant-design/icons';
import {
  useTranslation,
} from 'react-i18next';
import React, {
  useMemo,
} from 'react';
import Text from '../../../common/components/base/Text';
import Modal from '../../dialogs/Modal';
import Filters from './Filters';
import Button from '../../../common/components/Button';
import {
  getValues,
} from './helpers';

const FilterModal = ({
  open,
  onOk,
  onCancel,
  onOpen,
  onClearFilters,
  setFilter,
  clearFilter,
  hasFilters,
  projectId,
  boundFilters,
  appliedFilters,
  tags,
}) => {
  const {
    t,
  } = useTranslation();

  const filtersLength = useMemo(() => getValues(appliedFilters).length, [
    appliedFilters,
  ]);

  return (
    <>
      <Modal
        title={t('filter', {
          count: 0,
        })}
        visible={open}
        onOk={onOk}
        onCancel={onCancel}
      >
        <Filters
          projectId={projectId}
          filters={boundFilters}
          tags={tags}
          setFilter={setFilter}
          clearFilter={clearFilter}
        />
      </Modal>
      <Button.Group className="zedoc-tour__filter">
        <Button
          data-testid="patient-filters"
          onClick={onOpen}
        >
          {filtersLength ? (
            <>
              <Text.Span importance="high">{filtersLength}</Text.Span>
              &nbsp;
              {t('filterActive', {
                count: filtersLength,
              })}
            </>
          ) : (
            t('addFilter', {
              count: 0,
            })
          )}
        </Button>
        {hasFilters && (
          <Button
            data-testid="patient-clean-filters"
            icon={<DeleteOutlined />}
            onClick={onClearFilters}
          />
        )}
      </Button.Group>
    </>
  );
};

export default FilterModal;
