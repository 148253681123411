import React from 'react';
import PropTypes from 'prop-types';
import ProjectFilter from '../../../common/models/ProjectFilter';
import Grid from '../../../common/components/primitives/Grid';
import FormItem from '../../../common/components/FormItem';
import FilterCheckbox from './FilterCheckbox';
import FilterSelect from './FilterSelect';

const ITEMS_MAX_COMFORTABLE_LENGTH = 4;

const Filter = ({
  items,
  filter,
  onChange,
  values,
}) => (items && items.length > 0 ? (
  <FormItem label={filter.title}>
    {items.length < ITEMS_MAX_COMFORTABLE_LENGTH ? (
      <FilterCheckbox
        items={items}
        filter={filter}
        onChange={onChange}
        values={values}
      />
    ) : (
      <Grid>
        <FilterSelect
          items={items}
          filter={filter}
          onChange={onChange}
          values={values}
        />
      </Grid>
    )}
  </FormItem>
) : null);

Filter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.instanceOf(ProjectFilter).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

Filter.defaultProps = {
  items: [],
  values: [],
};

export default Filter;
