import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  compose,
  withProps,
  withHandlers,
} from 'recompose';
import {
  connect,
} from 'react-redux';
import {
  Field,
  reduxForm,
  submit,
} from 'redux-form';
import Schema from '../../../common/utils/Schema';
import FormFieldDate from '../../../common/components/FormFieldDate';
import Modal from '../Modal';

const defaultForm = 'Components | Dialogs | Snooze';

const SnoozeForm = reduxForm({
  form: defaultForm,
  validate: new Schema({
    snoozeEnd: {
      type: String,
      yearMonthDay: true,
    },
  }).validator({
    noException: true,
  }),
})(({
  error,
  handleSubmit,
  onSubmit,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Field
      component={FormFieldDate}
      name="snoozeEnd"
      label="Snooze End (8AM on any chosen day)"
    />
    {error && (
      <p>
        <strong>{error}</strong>
      </p>
    )}
  </form>
));

const Snooze = compose(
  connect(
    () => ({}),
    (dispatch, {
      form,
    }) => ({
      doSubmit: () => dispatch(submit(form)),
    }),
  ),
  withProps(({
    snoozeEnd: initialSnoozeEnd,
  }) => ({
    initialValues: {
      snoozeEnd: initialSnoozeEnd,
    },
  })),
  withHandlers({
    onSubmit: ({
      onSubmit,
    }) => ({
      snoozeEnd,
    }) => onSubmit({
      // NOTE: Currently, we always time set to 8 am
      snoozeEnd: moment(snoozeEnd)
        .hours(8)
        .toDate(),
    }),
  }),
)(({
  open,
  form,
  message,
  onCancel,
  onSubmit,
  doSubmit,
  initialValues,
}) => (
  <Modal
    title="Snooze Patient"
    message={message}
    visible={open}
    onOk={doSubmit}
    onCancel={onCancel}
  >
    <SnoozeForm
      form={form}
      onSubmit={onSubmit}
      initialValues={initialValues}
    />
  </Modal>
));

Snooze.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  snoozeEnd: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

Snooze.defaultProps = {
  form: defaultForm,
  message: '',
  snoozeEnd: null,
  onCancel: () => {},
  onSubmit: () => {},
};

export default Snooze;
