import styled from 'styled-components/macro';
import React from 'react';
import Spinner from '../Spinner';

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const Loading = () => (
  <LoadingWrapper>
    <Spinner size="18" />
  </LoadingWrapper>
);

export default Loading;
