export const MESSAGE_TYPE__ACTION = 'action';
export const MESSAGE_TYPE__MUTATION = 'mutation';
export const MESSAGE_TYPE__UNKNOWN = 'unknown';

export const MESSAGE_TYPES = [
  MESSAGE_TYPE__ACTION,
  MESSAGE_TYPE__MUTATION,
  MESSAGE_TYPE__UNKNOWN,
];

export const MUTATION_TYPE__INSERT = 'insert';
export const MUTATION_TYPE__UPDATE = 'update';
export const MUTATION_TYPE__REMOVE = 'remove';

export const MUTATION_TYPES = [
  MUTATION_TYPE__INSERT,
  MUTATION_TYPE__UPDATE,
  MUTATION_TYPE__REMOVE,
];

export const ACTION_TYPE__LOGIN = 'login';
export const ACTION_TYPE__LOGOUT = 'logout';
export const ACTION_TYPE__AMEND = 'amend';
export const ACTION_TYPE__FINALIZE = 'finalize';
export const ACTION_TYPE__MANUAL_DISCHARGE = 'manual_discharge';
export const ACTION_TYPE__MANUAL_REVERT_DISCHARGE = 'manual_revert_discharge';
export const ACTION_TYPE__FORM_SAVE = 'form_save';
export const ACTION_TYPE__FORM_DISCARD = 'form_discard';
export const ACTION_TYPE__FORM_SUBSCRIBE = 'form_subscribe';
export const ACTION_TYPE__FORM_FETCH = 'form_fetch';
export const ACTION_TYPE__SEARCH = 'search';
export const ACTION_TYPE__REVIEW = 'review';

export const ACTION_TYPES = [
  ACTION_TYPE__LOGIN,
  ACTION_TYPE__LOGOUT,
  ACTION_TYPE__AMEND,
  ACTION_TYPE__FINALIZE,
  ACTION_TYPE__MANUAL_DISCHARGE,
  ACTION_TYPE__MANUAL_REVERT_DISCHARGE,
  ACTION_TYPE__FORM_SAVE,
  ACTION_TYPE__FORM_DISCARD,
  ACTION_TYPE__FORM_SUBSCRIBE,
  ACTION_TYPE__FORM_FETCH,
  ACTION_TYPE__SEARCH,
  ACTION_TYPE__REVIEW,
];

export const ACTION_RESULT__SUCCESS = 'success';
export const ACTION_RESULT__FAILURE = 'failure';

export const ACTION_RESULTS = [
  ACTION_RESULT__SUCCESS,
  ACTION_RESULT__FAILURE,
];
