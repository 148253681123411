import {
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__TAG,
  FILTER_TYPE__ANY_OF,
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
  FILTER_CONDITION__INCLUDE,
  FILTER_CONDITION__EXCLUDE,
  FILTER_CONDITION__EXISTS,
  FILTER_CONDITION__DOES_NOT_EXIST,
} from '../../common/constants';
import QuestionnaireTag from '../../common/models/QuestionnaireTag';

const defaultFilters = [
  {
    name: 'Name',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__SEARCH_TERMS,
    settings: {
      id: 'searchTerms',
    },
    meta: {
      conditions: [
        FILTER_CONDITION__SEARCH_TERMS,
      ],
    },
  },
  {
    name: 'Namespace',
    type: FILTER_TYPE__PROPERTY,
    condition: FILTER_CONDITION__INCLUDE,
    settings: {
      id: 'namespace',
    },
    meta: {
      conditions: [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
      ],
    },
  },
  {
    name: 'Any of',
    type: FILTER_TYPE__ANY_OF,
    settings: {
      filters: [],
    },
    meta: {
      nested: true,
    },
  },
];

export const tagToFilter = (tag) => {
  let condition;
  let conditions;
  switch (tag.type) {
    case 'date':
    case 'string':
      condition = FILTER_CONDITION__TEXT;
      conditions = [
        FILTER_CONDITION__TEXT,
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
      break;
    case 'coding':
      condition = FILTER_CONDITION__INCLUDE;
      conditions = [
        FILTER_CONDITION__INCLUDE,
        FILTER_CONDITION__EXCLUDE,
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
      break;
    default:
      condition = FILTER_CONDITION__EXISTS;
      conditions = [
        FILTER_CONDITION__EXISTS,
        FILTER_CONDITION__DOES_NOT_EXIST,
      ];
  }
  return {
    name: tag.name,
    type: FILTER_TYPE__TAG,
    condition,
    state: {
      tagType: tag.type,
      tagName: tag.name,
    },
    settings: {
      id: 'properties.tags',
      valueKey: QuestionnaireTag.getValueKey(tag.type),
      labelKey: QuestionnaireTag.getLabelKey(tag.type),
      tagNameKey: 'name',
      tagTypeKey: 'type',
    },
    meta: {
      conditions,
    },
  };
};

export default defaultFilters;
