import React, {
  lazy,
  Suspense,
} from 'react';

const Slider = lazy(() => import(/* webpackChunkName: "Slider" */ './Slider'));

export default props => (
  <Suspense fallback={<div>...</div>}>
    <Slider {...props} />
  </Suspense>
);
