import React from 'react';
import styled from 'styled-components/macro';
import Slider from './LazySlider';

const Component = styled(Slider)`
  && {
    margin-right: auto;
    margin-left: auto;
    width: auto;

    .rc-slider-rail,
    .rc-slider-step {
      width: 0.125em;
    }

    .rc-slider-handle {
      left: -0.275em;
      transform: translateY(25%);
      will-change: bottom;

      /* rc-slider has issues with right-to-left direction and that's a hack to make it work */
      [dir='rtl'] & {
        left: -0.4375em;
      }
    }

    .rc-slider-mark {
      left: 3em;

      &-text {
        margin-bottom: 0 !important;
        transform: translateY(50%);
        line-height: 1rem;
      }
    }

    .rc-slider-dot {
      margin-left: -5px;
    }
  }
`;

export default props => (
  <Component
    vertical
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);
