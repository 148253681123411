import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import Stack from '../primitives/Stack';
import Cluster from '../primitives/Cluster';
import Center from '../primitives/Center';
import Text from '../base/Text';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import {
  resetStyles,
  transitionStyles,
  activeStyles,
} from '../styles/form-controls';

const StyledButton = styled.button`
  ${resetStyles};
  ${transitionStyles};

  cursor: pointer;

  &:hover,
  &:focus {
    ${activeStyles};
  }

  ${props => (props.appearance === 'round'
    ? `
    border-radius: 50%;
    width: 24px;
    height: 24px;
  `
    : `
    padding: ${theme('rhythm.xs')(props)} ${theme('rhythm.s')(props)};
    border-radius: ${theme('border.radius.2')(props)};
    min-width: 125px;
    max-width: 225px;
  `)}
`;

const Milestone = ({
  name,
  status,
  label,
  appearance,
  onClick,
  // eslint-disable-next-line react/prop-types
  className,
  'data-testid': testId,
}) => (
  <StyledButton
    type="button"
    appearance={appearance}
    className={className}
    onClick={onClick}
    data-testid={testId}
  >
    {!appearance && (
      <Stack space={0}>
        {name && (
          <Center intrinsic>
            <Text.Paragraph
              size="small"
              importance="high"
            >
              {name}
            </Text.Paragraph>
          </Center>
        )}
        <Cluster
          justify="space-around"
          space={1}
        >
          <Text.Span
            size="small"
            // importance="high"
          >
            {status}
          </Text.Span>
          {label && (
            <Text.Span
              size="small"
              importance="low"
            >
              {label}
            </Text.Span>
          )}
        </Cluster>
      </Stack>
    )}
  </StyledButton>
);

Milestone.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.node,
  appearance: PropTypes.oneOf([
    'round',
  ]),
  onClick: PropTypes.func,
  'data-testid': PropTypes.string,
};

Milestone.defaultProps = {
  name: 'Name',
  status: 'Status',
  label: null,
  appearance: null,
  onClick: () => {},
  'data-testid': null,
};

export default Milestone;
