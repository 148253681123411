import React from 'react';
import Box from '../../../common/components/primitives/Box';
import Checkbox from '../../../common/components/Checkbox';
import {
  formFieldPropTypes,
} from '../propTypes';

const FormFieldCheckbox = ({
  label,
  input,
  meta: {
    touched,
    error,
  },
  ...custom
}) => (
  <>
    {touched && error && <span key="1">{error}</span>}
    <Box.Primary>
      <Checkbox
        key="2"
        checked={!!input.value}
        onChange={input.onChange}
        {...custom}
        {...input}
      >
        {label}
      </Checkbox>
    </Box.Primary>
  </>
);

FormFieldCheckbox.propTypes = formFieldPropTypes;
export default FormFieldCheckbox;
