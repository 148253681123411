import {
  CloseCircleOutlined,
} from '@ant-design/icons';
import styled, {
  css,
} from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import Cluster from '../primitives/Cluster';
import Text from '../base/Text';
import {
  theme,
} from '../../utilsClient/cssHelpers';

const StyledListItem = styled.li`
  white-space: nowrap;
`;

const activeLinkStyles = css`
  color: ${theme('color.primary')};
  font-weight: ${theme('font.weight.bold')};

  &::after {
    transform: translateY(0);
  }
`;

const StyledLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${theme('space.2')} ${theme('space.3')};
  color: ${theme('color.onSurface')};
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${theme('color.primary')};
    border-top-right-radius: ${theme('border.radius.2')};
    border-top-left-radius: ${theme('border.radius.2')};
    transform: translateY(2px);
    transition: 0.15s ease-in-out;
  }

  &[aria-selected='true'],
  &:focus {
    ${activeLinkStyles};
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Tab = ({
  activeKey,
  value,
  label,
  onClose,
  onChange,
}) => {
  const onSelectTab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(value);
  };

  const onCloseTab = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose(value);
  };

  return (
    <StyledListItem
      key={value}
      role="presentation"
    >
      <StyledLink
        aria-selected={value === activeKey}
        href={`#${value}`}
        role="tab"
        id={`tab_${value}`}
        onClick={onSelectTab}
      >
        <Cluster>
          <Text.Span data-testid={`tab-${label}`}>{label}</Text.Span>
          {!!onClose && <CloseCircleOutlined onClick={onCloseTab} />}
        </Cluster>
      </StyledLink>
    </StyledListItem>
  );
};

Tab.propTypes = {
  activeKey: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

Tab.defaultProps = {
  label: 'Tab',
  onClose: null,
  onChange: () => {},
};

export default Tab;
