import React from 'react';
import {
  compose,
  withProps,
  withHandlers,
} from 'recompose';
import {
  reset,
} from 'redux-form';
import {
  withTranslation,
} from 'react-i18next';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  apiAdminArchiveProject,
} from '../../../common/api/admin';
import ProjectSelect from '../../../common/selectors/Project';
import Stack from '../../../common/components/primitives/Stack';
import Text from '../../../common/components/base/Text';
import createDialog from '../../../store/ui/dialogs/create';
import ConfirmRemoveObject from '../../../components/dialogs/ConfirmRemoveObject';

const dialog = createDialog('Screens | SettingsProjects | DialogRemoveProject');

const Container = compose(
  dialog.connect({
    selectors: {
      project: ProjectSelect.one().whereIdEquals(dialog.getProp('projectId')),
    },
  }),
  withTranslation(),
  withProps(({
    project,
    t,
  }) => ({
    id: project && project._id,
    form: dialog.name,
    title: (
      <>
        <Text.Span>{t('archiveProject')}</Text.Span>
        {': '}
        <Text.Span importance="high">{project && project.getName()}</Text.Span>
      </>
    ),
    confirmText: t('archive'),
    expectedValue: project && project.getName(),
    expectedValueLabel: t('confirmations:archiveProject.label'),
    message: (
      <Stack>
        <Text.Paragraph>
          {t('confirmations:archiveProject.title')}
        </Text.Paragraph>
        <Text.Paragraph>
          <strong>{t('confirmations:archiveProject.danger')}</strong>
        </Text.Paragraph>
        <Text.Paragraph>
          {t('confirmations:archiveProject.description')}
        </Text.Paragraph>
      </Stack>
    ),
  })),
  withHandlers({
    onCancel: ({
      dispatch,
    }) => () => {
      dispatch(dialog.close());
      dispatch(reset(dialog.name));
    },
    onConfirm: ({
      dispatch,
      t,
    }) => projectId => dispatch(
      callMethod(apiAdminArchiveProject, {
        projectId,
      }),
    )
      .then(() => dispatch(dialog.close()))
      .then(notifySuccess(t('confirmations.archiveProject.success')))
      .catch(notifyError()),
  }),
)(ConfirmRemoveObject);

export default Container;
export {
  dialog,
};
