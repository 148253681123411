export const NOTIFICATION_DELIVERY_TYPE_SMS = 'sms';
export const NOTIFICATION_DELIVERY_TYPE_EMAIL = 'email';

export const NOTIFICATION_DELIVERY_TYPES = [
  NOTIFICATION_DELIVERY_TYPE_SMS,
  NOTIFICATION_DELIVERY_TYPE_EMAIL,
];

export const NOTIFICATION_LOG_STATE__INITIAL = 'INITIAL';
export const NOTIFICATION_LOG_STATE__ABORTED = 'ABORTED';
export const NOTIFICATION_LOG_STATE__SENDING = 'ATTEMPTED';
export const NOTIFICATION_LOG_STATE__ENQUEUED = 'ENQUEUED';
export const NOTIFICATION_LOG_STATE__SENT = 'SENT';
export const NOTIFICATION_LOG_STATE__FAILED = 'FAILED';
export const NOTIFICATION_LOG_STATE__DELIVERED = 'DELIVERED';
export const NOTIFICATION_LOG_STATE__RECEIVED = 'RECEIVED';

export const NOTIFICATION_LOG_STATE__OPENED = 'OPENED';
export const NOTIFICATION_LOG_STATE__CLICKED = 'CLICKED';

export const NOTIFICATION_LOG_STATES = [
  NOTIFICATION_LOG_STATE__INITIAL,
  NOTIFICATION_LOG_STATE__ABORTED,
  NOTIFICATION_LOG_STATE__SENDING,
  NOTIFICATION_LOG_STATE__ENQUEUED,
  NOTIFICATION_LOG_STATE__SENT,
  NOTIFICATION_LOG_STATE__FAILED,
  NOTIFICATION_LOG_STATE__DELIVERED,
  NOTIFICATION_LOG_STATE__RECEIVED,
  NOTIFICATION_LOG_STATE__OPENED,
  NOTIFICATION_LOG_STATE__CLICKED,
];

export const NOTIFICATION_EVENT_TYPE__CREATE = 'CREATE';
export const NOTIFICATION_EVENT_TYPE__ATTEMPT = 'ATTEMPT';
export const NOTIFICATION_EVENT_TYPE__CANCEL = 'CANCEL';
export const NOTIFICATION_EVENT_TYPE__ENQUEUE = 'ENQUEUE';
export const NOTIFICATION_EVENT_TYPE__SEND = 'SEND';
export const NOTIFICATION_EVENT_TYPE__DELIVER = 'DELIVER';
export const NOTIFICATION_EVENT_TYPE__RECEIVE = 'RECEIVE';
export const NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE = 'FAILED';

export const NOTIFICATION_EVENT_TYPE__OPEN = 'OPEN';
export const NOTIFICATION_EVENT_TYPE__CLICK = 'CLICK';

export const NOTIFICATION_EVENT_TYPES = [
  NOTIFICATION_EVENT_TYPE__CREATE,
  NOTIFICATION_EVENT_TYPE__CANCEL,
  NOTIFICATION_EVENT_TYPE__ENQUEUE,
  NOTIFICATION_EVENT_TYPE__SEND,
  NOTIFICATION_EVENT_TYPE__DELIVER,
  NOTIFICATION_EVENT_TYPE__RECEIVE,
  NOTIFICATION_EVENT_TYPE__DELIVERY_FAILURE,
  NOTIFICATION_EVENT_TYPE__OPEN,
  NOTIFICATION_EVENT_TYPE__CLICK,
];
