/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import Table from '../../../../common/components/Table';
import Text from '../../../../common/components/base/Text';
import Modal from '../../../../components/dialogs/Modal';

const getStatusColorCode = (status) => {
  switch (status) {
    case 'Failed':
      return 'danger';
    case 'Delivered':
      return 'success';
    default:
      return null;
  }
};

const DashboardNotificationModal = ({
  dataSource,
  title,
  visible,
  onCancel,
}) => {
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => (
        <Text.Span type={getStatusColorCode(status)}>{status}</Text.Span>
      ),
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: timestamp => moment(timestamp).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
    },
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onCancel}
      onCancel={onCancel}
    >
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </Modal>
  );
};

DashboardNotificationModal.propTypes = {};

DashboardNotificationModal.defaultProps = {};

export default DashboardNotificationModal;
