import styled from 'styled-components';
import theming from 'styled-theming';
import palette from '../../utilsClient/palette';

const themeModes = {
  light: palette.blue[60],
  dark: palette.blue[30],
};

const fill = theming('mode', themeModes);

// eslint-disable-next-line import/prefer-default-export
export const Component = styled.svg`
  fill: ${props => (props.themeMode ? themeModes[props.themeMode] : fill)};
`;
