import map from 'lodash/map';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import isNaN from 'lodash/isNaN';
import includes from 'lodash/includes';
import omit from 'lodash/omit';
import lodashFilter from 'lodash/filter';
import PropTypes from 'prop-types';
import {
  PlusOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import React, {
  useRef,
  useMemo,
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Filter from './Filter';
import Search from './Search';
import reducer from './reducer';
import Box from '../../common/components/primitives/Box';
import Cluster from '../../common/components/primitives/Cluster';
import Button from '../../common/components/Button';
import {
  FILTER_CONDITION__TEXT,
  FILTER_CONDITION__SEARCH_TERMS,
} from '../../common/constants';
import Random from '../../common/utils/random';

const init = value => ({
  searchText: '',
  filtersOrder: map(value, 'id'),
  filters: keyBy(value, 'id'),
  newValue: null,
});

const Filters = ({
  groupId,
  presets,
  value,
  onChange,
  optionsSelector,
  optionsSubscription,
  activeId: contextActiveId,
  onChangeActive: contextOnChangeActive,
  onDelete,
  onSubmit,
}) => {
  const [
    localActiveId,
    setLocalActiveId,
  ] = useState(null);
  const activeId = contextActiveId || localActiveId;
  const onChangeActive = contextOnChangeActive || setLocalActiveId;
  const [
    {
      filtersOrder,
      filters,
      searchText,
      newValue,
    },
    dispatch,
  ] = useReducer(reducer, value, init);
  useEffect(() => {
    if (newValue) {
      dispatch({
        type: 'CHANGE',
        payload: null,
        meta: {
          key: 'newValue',
        },
      });
      onChange(newValue);
    }
  }, [
    dispatch,
    newValue,
    onChange,
  ]);
  const isActive = includes(filtersOrder, activeId);
  useEffect(() => {
    if (!isActive) {
      dispatch({
        type: 'SUBMIT',
      });
    }
  }, [
    isActive,
  ]);
  const searchEl = useRef(null);
  const handleOnSearch = useCallback(
    (newSearchText) => {
      dispatch({
        type: 'CHANGE',
        payload: newSearchText,
        meta: {
          key: 'searchText',
        },
      });
    },
    [
      dispatch,
    ],
  );
  const handleFilterOnChange = (id, fields) => {
    dispatch({
      type: 'UPDATE',
      payload: fields,
      meta: {
        id,
      },
    });
  };
  const handleOnStartEdit = useCallback(
    (id, submit = false) => {
      onChangeActive(id);
      if (submit) {
        dispatch({
          type: 'SUBMIT',
        });
      }
      setTimeout(() => {
        if (searchEl.current) {
          searchEl.current.focus();
        }
      }, 100);
    },
    [
      dispatch,
      searchEl,
      onChangeActive,
    ],
  );
  const handleOnFocus = useCallback(() => {
    handleOnStartEdit(groupId, true);
  }, [
    groupId,
    handleOnStartEdit,
  ]);
  const handleOnSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }

    handleOnStartEdit(groupId, false);
  }, [
    groupId,
    onSubmit,
    handleOnStartEdit,
  ]);
  const handleOnDelete = useCallback(
    (id) => {
      if (onDelete) {
        onDelete();
      }

      dispatch({
        type: 'REMOVE',
        meta: {
          id,
          submit: true,
        },
      });
    },
    [
      dispatch,
      onDelete,
    ],
  );
  const onSelect = (selectedKey) => {
    const parts = selectedKey.split(':');
    if (parts[0] !== 'preset') {
      return;
    }
    const index = +parts[1];
    if (isNaN(index)) {
      return;
    }
    const newFilter = cloneDeep(presets[index]);
    if (
      searchText &&
      (newFilter.condition === FILTER_CONDITION__TEXT ||
        newFilter.condition === FILTER_CONDITION__SEARCH_TERMS)
    ) {
      newFilter.state = {
        ...newFilter.state,
        text: searchText,
      };
      dispatch({
        type: 'INSERT',
        payload: newFilter,
        meta: {
          id: Random.id(),
          submit: true,
        },
      });
    } else {
      const newId = Random.id();
      dispatch({
        type: 'INSERT',
        payload: newFilter,
        meta: {
          id: newId,
          submit: newFilter.meta && newFilter.meta.nested,
        },
      });
      handleOnStartEdit(newId);
    }
    dispatch({
      type: 'CHANGE',
      payload: '',
      meta: {
        key: 'searchText',
      },
    });
  };
  const options = useMemo(() => {
    return map(presets, (preset, index) => {
      return {
        label:
          searchText &&
          (preset.condition === FILTER_CONDITION__TEXT ||
            preset.condition === FILTER_CONDITION__SEARCH_TERMS)
            ? `Filter: ${preset.name} <- "${searchText}"`
            : `Filter: ${preset.name}`,
        value: `preset:${index}`,
      };
    });
  }, [
    searchText,
    presets,
  ]);
  return (
    <Cluster space={1}>
      {map(filtersOrder, (id) => {
        const filter = filters[id];
        if (filter && filter.meta && filter.meta.nested) {
          return (
            <Box.Secondary
              key={id}
              space={1}
            >
              <Cluster space={1}>
                <Button
                  key={id}
                  disabled
                >
                  {filter.name}
                </Button>
                <Filters
                  id={id}
                  groupId={id}
                  presets={presets}
                  value={filter.settings.filters}
                  onChange={(payload) => {
                    dispatch({
                      type: 'CHANGE',
                      payload,
                      meta: {
                        key: `filters.${id}.settings.filters`,
                        submit: true,
                      },
                    });
                  }}
                  activeId={activeId}
                  onChangeActive={onChangeActive}
                  onDelete={() => handleOnDelete(id)}
                  optionsSelector={optionsSelector}
                  optionsSubscription={({
                    currentFilters,
                    ...other
                  }) => {
                    if (!optionsSubscription) {
                      return null;
                    }
                    return optionsSubscription({
                      ...other,
                      currentFilters: map(value, (f) => {
                        const allowedProps = omit(f, 'meta');
                        if (id === f.id) {
                          return {
                            ...allowedProps,
                            settings: {
                              ...f.settings,
                              filters: currentFilters,
                            },
                          };
                        }
                        return allowedProps;
                      }),
                    });
                  }}
                />
              </Cluster>
            </Box.Secondary>
          );
        }
        return (
          <Filter
            key={id}
            id={id}
            name={filter.name}
            type={filter.type}
            condition={filter.condition}
            state={filter.state}
            settings={filter.settings}
            meta={filter.meta}
            onStartEdit={handleOnStartEdit}
            onDelete={handleOnDelete}
            onChange={handleFilterOnChange}
            onSubmit={handleOnSubmit}
            active={activeId === id}
            optionsSelector={optionsSelector}
            optionsSubscription={(params) => {
              const otherFilters = lodashFilter(value, f => f.id !== id);
              return optionsSubscription
                ? optionsSubscription({
                  ...params,
                  currentFilters: map(otherFilters, f => omit(f, 'meta')),
                })
                : null;
            }}
          />
        );
      })}
      {activeId === groupId && (
        <Search
          ref={searchEl}
          active={groupId === activeId}
          options={options}
          value={searchText}
          onChange={handleOnSearch}
          onSelect={onSelect}
          onFocus={handleOnFocus}
        />
      )}
      {activeId !== groupId && (
        <Button.Group>
          <Button
            icon={<PlusOutlined />}
            onClick={handleOnFocus}
          />
          {groupId && (
            <Button
              data-testid="filters-group-delete"
              icon={<DeleteOutlined />}
              onClick={onDelete}
            />
          )}
        </Button.Group>
      )}
    </Cluster>
  );
};

Filters.propTypes = {
  groupId: PropTypes.string,
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  value: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onChange: PropTypes.func.isRequired,
  activeId: PropTypes.string,
  onChangeActive: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  optionsSubscription: PropTypes.func,
  optionsSelector: PropTypes.shape({
    all: PropTypes.func,
  }),
};

Filters.defaultProps = {
  groupId: null,
  presets: [],
  value: null,
  activeId: null,
  onChangeActive: null,
  onDelete: () => {},
  onSubmit: () => {},
  optionsSubscription: null,
  optionsSelector: null,
};

export default Filters;
