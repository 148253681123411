import {
  EllipsisOutlined,
} from '@ant-design/icons';
import map from 'lodash/map';
import React, {
  useRef,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  useDDPSubscription,
} from '@theclinician/ddp-connector';
import {
  withHandlers,
  compose,
} from 'recompose';
import {
  useTranslation,
} from 'react-i18next';
import {
  slugify,
} from '../../../../common/utils/formatting';
import {
  apiZedocPatientServiceSyncProgress,
} from '../../../../common/api/zedoc';
import {
  PROJECT_ATTACH_PARTICIPATION,
  PROJECT_IMPORT_PARTICIPANTS,
  PROJECT_FORCE_SYNCHRONIZATION,
} from '../../../../common/permissions';
import ProjectFilter from '../../../../common/models/ProjectFilter';
import Dropdown from '../../../../common/components/Dropdown';
import Menu from '../../../../common/components/Menu';
import Button from '../../../../common/components/Button';
import Stack from '../../../../common/components/primitives/Stack';
import Sidebar from '../../../../common/components/primitives/Sidebar';
import Cluster from '../../../../common/components/primitives/Cluster';
import Search from '../../../../components/inputs/Search';
import Filters from '../../../../components/inputs/Filters';
import ProjectTable from '../ProjectTable';
import ProjectInfo from '../ProjectTable/ProjectInfo';
import branding from '../../../../utils/branding';

const HiddenInput = styled.input`
  display: none;
`;

const SearchContainer = styled.div`
  margin-right: 1.5rem;
`;

const ProjectMilestones = compose(
  withHandlers({
    onSelectFilter: ({
      dateRangeFilter,
      onSelectFilter,
    }) => range => onSelectFilter(
      dateRangeFilter.id,
      range.length > 0
        ? ProjectFilter.createDateRangeState(range[0], range[1])
        : {},
    ),
  }),
)(
  ({
    onAddMilestone,
    validate,
    onAddPatient,
    project,
    projectId,
    dashboardId,
    variables,
    onProjectExport,
    onCsvUpload,
    csvInputSchemas,
    exportSelections,
    onForceSyncToProms,
  }) => {
    const {
      t,
    } = useTranslation();
    const inputRef = useRef();

    const {
      ready: progressReady,
    } = useDDPSubscription(
      apiZedocPatientServiceSyncProgress.withParams({
        projectId,
      }),
    );

    const [
      inputSchemaId,
      setInputSchemaId,
    ] = useState(null);
    const handleFileInputOnChange = useCallback(
      (event) => {
        const formData = new FormData();
        formData.append('patients', event.target.files[0]);
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
        onCsvUpload(inputSchemaId, formData);
      },
      [
        onCsvUpload,
        inputSchemaId,
      ],
    );
    return (
      <Stack>
        {project && <ProjectInfo projectId={projectId} />}

        <Sidebar
          space={0}
          sidebar={(
            <Cluster>
              <HiddenInput
                ref={inputRef}
                type="file"
                accept="text/csv"
                onChange={handleFileInputOnChange}
              />
              <Filters
                projectId={projectId}
                tags={[
                  'dashboard',
                ]}
              />
              <Button
                data-testid="add-patient"
                onClick={onAddPatient}
                disabled={
                  !validate(PROJECT_ATTACH_PARTICIPATION, {
                    relativeTo: project && project.getDomains(),
                  })
                }
              >
                {t('addRecipient', {
                  context: branding,
                })}
              </Button>
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item
                      data-testid="additional-menu-milestone-add"
                      key="milestone-add"
                      onClick={onAddMilestone}
                    >
                      {t('addMilestone')}
                    </Menu.Item>
                    <Menu.Item
                      data-testid="additional-menu-download-csv"
                      key="download-csv"
                      onClick={() => onProjectExport()}
                    >
                      {t('downloadResponsesCSV')}
                    </Menu.Item>
                    {map(exportSelections, ({
                      _id,
                      name,
                    }) => {
                      return (
                        <Menu.Item
                          key={_id}
                          data-testid={`additional-menu-download-csv-${slugify(
                            name,
                          )}`}
                          onClick={() => onProjectExport(_id)}
                        >
                          {t('downloadResponsesCSV')}
                          {' '}
                          {name}
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item
                      data-testid="additional-menu-download-csv-cat-irt"
                      key="download-csv-cat-irt"
                      disabled
                    >
                      {t('downloadResponsesCSV')}
                      {' '}
                      {t('projects:catIrt')}
                    </Menu.Item>
                    {map(csvInputSchemas, ({
                      _id,
                      name,
                    }) => {
                      return (
                        <Menu.Item
                          key={_id}
                          disabled={!validate(PROJECT_IMPORT_PARTICIPANTS)}
                          data-testid={`additional-menu-upload-csv-${slugify(
                            name,
                          )}`}
                          onClick={() => {
                            setInputSchemaId(_id);
                            if (inputRef.current) {
                              inputRef.current.click();
                            }
                          }}
                        >
                          {t('uploadRecipientCSV', {
                            context: branding,
                          })}
                          {' '}
                          {name}
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item
                      data-testid="additional-menu-force-sync-to-proms"
                      key="force-sync-to-proms"
                      onClick={onForceSyncToProms}
                      disabled={
                        !validate(PROJECT_FORCE_SYNCHRONIZATION, {
                          relativeTo: project && project.getDomains(),
                        })
                      }
                      // NOTE: It can be problematic if the sync process is stuck for some reason.
                      // disabled={
                      //   !progressReady ||
                      //   !project ||
                      //   project.patientServiceSyncProgress < 100
                      // }
                      loading
                    >
                      {t('syncRecipientService', {
                        context: branding,
                      })}
                      {' '}
                      {project &&
                        progressReady &&
                        project.patientServiceSyncProgress < 100 &&
                        `(${project.patientServiceSyncProgress}%)`}
                    </Menu.Item>
                  </Menu>
                )}
                trigger={[
                  'click',
                ]}
              >
                <Button
                  data-testid="additional-menu"
                  icon={<EllipsisOutlined />}
                />
              </Dropdown>
            </Cluster>
          )}
        >
          <SearchContainer>
            <Search />
          </SearchContainer>
        </Sidebar>

        <ProjectTable
          project={project}
          projectId={projectId}
          dashboardId={dashboardId}
          variables={variables}
        />
      </Stack>
    );
  },
);

ProjectMilestones.propTypes = {
  projectId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
};

export default ProjectMilestones;
