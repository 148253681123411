import {
  MailOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import React from 'react';
import find from 'lodash/find';
import {
  connect,
} from 'react-redux';
import {
  compose,
  lifecycle,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import List from '../../../common/components/List';
import Text from '../../../common/components/base/Text';
import Button from '../../../common/components/Button';
import Cluster from '../../../common/components/primitives/Cluster';
import Box from '../../../common/components/primitives/Box';
import Sidebar from '../../../common/components/primitives/Sidebar';
import {
  withModal,
} from '../../../utils/modal';
import ShareReport from './ShareReport';

import {
  apiReportsGetReports,
  apiReportsAllReportSchedules,
} from '../../../common/api/reportSchedule';

import ReportScheduleSelect from '../../../common/selectors/ReportSchedule';

import {
  setReports,
} from '../actions';
import {
  selectReports,
} from '../selectors';

import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';

const ReportsDirectory = compose(
  withModal(),
  ddp({
    subscriptions: () => [
      apiReportsAllReportSchedules.withParams({
        isAutomated: true,
      }),
    ],
    selectors: () => ({
      reportSchedules: ReportScheduleSelect.all(),
    }),
  }),
  connect(
    state => ({
      reports: selectReports(state),
    }),
    dispatch => ({
      getReports: () => dispatch(callMethod(apiReportsGetReports)).then(({
        reports,
      }) => {
        dispatch(setReports(reports));
      }),
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.getReports();
    },
  }),
)(({
  modal,
  reports,
  reportSchedules,
}) => {
  const onCreateShareModal = ({
    id,
  }) => {
    modal.render(<ShareReport
      modal={modal}
      powerBIReportId={id}
    />);
  };

  const getPeriod = ({
    id,
  }) => {
    const reportSchedule = find(reportSchedules, {
      powerBIReportId: id,
    });
    if (reportSchedule && reportSchedule.isAutomated) {
      return reportSchedule.schedule.period;
    }
    return '';
  };

  const getDay = ({
    id,
  }) => {
    const reportSchedule = find(reportSchedules, {
      powerBIReportId: id,
    });
    if (reportSchedule && reportSchedule.isAutomated) {
      return reportSchedule.schedule.day;
    }
    return '';
  };

  return (
    <List
      dataSource={reports}
      renderItem={({
        name,
        id,
        downloadUrl,
      }) => (
        <List.Item key={id}>
          <Box.Primary>
            <Sidebar
              sidebar={(
                <Cluster>
                  <Text.Span importance="low">
                    {getPeriod({
                      id,
                    })}
                  </Text.Span>
                  <Text.Span importance="low">
                    {getDay({
                      id,
                    })}
                  </Text.Span>
                  <Button
                    type="link"
                    icon={<MailOutlined />}
                    onClick={() => onCreateShareModal({
                      id,
                    })}
                  />
                  <Text.Link
                    target="_new"
                    href={downloadUrl}
                  >
                    <DownloadOutlined />
                  </Text.Link>
                </Cluster>
              )}
            >
              <Text.Paragraph importance="high">{name}</Text.Paragraph>
            </Sidebar>
          </Box.Primary>
        </List.Item>
      )}
    />
  );
});

ReportsDirectory.propTypes = {};

ReportsDirectory.defaultProps = {};

export default ReportsDirectory;
