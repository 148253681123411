import {
  strToBase64,
} from '../utils/base64';

export default function stateParam(loginStyle, credentialToken, redirectUrl) {
  const state = {
    loginStyle,
    credentialToken,
  };

  // NOTE: If redirectUrl is explicitly provided, include it even in "popup" login style.
  //       The reason we need it is the server needs to figure out which frontend the
  //       OAuth request originated from, in case there are multiple frontend applications
  //       connected to the same ddp-server.
  if (loginStyle === 'redirect' || redirectUrl) {
    state.redirectUrl = redirectUrl || `${window.location}`;
  }

  // Encode base64 as not all login services URI-encode the state
  // parameter when they pass it back to us.
  // Use the 'base64' package here because 'btoa' isn't supported in IE8/9.
  return strToBase64(JSON.stringify(state));
}
