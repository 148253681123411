import {
  css,
} from 'styled-components';
import theming from 'styled-theming';
import {
  BRANDING_TYPE__ZEDOC,
  BRANDING_TYPE__ZESIGHT,
} from '../common/constants/branding';
import settings from '../common/settings';
import pattern from '../assets/patterns/topography.svg';
import entry from '../assets/entry.jpg';

const entryScreenBackground = theming('mode', {
  light: css`
    background-image: linear-gradient(
        transparent,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.85)
      ),
      url(${entry});
  `,
  dark: css`
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.85)
      ),
      url(${entry});
  `,
});

const getBranding = () => settings.public.branding || BRANDING_TYPE__ZEDOC;

export const getProductName = () => {
  return process.env.REACT_APP_TITLE;
};

export const getEntryScreenBackground = () => {
  switch (getBranding()) {
    case BRANDING_TYPE__ZESIGHT:
      return css`
        background-image: linear-gradient(
            rgba(242, 242, 243, 0.975),
            rgba(242, 242, 243, 0.975)
          ),
          url(${pattern});
      `;
    default:
      return entryScreenBackground;
  }
};

export const getMatomoOptions = () => {
  switch (getBranding()) {
    case BRANDING_TYPE__ZESIGHT:
      return {
        url: 'https://mtm.zedoc.io/',
        siteId: 2,
      };
    default:
      return null;
  }
};

export default getBranding();
