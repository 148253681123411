import {
  QUESTION_TYPE__UPLOAD,
} from '../../../constants';
import Question from '../Question';

export default class QuestionUpload extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__UPLOAD;
  }
}

QuestionUpload.expectedValueType = {
  type: 'object',
  additionalProperties: true,
};

Question.types[QUESTION_TYPE__UPLOAD] = QuestionUpload;
