import find from 'lodash/find';
import React from 'react';
import Select, {
  StyledSelect,
} from '../../Select';
import Text from '../Text';

// We render different links based on history object because you cannot render react link outside of react router
const getLink = (route, paths, history) => (history ? (
  <Text.Link
    data-testid={`breadcrumb-${route.breadcrumbName}`}
    to={`/${paths.join('/')}`}
  >
    {route.breadcrumbName}
  </Text.Link>
) : (
  <Text.Link
    data-testid={`breadcrumb-${route.breadcrumbName}`}
    href={`/${paths.join('/')}`}
  >
    {route.breadcrumbName}
  </Text.Link>
));

const getPaths = (route, routes) => {
  const paths = [];

  routes.forEach(({
    path,
  }, index) => {
    if (route.path === path) {
      paths.push(path);
    }

    if (routes.indexOf(route) > index) {
      paths.push(path);
    }
  });

  return paths;
};

export const getIsLastRoute = (route, routes) => routes.indexOf(route) === routes.length - 1;

export const renderItem = (route, routes, history, size) => {
  const paths = getPaths(route, routes);
  const isLastRoute = getIsLastRoute(route, routes);

  if (route.children) {
    const currentNestedRoute = find(route.children, r => r.isActive);
    const value = currentNestedRoute && currentNestedRoute.path;
    const handleOnChange = (path) => {
      if (path) {
        // NOTE: After onClear this one is also called with nil path.
        //       We don't want to update in this case of course.
        history.push(path);
      }
    };

    return (
      <StyledSelect
        data-testid="breadcrumb-select"
        size={size}
        value={value}
        onChange={val => handleOnChange(val)}
        onClear={currentNestedRoute ? currentNestedRoute.onClear : () => {}}
        allowClear={currentNestedRoute && currentNestedRoute.isClearable}
        dropdownClassName="should-wrap-text"
        compact
      >
        {route.children.map(nestedRoute => (
          <Select.Option
            data-testid={`breadcrumb-select-${nestedRoute.breadcrumbName}`}
            key={nestedRoute.path}
            value={nestedRoute.path}
          >
            {nestedRoute.breadcrumbName}
          </Select.Option>
        ))}
      </StyledSelect>
    );
  }

  return isLastRoute ? (
    <Text.Span>{route.breadcrumbName}</Text.Span>
  ) : (
    getLink(route, paths, history)
  );
};
