import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import {
  compose,
  withState,
} from 'recompose';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createSelector,
} from 'reselect';
import {
  reset,
} from 'redux-form';
import {
  callMethod,
} from '../../../common/utilsClient/ddp/actions';
import {
  notifyError,
  notifySuccess,
} from '../../../utils/notify';
import {
  insert,
  update,
} from '../../../common/api/collections/Users';
import {
  apiAdminOneUser,
} from '../../../common/api/admin';
import {
  all as allPermissionsDomains,
} from '../../../common/api/collections/PermissionsDomains';
import {
  all as allGroups,
} from '../../../common/api/collections/UsersGroups';
import {
  apiCollectionsRolesAll,
} from '../../../common/api/collections/Roles';
import PermissionsDomainSelect from '../../../common/selectors/PermissionsDomain';
import RoleSelect from '../../../common/selectors/Role';
import CurrentUserSelect from '../../../common/selectors/CurrentUser';
import {
  ADMIN_UPDATE_USER,
} from '../../../common/permissions';
import UsersGroupSelect from '../../../common/selectors/UsersGroup';
import EditUser from '../../../components/dialogs/EditUser';
import {
  getActiveUser,
  getActiveUserId,
  getEditUserDialogVisible,
  closeEditUserDialog,
} from '../store';

const constant = x => () => x;

const form = 'screens_settings_users_editUser';
const Container = compose(
  withState('isConfirmLoading', 'setConfirmLoading', false),
  ddp({
    renderLoader: null,
    subscriptions: () => createSelector(getActiveUserId, userId => [
      userId &&
          apiAdminOneUser.withParams({
            userId,
          }),
      apiCollectionsRolesAll.withParams(),
      allGroups.withParams(),
      allPermissionsDomains.withParams(),
    ]),
    selectors: () => {
      const selectRolesByPermissionDomain = createSelector(
        RoleSelect.all(),
        roles => groupBy(roles, role => role.getDomain()),
      );
      const selectGroupsByPermissionDomain = createSelector(
        UsersGroupSelect.all(),
        roles => groupBy(roles, role => role.getDomain()),
      );
      const createOptionsSelector = selectByPermissionsDomain => createSelector(
        PermissionsDomainSelect.all().whereUserHasPermission(
          CurrentUserSelect.user(),
          ADMIN_UPDATE_USER,
        ),
        selectByPermissionsDomain,
        (permissionsDomains, byPermissionsDomain) => map(permissionsDomains, permissionDomain => ({
          key: permissionDomain._id,
          label: permissionDomain.getName(),
          options: map(
            byPermissionsDomain[permissionDomain._id],
            item => ({
              value: item._id,
              label: item.getName(),
            }),
          ),
        })),
      );
      return {
        form: constant(form),
        open: getEditUserDialogVisible,
        user: getActiveUser,
        userId: getActiveUserId,
        groupedRolesOptions: createOptionsSelector(
          selectRolesByPermissionDomain,
        ),
        groupedGroupsOptions: createOptionsSelector(
          selectGroupsByPermissionDomain,
        ),
        readOnly: createSelector(
          CurrentUserSelect.getCurrentPermissionsValidator({
            relativeTo: createSelector(getActiveUser, user => (user ? user.getTopLevelDomains() : '')),
          }),
          validate => !validate(ADMIN_UPDATE_USER),
        ),
      };
    },
    mutations: {
      onCreate: ({
        dispatch,
        setConfirmLoading,
      }) => (data) => {
        setConfirmLoading(true);

        dispatch(callMethod(insert, data))
          .then(notifySuccess('User created'))
          .then(() => dispatch(closeEditUserDialog()))
          .catch(notifyError())
          .then(() => setConfirmLoading(false));
      },

      onUpdate: ({
        dispatch,
        setConfirmLoading,
      }) => (data) => {
        setConfirmLoading(true);

        dispatch(callMethod(update, data))
          .then(notifySuccess('User updated'))
          .then(() => dispatch(closeEditUserDialog()))
          .catch(notifyError())
          .then(() => setConfirmLoading(false));
      },

      onCancel: ({
        dispatch,
      }) => () => {
        dispatch(closeEditUserDialog());
        dispatch(reset(form));
      },
    },
  }),
)(EditUser);

export default Container;
