import React from 'react';
import PropTypes from 'prop-types';
import {
  BRANDING_TYPE__ZEDOC,
  BRANDING_TYPE__ZESIGHT,
} from '../../constants/branding';
import LogoZedoc from './LogoZedoc';
import LogoZeSight from './LogoZeSight';

const Logo = ({
  // eslint-disable-next-line react/prop-types
  className,
  branding,
  themeMode,
}) => {
  if (branding === BRANDING_TYPE__ZEDOC) {
    return (
      <LogoZedoc
        className={className}
        themeMode={themeMode}
      />
    );
  }

  if (branding === BRANDING_TYPE__ZESIGHT) {
    return (
      <LogoZeSight
        className={className}
        themeMode={themeMode}
      />
    );
  }

  return null;
};

Logo.propTypes = {
  branding: PropTypes.oneOf([
    BRANDING_TYPE__ZEDOC,
    BRANDING_TYPE__ZESIGHT,
  ]),
  themeMode: PropTypes.oneOf([
    'light',
    'dark',
  ]),
};

Logo.defaultProps = {
  branding: null,
  themeMode: null,
};

export default Logo;
