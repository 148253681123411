import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  compose,
} from 'redux';
import {
  connect,
} from 'react-redux';
import Layout from './Layout';
import CurrentUserSelect from '../../../../common/selectors/CurrentUser';
import {
  assignedToMeAndPending,
} from '../../../../common/api/zedoc';
import CalendarDate from '../../../../common/models/CalendarDate';
import PatientRecordSelect from '../../../../common/selectors/PatientRecord';
import {
  setFilterBy,
} from './actions';
import {
  getFilterBy,
} from './selectors';

const property = name => (state, props) => props[name];

const ActionItems = compose(
  ddp({
    subscriptions: (state, {
      projectId,
    }) => [
      assignedToMeAndPending.withParams({
        projectId,
        // TODO: We should probably use clock selector to ensure this changes dynamically
        currentDate: CalendarDate.current().toJSON(),
      }),
    ],
  }),
  connect(
    () => createStructuredSelector({
      currentUser: CurrentUserSelect.user(),
      filterBy: getFilterBy,
      patientRecords: PatientRecordSelect.all().where(
        createSelector(
          property('projectId'),
          CurrentUserSelect.user(),
          (projectId, currentUser) => (record) => {
            if (!currentUser || record.getProjectId() !== projectId) {
              return false;
            }
            return record.hasPendingQuestionnaires({
              assigneeId: currentUser._id,
              ignoreSnoozed: new Date(),
            });
          },
        ),
      ),
    }),
    dispatch => ({
      onChange: value => dispatch(setFilterBy(value)),
    }),
  ),
)(Layout);

export default ActionItems;
