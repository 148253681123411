import React, {
  forwardRef,
} from 'react';
import SelectorsHub from './SelectorsHub';

const QuestionnaireContext = React.createContext(new SelectorsHub({}));

export function withContext(options) {
  return (Component) => {
    const ComponentWithContext = (props) => {
      return (
        <QuestionnaireContext.Consumer>
          {context => (
            <Component
              {...props}
              context={context}
            />
          )}
        </QuestionnaireContext.Consumer>
      );
    };
    // set display name
    const name = Component.displayName || Component.name;
    ComponentWithContext.displayName = name;

    if (options && options.forwardRef) {
      // NOTE: I cannot use <ComponentWithContext /> syntax, because
      //       ref would not be passed to <Component />; think about
      //       it as a completely different render function, though
      //       we are lucky to be able to re-use ComponentWithContext
      //       (as a function, not as a component).
      return forwardRef((props, ref) => ComponentWithContext({
        ...props,
        ref,
      }));
    }
    return ComponentWithContext;
  };
}

export default QuestionnaireContext;
