import {
  BEHAVIOR_ACTION_TYPE__SHOW_QUESTION,
} from '../../../constants';
import QuestionBehavior from '../QuestionBehavior';
import {
  showQuestion,
  hideQuestion,
} from './helpers';

export default class ShowQuestion extends QuestionBehavior.Action {
  doSelf(context) {
    const questionsToHide = context.questionnaire.getSubQuestionsIncludingSelf(
      this.target,
    );
    return questionsToHide.map(({
      id,
    }) => ({
      questionId: id,
      transform: showQuestion,
    }));
  }

  undoSelf(context) {
    const questionsToHide = context.questionnaire.getSubQuestionsIncludingSelf(
      this.target,
    );
    return questionsToHide.map(({
      id,
    }) => ({
      questionId: id,
      transform: hideQuestion,
    }));
  }
}

QuestionBehavior.actions[BEHAVIOR_ACTION_TYPE__SHOW_QUESTION] = ShowQuestion;
