import React from 'react';

export default () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
  >
    <title>Sign Out</title>
    <g fillRule="nonzero">
      <path d="M19.656 2H4.344A2.346 2.346 0 002 4.344v3.125h1.563V4.344c0-.431.35-.781.78-.781h15.313c.431 0 .781.35.781.78v15.313c0 .431-.35.781-.78.781H4.343a.782.782 0 01-.781-.78V16.53H2v3.125A2.346 2.346 0 004.344 22h15.312A2.346 2.346 0 0022 19.656V4.344A2.346 2.346 0 0019.656 2z" />
      <path d="M16 11.195H4.943l2.92-3.057L6.776 7 2 12l4.776 5 1.087-1.138-2.92-3.057H16z" />
    </g>
  </svg>
);
