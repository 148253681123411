import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
} from 'react';
import {
  Remove,
  Add,
} from 'styled-icons/material';
import Button from '../Button';
import Input from '../Input';
import {
  getSubtractedValue,
  getIncreasedValue,
  formatToString,
  formatToNumber,
} from './helpers';

const Component = styled(Input)`
  /* TODO: I encountered a specificity issue with InputWithSuffix */
  && {
    padding-right: 0;
    padding-left: 0;

    display: inline-flex;
    width: auto;
  }

  input {
    text-align: center;
    width: 4rem;

    /* Hide input number up/down arrows */
    -moz-appearance: textfield !important;

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;

const InputNumber = ({
  'data-testid': datatestid,
  id,
  value,
  onChange,
  size,
  min,
  max,
  step,
  precision,
  placeholder,
  disabled,
  autoFocus,
}) => {
  const [
    stateValue,
    setStateValue,
  ] = useState();
  const [
    shouldFormatWithPrecision,
    setShouldFormatWithPrecision,
  ] = useState(false);


  const handleOnSubtract = () => {
    const newValue = getSubtractedValue({
      value,
      max,
      min,
      step,
      precision,
    });
    onChange(newValue);
  };

  const handleOnIncrease = () => {
    const newValue = getIncreasedValue({
      value,
      max,
      min,
      step,
      precision,
    });
    onChange(newValue);
  };

  const handleOnChange = (event) => {
    setShouldFormatWithPrecision(false);
    onChange(formatToNumber(event.target.value, precision));
  };
  const handleOnBlur = () => setShouldFormatWithPrecision(true);

  useEffect(() => {
    // We don't want to format state value when 'onChange' happens and only 'onBlur'
    if (shouldFormatWithPrecision) {
      setStateValue(formatToString(value, precision));
    } else {
      setStateValue(formatToString(value));
    }
  }, [
    value,
    precision,
    shouldFormatWithPrecision,
  ]);

  return (
    <Component
      data-testid={datatestid}
      id={id}
      type="number"
      inputMode="decimal"
      size={size}
      value={stateValue}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      min={min}
      max={max}
      step={step}
      placeholder={placeholder}
      disabled={disabled}
      autoFocus={autoFocus}
      prefix={(
        <Button
          type="link"
          icon={<Remove />}
          onClick={handleOnSubtract}
          disabled={disabled}
        />
      )}
      suffix={(
        <Button
          type="link"
          icon={<Add />}
          onClick={handleOnIncrease}
          disabled={disabled}
        />
      )}
    />
  );
};

InputNumber.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.oneOf([
    'small',
    'default',
    'large',
  ]),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  precision: PropTypes.number,
};

InputNumber.defaultProps = {
  'data-testid': null,
  id: null,
  value: null,
  onChange: () => {},
  size: 'default',
  disabled: false,
  autoFocus: false,
  placeholder: null,
  min: null,
  max: null,
  step: 1,
  precision: 0,
};

export default InputNumber;
