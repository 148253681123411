import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import Formula from '../Formula';
import {
  FORMULA_TYPE__TO_VALUE_SET,
} from '../../../constants';
import Schema from '../../../utils/Schema';
import {
  collapse,
} from '../../../utils/formValues';
import {
  isEmptyAnswer,
} from '../../../utils/question';

const settingsSchema = new Schema(
  {
    id: {
      type: String,
    },
    code: {
      type: String,
      optional: true,
    },
    display: {
      type: String,
    },
    filter: {
      type: Schema.Blackbox,
      optional: true,
    },
  },
  {
    additionalProperties: true,
  },
);

function cleanValue(value) {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return undefined;
}

class FormulaToValueSet extends Formula {
  validate() {
    if (!this.settings) {
      return this.constructor.NotConfigured;
    }
    if (settingsSchema.getErrors(this.settings)) {
      return this.constructor.NotConfigured;
    }
    return undefined;
  }

  evaluate(scope) {
    const answer = scope.lookupAnswer(this.settings.id);
    const parentScope = scope.lookupQuestionDefinitionScope(this.settings.id);
    const results = map(answer && answer._elementsOrder, (elementId) => {
      const subScope = parentScope
        .getOrCreateSubScope(this.settings.id)
        .getOrCreateSubScope(elementId);
      if (!subScope) {
        return null;
      }
      if (this.settings.filter) {
        const filterAnswer = Formula.create(this.settings.filter).evaluate(
          subScope,
        );
        if (isEmptyAnswer(filterAnswer) || filterAnswer.value === false) {
          return null;
        }
      }
      const codeAnswer = this.settings.code
        ? subScope.lookupAnswer(this.settings.code)
        : subScope.getElementId();
      const displayAnswer = subScope.lookupAnswer(this.settings.display);
      if (isEmptyAnswer(codeAnswer) || isEmptyAnswer(displayAnswer)) {
        // NOTE: This includes potential formula error.
        return null;
      }
      const code = cleanValue(collapse(codeAnswer));
      const display = cleanValue(collapse(displayAnswer));
      if (!code || !display) {
        return null;
      }
      return {
        code,
        display,
      };
    });
    return {
      value: {
        expansion: {
          contains: uniqBy(compact(results), 'code'),
        },
      },
    };
  }

  compile() {
    const {
      id,
      code,
      display,
      useFilter,
      filter,
    } = this.settings;
    return {
      ...this,
      settings: {
        id,
        code,
        display,
        filter: useFilter ? filter : null,
      },
    };
  }

  toMongoExpression() {
    return this.constructor.createMongoExpression(this.settings.id);
  }

  static createMongoExpression() {
    return {
      $literal: '[unknown]',
    };
  }

  static createMapSettings(mapQuestionId) {
    return (value, key) => {
      switch (key) {
        case 'id':
        case 'code':
        case 'display':
          return mapQuestionId(value);
        case 'filter': {
          if (value && value.type) {
            return Formula.create(value).remap(mapQuestionId).toRawFormula();
          }
          return value;
        }
        default:
          return value;
      }
    };
  }
}

Formula.types[FORMULA_TYPE__TO_VALUE_SET] = FormulaToValueSet;

export default FormulaToValueSet;
