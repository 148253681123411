import forEach from 'lodash/forEach';
import find from 'lodash/find';
import map from 'lodash/map';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import BaseModel from './BaseModel';
import AnswersSheet from './AnswersSheet';
import {
  toFormValues,
} from '../utils/responses';
import {
  ACTIVITY_STATES_AFTER_START,
  ACTIVITY_ACTION__START,
  ACTIVITY_ACTION__COMPLETE,
} from '../constants';

class Activity extends BaseModel {
  get status() {
    const status = reduce(
      this.actions,
      this.constructor.reducer,
      this.constructor.reducer(undefined, {
        type: '@INIT',
      }),
    );
    Object.defineProperty(this, 'status', {
      value: status,
    });
    return status;
  }

  getDomains() {
    return map(this.ownership, 'domain');
  }

  getProjectId() {
    return this.projectId;
  }

  getMilestoneId() {
    return this.milestoneId;
  }

  getEncounterId() {
    return this.encounterId;
  }

  getParticipationId() {
    return this.participationId;
  }

  getClinicianId() {
    return this.clinicianId;
  }

  getCaseManagerId() {
    return this.caseManagerId;
  }

  getInitialValues(questionnaireId) {
    const questionnaire = find(this.questionnaires, {
      id: questionnaireId,
    });
    if (questionnaire) {
      return toFormValues(questionnaire.responses);
    }
    return {};
  }

  isStarted() {
    return includes(ACTIVITY_STATES_AFTER_START, this.state);
  }

  mergeQuestionnaires(questionnaires) {
    const mergedQuestionnaires = [];
    const responsesByQuestionnaireId = this.constructor.groupResponses([
      ...this.questionnaires,
      ...questionnaires,
    ]);
    const collectResponses = ({
      id,
    }) => {
      if (responsesByQuestionnaireId[id]) {
        mergedQuestionnaires.push({
          id,
          responses: responsesByQuestionnaireId[id],
        });
        delete responsesByQuestionnaireId[id];
      }
    };
    forEach(this.questionnaires, collectResponses);
    forEach(questionnaires, collectResponses);
    return mergedQuestionnaires;
  }

  static groupResponses(questionnaires) {
    const responsesByQuestionnaireId = {};
    forEach(questionnaires, ({
      id,
      responses,
    }) => {
      // eslint-disable-next-line no-param-reassign
      responsesByQuestionnaireId[id] = AnswersSheet.mergeResponses(
        responsesByQuestionnaireId[id] || [],
        responses,
      );
    });
    return responsesByQuestionnaireId;
  }
}

Activity.collection = 'Activities';
Activity.scopeName = '@activity';
Activity.reducer = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_ACTION__START: {
      return {
        ...state,
        started: true,
        startedAt: action.meta && action.meta.timestamp,
      };
    }
    case ACTIVITY_ACTION__COMPLETE:
      return {
        ...state,
        completed: true,
        completedAt: action.meta && action.meta.timestamp,
      };
    default:
      return state;
  }
};

Activity.constraintsVersion = 1;
Activity.constraints = [
  {
    operator: '$lte',
    id: 'dateStartLteDateEnd',
    fields: [
      {
        name: 'dateStart',
        message: 'Expected dateStart to be smaller or equal to dateEnd',
      },
      {
        name: 'dateEnd',
        message: 'Expected dateEnd to be greater or equal to dateStart',
      },
    ],
  },
  {
    operator: '$lte',
    id: 'timeStartLteTimeEnd',
    fields: [
      {
        name: 'timeStart',
        message: 'Expected timeStart to be smaller or equal to timeEnd',
      },
      {
        name: 'timeEnd',
        message: 'Expected timeEnd to be greater or equal to timeStart',
      },
    ],
  },
];

export default Activity;
