import PropTypes from 'prop-types';

export const selectOptionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
);

export const formFieldPropTypesInput = PropTypes.shape({
  value: PropTypes.any,
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
});

export const formFieldPropTypesMeta = PropTypes.shape({
  active: PropTypes.bool,
  autofilled: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  form: PropTypes.string,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
  warning: PropTypes.string,
});

// See redux-form documentation here:
// http://redux-form.com/6.5.0/docs/api/Field.md/#usage
export const formFieldPropTypes = {
  input: formFieldPropTypesInput.isRequired,
  meta: formFieldPropTypesMeta.isRequired,
  label: PropTypes.string.isRequired,
};

/*

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const themePropTypes = PropTypes.shape({
  font: PropTypes.shape({
    family: PropTypes.string,
    size: PropTypes.objectOf(stringOrNumber),
    weight: PropTypes.objectOf(stringOrNumber),
  }),
  line: PropTypes.shape({
    height: PropTypes.objectOf(stringOrNumber),
  }),
  color: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ),
  border: PropTypes.shape({
    color: PropTypes.objectOf(PropTypes.string),
    radius: PropTypes.objectOf(stringOrNumber),
  }),
  screen: PropTypes.objectOf(stringOrNumber),
  padding: PropTypes.objectOf(stringOrNumber),
  rhythm: PropTypes.objectOf(stringOrNumber),
});

*/
