import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import compact from 'lodash/compact';
import startsWith from 'lodash/startsWith';
import indexOf from 'lodash/indexOf';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import isMatch from 'lodash/isMatch';
import get from 'lodash/get';
import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import isNaN from 'lodash/isNaN';

const simpleSetValue = (target, path, value, options = {}) => {
  const {
    modifiers,
  } = options;
  const parts = path.split('.');
  if (parts.length > 0) {
    const key = parts[0];
    if (parts.length === 1) {
      // eslint-disable-next-line no-param-reassign
      target[key] = value;
    } else {
      if (target[key] === undefined) {
        const modifier = find(modifiers, {
          key,
          operator: '$default',
        });
        if (modifier) {
          // eslint-disable-next-line no-param-reassign
          target[key] = cloneDeep(modifier.value);
        } else {
          // eslint-disable-next-line no-param-reassign
          target[key] = {};
        }
      }
      if (isArray(target[key]) || isPlainObject(target[key])) {
        simpleSetValue(target[key], parts.slice(1).join('.'), value, {
          modifiers: compact(
            map(modifiers, (modifier) => {
              const prefix = `${key}.`;
              if (startsWith(modifier.key, prefix)) {
                const newKey = modifier.key.substr(prefix.length);
                if (newKey) {
                  return {
                    ...modifier,
                    key: newKey,
                  };
                }
              }
              return null;
            }),
          ),
        });
      }
    }
  }
};

const setValue = (target, path, value, options = {}) => {
  const {
    modifiers,
    arrayFilters,
  } = options;
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index < 0) {
    simpleSetValue(target, path, value, {
      modifiers,
    });
  } else {
    const key = parts.slice(0, index).join('.');
    let array = get(target, key);
    if (array === undefined) {
      array = [];
      simpleSetValue(target, key, array, {
        modifiers,
      });
    }
    if (isArray(array) && arrayFilters && arrayFilters[0]) {
      let item = find(array, arrayFilters[0]);
      if (!item) {
        const modifier = find(modifiers, {
          key: `${key}.$`,
          operator: '$default',
        });
        if (modifier && isMatch(modifier.value, arrayFilters[0])) {
          item = cloneDeep(modifier.value);
          array.push(item);
        }
      }
      if (item && index < parts.length - 1) {
        setValue(item, parts.slice(index + 1).join('.'), value, {
          ...options,
          arrayFilters: arrayFilters.slice(1),
          modifiers: compact(
            map(modifiers, (modifier) => {
              const prefix = `${key}.$.`;
              if (startsWith(modifier.key, prefix)) {
                const newKey = modifier.key.substr(prefix.length);
                if (newKey) {
                  return {
                    ...modifier,
                    key: newKey,
                  };
                }
              }
              return null;
            }),
          ),
        });
      }
    }
  }
};

class RequiredKey extends Error {
  constructor(message) {
    super(message);
    this.name = 'RequiredKey';
  }
}

const simpleDeleteKey = (target, path, options = {}) => {
  const parts = path.split('.');
  if (parts.length > 0) {
    const key = parts[0];
    if (parts.length === 1) {
      if (isPlainObject(target)) {
        // eslint-disable-next-line no-param-reassign
        delete target[key];
      } else if (isArray(target)) {
        const i = +key;
        if (!isNaN(i) && i < target.length) {
          target.splice(i, 1);
        }
      }
    } else if (isArray(target[key]) || isPlainObject(target[key])) {
      simpleDeleteKey(target[key], parts.slice(1).join('.'), options);
    }
  }
};

export const isRequiredKeyError = (err) => {
  return err instanceof RequiredKey;
};

export const deleteKey = (target, path, options = {}) => {
  const {
    arrayFilters,
    requiredKeys,
    throwOnRequiredKey = false,
  } = options;
  const parts = path.split('.');
  const index = indexOf(parts, '$');
  if (index < 0) {
    if (!includes(requiredKeys, path)) {
      simpleDeleteKey(target, path);
    } else if (throwOnRequiredKey) {
      throw new RequiredKey(`Cannot delete required key at ${path}`);
    }
  } else {
    const key = parts.slice(0, index).join('.');
    const array = get(target, key);
    if (isArray(array) && arrayFilters && arrayFilters[0]) {
      const i = findIndex(array, arrayFilters[0]);
      if (i >= 0 && index < parts.length - 1) {
        try {
          deleteKey(array[i], parts.slice(index + 1).join('.'), {
            ...options,
            throwOnRequiredKey: true,
            arrayFilters: arrayFilters.slice(1),
            requiredKeys: compact(
              map(requiredKeys, (requiredKey) => {
                const prefix = `${key}.$.`;
                if (startsWith(requiredKey, prefix)) {
                  return requiredKey.substr(prefix.length);
                }
                return null;
              }),
            ),
          });
        } catch (err) {
          if (isRequiredKeyError(err)) {
            array.splice(i, 1);
          } else {
            throw err;
          }
        }
      }
    }
  }
};

export default setValue;
