import {
  QUESTION_TYPE__FORMULA,
} from '../../../constants';
import Question from '../Question';

export default class QuestionFormula extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__FORMULA;
  }
}

QuestionFormula.expectedRawKeys = [
  'value',
  'error',
];
QuestionFormula.expectedValueType = {};
QuestionFormula.editorSettings = {};

Question.types[QUESTION_TYPE__FORMULA] = QuestionFormula;
