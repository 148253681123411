import React from 'react';
import PropTypes from 'prop-types';
import {
  createSelector,
} from 'reselect';
import {
  useTranslation,
} from 'react-i18next';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  generateRecipientLoginForServerless,
} from '../../../common/api/zedoc';
import Cluster from '../../../common/components/primitives/Cluster';
import Stack from '../../../common/components/primitives/Stack';
import Skeleton from '../../../common/components/Skeleton';
import Text from '../../../common/components/base/Text';
import copyToClipboard from '../../../common/utilsClient/copyToClipboard';
import Modal from '../Modal';

const ShareLoginInfo = ddp({
  renderLoader: null,
  queries: (state, {
    activityId,
  }) => ({
    login: generateRecipientLoginForServerless.withParams({
      activityId,
    }),
  }),
  selectors: (select) => {
    return {
      error: createSelector(
        select.queries,
        queries => queries.login.error,
      ),
    };
  },
})(({
  login: {
    loginUrl,
    qrcodeDataUrl,
  } = {},
  error,
  open,
  onClose,
}) => {
  const {
    t,
  } = useTranslation();

  const onOk = () => copyToClipboard(loginUrl);

  return (
    <Modal
      title={t('shareWithOthers')}
      okText={t('copyURL')}
      onOk={onOk}
      isOkDisabled={!loginUrl}
      visible={open}
      onCancel={onClose}
    >
      {error ? (
        <Text.Paragraph align="center">{error.reason}</Text.Paragraph>
      ) : (
        <Cluster justify="center">
          <Stack space={1}>
            {qrcodeDataUrl ? (
              <img
                src={qrcodeDataUrl}
                alt={t('qrcode')}
                height="150"
                width="150"
              />
            ) : (
              <Skeleton
                height={150}
                width={150}
              />
            )}
            <Text.Paragraph align="center">
              {loginUrl ? (
                <Text.Link
                  href={loginUrl}
                  target="_blank"
                >
                  URL
                </Text.Link>
              ) : (
                <Skeleton width={75} />
              )}
            </Text.Paragraph>
          </Stack>
        </Cluster>
      )}
    </Modal>
  );
});

ShareLoginInfo.propTypes = {
  activityId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ShareLoginInfo;
