import {
  MESSAGE_TYPE__UNKNOWN,
} from '../constants';
import Message from '../Message';

class MessageUnknown extends Message {
  constructor(doc) {
    super(doc);
    this.type = this.type || MESSAGE_TYPE__UNKNOWN;
  }
}

MessageUnknown.topic = 'unknown';
Message.types[MESSAGE_TYPE__UNKNOWN] = MessageUnknown;

export default MessageUnknown;
