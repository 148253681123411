/* eslint class-methods-use-this: "off" */
import isArray from 'lodash/isArray';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import isNaN from 'lodash/isNaN';
import {
  isEmptyAnswer,
  parseValueExpr,
} from '../../utils/question';
import {
  collapse,
} from '../../utils/formValues';
import {
  getAtPath,
  evaluateFormula,
} from './utils';

const CURRENT_VARIABLE = '$';

class AbstractEvaluationScope {
  constructor(doc) {
    Object.assign(this, doc);
    this.stack = [];
    if (this.parent) {
      this.root = this.parent.root;
    } else {
      this.root = this;
    }
    [
      'getUser',
      'getQuestionnaire',
    ].forEach((method) => {
      this[method] = this[method].bind(this.root);
    });
    this.subScopes = {};
  }

  isRoot() {
    return !this.parent;
  }

  isElementsScope() {
    return !!this.elementsScope;
  }

  toFormValues() {
    // NOTE: We cannot simply return this.answers, because it may not be a plain
    //       object, e.g. this is equal a ReactiveDict in ReactiveEvaluationScope.
    const formValues = {};
    this.forEachAnswer((answer, id) => {
      formValues[id] = answer;
    });
    return formValues;
  }

  toVariables() {
    // NOTE: We cannot simply return this.variables, because it may not be a plain
    //       object, e.g. this is equal a ReactiveDict in ReactiveEvaluationScope.
    const questionnaire = this.getQuestionnaire();
    const variables =
      questionnaire && this.isRoot()
        ? questionnaire.getDefaultVariables({
          expanded: true,
        })
        : {};
    this.forEachVariable((variable, id) => {
      if (id !== CURRENT_VARIABLE) {
        variables[id] = variable;
      }
    });
    return variables;
  }

  copyWithFormValues() {
    throw new Error('Not implemented');
  }

  getAnswerHierarchy() {
    return this.hierarchy;
  }

  getHierarchyKey() {
    return this.hierarchyKey;
  }

  getCollectionQuestionId() {
    return this.parentQuestionId;
  }

  getElementId() {
    const questionId = this.getCollectionQuestionId();
    const hierarchyKey = this.getHierarchyKey();
    if (!questionId || !hierarchyKey) {
      return undefined;
    }
    const parts = this.hierarchyKey.split('.');
    const index = findIndex(parts, x => x === questionId);
    if (index < 0) {
      return undefined;
    }
    return parts[index + 1];
  }

  lookup(id, method) {
    let scope = this;
    while (scope) {
      const answer = scope[method](id);
      if (answer) {
        return answer;
      }
      scope = scope.parent;
    }
    return undefined;
  }

  lookupScope(predicate) {
    let scope = this;
    while (scope) {
      if (predicate(scope)) {
        return scope;
      }
      scope = scope.parent;
    }
    return undefined;
  }

  lookupQuestionDefinitionScope(id) {
    const questionnaire = this.getQuestionnaire();
    const closestCollectionQuestionId = questionnaire.getClosestCollectionQuestionId(
      id,
    );
    if (!questionnaire) {
      return undefined;
    }
    return this.lookupScope(
      scope => !scope.isElementsScope() &&
        scope.getCollectionQuestionId() === closestCollectionQuestionId,
    );
  }

  lookupVariableDefinitionScope(id) {
    const questionnaire = this.getQuestionnaire();
    return this.lookupScope((scope) => {
      if (scope.getVariable(id)) {
        return true;
      }
      if (!questionnaire) {
        return false;
      }
      if (scope.isRoot() && questionnaire.hasVariable(id)) {
        return true;
      }
      return !isEmpty(
        questionnaire.getQuestionsByVariableId(
          id,
          scope.getCollectionQuestionId(),
        ),
      );
    });
  }

  /**
   * Find closest parent scope with the given collectionQuestionId.
   * If value not provided, the function will return the root scope.
   * @param {String|String[]} [collectionQuestionId]
   */
  lookupScopeWithQuestionId(collectionQuestionId) {
    let predicate;
    if (typeof collectionQuestionId === 'string') {
      predicate = s => s.getCollectionQuestionId() === collectionQuestionId;
    } else if (isArray(collectionQuestionId)) {
      predicate = s => collectionQuestionId.indexOf(s.getCollectionQuestionId()) >= 0;
    } else if (!collectionQuestionId) {
      predicate = s => !s.parent;
    }
    let scope = this;
    while (scope) {
      if (predicate(scope)) {
        return scope;
      }
      scope = scope.parent;
    }
    return undefined;
  }

  getUser() {
    throw new Error('Not implemented');
  }

  getQuestionnaire() {
    throw new Error('Not implemented');
  }

  getQuestion(id) {
    const questionnaire = this.getQuestionnaire();
    if (questionnaire) {
      return questionnaire.getQuestionById(id);
    }
    return undefined;
  }

  evaluateAsNumber(value, questionId) {
    let valueNumber;
    const question = questionId && this.getQuestion(questionId);
    if (question) {
      valueNumber = question.toNumericValue(value);
    } else {
      valueNumber = parseValueExpr(value);
    }
    if (!isNaN(valueNumber)) {
      return {
        value: valueNumber,
      };
    }
    return {
      error: this.constructor.NotNumber,
    };
  }

  shouldUseNestedVariable(sectionId) {
    const questionnaire = this.getQuestionnaire();
    if (questionnaire) {
      return !isEmpty(
        questionnaire.getQuestionsByVariableId(CURRENT_VARIABLE, sectionId),
      );
    }
    return false;
  }

  getUserProperty(id, propName) {
    const user = this.getUser(id);
    return user && user[propName];
  }

  getAnswer() {
    throw new Error('Not implemented');
  }

  lookupAnswer(id) {
    const questionnaire = this.getQuestionnaire();
    if (!questionnaire) {
      return this.lookup(id, 'getAnswer');
    }
    const question = questionnaire.getQuestionById(id);
    if (!question) {
      return this.lookup(id, 'getAnswer');
    }
    if (question.isRealQuestion()) {
      const answer = this.lookup(id, 'getAnswer');
      if (!isEmptyAnswer(answer)) {
        return answer;
      }
      if (!question.hasFormulaToEvaluate()) {
        // NOTE: There's a subtle difference between "undefined" which
        //       represents no answer at all, and "empty answer", which
        //       can be an empty collection for example. The latter
        //       can indicate that all elements where deleted, which
        //       is different from no changes were made.
        return answer;
      }
    } else if (!question.isFormula()) {
      return undefined;
    }
    if (!question.hasFormulaToEvaluate()) {
      return undefined;
    }
    // NOTE: The value will be evaluated with formula. It can either be an
    //       explicity formula or a default value evaluated with formula.
    if (this.stack.indexOf(id) >= 0) {
      return {
        error: this.constructor.CircularDependency,
      };
    }
    const collectionQuestionId = questionnaire.getClosestCollectionQuestionId(
      id,
    );
    const scope = this.lookupScopeWithQuestionId(collectionQuestionId);
    if (!scope) {
      return {
        error: this.constructor.UnrelatedScope,
      };
    }
    this.stack.push(id);
    const result = evaluateFormula(question, scope);
    if (this.stack.length > 0) {
      this.stack.pop();
    }
    return result;
  }

  getAnswerMeta(id) {
    const answer = this.getAnswer(id);
    // eslint-disable-next-line no-underscore-dangle
    return answer && answer._meta;
  }

  lookupAnswerMeta(id) {
    return this.lookup(id, 'getAnswerMeta');
  }

  getVariable() {
    throw new Error('Not implemented');
  }

  findVariableBoundTo(question) {
    if (!question.variableId) {
      return undefined;
    }
    const containerId = this.getCollectionQuestionId();
    const path = [];
    let parent = question;
    while (parent) {
      if (parent.variableId && parent.variableId !== CURRENT_VARIABLE) {
        path.unshift(parent.variableId);
      }
      if (parent.sectionId !== containerId) {
        parent = this.getQuestion(parent.sectionId);
      } else {
        break;
      }
    }
    if (path.length > 0) {
      return getAtPath(this.getVariable(path[0]), path.slice(1));
    }
    return this.getVariable(CURRENT_VARIABLE);
  }

  lookupVariable(id, initialValueOnly) {
    const questionnaire = this.getQuestionnaire();
    const scope = this.lookupVariableDefinitionScope(id);
    if (!scope) {
      return undefined;
    }
    if (initialValueOnly) {
      const variable = scope.getVariable(id);
      if (variable) {
        return variable;
      }
      if (questionnaire && scope.isRoot()) {
        return questionnaire.getDefaultVariableValue(id, {
          expanded: true,
        });
      }
    }
    const sectionId = scope.getCollectionQuestionId();
    const variables = scope.evaluateVariables({
      expanded: true,
      questionIds:
        questionnaire && questionnaire.getQuestionsByVariableId(id, sectionId),
    });
    if (variables[id]) {
      return {
        value: collapse(variables[id]),
      };
    }
    return undefined;
  }

  forEachAnswer() {
    throw new Error('Not implemented');
  }

  forEachVariable() {
    throw new Error('Not implemented');
  }

  createSubScope(questionOrElementId) {
    if (!questionOrElementId) {
      return this;
    }
    const hierarchyKey = this.hierarchyKey
      ? `${this.hierarchyKey}.${questionOrElementId}`
      : questionOrElementId;
    const question = this.isElementsScope()
      ? this.getQuestion(this.getCollectionQuestionId())
      : this.getQuestion(questionOrElementId);
    const variables = {};
    if (
      question &&
      question.variableId &&
      question.variableId !== CURRENT_VARIABLE
    ) {
      if (this.isElementsScope()) {
        const variable =
          this.parent && this.parent.findVariableBoundTo(question);
        const element =
          variable &&
          variable._elements &&
          variable._elements[questionOrElementId];
        if (element) {
          Object.assign(
            variables,
            {
              [CURRENT_VARIABLE]: element,
            },
            element._elements,
          );
        }
      } else {
        const variable = this.findVariableBoundTo(question);
        if (variable) {
          Object.assign(
            variables,
            {
              [CURRENT_VARIABLE]: variable,
            },
            variable._elements,
          );
        }
      }
    }
    const answer = this.getAnswer(questionOrElementId);
    return new this.constructor({
      variables,
      hierarchyKey,
      elementsScope:
        !this.isElementsScope() && !!(question && question.isCollection()),
      parentQuestionId: question && question.id,
      parent: this,
      answers: answer && answer._elements,
    });
  }

  pickAllAnswers(questionId) {
    const answers = [];
    const iterate = (scope, lookupIds) => {
      if (lookupIds.length === 0) {
        return;
      }
      // NOTE: Theoretically, this can be a nested formula, so we use lookupAnswer instead of getAnswer.
      const answer = scope.lookupAnswer(lookupIds[0]);
      if (answer) {
        if (lookupIds.length === 1) {
          answers.push(answer);
        } else {
          forEach(answer._elementsOrder, (elementId) => {
            iterate(
              scope
                .getOrCreateSubScope(lookupIds[0])
                .getOrCreateSubScope(elementId),
              lookupIds.slice(1),
            );
          });
        }
      }
    };

    const questionnaire = this.getQuestionnaire();
    if (questionnaire) {
      const collectionQuestionIds = questionnaire.getParentIdsWhere(
        questionId,
        q => q.isCollection(),
      );
      const scope = this.lookupScopeWithQuestionId(collectionQuestionIds);
      if (scope) {
        const index = collectionQuestionIds.indexOf(
          scope.getCollectionQuestionId(),
        );
        iterate(scope, [
          ...collectionQuestionIds.slice(index + 1),
          questionId,
        ]);
      } else {
        iterate(this.lookupScopeWithQuestionId(), [
          ...collectionQuestionIds,
          questionId,
        ]);
      }
    }

    return answers;
  }

  mapQuestionToVariable(question) {
    const answer = this.lookupAnswer(question.id);
    if (question.isComposite()) {
      const questionnaire = this.getQuestionnaire();
      const {
        variableId,
      } = question;
      if (questionnaire) {
        const variables = this.toVariables();
        const descriptor = {
          _elements: this.evaluateVariables({
            expanded: true,
            localVariablesOverwrite:
              variables[variableId] && variables[variableId]._elements,
            questionIds: questionnaire.mapQuestions(q => q.id, {
              sectionId: question.id,
              stopRecursion: q => q.isCollection() || q.isComposite(),
            }),
          }),
        };
        if (this.shouldUseNestedVariable(question.id)) {
          return descriptor._elements[CURRENT_VARIABLE];
        }
        if (!isEmpty(descriptor._elements)) {
          return descriptor;
        }
      }
      return undefined;
    }
    if (question.isCollection()) {
      if (answer) {
        const descriptor = {
          _elements: {},
          _elementsOrder: (answer && answer._elementsOrder) || [],
        };
        forEach(descriptor._elementsOrder, (elementId) => {
          const subScope = this.getOrCreateSubScope(
            question.id,
          ).getOrCreateSubScope(elementId);
          const _elements = subScope.evaluateVariables({
            expanded: true,
          });
          descriptor._elements[elementId] = this.shouldUseNestedVariable(
            question.id,
          )
            ? _elements[CURRENT_VARIABLE]
            : {
              _elements,
            };
        });
        return descriptor;
      }
      return undefined;
    }
    return answer;
  }

  /**
   * Compute current values for all variables bound to questions in the current scope.
   * @param {Object} [options]
   * @param {Array<string>} [options.questionIds] only use these questions
   * @param {Boolean} [options.expanded] return variables in "expanded" form
   * @param {Boolean} [options.localVariablesOverwrite] replace variables in local scope if needed
   * @returns {Object<string, any>} variables map
   */
  evaluateVariables({
    questionIds,
    expanded = false,
    localVariablesOverwrite,
  } = {}) {
    const questionnaire = this.getQuestionnaire();

    const variables = {
      _elements: localVariablesOverwrite || this.toVariables(),
    };
    const handleQuestion = (question) => {
      const id = question.variableId;
      if (id) {
        if (this.stack.indexOf(id) >= 0) {
          // Circular dependency detected ...
          return;
        }
        this.stack.push(id);
        const result = this.mapQuestionToVariable(question);
        if (result && !result.error) {
          variables._elements[id] = result;
        } else {
          delete variables._elements[id];
        }
        if (this.stack.length > 0) {
          this.stack.pop();
        }
      }
    };

    if (questionnaire) {
      if (questionIds) {
        forEach(questionIds, (questionId) => {
          const question = questionnaire.getQuestionById(questionId);
          if (question) {
            handleQuestion(question);
          }
        });
      } else {
        questionnaire.forEachQuestion(handleQuestion, {
          stopRecursion: q => q.isCollection() || q.isComposite(),
          sectionId: this.getCollectionQuestionId(),
        });
      }
    }

    return expanded ? variables._elements : collapse(variables);
  }

  /**
   * Return formValues object with initial values obtained from evaluationScope.
   * @param {EvaluationScope} evaluationScope
   * @returns {Object}
   */
  getInitialValues() {
    const questionnaire = this.getQuestionnaire();
    const formValues = {};
    if (!questionnaire) {
      return formValues;
    }
    questionnaire.forEachQuestion(
      (question) => {
        if (!question.isRealQuestion()) {
          return;
        }
        let variable;
        let variableAnswer;
        if (question.shouldUseBoundVariableForInitialValue()) {
          variable = this.findVariableBoundTo(question);
          if (variable) {
            variableAnswer = {
              value: collapse(variable),
            };
          }
        }
        if (question.isCollection()) {
          if (variable && !isEmpty(variable._elementsOrder)) {
            formValues[question.id] = {
              _elements: {},
              _elementsOrder: [
                ...variable._elementsOrder,
              ],
            };
          } else if (question.shouldUseFormulaForInitialValue()) {
            formValues[question.id] = {
              _elements: {},
              _elementsOrder: [
                '0',
              ],
            };
          }
          forEach(
            formValues[question.id] && formValues[question.id]._elementsOrder,
            (elementId) => {
              const initialValues = this.getOrCreateSubScope(question.id)
                .getOrCreateSubScope(elementId)
                .getInitialValues();
              if (!isEmpty(initialValues)) {
                formValues[question.id]._elements[elementId] = {
                  _elements: initialValues,
                };
              }
            },
          );
        } else if (
          isEmptyAnswer(variableAnswer) &&
          question.shouldUseFormulaForInitialValue()
        ) {
          const result = evaluateFormula(question, this);
          if (!isEmptyAnswer(result)) {
            formValues[question.id] = result;
          }
        } else if (variableAnswer) {
          formValues[question.id] = variableAnswer;
        }
      },
      {
        sectionId: this.getCollectionQuestionId(),
        stopRecursion: q => q.isCollection(),
      },
    );
    return formValues;
  }

  getOrCreateSubScope(questionOrElementId) {
    if (!questionOrElementId) {
      return this;
    }
    if (!this.subScopes[questionOrElementId]) {
      this.subScopes[questionOrElementId] = this.createSubScope(
        questionOrElementId,
      );
    }
    return this.subScopes[questionOrElementId];
  }

  getScopeForScopeKey(scopeKey) {
    if (!scopeKey) {
      return this;
    }
    const [
      first,
      second,
      ...rest
    ] = scopeKey.split('.');
    if (second !== '_elements') {
      throw new Error(`Invalid scopeKey: ${scopeKey}`);
    }
    const scope = this.getOrCreateSubScope(first);
    if (rest.length === 0) {
      return scope;
    }
    return scope.getScopeForScopeKey(rest.join('.'));
  }
}

AbstractEvaluationScope.CircularDependency = {
  message: 'Circular dependency detected',
};

AbstractEvaluationScope.UnrelatedScope = {
  message: 'Cannot evaluate formula from unrelated scope',
};

AbstractEvaluationScope.NotNumber = {
  message: 'Value is not a number',
};

export default AbstractEvaluationScope;
