import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import {
  initReactI18next,
} from 'react-i18next';

export const ENGLISH_VALUE = 'en';

export const getNavigatorLanguage = () => {
  if (typeof navigator !== 'undefined') {
    return navigator.language && navigator.language.substr(0, 2);
  }

  return null;
};

export const getSupportedLanguage = (
  supportedLanguages,
  language = getNavigatorLanguage(),
) => {
  if (supportedLanguages.indexOf(language) >= 0) {
    return language;
  }

  return ENGLISH_VALUE;
};

export const getPreferredLanguage = (
  languagePreference,
  availableLanguages,
  defaultLanguage = null,
) => {
  if (isEmpty(languagePreference)) {
    return defaultLanguage;
  }
  const n = languagePreference.length;
  for (let i = 0; i < n; i += 1) {
    const language = languagePreference[i];
    if (includes(availableLanguages, language)) {
      return language;
    }
  }
  return defaultLanguage;
};

export const getCurrentLanguage = () => i18next.language;

export const getLanguageDirection = () => i18next.dir(getCurrentLanguage());

export const changeLanguage = lng => i18next.changeLanguage(lng);

export const initI18next = ({
  supportedLanguages,
  supportedNamespaces,
}) => {
  i18next
    .use(Backend)
    .use(initReactI18next)
    .init(
      {
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        interpolation: {
          escapeValue: false,
        },
        nonExplicitWhitelist: true,
        lng: getSupportedLanguage(supportedLanguages),
        whitelist: supportedLanguages,
        ns: supportedNamespaces,
        fallbackLng: ENGLISH_VALUE,
        defaultNS: 'common',
        // debug: true,
      },
      // eslint-disable-next-line no-console
      err => err && console.error(err),
    );
};
