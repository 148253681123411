import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
} from 'recompose';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  connect,
} from 'react-redux';
import {
  PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
} from '../../../../../common/permissions';
import {
  ROLE_TIER__HIGH,
} from '../../../../../common/constants';
import {
  notifyError,
} from '../../../../../utils/notify';
import {
  callMethod,
  logout,
} from '../../../../../common/utilsClient/ddp/actions';
import {
  generateRecipientLoginForServerless,
} from '../../../../../common/api/zedoc';
import CurrentUserSelect from '../../../../../common/selectors/CurrentUser';
import AnswersSheet from '../../../../../common/models/AnswersSheet';
import {
  takeQuestionnaire,
} from '../../../../../components/dialogs/TakeQuestionnaire';
import {
  closeMilestoneDialog,
} from '../../../actions';
import FormStart from './FormStart';

const startQuestionnaire = ({
  // answersSheetId,
  activityId,
  dispatch,
}) => {
  let newTab = null;
  if (process.env.NODE_ENV === 'development') {
    newTab = window;
  } else {
    newTab = window.open();
  }

  newTab.document.write('Loading the questionnaire...');
  newTab.document.title = 'MY ZEDOC';

  dispatch(
    callMethod(generateRecipientLoginForServerless, {
      activityId,
    }),
  )
    .then(({
      loginUrl,
    }) => {
      dispatch(logout()).then(() => {
        newTab.location.href = loginUrl;
      });
    })
    .catch((err) => {
      if (newTab) {
        newTab.close();
      }
      notifyError()(err);
    });
};

const Container = compose(
  connect(() => {
    const selectAnswersSheet = (state, {
      answersSheet,
    }) => answersSheet;
    const selectIsAssignedToPatient = createSelector(
      selectAnswersSheet,
      answersSheet => answersSheet && answersSheet.isAssignedToPatient(),
    );
    return createStructuredSelector({
      answersSheetId: createSelector(
        selectAnswersSheet,
        answersSheet => answersSheet && answersSheet._id,
      ),
      activityId: createSelector(
        selectAnswersSheet,
        answersSheet => answersSheet && answersSheet.activityId,
      ),
      isAssignedToPatient: selectIsAssignedToPatient,
      canUsePatientMode: createSelector(
        selectAnswersSheet,
        answersSheet => answersSheet && answersSheet.canBeCompletedByPatient(),
      ),
      currentUser: CurrentUserSelect.user(),
      canStartNow: createSelector(
        selectAnswersSheet,
        CurrentUserSelect.user(),
        CurrentUserSelect.getPermissionGrant(
          PATIENT_MILESTONE_COMPLETE_ANSWERS_SHEET,
        ),
        selectIsAssignedToPatient,
        (answersSheet, currentUser, permission, isAssignedToPatient) => {
          if (isAssignedToPatient) {
            return true;
          }
          if (answersSheet && currentUser && permission) {
            const assigneeId = answersSheet.getAssigneeId();
            return (
              assigneeId === currentUser._id ||
              permission.tier === ROLE_TIER__HIGH
            );
          }
          return false;
        },
      ),
    });
  }),
  withHandlers({
    onStart: ({
      dispatch,
      answersSheetId,
    }) => () => {
      dispatch(closeMilestoneDialog());
      dispatch(takeQuestionnaire(answersSheetId));
    },
    onPatientStart: ({
      dispatch,
      activityId,
      answersSheetId,
    }) => () => {
      startQuestionnaire({
        answersSheetId,
        activityId,
        dispatch,
      });
    },
  }),
)(FormStart);

Container.propTypes = {
  answersSheet: PropTypes.instanceOf(AnswersSheet).isRequired,
};

export default Container;
