export default {
  blue: {
    10: 'hsl(220, 80%, 94%)',
    20: 'hsl(220, 80%, 86%)',
    30: 'hsl(220, 80%, 60%)',
    40: 'hsl(220, 80%, 55%)',
    50: 'hsl(220, 80%, 50%)',
    60: 'hsl(220, 80%, 30%)',
    70: 'hsl(220, 80%, 25%)',
    80: 'hsl(220, 80%, 20%)',
    90: 'hsl(220, 80%, 15%)',
    100: 'hsl(220, 80%, 9%)',
  },
  red: {
    30: 'hsl(350, 90%, 80%)',
    40: 'hsl(350, 90%, 75%)',
    50: 'hsl(350, 90%, 70%)',
    70: 'hsl(350, 90%, 65%)',
    60: 'hsl(350, 90%, 60%)',
    80: 'hsl(350, 90%, 55%)',
  },
  green: {
    40: 'hsl(165, 100%, 40%)',
    50: 'hsl(165, 100%, 35%)',
  },
  yellow: {
    40: 'hsl(45, 100%, 65%)',
    50: 'hsl(45, 100%, 50%)',
  },
  gray: {
    10: 'hsl(210, 28%, 96%)',
    20: 'hsl(210, 28%, 88%)',
    30: 'hsl(210, 28%, 78%)',
    40: 'hsl(210, 28%, 66%)',
    50: 'hsl(210, 28%, 55%)',
    60: 'hsl(210, 28%, 44%)',
    70: 'hsl(210, 28%, 32%)',
    80: 'hsl(210, 28%, 22%)',
    90: 'hsl(210, 28%, 15%)',
    100: 'hsl(210, 28%, 9%)',
  },
  white: 'hsl(210, 28%, 100%)',
  black: 'hsl(210, 28%, 7%)',
};
