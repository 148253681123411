import React from 'react';
import {
  createStructuredSelector,
} from 'reselect';
import {
  compose,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import CurrentUserSelect from '../../common/selectors/CurrentUser';
import Cover from '../../common/components/primitives/Cover';
import Center from '../../common/components/primitives/Center';
import Text from '../../common/components/base/Text';
import useDocumentTitle from '../../utils/useDocumentTitle';

const Welcome = compose(
  connect(() => createStructuredSelector({
    currentUser: CurrentUserSelect.user(),
  })),
)(({
  currentUser,
}) => {
  const {
    t,
  } = useTranslation();

  useDocumentTitle([
    t('entry:welcome'),
  ]);

  return (
    <Cover>
      <Center>
        <Text.Paragraph size="large">
          {t('entry:welcome')}
          {', '}
          <b>{currentUser && currentUser.getFullName()}</b>
          !
        </Text.Paragraph>
        {isEmpty(currentUser.roles) && (
          <Text.Paragraph size="small">
            {t('entry:accountIsBeingPrepared')}
          </Text.Paragraph>
        )}
      </Center>
    </Cover>
  );
});

export default Welcome;
