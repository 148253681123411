import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import first from 'lodash/first';
import {
  saveAs,
} from 'file-saver';
import {
  compose,
  withHandlers,
  lifecycle,
} from 'recompose';
import {
  createSelector,
  createStructuredSelector,
} from 'reselect';
import {
  connect,
} from 'react-redux';
import {
  ddp,
} from '@theclinician/ddp-connector';
import CurrentUserSelect from '../../../../common/selectors/CurrentUser';
import ProjectSelect from '../../../../common/selectors/Project';
import PatientRecordSelect from '../../../../common/selectors/PatientRecord';
import VariableSelect from '../../../../common/selectors/Variable';
import Variable from '../../../../common/models/Variable';
import CountSelect from '../../../../common/selectors/Count';
import {
  getUserNames,
  getProjectNames,
} from '../../../../common/api/aggregations/Projects';
import {
  apiZedocAllProjects,
  apiZedocProjectVariables,
  apiZedocSearchPatientRecords,
} from '../../../../common/api/zedoc';
import {
  getSorter,
  getQueryFilters,
  getSortingOrder,
  getSortingPresets,
  getCurrentSortingPresetId,
  getFilters,
} from '../../../../store/ui/search/selectors';
import {
  setSorting,
  setSortingOrder,
} from '../../../../store/ui/search/actions';
import PatientsTable from './PatientsTable';
import {
  openProjectProfileDialog,
  openEditPatientDialog,
  setActiveProjectId,
  setCurrentPage,
  setNumberOfPatients,
  setPageSize,
} from '../../actions';
import {
  getActiveProjectId,
  getCurrentPage,
  getNumberOfPatients,
  getPageSize,
  getCurrentPageIndex,
} from '../../selectors';
import settings from '../../../../common/settings';
import {
  getResumeToken,
} from '../../../../common/utilsClient/ddp/selectors';
import {
  notifyError,
  notifySuccess,
} from '../../../../utils/notify';
import {
  slugify,
} from '../../../../common/utils/formatting';
import getExportFileName from '../../../../common/utils/getExportFileName';

const {
  backendUrl,
} = settings.public;

const Container = compose(
  ddp({
    subscriptions: createSelector(
      getActiveProjectId,
      getQueryFilters,
      getSorter,
      getCurrentPageIndex,
      getPageSize,
      (projectId, filters, sorter, pageIndex, pageSize) => ({
        projects: apiZedocAllProjects.withParams({}),
        patients: apiZedocSearchPatientRecords.withParams({
          projectId,
          filters,
          sorter,
          controlId: '$meta.id',
          pageIndex,
          resultsPerPage: pageSize,
        }),
        variables:
          projectId &&
          apiZedocProjectVariables.withParams({
            projectId,
          }),
      }),
    ),
    queries: createSelector(getActiveProjectId, projectId => ({
      userNames:
        projectId &&
        getUserNames.withParams({
          projectId,
        }),
      projectNames: getProjectNames.withParams({}),
    })),
    selectors: ({
      subscriptions,
    }) => ({
      totalItems: createSelector(
        CountSelect.one().whereIdEquals(
          createSelector(
            subscriptions,
            subs => `${apiZedocSearchPatientRecords.getName()}.${subs.patients.id}`,
          ),
        ),
        doc => get(doc, 'count', null),
      ),
      patients: PatientRecordSelect.all()
        .where(
          createSelector(
            createSelector(
              subscriptions,
              subs => `_pagination_${subs.patients.id}`,
            ),
            getCurrentPageIndex,
            (key, pageIndex) => record => record[key] && record[key].pageIndex === pageIndex,
          ),
        )
        .sort(
          createSelector(
            createSelector(
              subscriptions,
              subs => `_pagination_${subs.patients.id}.index`,
            ),
            key => ({
              [key]: 1,
            }),
          ),
        ),
      projects: ProjectSelect.all(),
    }),
    renderLoader: null,
    subscriptionsUpdateDelay: 1000,
  }),
  connect(
    createStructuredSelector({
      nPatients: getNumberOfPatients,
      pageSize: getPageSize,
      currentPage: getCurrentPage,
      currentUser: CurrentUserSelect.user(),
      projectId: getActiveProjectId,
      project: ProjectSelect.one()
        .whereIdEquals(getActiveProjectId)
        .lookup({
          from: VariableSelect.all(),
          as: 'variables',
          foreignKey: '_id',
          key: (doc, docId, variables) => {
            return map(doc.variables, ({
              id,
            }) => {
              return (
                first(variables[id]) ||
                new Variable({
                  _id: id,
                })
              );
            });
          },
        }),
      currentSorting: getCurrentSortingPresetId,
      currentSortingOrder: getSortingOrder,
      sortingOptions: getSortingPresets,
      filters: getQueryFilters,
      sorter: getSorter,
      validate: CurrentUserSelect.getCurrentPermissionsValidator(),
      exportFilters: getQueryFilters,
      exportSorter: getSorter,
      resumeToken: getResumeToken,
      hasFilters: createSelector(getFilters, filters => !isEmpty(filters)),
    }),
    dispatch => ({
      onChangeProject: projectId => dispatch(setActiveProjectId(projectId)),
      onSelectSorting: sorting => dispatch(setSorting(sorting)),
      onSelectSortingOrder: sortingOrder => dispatch(setSortingOrder(sortingOrder)),
    }),
  ),
  withHandlers({
    onAddPatient: ({
      projectId,
      dispatch,
    }) => () => {
      if (projectId) {
        dispatch(openProjectProfileDialog());
      } else {
        dispatch(openEditPatientDialog());
      }
    },
    onPageChange: ({
      dispatch,
    }) => (page) => {
      dispatch(setCurrentPage(page));
    },
    onShowSizeChange: ({
      dispatch,
    }) => (current, size) => dispatch(setPageSize(size)),
    onProjectExport: ({
      project,
      resumeToken,
      exportFilters,
      exportSorter,
    }) => () => (project
      ? fetch(
        `${backendUrl}/integrations/dataExports/projects/${project._id}`,
        {
          // see https://stackoverflow.com/a/32545850/2817257
          method: 'POST',
          body: JSON.stringify({
            filters: exportFilters,
            sorter: exportSorter,
          }),
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': resumeToken,
          },
        },
      )
      : Promise.reject(new Error('Please select a project first'))
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        return response
          .json()
          .then(({
            error,
            reason,
          }) => Promise.reject(new Error(`[${error}] ${reason}`)));
      })
      .then(blob => saveAs(blob, getExportFileName(slugify(project.getName()), 'csv')))
      .then(notifySuccess('Exported Patients'))
      .catch(notifyError()),
  }),
  lifecycle({
    componentDidMount() {
      const {
        dispatch,
        totalItems,
      } = this.props;
      if (totalItems !== null) {
        dispatch(setNumberOfPatients(totalItems));
      }
    },
    componentDidUpdate(prevProps) {
      const {
        dispatch,
        totalItems,
      } = this.props;
      if (totalItems !== null && totalItems !== prevProps.totalItems) {
        dispatch(setNumberOfPatients(totalItems));
      }
    },
  }),
)(PatientsTable);

export default Container;
