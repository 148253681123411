import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import Logo from '../../../common/components/Logo';
import Text from '../../../common/components/base/Text';
import Box from '../../../common/components/primitives/Box';
import Stack from '../../../common/components/primitives/Stack';
import Cluster from '../../../common/components/primitives/Cluster';
import {
  theme,
} from '../../../common/utilsClient/cssHelpers';
import renderEnvironmentAlert from '../../../utils/renderEnvironmentAlert';
import branding, {
  getEntryScreenBackground,
} from '../../../utils/branding';

const entryScreenBackground = getEntryScreenBackground();

const StyledLayout = styled.section`
  ${entryScreenBackground};

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100%;
`;

const StyledContent = styled.main`
  padding: ${theme('rhythm.small')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  > div {
    max-width: ${theme('screen.xs')}px;
    width: 100%;
  }
`;

const StyledLogo = styled(Logo)`
  height: 2rem;
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  logoTo: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
};

const defaultProps = {
  logoTo: '',
  actions: [],
};

const EntryLayout = ({
  children,
  logoTo,
  actions,
}) => {
  const {
    t,
  } = useTranslation();

  return (
    <StyledLayout>
      {renderEnvironmentAlert()}
      <StyledContent>
        <div />
        <Box.Primary boxShadow="base">
          <Stack>
            <Link to={logoTo}>
              <StyledLogo branding={branding} />
            </Link>
            {children}
            {!isEmpty(actions) && <Cluster justify="center">{actions}</Cluster>}
          </Stack>
        </Box.Primary>
        <Text.Paragraph size="small">
          ©
          {' '}
          {new Date().getFullYear()}
          {' '}
          <a
            href="https://www.theclinician.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('brand:name')}
          </a>
          {'. '}
          {t('brand:copyright')}
        </Text.Paragraph>
      </StyledContent>
    </StyledLayout>
  );
};

EntryLayout.propTypes = propTypes;
EntryLayout.defaultProps = defaultProps;

export default EntryLayout;
