/* eslint-disable no-underscore-dangle */
/* eslint global-require: "off" */
/* eslint import/first: "off" */
import 'focus-visible';
import DDPClient from '@theclinician/ddp-client';
import DDPConnector from '@theclinician/ddp-connector';
import {
  compose,
  createStore,
  applyMiddleware,
} from 'redux';
import {
  persistStore,
  persistReducer,
} from 'redux-persist';
import storage from 'localforage';
import thunk from 'redux-thunk';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createBrowserHistory,
} from 'history';
import {
  routerMiddleware,
} from 'connected-react-router';
import App from './containers/App';
import {
  createReducer,
} from './store/rootReducer';
import {
  clockMiddleware,
} from './common/utils/clock';
import {
  setDeferredPrompt,
} from './store/ui/drawer/actions';
import Loading from './common/components/Loading';
import {
  parse,
} from './common/utils/querystring';
import {
  clearAllQuestionnaires,
} from './common/containers/Questionnaire';
import {
  createMiddleware as createQuestionnaireMiddleware,
} from './store/questionnaire';
import settings from './common/settings';
import {
  del,
} from './common/utilsClient/multiReducer';
import './common/logger/client/register';
import {
  cleanStorage,
  createAsyncStorage,
} from './common/utilsClient/redux/storage';
import resolveOnSelector from './common/utilsClient/redux/resolveOnSelector';
import transformMetaFields from './common/utilsClient/ddp/transformMetaFields';
import * as serviceWorker from './serviceWorker';
import './common/utils/i18next';
import './common/fonts';
import {
  tryLoginOnStartup,
} from './common/oauth/utils';

const ddpClient = new DDPClient({
  endpoint: settings.public.wsEndpoint,
  SocketConstructor: WebSocket,
  autoConnect: false, // we will connect manually once storage is defined
  reconnectInterval: 1000,
});

const ddpConnector = new DDPConnector({
  // debug: true,
  ddpClient,
  defaultLoaderComponent: Loading,
  transformRequest: transformMetaFields,
});

let enhancer;
const query = parse(window.location.search);

if (query.debug) {
  enhancer = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({})
      : x => x,
  );
} else if (process.env.NODE_ENV !== 'production') {
  enhancer = compose(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__({})
      : x => x,
  );
} else {
  enhancer = compose();
}

const history = createBrowserHistory();
const enhanceReducer = reducer => persistReducer(
  {
    storage,
    key: 'zedoc',
    whitelist: [
      'storage',
      'preferences',
    ],
  },
  reducer,
);

const store = createStore(
  enhanceReducer(createReducer(history)),
  {},
  compose(
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument({
        ddpConnector,
      }),
      createQuestionnaireMiddleware(),
      clockMiddleware,
    ),
    enhancer,
  ),
);

const persistor = persistStore(store);
ddpClient.storage = createAsyncStorage(
  store,
  resolveOnSelector(persistor, 'bootstrapped'),
);
ddpClient.connect();

tryLoginOnStartup(ddpConnector, (err) => {
  // eslint-disable-next-line no-console
  console.log(err);
});

ddpClient.on('loggedOut', () => {
  store.dispatch(del('ui'));
  store.dispatch(cleanStorage());
  store.dispatch(clearAllQuestionnaires());
});

ddpConnector.bindToStore(store);

ReactDOM.render(
  <App
    store={store}
    history={history}
    persistor={persistor}
    ddpConnector={ddpConnector}
  />,
  document.getElementById('root'),
);

if (process.env.NODE_ENV !== 'production') {
  if (typeof module !== 'undefined' && module.hot) {
    module.hot.accept('./containers/App', () => {
      const NextApp = require('./containers/App').default;
      ReactDOM.render(
        <NextApp
          store={store}
          history={history}
          persistor={persistor}
          ddpConnector={ddpConnector}
        />,
        document.getElementById('root'),
      );
    });

    module.hot.accept('./store/rootReducer.js', () => store.replaceReducer(
      enhanceReducer(
        require('./store/rootReducer.js').createReducer(history),
      ),
    ));
  }
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();

  // eslint-disable-next-line no-console
  console.log('The app can be installed');

  store.dispatch(setDeferredPrompt(e));
});

window.addEventListener('appinstalled', () => {
  store.dispatch(setDeferredPrompt(null));
});

serviceWorker.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      registration.waiting.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });

      registration.waiting.postMessage({
        type: 'SKIP_WAITING',
      });
    }
  },
});
