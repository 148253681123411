import PropTypes from 'prop-types';
import React, {
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components/macro';
import {
  ExpandLess,
  ExpandMore,
} from 'styled-icons/material';
import {
  theme,
} from '../../utilsClient/cssHelpers';
import Divider from '../Divider';
import Cluster from '../primitives/Cluster';
import Stack from '../primitives/Stack';
import Text from '../base/Text';

const Component = styled.div``;

const StyledButton = styled.button`
  all: inherit;
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  border-radius: ${theme('border.radius.2')};
  width: 100%;

  /* Animated */
  /* &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${theme('border.radius.2')};
    box-shadow: 0 0 0 2px #1890ff;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    will-change: opacity;
  }

  &:hover,
  &:focus {
    &::after {
      opacity: 1;
    }
  } */
`;

export const CollapseItem = ({
  title,
  titleToken,
  content,
  defaultOpen,
}) => {
  const [
    visible,
    setVisible,
  ] = useState(defaultOpen);
  const [
    expanded,
    setExpanded,
  ] = useState(defaultOpen);

  useEffect(() => {
    setVisible(expanded);
  }, [
    expanded,
  ]);

  return (
    <Stack>
      <StyledButton
        data-testid={`collapse-button-${title}`}
        aria-expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      >
        <Cluster
          justify="space-between"
          wrap="nowrap"
        >
          <Text token={titleToken}>{title}</Text>
          {expanded ? <ExpandLess size={24} /> : <ExpandMore size={24} />}
        </Cluster>
      </StyledButton>
      {visible && content}
    </Stack>
  );
};

CollapseItem.propTypes = {
  title: PropTypes.node,
  titleToken: PropTypes.string,
  content: PropTypes.node,
  defaultOpen: PropTypes.bool,
};

CollapseItem.defaultProps = {
  title: null,
  titleToken: 'title5',
  content: null,
  defaultOpen: false,
};

const Collapse = ({
  'data-testid': datatestid,
  items,
  bordered,
}) => {
  return (
    <Component data-testid={datatestid}>
      <Stack>
        {items.map(({
          key,
          title,
          titleAs,
          content,
        }) => (
          <React.Fragment key={key}>
            <CollapseItem
              title={title}
              titleAs={titleAs}
              content={content}
              bordered={bordered}
            />
            {bordered && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Component>
  );
};

Collapse.propTypes = {
  'data-testid': PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleToken: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  bordered: PropTypes.bool,
};

Collapse.defaultProps = {
  'data-testid': null,
  items: [],
  bordered: true,
};

export default Collapse;
