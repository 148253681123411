import forEach from 'lodash/forEach';
import get from 'lodash/get';
import shallowEqual from '../shallowEqual';

/**
 * Modify form values by masking certain answers based on the current
 * values of dynamic properties that are provided as one of the parameters.
 *
 * @param {Questionnaire} questionnaire
 * @param {Object} formValues
 * @param {Object} properties
 * @param {Object} [options]
 * @param {Object} [options.sectionId = null]
 */
function maskHiddenFormValues(
  questionnaire,
  formValues,
  properties,
  {
    sectionId = null,
  } = {},
) {
  if (!formValues) {
    return formValues;
  }
  const newFormValues = {};
  // TODO: Because we iterate only on questions that are in sections, we will
  //       fail to capture (reject) all "variable" questions. I need to think
  //       how they should be handled. I think the only reason we use them
  //       was to allow QSFirst exporting some data without creating explicit
  //       questions, but this can now be handled by "internal usage" flag.
  questionnaire.forEachQuestion(
    (question) => {
      const modifiedQuestion = Object.assign(
        Object.create(question),
        properties && properties[question.id],
      );
      if (
        question.isSection() &&
        !modifiedQuestion.shouldHideAndIgnoreAnswer()
      ) {
        Object.assign(
          newFormValues,
          maskHiddenFormValues(questionnaire, formValues, properties, {
            sectionId: question.id,
          }),
        );
      } else if (
        formValues[question.id] &&
        !modifiedQuestion.shouldHideAndIgnoreAnswer()
      ) {
        const answer = formValues[question.id];
        newFormValues[
          question.id
        ] = modifiedQuestion.removeHiddenChunksFromAnswer(answer);
        if (question.isCollection()) {
          const newElements = {};
          forEach(answer._elements, (element, elementId) => {
            newElements[elementId] = {
              ...element,
              _elements: maskHiddenFormValues(
                questionnaire,
                element._elements,
                get(properties, [
                  question.id,
                  '_elements',
                  elementId,
                  '_elements',
                ]),
                {
                  sectionId: question.id,
                },
              ),
            };
            if (newElements[elementId]._elements === element._elements) {
              // fallback to the original value if nothing really changed
              newElements[elementId] = element;
            }
          });
          if (!shallowEqual(answer._elements, newElements)) {
            newFormValues[question.id] = {
              ...newFormValues[question.id],
              _elements: newElements,
            };
          }
        }
      }
    },
    {
      sectionId,
      stopRecursion: q => q.isSection() || q.isCollection(),
    },
  );
  if (!shallowEqual(formValues, newFormValues)) {
    return newFormValues;
  }
  return formValues;
}

export default maskHiddenFormValues;
