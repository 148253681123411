import {
  compose,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  createStructuredSelector,
} from 'reselect';
import Search from './Search';
import SearchHighlighter from './SearchHighlighter';
import {
  selectQueryParam,
  setQueryParam,
} from '../../../store/router';

const Container = compose(
  connect(
    createStructuredSelector({
      value: selectQueryParam('search', ''),
    }),
    dispatch => ({
      onChange: e => dispatch(setQueryParam('search', e.target.value || null, true)),
    }),
  ),
)(Search);

export default Container;

export {
  SearchHighlighter,
};
