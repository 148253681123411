import React, {
  lazy,
  Suspense,
} from 'react';

const Icon = lazy(() => import(/* webpackChunkName: "Icon" */ './Icon'));

export default props => (
  <Suspense fallback={<span />}>
    <Icon {...props} />
  </Suspense>
);
