import reduce from 'lodash/reduce';
import find from 'lodash/find';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import React, {
  useMemo,
} from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import {
  formFieldPropTypesInput,
} from '../../utilsClient/propTypes';
import Stack from '../primitives/Stack';
import Text from '../base/Text';
import usePrefersReducedMotion from '../../utils/usePrefersReducedMotion';

const StyledStack = styled(Stack)`
  justify-content: center;
`;

const AnimationComponent = styled(motion.div)`
  will-change: opacity, transform;
`;

const Animation = ({
  id,
  children,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const variants = prefersReducedMotion
    ? {}
    : {
      initial: {
        opacity: 0,
        x: 8,
      },
      animate: {
        opacity: 1,
        x: 0,
      },
      exit: {
        opacity: 0,
        x: -8,
      },
    };

  return (
    <AnimatePresence
      initial={false}
      exitBeforeEnter
    >
      <AnimationComponent
        key={id}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        {children}
      </AnimationComponent>
    </AnimatePresence>
  );
};

Animation.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Animation.defaultProps = {
  children: null,
};

const FormFieldValue = ({
  input: {
    value,
  },
  marks,
  label,
  showClosestMark,
}) => {
  const closest = reduce(
    marks,
    (prev, mark) => (Math.abs(mark.value - value) < Math.abs(prev - value) ? mark.value : prev),
    0,
  );

  const currentMark = useMemo(() => {
    if (!showClosestMark) {
      return value;
    }

    const closestMark = find(marks, mark => mark.value === closest);

    return (closestMark && closestMark.label) || value;
  }, [
    value,
    closest,
    marks,
    showClosestMark,
  ]);

  return (
    <StyledStack
      space={0}
      isFullHeight
    >
      {label && <Text.Paragraph size="small">{label}</Text.Paragraph>}
      <Animation id={currentMark}>
        <Text.Paragraph importance="high">{currentMark}</Text.Paragraph>
      </Animation>
    </StyledStack>
  );
};

FormFieldValue.propTypes = {
  input: formFieldPropTypesInput.isRequired,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.string,
  showClosestMark: PropTypes.bool,
};

FormFieldValue.defaultProps = {
  marks: [],
  label: null,
  showClosestMark: false,
};

export default FormFieldValue;
