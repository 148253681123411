import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';

const identity = x => x;
const constant = x => () => x;

const createGetAtKey = (key) => {
  if (isNil(key)) {
    return identity;
  }
  if (isArray(key)) {
    if (isEmpty(key)) {
      return identity;
    }
    const [
      k,
      ...tail
    ] = key;
    const getAtKey = createGetAtKey(tail);
    return value => getAtKey(value && value[k]);
  }
  if (typeof key === 'string') {
    if (key !== '') {
      return createGetAtKey(key.split('.'));
    }
    return identity;
  }
  if (typeof key === 'number' && !isNaN(key)) {
    return value => value[key];
  }
  return constant(undefined);
};

export default createGetAtKey;
