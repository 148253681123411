import {
  useSelector,
} from 'react-redux';
import CurrentUserSelect from '../common/selectors/CurrentUser';

const usePermission = (permissions, options) => {
  return useSelector(CurrentUserSelect.hasPermission(permissions, options));
};

export default usePermission;
