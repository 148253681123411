import PropTypes from 'prop-types';

export const ValueSetPropTypes = PropTypes.shape({
  url: PropTypes.string,
  expansion: PropTypes.shape({
    contains: PropTypes.arrayOf(
      PropTypes.shape({
        system: PropTypes.string,
        display: PropTypes.string,
        code: PropTypes.string,
        version: PropTypes.string,
      }),
    ),
  }),
});

export const PropTypesRef = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.any,
  }),
]);
