import React from 'react';

export default () => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    aria-hidden="true"
    focusable="false"
  >
    <title>Forms</title>
    <path
      d="M6.71 10.727C4.666 10.727 3 8.994 3 6.864S4.665 3 6.71 3c2.047 0 3.712 1.733 3.712 3.864 0 2.13-1.665 3.863-3.711 3.863zm0-6.182c-1.227 0-2.226 1.04-2.226 2.319 0 1.278 1 2.318 2.227 2.318 1.228 0 2.226-1.04 2.226-2.318 0-1.279-.998-2.319-2.226-2.319zM22 6.091H11.906v1.545H22V6.091zm-15.29 0c-.408 0-.741.347-.741.773 0 .426.333.772.742.772.41 0 .742-.346.742-.772 0-.426-.333-.773-.742-.773zM6.71 20C4.666 20 3 18.267 3 16.136c0-2.13 1.665-3.863 3.71-3.863 2.047 0 3.712 1.733 3.712 3.863S8.757 20 6.71 20zm0-6.182c-1.227 0-2.226 1.04-2.226 2.318 0 1.279 1 2.319 2.227 2.319 1.228 0 2.226-1.04 2.226-2.319 0-1.278-.998-2.318-2.226-2.318zM22 15.364H11.906v1.545H22v-1.545z"
      fillRule="nonzero"
    />
  </svg>
);
