import React, {
  Suspense,
  lazy,
} from 'react';

const LazyFormFieldBluetoothDevice = lazy(() => import(/* webpackChunkName: "FormFieldBluetoothDevice" */ './FormFieldBluetoothDevice'));

export default props => (
  <Suspense fallback={<div>loading...</div>}>
    <LazyFormFieldBluetoothDevice {...props} />
  </Suspense>
);
