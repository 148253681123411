import {
  registerStore,
} from '../common/utilsClient/multiReducer';
import cleanOnPurge from './clearOnPurge';

const initialState = {
  language: 'en',
  theme: null,
};

const store = registerStore({
  path: 'preferences',
  initialState,
  reducer: cleanOnPurge(initialState),
});

export const getTheme = store.get('theme');
export const getLanguage = store.get('language');

export const setTheme = store.create.set('theme');
export const setLanguage = store.create.set('language');

export default store;
