import React, {
  useContext,
  useCallback,
} from 'react';
import {
  useDDPCall,
} from '@theclinician/ddp-connector';
import PropTypes from 'prop-types';
import {
  generateStudyNo,
} from '../../../common/api/zedoc';
import FormFieldText from '../../forms/FormFieldText';
import {
  notifyError,
} from '../../../utils/notify';
import FormFieldContext from './FormFieldContext';

const FormFieldStudyNo = React.forwardRef((props, forwardedRef) => {
  const {
    ddpCall,
    ddpIsPending,
  } = useDDPCall();

  const {
    projectId,
  } = useContext(FormFieldContext);

  const handleGenerateStudyNo = useCallback(() => {
    if (!ddpIsPending) {
      return ddpCall(
        generateStudyNo.withParams({
          projectId,
        }),
      ).catch(notifyError());
    }
    return null;
  }, [
    ddpIsPending,
    ddpCall,
    projectId,
  ]);

  return (
    <FormFieldText
      ref={forwardedRef}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onGenerateValue={handleGenerateStudyNo}
    />
  );
});

FormFieldStudyNo.propTypes = {
  projectId: PropTypes.string,
};

FormFieldStudyNo.defaultProps = {
  projectId: null,
};

export default FormFieldStudyNo;
