import ApiSpec from './ApiSpec';
import {
  PROJECT_VIEW_NLP_ANALYTICS,
} from '../permissions';

export const apiNlpAllKeywords = new ApiSpec({
  name: 'api.nlp.apiNlpAllKeywords',
  permissions: [
    PROJECT_VIEW_NLP_ANALYTICS,
  ],
  schema: {
    type: 'object',
    properties: {
      projectId: {
        type: 'string',
      },
      questionId: {
        type: 'string',
      },
      questionnaireId: {
        type: 'string',
      },
      controlId: {
        type: 'string',
      },
      pageIndex: {
        type: 'number',
      },
      resultsPerPage: {
        type: 'number',
      },
    },
    required: [
      'projectId',
      'questionId',
      'questionnaireId',
    ],
  },
});

export const apiNlpAllResponses = new ApiSpec({
  name: 'api.nlp.apiNlpAllResponses',
  permissions: [
    PROJECT_VIEW_NLP_ANALYTICS,
  ],
  schema: {
    type: 'object',
    properties: {
      projectId: {
        type: 'string',
      },
      questionId: {
        type: 'string',
      },
      questionnaireId: {
        type: 'string',
      },
      controlId: {
        type: 'string',
      },
      pageIndex: {
        type: 'number',
      },
      resultsPerPage: {
        type: 'number',
      },
    },
    required: [
      'projectId',
      'questionId',
      'questionnaireId',
    ],
  },
});

export const apiNlpTopKeywords = new ApiSpec({
  name: 'api.nlp.apiNlpTopKeywords',
  permissions: [
    PROJECT_VIEW_NLP_ANALYTICS,
  ],
  schema: {
    type: 'object',
    properties: {
      projectId: {
        type: 'string',
      },
      questionId: {
        type: 'string',
      },
      questionnaireId: {
        type: 'string',
      },
      nResults: {
        type: 'number',
      },
    },
    required: [
      'projectId',
      'questionId',
      'questionnaireId',
      'nResults',
    ],
  },
});
