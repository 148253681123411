import Questionnaire from '../../models/Questionnaire';
import collapseObject from './collapseObject';

function getDynamicQuestionnaire(questionnaire, properties, hierarchyKey) {
  const collapsed = collapseObject(properties, hierarchyKey);
  const rawQuestions = questionnaire.mapQuestions((question) => {
    // NOTE: collapsed can be null if properties is null
    const newProperties = collapsed && collapsed[question.id];
    if (newProperties) {
      return {
        ...question,
        ...newProperties,
      };
    }
    return {
      ...question,
    };
  });
  return new Questionnaire({
    ...questionnaire,
    questions: rawQuestions,
  });
}

export default getDynamicQuestionnaire;
