/* eslint-disable react/prop-types */
import React from 'react';
import Question from './Question';

const PatientPropertiesForm = ({
  projectId,
  variables,
  questionnaire,
}) => {
  const collectionId = 'c_variables';

  const questionsIds = [
    collectionId,
  ];

  questionnaire
    .getChildQuestions(collectionId)
    .forEach(question => questionsIds.push(question.id));

  questionsIds.forEach(questionId => questionnaire
    .getChildQuestions(questionId)
    .forEach(question => questionsIds.push(question.id)));

  return (
    <Question
      projectId={projectId}
      variables={variables}
      questionnaire={questionnaire}
      questionsIds={questionsIds}
      valueSetId="zedoc/variables@x.x.x"
      nameId="ac1_id"
      hasOrder
    />
  );
};

export default PatientPropertiesForm;
