/* eslint-disable react/prop-types */
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import minOf from 'lodash/min';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import {
  StickyNote,
} from 'styled-icons/fa-regular';
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from 'styled-icons/material';
import React, {
  useState,
} from 'react';
import {
  useDispatch,
} from 'react-redux';
import styled from 'styled-components/macro';
import {
  useTranslation,
} from 'react-i18next';
import {
  theme,
} from '../../../../common/utilsClient/cssHelpers';
import Text from '../../../../common/components/base/Text';
import Cluster from '../../../../common/components/primitives/Cluster';
import Stack from '../../../../common/components/primitives/Stack';
import Badge from '../../../../common/components/Badge';
import Button, {
  ButtonLink,
} from '../../../../common/components/Button';
import Table from '../../../../common/components/Table';
import Icon from '../../../../common/components/Icon';
import {
  VARIABLE_ID__PARTICIPATION_EMR_SYNC,
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
  VARIABLE_ID__BIRTH_DATE,
  VARIABLE_ID__BIRTH_YEAR,
  VARIABLE_ID__PARTICIPATION_CATEGORY,
  VARIABLE_ID__NAME,
  VARIABLE_ID__NATIONAL_ID,
  VARIABLE_ID__PARTICIPATION_BED_NUMBER,
  SIGNED_NOTE_TYPE__RESOLVED,
} from '../../../../common/constants';
import branding from '../../../../utils/branding';
import {
  openAnswersSheetDialog,
} from '../../actions';
import DashboardPatientModal from './DashboardPatientModal';
import DashboardResolveModal from './DashboardResolveModal';
import DashboardVariableItem from './DashboardVariableItem';
import {
  labels,
} from './helpers';

const StyledTable = styled(Table)`
  th {
    font-size: ${theme('font.size.small')};
  }

  th,
  td {
    white-space: nowrap;
  }

  /*  Reduce space between columns with icons */

  thead > tr > th,
  tbody > tr > td {
    &:first-child {
      padding-right: 0;
    }

    &:nth-child(2) {
      padding-left: 0;
    }
  }
`;

const Status = ({
  type,
  value,
}) => {
  return (
    <Text.Paragraph type={type}>
      {value ? (
        <Text.Span
          data-testid="status"
          importance={type === 'warning' || type === 'danger' ? 'high' : null}
        >
          {value}
        </Text.Span>
      ) : (
        <Text.Span data-testid="status">&mdash;</Text.Span>
      )}
    </Text.Paragraph>
  );
};

const getVariable = (variables, variableId) => {
  return variables && variables[variableId];
};

const getNotesCount = (record) => {
  let answersSheet;

  if (record.answersSheetId) {
    answersSheet = find(
      record.answersSheets,
      item => item.id === record.answersSheetId,
    );
  } else {
    answersSheet = record;
  }

  const signedNotes = answersSheet && answersSheet.signedNotes;

  return signedNotes && signedNotes.length;
};

const getRecordResolved = (record) => {
  if (record.signedNotes) {
    return some(record.signedNotes, {
      type: SIGNED_NOTE_TYPE__RESOLVED,
    });
  }

  return record.resolved;
};

const getStatus = (ids, values, thresholds) => {
  const idToIndex = {};
  if (!thresholds) {
    return null;
  }
  forEach(ids, (variableId, index) => {
    idToIndex[index] = variableId;
  });
  const statusCodes = map(ids, (variableId, index) => {
    const variableValue = values[index];
    if (isNil(variableValue)) {
      return 4;
    }
    for (let i = 0; i < thresholds.length; i += 1) {
      const {
        id,
        max,
        min,
        type,
        value,
      } = thresholds[i];
      let statusCode;
      switch (type) {
        case 'danger':
          statusCode = 1;
          break;
        case 'warning':
          statusCode = 2;
          break;
        case 'ok':
          statusCode = 3;
          break;
        default:
          statusCode = 4;
      }

      if (!id || id === variableId) {
        if (!isNil(value)) {
          if (value === variableValue) {
            return statusCode;
          }
        } else if (
          (isNil(max) || variableValue <= max) &&
          (isNil(min) || variableValue >= min)
        ) {
          return statusCode;
        }
      }
    }
    return 4;
  });
  const statusCode = minOf(statusCodes);
  switch (statusCode) {
    case 1:
      return 'danger';
    case 2:
      return 'warning';
    case 3:
      return 'success';
    default:
      return null;
  }
};

const DashboardTable = ({
  columns,
  dataSource,
  pagination,
  loading,
}) => {
  const {
    t,
  } = useTranslation();

  const [
    isResolveModalVisible,
    setIsResolveModalVisible,
  ] = useState(false);
  const [
    isPatientModalVisible,
    setIsPatientModalVisible,
  ] = useState(false);
  const [
    dataSourceHistorical,
    setDataSourceHistorical,
  ] = useState([]);
  const [
    selectedAnswersSheetId,
    setSelectedAnswersSheetId,
  ] = useState('');
  const [
    selectedPatientName,
    setSelectedPatientName,
  ] = useState('');

  const handleResolveModalOpen = (answersSheetId, patientName) => {
    setIsResolveModalVisible(true);
    setSelectedAnswersSheetId(answersSheetId);
    setSelectedPatientName(patientName);
  };
  const handleResolveModalClose = () => {
    setIsResolveModalVisible(false);
    setSelectedAnswersSheetId(null);
    setSelectedPatientName(null);
  };
  const handlePatientModalOpen = (record) => {
    if (!record) {
      return;
    }

    setIsPatientModalVisible(true);
    setDataSourceHistorical(
      map(record.answersSheets, answersSheet => ({
        ...answersSheet,
        variables: record.variables,
      })),
    );
  };
  const handlePatientModalClose = () => setIsPatientModalVisible(false);

  const dispatch = useDispatch();
  const onNotesOpen = answersSheetId => dispatch(
    openAnswersSheetDialog({
      answersSheetId,
    }),
  );

  const tableColumns = [
    {
      title: '',
      dataIndex: 'resolve',
      key: 'resolve',
      fixed: 'left',
      width: '55px',
      align: 'center',
      render: (_, record) => (record.answersSheetId || record.id ? (
        <Button
          size="large"
          icon={
              getRecordResolved(record) ? (
                <CheckBox />
              ) : (
                <CheckBoxOutlineBlank />
              )
            }
          onClick={() => handleResolveModalOpen(
            record.answersSheetId || record.id,
            getVariable(record.variables, VARIABLE_ID__NAME),
          )}
          disabled={getRecordResolved(record)}
          ghost
        />
      ) : null),
    },
    {
      title: t('note', {
        count: 0,
      }),
      dataIndex: 'notes',
      key: 'notes',
      fixed: 'left',
      width: '55px',
      align: 'center',
      render: (_, record) => (record.answersSheetId || record.id ? (
        <Badge
          type="info"
          count={getNotesCount(record)}
          offsetX={-8}
          offsetY={8}
        >
          <Button
            type="link"
            size="large"
            icon={<StickyNote />}
            onClick={() => onNotesOpen(record.answersSheetId || record.id)}
          />
        </Badge>
      ) : null),
    },
    {
      title: t('recipient', {
        context: branding,
      }),
      dataIndex: 'patientColumn1',
      key: 'patientColumn1',
      fixed: 'left',
      // sorter: () => true,
      render: (_, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Stack space={0}>
            <ButtonLink onClick={() => handlePatientModalOpen(record)}>
              {getVariable(record.variables, VARIABLE_ID__NAME)}
            </ButtonLink>
            <DashboardVariableItem
              size="small"
              label={labels[VARIABLE_ID__PARTICIPATION_EMR_SYNC]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_EMR_SYNC,
              )}
            />
            <Cluster
              space={0}
              wrap="nowrap"
            >
              <DashboardVariableItem
                size="small"
                value={(
                  <Icon
                    type={
                      getVariable(record.variables, VARIABLE_ID__GENDER) ||
                      getVariable(
                        record.variables,
                        VARIABLE_ID__GENDER_SINGAPORE,
                      )
                    }
                  />
                )}
              />
              <DashboardVariableItem
                size="small"
                label={labels[VARIABLE_ID__BIRTH_DATE]}
                value={
                  getVariable(record.variables, VARIABLE_ID__BIRTH_DATE) ||
                  getVariable(record.variables, VARIABLE_ID__BIRTH_YEAR)
                }
              />
            </Cluster>
          </Stack>
        </div>
      ),
    },
    {
      dataIndex: 'patientColumn2',
      key: 'patientColumn2',
      fixed: 'left',
      // sorter: () => true,
      render: (_, record) => (
        <div
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          <Stack space={0}>
            <Text.Paragraph>
              {getVariable(record.variables, VARIABLE_ID__NATIONAL_ID) ||
                'Patient ID'}
            </Text.Paragraph>
            <DashboardVariableItem
              size="small"
              label={labels[VARIABLE_ID__PARTICIPATION_BED_NUMBER]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_BED_NUMBER,
              )}
            />
            <DashboardVariableItem
              size="small"
              label={labels[VARIABLE_ID__PARTICIPATION_CATEGORY]}
              value={getVariable(
                record.variables,
                VARIABLE_ID__PARTICIPATION_CATEGORY,
              )}
            />
          </Stack>
        </div>
      ),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      // sorter: () => true,
      render: (_, record) => (record.completedAt ? (
        <Stack space={0}>
          <Text.Paragraph>
            {moment(record.completedAt).format('DD/MM')}
          </Text.Paragraph>
          <Text.Paragraph>
            {moment(record.completedAt).format('HH:mm')}
          </Text.Paragraph>
        </Stack>
      ) : (
        <Text.Span>&mdash;</Text.Span>
      )),
    },
    {
      title: t('missed'),
      dataIndex: 'missed',
      key: 'numberOfMissed',
      align: 'right',
      render: (_, record) => (record.numberOfMissed || 0).toString(),
    },
  ];

  forEach(
    columns,
    ({
      title,
      id,
      variableType,
      valueType,
      precision,
      thresholds,
    }) => {
      tableColumns.push({
        title,
        dataIndex: id.join('_'),
        key: id.join('_'),
        align: 'right',
        // sorter: () => true,
        render: (_, record) => {
          let values = map(id, (variableId) => {
            if (variableType === 'questionnaire') {
              let value = getVariable(
                record.questionnaireVariables,
                variableId,
              );

              if (value && valueType === 'number' && precision) {
                value = value.toFixed(precision);
              }

              return value;
            }
            return null;
          });
          values = filter(values, val => val);
          const status = getStatus(id, values, thresholds);
          return (
            <Status
              type={status}
              value={values.join('/')}
            />
          );
        },
      });
    },
  );

  return (
    <Stack space={2}>
      <DashboardResolveModal
        answersSheetId={selectedAnswersSheetId}
        patientName={selectedPatientName}
        visible={isResolveModalVisible}
        onCancel={handleResolveModalClose}
      />
      <DashboardPatientModal
        dataSource={dataSourceHistorical}
        tableColumns={tableColumns}
        visible={isPatientModalVisible}
        onCancel={handlePatientModalClose}
        getVariable={getVariable}
      />
      <StyledTable
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        loading={loading}
        pagination={pagination}
        scroll={{
          x: true,
        }}
      />
    </Stack>
  );
};

DashboardTable.propTypes = {};

DashboardTable.defaultProps = {};

export default DashboardTable;
