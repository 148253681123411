/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../../../common/components/primitives/Grid';
import ActionItem from '../ActionItem';

const propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string.isRequired,
      recipientId: PropTypes.string.isRequired,
      recipientName: PropTypes.string,
      milestoneIds: PropTypes.arrayOf(PropTypes.string),
      questionnaires: PropTypes.arrayOf(
        PropTypes.shape({
          answersSheetId: PropTypes.string,
          questionnaireId: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  filterBy: PropTypes.string,
};

const defaultProps = {
  notifications: [],
  milestoneNames: {},
  filterBy: 'questionnaire',
};

const ActionItemsList = ({
  notifications,
  filterBy,
}) => (
  <Grid>
    {notifications.map(
      ({
        recipientId,
        recipientName,
        projectId,
        milestoneIds,
        questionnaires,
      }) => (filterBy === 'milestone' ? (
        <ActionItem
          key={`${recipientId}/${projectId}`}
          projectId={projectId}
          recipientId={recipientId}
          recipientName={recipientName}
          milestoneIds={milestoneIds}
        />
      ) : (
        questionnaires.map(({
          answersSheetId,
          questionnaireId,
          dateEnd,
        }) => (
          <ActionItem
            key={`${recipientId}/${projectId}/${answersSheetId}`}
            projectId={projectId}
            recipientId={recipientId}
            recipientName={recipientName}
            answersSheetId={answersSheetId}
            questionnaireId={questionnaireId}
            dateEnd={dateEnd}
          />
        ))
      )),
    )}
  </Grid>
);

ActionItemsList.propTypes = propTypes;
ActionItemsList.defaultProps = defaultProps;

export default ActionItemsList;
