import {
  QUESTION_TYPE__DATE_TIME,
} from '../../../constants';
import Question from '../Question';

export default class QuestionDateTime extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__DATE_TIME;
  }
}

QuestionDateTime.expectedValueType = {
  type: 'string',
  format: 'date-time', // i.e. partial date with optional time of day
};

QuestionDateTime.editorSettings = {};

Question.types[QUESTION_TYPE__DATE_TIME] = QuestionDateTime;
