import {
  DeleteOutlined,
} from '@ant-design/icons';
import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  useTranslation,
} from 'react-i18next';
import {
  useSelector,
} from 'react-redux';
import {
  ADMIN_DELETE_PROJECT,
} from '../../../common/permissions';
import Button from '../../../common/components/Button';
import Stack from '../../../common/components/primitives/Stack';
import Sidebar from '../../../common/components/primitives/Sidebar';
import Text from '../../../common/components/base/Text';
import Highlighter from '../../../common/components/Highlighter';
import ProjectSelect from '../../../common/selectors/Project';
import usePermission from '../../../utils/usePermission';

const ProjectItem = React.memo(
  ({
    name,
    description,
    projectId,
    onDelete,
    highlight,
  }) => {
    const project = useSelector(ProjectSelect.one().whereIdEquals(projectId));
    const canDeleteProjects = usePermission([
      ADMIN_DELETE_PROJECT,
    ], {
      relativeTo: project && project.getDomains(),
    });
    const {
      t,
    } = useTranslation();
    const handleDeleteProject = useCallback(() => {
      if (onDelete) {
        onDelete(projectId);
      }
    }, [
      projectId,
      onDelete,
    ]);
    return (
      <Sidebar
        sidebar={(
          <Button
            data-testid={`button-delete-${name}`}
            type="link"
            icon={<DeleteOutlined />}
            disabled={!canDeleteProjects}
            onClick={handleDeleteProject}
          >
            {t('archive')}
          </Button>
        )}
      >
        <Stack space={0}>
          <Text.Link
            data-testid={`item-${name}`}
            size="large"
            importance="high"
            to={`/settings/projects/${projectId}/project-variables`}
          >
            <Highlighter
              text={project.name}
              highlight={highlight}
            />
          </Text.Link>
          {description && (
            <Text.Paragraph importance="low">{description}</Text.Paragraph>
          )}
        </Stack>
      </Sidebar>
    );
  },
);

ProjectItem.propTypes = {
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  description: PropTypes.string,
  onDelete: PropTypes.func,
  highlight: PropTypes.arrayOf(PropTypes.string),
};

ProjectItem.defaultProps = {
  description: null,
  onDelete: null,
  highlight: [],
};

export default ProjectItem;
