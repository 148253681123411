import {
  QUESTION_TYPE__DATE,
} from '../../../constants';
import Question from '../Question';

export default class QuestionDate extends Question {
  constructor(doc) {
    super(doc);
    this.type = this.type || QUESTION_TYPE__DATE;
  }
}

QuestionDate.expectedValueType = {
  type: 'string',
  format: 'date', // i.e. partial date
};

QuestionDate.editorSettings = {};

Question.types[QUESTION_TYPE__DATE] = QuestionDate;
