import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
  setDisplayName,
} from 'recompose';
import {
  createSelector,
} from 'reselect';
import {
  useSelector,
} from 'react-redux';
import {
  ddp,
} from '@theclinician/ddp-connector';
import {
  useTranslation,
} from 'react-i18next';
import {
  Link as LinkIcon,
} from 'styled-icons/material';
import {
  patientActivityDetails,
  sendActivityReminder,
  projectMilestones,
  // apiZedocCopyActivity,
  apiZedocSyncActivity,
} from '../../../../common/api/zedoc';
import {
  callMethod,
} from '../../../../common/utilsClient/ddp/actions';
import {
  notifyError,
  notifySuccess,
} from '../../../../utils/notify';
import {
  getUserNames,
  getQuestionnaireNames,
} from '../../../../common/api/aggregations/Projects';
import Dropdown from '../../../../common/components/Dropdown';
import Divider from '../../../../common/components/Divider';
import Menu from '../../../../common/components/Menu';
import Button from '../../../../common/components/Button';
import Text from '../../../../common/components/base/Text';
import Modal from '../../../../components/dialogs/Modal';
import PatientDetails from '../../../../components/patients/PatientDetails';
import Tabs from '../../../../components/layouts/Tabs';
import ProjectSelect from '../../../../common/selectors/Project';
import AnswersSheet from '../../../../common/models/AnswersSheet';
import AnswersSheetSelect from '../../../../common/selectors/AnswersSheet';
import RecipientSelect from '../../../../common/selectors/Recipient';
import ActivitySelect from '../../../../common/selectors/Activity';
import ParticipationSelect from '../../../../common/selectors/Participation';
import ProjectMilestoneSelect from '../../../../common/selectors/ProjectMilestone';
import NotificationSelect from '../../../../common/selectors/Notification';
import {
  property,
} from '../../../../common/utilsClient/selectors';
import {
  withModal,
} from '../../../../utils/modal';
import usePermission from '../../../../utils/usePermission';
import ShareLoginInfo from '../../../../components/dialogs/ShareLoginInfo';
import FormsList from '../FormsList';
import RemindersList from '../RemindersList';
import {
  getActiveActivityId,
  getMilestoneDialogVisible,
  getMilestoneDialogActiveKey,
  selectAllPerspectiveResponsesDashboards,
} from '../../selectors';
import {
  setMilestoneDialogActiveKey,
  openEditPatientDialog,
  openPreviewPatientResponseDialog,
  openAddAnswersSheetsDialog,
  closeMilestoneDialog,
  // openMilestoneDialog,
  openTab,
  openEditActivityDialog,
} from '../../actions';
import {
  DASHBOARD_PERSPECTIVE__RESPONSES,
  NOTIFICATION_MANUAL_REMINDER,
  ACTIVITY_STATE__ACTIVE,
  ACTIVITY_STATE__EXPIRED,
} from '../../../../common/constants';
import {
  PATIENT_ACCESS_PATIENT_PII_VARIABLES,
  PATIENT_MILESTONE_FORCE_SYNCHRONIZATION,
} from '../../../../common/permissions';
import branding from '../../../../utils/branding';

const selectAnswersSheets = AnswersSheetSelect.all()
  .where({
    activityId: getActiveActivityId,
  })
  .satisfying(answersSheet => !answersSheet.isCanceled());

const selectRecipientId = createSelector(
  ActivitySelect.one().whereIdEquals(getActiveActivityId),
  activity => activity && activity.recipientId,
);

const ActivityDialog = compose(
  setDisplayName('MilestoneDialog'),
  ddp({
    renderLoader: null,
    subscriptions: createSelector(
      property('projectId'),
      getActiveActivityId,
      (projectId, activityId) => {
        return [
          {
            name: 'Users.projectMembers',
            params: [
              projectId,
            ],
          },
          projectId &&
            projectMilestones.withParams({
              projectId,
            }),
          activityId &&
            patientActivityDetails.withParams({
              activityId,
            }),
        ];
      },
    ),
    queries: (state, {
      projectId,
    }) => ({
      userNames: getUserNames.withParams({
        projectId,
      }),
      questionnaireNames: getQuestionnaireNames.withParams({
        projectId,
      }),
    }),
    selectors: () => {
      const selectRecipient = RecipientSelect.one().whereIdEquals(
        selectRecipientId,
      );
      const selectActivity = ActivitySelect.one().whereIdEquals(
        getActiveActivityId,
      );
      const selectProjectId = createSelector(
        selectActivity,
        activity => activity && activity.projectId,
      );
      const selectProject = ProjectSelect.one().whereIdEquals(selectProjectId);
      const selectMilestoneId = createSelector(
        selectActivity,
        activity => activity && activity.milestoneId,
      );
      const selectMilestone = ProjectMilestoneSelect.one().whereIdEquals(
        selectMilestoneId,
      );
      const selectNotifications = NotificationSelect.all()
        .where({
          projectId: selectProjectId,
          type: NOTIFICATION_MANUAL_REMINDER,
        })
        .where(
          createSelector(
            selectActivity,
            selectAnswersSheets,
            (activity, answersSheets) => {
              return (notification) => {
                return notification.appliesToActivity(activity) &&
                  notification.appliesToAnswersSheets(answersSheets);
              };
            },
          ),
        );
      return {
        open: getMilestoneDialogVisible,
        activeKey: getMilestoneDialogActiveKey,
        projectId: selectProjectId,
        project: selectProject,
        milestoneId: selectMilestoneId,
        milestone: selectMilestone,
        recipient: selectRecipient,
        recipientId: selectRecipientId,
        activity: selectActivity,
        activityId: getActiveActivityId,
        answersSheets: selectAnswersSheets,
        title: createSelector(selectMilestone, (milestone) => {
          return milestone ? milestone.getName() : 'No Milestone';
        }),
        canSendReminder: createSelector(
          selectRecipient,
          selectNotifications,
          (recipient, notifications) => notifications.length > 0 &&
            recipient &&
            (recipient.getPhoneNumber() || recipient.getEmailAddress()),
        ),
      };
    },
  }),
  withModal(),
  withHandlers({
    onClose: ({
      dispatch,
    }) => () => dispatch(closeMilestoneDialog()),
    setActiveKey: ({
      dispatch,
    }) => key => dispatch(setMilestoneDialogActiveKey(key)),
    onPreview: ({
      dispatch,
      projectId,
    }) => answersSheetId => dispatch(
      openPreviewPatientResponseDialog({
        answersSheetId,
        projectId,
      }),
    ),
    onAddForms: ({
      dispatch,
    }) => () => dispatch(openAddAnswersSheetsDialog()),
    handleOnEdit: ({
      dispatch,
    }) => recipientId => dispatch(
      openEditPatientDialog({
        recipientId,
      }),
    ),
    // NOTE: We are not using it at the moment, but maybe it will be
    //       useful in the future.
    // handleCopyActivity: ({
    //   dispatch,
    //   modal,
    //   activityId,
    //   recipientId,
    //   milestoneId,
    // }) => ({
    //   state,
    //   dateStart,
    //   dateEnd,
    // }) => dispatch(
    //   callMethod(apiZedocCopyActivity, {
    //     activityId,
    //     state,
    //     dateStart,
    //     dateEnd,
    //     keepOriginal: false,
    //     keepOriginalResponses: true,
    //   }),
    // )
    //   .then((result) => {
    //     modal.cancel();
    //     dispatch(
    //       openMilestoneDialog({
    //         activityId: result.activityId,
    //         recipientId,
    //         milestoneId,
    //       }),
    //     );
    //   })
    //   .then(notifySuccess(getSuccessMessage(dateStart, dateEnd)))
    //   .catch(notifyError()),
    handleSendReminder: ({
      dispatch,
      activityId,
    }) => () => dispatch(
      callMethod(sendActivityReminder, {
        activityId,
      }),
    )
      .then(notifySuccess('Reminder sent'))
      .catch(notifyError()),
    handleSyncToPatientService: ({
      dispatch,
      activityId,
    }) => () => dispatch(
      callMethod(
        apiZedocSyncActivity,
        {
          activityId,
        },
        {
          noRetry: true,
        },
      ),
    )
      .then(
        notifySuccess(
          ({
            nRecords,
          }) => `${nRecords} records are now being synchronized - please wait`,
        ),
      )
      .catch(notifyError()),
  }),
)(
  ({
    open,
    onClose,
    onAddForms,
    title,
    answersSheets,
    userNames,
    questionnaireNames,
    recipient,
    project,
    milestone,
    onPreview,
    subscriptionsReady,
    queriesReady,
    // handleCopyActivity,
    modal,
    activity,
    activityId,
    handleOnEdit,
    handleSendReminder,
    canSendReminder,
    handleSyncToPatientService,
    activeKey,
    setActiveKey,
    dispatch,
  }) => {
    const canSeePII = usePermission([
      PATIENT_ACCESS_PATIENT_PII_VARIABLES,
    ], {
      relativeTo: recipient && recipient.getDomains(),
    });
    const canSyncToPatientService = usePermission(
      [
        PATIENT_MILESTONE_FORCE_SYNCHRONIZATION,
      ],
      {
        relativeTo: recipient && recipient.getDomains(),
      },
    );

    const {
      t,
    } = useTranslation();

    const handleShareLoginInfo = () => {
      modal.render(
        <ShareLoginInfo
          activityId={activityId}
          onClose={modal.cancel}
          open
        />,
      );
    };

    const dashboardsPerspectiveResponses = useSelector(
      selectAllPerspectiveResponsesDashboards,
    );
    const handleDashboardsPerspectiveResponsesPreview = ({
      type,
      presetNo,
      answersSheet,
    }) => {
      dispatch(closeMilestoneDialog());
      dispatch(
        openTab({
          type,
          presetNo,
          perspective: DASHBOARD_PERSPECTIVE__RESPONSES,
          projectId: answersSheet.getProjectId(),
          settings: {
            responseId: answersSheet._id,
          },
        }),
      );
    };

    const {
      recipientId,
      participationId,
    } = activity || {};

    const participation = useSelector(
      ParticipationSelect.one().whereIdEquals(participationId),
    );

    const bars = (
      <Menu>
        {true && (
          <Menu.Item
            data-testid="menu-item-settings"
            key="1"
            onClick={() => {
              dispatch(
                openEditActivityDialog({
                  activityId,
                  recipientId,
                  participationId,
                }),
              );
            }}
          >
            {t('settings')}
          </Menu.Item>
        )}
        <Menu.Item
          data-testid="menu-item-send-reminder"
          key="2"
          onClick={handleSendReminder}
          disabled={!canSendReminder}
        >
          {t('sendReminder')}
        </Menu.Item>
        <Menu.Item
          data-testid="menu-item-sync-to-patient-service"
          key="3"
          onClick={handleSyncToPatientService}
          disabled={!canSyncToPatientService}
        >
          {t('syncRecipientService', {
            context: branding,
          })}
        </Menu.Item>
      </Menu>
    );

    const canAddQuestionnaire = !!activity && activity.isStarted();
    const canStartInPatientApp =
      !!activity &&
      activity.isStarted() &&
      activity.state !== ACTIVITY_STATE__EXPIRED;
    const loading = !subscriptionsReady || !queriesReady;
    let fakeAnswersSheets = [];
    if (project && milestone && !activity.isStarted()) {
      fakeAnswersSheets = map(
        project.applyQuestionnaireDefaults(milestone.questionnaires),
        ({
          identifier,
          version,
          assigneeType,
        }, index) => {
          return new AnswersSheet({
            _id: `${index}`,
            questionnaireId: `${identifier}@${version}`,
            assigneeType,
            fake: true,
          });
        },
      );
    }
    return (
      <Modal
        title={(
          <>
            <Text.Span importance="high">{title}</Text.Span>
            {' '}
            -
            {' '}
            {canSeePII
              ? recipient && recipient.getFullName()
              : recipient && recipient.getTruncatedId()}
          </>
        )}
        visible={open}
        // confirmLoading={isConfirmLoading}
        onCancel={onClose}
        footer={(
          <>
            <Button
              data-testid="button-close-activity"
              onClick={onClose}
              hasBorder={false}
            >
              {t('cancel')}
            </Button>
            <Button
              data-testid="button-add-questionnaires"
              type="primary"
              onClick={onAddForms}
              disabled={!canAddQuestionnaire}
            >
              {t('addQuestionnaire', {
                count: 0,
              })}
            </Button>
            <Dropdown
              overlay={bars}
              trigger={[
                'click',
              ]}
            >
              <Button
                data-testid="button-more"
                hasBorder={false}
              >
                {t('more')}
              </Button>
            </Dropdown>
            <Divider.Vertical />
            <Button
              data-testid="button-share-login-info"
              icon={<LinkIcon />}
              onClick={handleShareLoginInfo}
              disabled={!canStartInPatientApp}
            />
          </>
        )}
        bodyPadding="0"
      >
        <Tabs
          activeKey={activeKey}
          onChange={key => setActiveKey(key)}
          tabs={[
            {
              value: 'questionnaires',
              label: t('questionnaire', {
                count: 0,
              }),
              content: (
                <FormsList
                  answersSheets={
                    isEmpty(answersSheets) ? fakeAnswersSheets : answersSheets
                  }
                  userNames={userNames}
                  questionnaireNames={questionnaireNames}
                  onPreview={onPreview}
                  loading={loading}
                  due={activity && activity.state === ACTIVITY_STATE__ACTIVE}
                  pastDue={
                    activity && activity.state === ACTIVITY_STATE__EXPIRED
                  }
                  activityDateEnd={activity && activity.dateEnd}
                  dashboardsPerspectiveResponses={
                    dashboardsPerspectiveResponses
                  }
                  onDashboardsPerspectiveResponsesPreview={
                    handleDashboardsPerspectiveResponsesPreview
                  }
                />
              ),
            },
            {
              value: 'patientDetails',
              label: t('recipientDetails', {
                context: branding,
              }),
              content: recipient && (
                <PatientDetails
                  onEdit={handleOnEdit}
                  recipient={recipient}
                  participations={[
                    participation,
                  ]}
                />
              ),
            },
            {
              value: 'activity',
              label: t('reminder', {
                count: 0,
              }),
              content: activityId && <RemindersList activityId={activityId} />,
            },
          ]}
        />
      </Modal>
    );
  },
);

ActivityDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default ActivityDialog;
