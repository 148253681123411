import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';
import React, {
  useMemo,
} from 'react';
import {
  useTranslation,
} from 'react-i18next';
import isSubset from '../../../common/utils/isSubset';
import FormFieldSelect from '../../forms/FormFieldSelect';

export const getPayload = (stateMachine, current, previous) => {
  const payload = [];
  const fieldName = stateMachine.coordinates[0].name;
  forEach(stateMachine.coordinates, ({
    name,
  }) => {
    if (name !== fieldName) {
      const currentValue = get(current, name);
      const previousValue = get(previous, name);
      if (currentValue !== previousValue) {
        payload.push(name);
      }
    }
  });
  return payload;
};

export const getNonEditableKeys = (stateMachine, payload, from, to) => {
  const fieldName = stateMachine.coordinates[0].name;
  return map(
    filter(stateMachine.coordinates, (coordinate) => {
      if (coordinate.name === fieldName) {
        return false;
      }
      return !some(stateMachine.transitions, (transition) => {
        if (transition.from !== from || transition.to !== to) {
          return false;
        }
        const allKeys = [
          ...payload,
          coordinate.name,
        ];
        return isSubset(allKeys, transition.payload);
      });
    }),
    'name',
  );
};

export const coincidesWithNonEditableKeys = (nonEditableKeys, key) => {
  return some(nonEditableKeys, (anotherKey) => {
    // NOTE: The last condition is probably too restrictive but I doubt this will be a practical issue
    //       because state coordinates will be top level fields in almost all situations that I can think of.
    return (
      anotherKey === key ||
      startsWith(key, `${anotherKey}.`) ||
      startsWith(anotherKey, `${key}.`)
    );
  });
};

const FormFieldState = React.forwardRef(
  ({
    stateMachine,
    previousState,
    payload,
    ...otherProps
  }, forwardedRef) => {
    const {
      t,
    } = useTranslation();
    const stateOptions = useMemo(() => {
      const fieldName = stateMachine.coordinates[0].name;
      const allowedStates = {};
      forEach(stateMachine.transitions, (transition) => {
        if (transition.from === previousState) {
          const {
            disabled = true,
          } = allowedStates[transition.to] || {};
          allowedStates[transition.to] = {
            disabled: disabled && !isSubset(payload, transition.payload),
          };
        }
      });
      return compact(
        map(stateMachine.states, (state) => {
          const value = state[fieldName];
          if (value === previousState || allowedStates[value]) {
            return {
              disabled: allowedStates[value]
                ? allowedStates[value].disabled
                : true,
              label: t(
                `stateMachines:${
                  stateMachine.modelName
                }.states.${value.toLowerCase()}`,
              ),
              value,
            };
          }
          return null;
        }),
      );
    }, [
      t,
      payload,
      previousState,
      stateMachine.coordinates,
      stateMachine.modelName,
      stateMachine.states,
      stateMachine.transitions,
    ]);

    const extra = useMemo(() => {
      const transitions = filter(stateMachine.transitions, (transition) => {
        if (
          previousState !== transition.from ||
          otherProps.input.value !== transition.to
        ) {
          return false;
        }
        return isSubset(payload, transition.payload);
      });
      if (!isEmpty(transitions)) {
        const previousStateLabel = t(
          `stateMachines:${
            stateMachine.modelName
          }.states.${previousState.toLowerCase()}`,
        );
        return `${t('forms:currentState.label')}: ${previousStateLabel}, ${t(
          'forms:transition.label',
          {
            count: transitions.length,
          },
        )}: ${map(transitions, (transition) => {
          return t(
            `stateMachines:${stateMachine.modelName}.transitions.${transition.actionType}`,
          );
        }).join(', ')}`;
      }
      return null;
    }, [
      t,
      payload,
      previousState,
      otherProps.input.value,
      stateMachine.modelName,
      stateMachine.transitions,
    ]);

    return (
      <FormFieldSelect
        ref={forwardedRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        options={stateOptions}
        extra={extra}
      />
    );
  },
);

FormFieldState.propTypes = {
  previousState: PropTypes.string,
  stateMachine: PropTypes.shape({
    modelName: PropTypes.string.isRequired,
    states: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
    coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    transitions: PropTypes.arrayOf(
      PropTypes.shape({
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ),
  }).isRequired,
  payload: PropTypes.arrayOf(PropTypes.string),
};

FormFieldState.defaultProps = {
  previousState: null,
  payload: null,
};

export default FormFieldState;
