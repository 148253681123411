/**
 * Created by apendua on 2017-12-19
 */

import has from 'lodash/has';
import isNaN from 'lodash/isNaN';
import omit from 'lodash/omit';
import indexOf from 'lodash/indexOf';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

export const splitKey = (key) => {
  if (typeof key !== 'string') {
    return [
      null,
      null,
    ];
  }
  const i = key.indexOf('.');
  if (i >= 0) {
    return [
      key.substr(0, i),
      key.substr(i + 1),
    ];
  }
  return [
    key,
    null,
  ];
};

export const replaceElementAt = (array, i, v) => {
  if (i >= 0 && i <= array.length) {
    if (v !== array[i]) {
      return [
        ...array.slice(0, i),
        v,
        ...array.slice(i + 1),
      ];
    }
  }
  return array;
};

export const createModifyAtKey = (modify) => {
  const modifyAtKey = (object, key, value, options) => {
    if (!key) {
      return modify(object, value, options);
    }
    const [
      k,
      tail,
    ] = splitKey(key);
    if (isNil(object)) {
      if (options && options.allowArrayCreation) {
        const i = +k;
        if (!isNaN(i)) {
          const array = [];
          array[i] = modifyAtKey(null, tail, value, options);
          return array;
        }
      }
      return {
        [k]: modifyAtKey(null, tail, value, options),
      };
    }
    if (isArray(object)) {
      const i = +k;
      if (!isNaN(i) && i >= 0 && i <= object.length) {
        return replaceElementAt(
          object,
          i,
          modifyAtKey(object[i], tail, value, options),
        );
      }
    } else if (isPlainObject(object)) {
      const v = modifyAtKey(object[k], tail, value, options);
      if (v !== object[k]) {
        return {
          ...object,
          [k]: v,
        };
      }
    }
    return object;
  };
  return modifyAtKey;
};

export const setAtKey = createModifyAtKey((currentValue, newValue) => newValue);
export const incAtKey = createModifyAtKey(
  (currentValue, newValue) => (currentValue || 0) + newValue,
);

export const pushAtKey = createModifyAtKey((currentValue, valueToAdd) => {
  if (isArray(currentValue)) {
    return [
      ...currentValue,
      valueToAdd,
    ];
  }
  if (isNil(currentValue)) {
    return [
      valueToAdd,
    ];
  }
  return currentValue;
});

export const pullAtKey = createModifyAtKey(
  (currentValue, valueToRemove, options) => {
    if (!isArray(currentValue)) {
      return currentValue;
    }
    const i = indexOf(currentValue, valueToRemove);
    if (i >= 0) {
      const newValue = [
        ...currentValue.slice(0, i),
        ...currentValue.slice(i + 1),
      ];
      if (has(options, 'onEmpty') && isEmpty(newValue)) {
        return options.onEmpty;
      }
      return newValue;
    }
    return currentValue;
  },
);

export const pullWhereAtKey = createModifyAtKey(
  (currentValue, valueToRemove, options) => {
    if (!isArray(currentValue)) {
      return currentValue;
    }
    const i = findIndex(currentValue, valueToRemove);
    if (i >= 0) {
      const newValue = [
        ...currentValue.slice(0, i),
        ...currentValue.slice(i + 1),
      ];
      if (has(options, 'onEmpty') && isEmpty(newValue)) {
        return options.onEmpty;
      }
      return newValue;
    }
    return currentValue;
  },
);

export const delAtKey = (object, key, options) => {
  if (!key) {
    return options && options.defaultValue;
  }
  if (!object) {
    return object;
  }
  const [
    k,
    tail,
  ] = splitKey(key);
  if (!has(object, k)) {
    return object;
  }
  const value = tail ? delAtKey(object[k], tail, options) : null;
  if (tail && (!options || !options.cascade || !isEmpty(value))) {
    // replace
    if (isArray(object)) {
      const i = +k;
      if (!isNaN(i) && i >= 0 && i < object.length) {
        return [
          ...object.slice(0, i),
          value,
          ...object.slice(i + 1),
        ];
      }
    } else if (isPlainObject(object)) {
      return {
        ...object,
        [k]: value,
      };
    }
  } else if (isArray(object)) {
    // remove
    const i = +k;
    if (!isNaN(i) && i >= 0 && i < object.length) {
      return [
        ...object.slice(0, i),
        ...object.slice(i + 1),
      ];
    }
  } else if (isPlainObject(object)) {
    return omit(object, k);
  }
  return object;
};
