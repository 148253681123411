import {
  SORTER_TYPE__PROPERTY,
  SORTING_ORDER__DESCENDING,
} from '../../../../common/constants';
import store from '../../store';

const constant = x => () => x;

export const createGetSorter = () => constant({
  type: SORTER_TYPE__PROPERTY,
  settings: {
    id: 'completedAt',
  },
  order: SORTING_ORDER__DESCENDING,
});

export const createGetFilters = storeKey => store.get(`${storeKey}`);
export const createSetFilters = storeKey => store.create.set(`${storeKey}`);
