import React, {
  useCallback,
} from 'react';
import map from 'lodash/map';
import omit from 'lodash/omit';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useTranslation,
} from 'react-i18next';
import {
  ADMIN_SEARCH_PROJECT,
} from '../../common/permissions';
import ProjectSelect from '../../common/selectors/Project';
import Cluster from '../../common/components/primitives/Cluster';
import Spacer from '../../common/components/primitives/Spacer';
import Button from '../../common/components/Button';
import Stack from '../../common/components/primitives/Stack';
import Sidebar from '../../common/components/primitives/Sidebar';
import Box from '../../common/components/primitives/Box';
import List from '../../common/components/List';
import Text from '../../common/components/base/Text';
import Search from '../../components/inputs/Search';
import {
  apiAdminSearchProjects,
} from '../../common/api/admin';
import DialogCreateProject, {
  dialog as dialogCreateProject,
} from './components/DialogCreateProject';
import DialogArchiveProject, {
  dialog as dialogArchiveProject,
} from './components/DialogArchiveProject';
import ProjectItem from './components/ProjectItem';
import useDocumentTitle from '../../utils/useDocumentTitle';
import usePagination from '../../utils/usePagination';
import usePermission from '../../utils/usePermission';
import useHighlight from '../../utils/useHighlight';
import {
  getFilters,
  getSorter,
} from './store';

const SettingsProjects = React.memo(() => {
  const filters = useSelector(getFilters);
  const sorter = useSelector(getSorter);
  const canCreateProjects = usePermission([
    ADMIN_SEARCH_PROJECT,
  ]);
  const {
    t,
  } = useTranslation();
  useDocumentTitle([
    t('settings'),
    t('project', {
      count: 0,
    }),
  ]);
  const {
    ready: subscriptionsReady,
    items: projects,
    totalItems,
    pageSize,
    renderPagination,
  } = usePagination({
    debounceMs: 1000,
    selector: ProjectSelect,
    getSubscription: (currentPage, resultsPerPage) => apiAdminSearchProjects.withParams({
      filters: map(filters, filter => omit(filter, 'meta')),
      sorter,
      controlId: '$meta.id',
      pageIndex: currentPage - 1,
      resultsPerPage,
    }),
  });
  const dispatch = useDispatch();
  const handleAddProject = useCallback(
    () => dispatch(dialogCreateProject.open({})),
    [
      dispatch,
    ],
  );
  const handleDeleteProject = useCallback(
    projectId => dispatch(
      dialogArchiveProject.open({
        projectId,
      }),
    ),
    [
      dispatch,
    ],
  );
  const highlight = useHighlight(filters, [
    'name',
  ]);
  return (
    <>
      <DialogCreateProject />
      <DialogArchiveProject />
      <Stack>
        <Sidebar
          sidebar={(
            <Button
              data-testid="button-add-project"
              type="primary"
              onClick={handleAddProject}
              disabled={!canCreateProjects}
            >
              {t('createProject')}
            </Button>
          )}
        >
          <Search />
        </Sidebar>
        <Spacer>
          <Cluster justify="space-between">
            <Text.Paragraph
              data-testid="text-total-projects"
              importance="high"
            >
              {t('total')}
              &nbsp;
              {totalItems}
            </Text.Paragraph>
            {renderPagination()}
          </Cluster>
        </Spacer>
        <Box.Primary>
          <List
            data-testid="list-projects"
            dataSource={projects}
            loading={!subscriptionsReady}
            pageSize={pageSize}
            renderItem={project => (
              <ProjectItem
                key={project._id}
                projectId={project._id}
                name={project.name}
                description={project.description}
                onDelete={handleDeleteProject}
                highlight={highlight}
              />
            )}
          />
        </Box.Primary>
      </Stack>
    </>
  );
});

export default SettingsProjects;
